// screens/FinalScreen.jsx
import React from "react";
import { Box, Typography, Card, Radio } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";

const OptionCard = styled(Card)(({ selected }) => ({
  padding: "14px 20px", // Reduced padding
  cursor: "pointer",
  borderRadius: "12px",
  border: "2px solid",
  borderColor: selected ? "#4F46E5" : "#E5E7EB",
  backgroundColor: selected ? "rgba(79, 70, 229, 0.03)" : "white",
  transition: "all 0.15s ease", // Faster transition
  boxShadow: "none",
  width: "100%",
  maxWidth: "500px",
  margin: "0 auto 10px", // Reduced margin
  "&:hover": {
    borderColor: selected ? "#4F46E5" : "#4F46E5",
    backgroundColor: selected
      ? "rgba(79, 70, 229, 0.03)"
      : "rgba(79, 70, 229, 0.02)",
  },
}));

const RadioOption = styled(Radio)({
  color: "#E5E7EB",
  "&.Mui-checked": {
    color: "#4F46E5",
  },
});

const GridContainer = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(220px, 1fr))",
  gap: "10px",
  maxWidth: "700px",
  margin: "20px auto 0",
  width: "100%",
});

const FinalScreen = ({ userData, setUserData }) => {
  const sources = [
    { value: "twitter", label: "Twitter" },
    { value: "quora", label: "Quora" },
    { value: "linkedin", label: "LinkedIn" },
    { value: "instagram", label: "Instagram" },
    { value: "tiktok", label: "TikTok" },
    { value: "reddit", label: "Reddit" },
    { value: "google", label: "Google" },
    { value: "other", label: "Other" },
  ];

  const handleSourceChange = (value) => {
    setUserData((prev) => ({
      ...prev,
      source: value,
    }));
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.03, // Reduced from 0.05 for faster appearance
        duration: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 5 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
        mt: 2, // Reduced from 4
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }} // Faster animation
        style={{ width: "100%" }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: { xs: "28px", md: "32px" },
            fontWeight: 600,
            color: "#111827",
            textAlign: "center",
            mb: 3, // Reduced from 4
          }}
        >
          Where did you hear about LazyApply?
        </Typography>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <GridContainer>
            {sources.map((source) => (
              <motion.div key={source.value} variants={itemVariants}>
                <OptionCard
                  selected={userData.source === source.value}
                  onClick={() => handleSourceChange(source.value)}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <RadioOption
                      checked={userData.source === source.value}
                      onChange={() => handleSourceChange(source.value)}
                    />
                    <Typography
                      sx={{
                        ml: 1,
                        fontSize: "16px",
                        fontWeight:
                          userData.source === source.value ? 600 : 400,
                      }}
                    >
                      {source.label}
                    </Typography>
                  </Box>
                </OptionCard>
              </motion.div>
            ))}
          </GridContainer>
        </motion.div>
      </motion.div>
    </Box>
  );
};

export default FinalScreen;
