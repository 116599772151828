// components/sections/Achievements/ViewMode.jsx
import React from "react";
import { Box, Typography, Stack } from "@mui/material";

const ViewMode = ({ achievements }) => {
  if (achievements.length === 0) {
    return (
      <Typography
        sx={{
          color: "#666666",
          fontStyle: "italic",
          textAlign: "center",
          py: 4,
        }}
      >
        No achievements added yet.
      </Typography>
    );
  }

  return (
    <Stack spacing={3}>
      {achievements.map((achievement, index) => (
        <Box
          key={index}
          sx={{
            p: 2.5,
            backgroundColor: "#F8F9FA",
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#2D2D2D",
              fontSize: "17px",
              fontWeight: 600,
              mb: 1,
            }}
          >
            {achievement.awardTitle}
          </Typography>

          <Typography
            sx={{
              color: "#2D2D2D",
              fontSize: "15px",
              fontWeight: 500,
              mb: 0.5,
            }}
          >
            {achievement.issuer}
            {achievement.issuingDate && ` • ${achievement.issuingDate}`}
          </Typography>

          <Typography
            sx={{
              color: "#666666",
              fontSize: "14px",
              whiteSpace: "pre-wrap",
            }}
          >
            {achievement.description}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};

export default ViewMode;
