import React from "react";
import { Box, Container, Typography, Button, Stack } from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RatingProof from "./RatingProof";
import SettingsIcon from "@mui/icons-material/Settings";

const gradientAnimation = keyframes`
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
`;

// Background with subtle animated gradient
const GradientBackground = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: "linear-gradient(135deg, #F0F8FF 0%, #F5FAFF 50%, #F8FCFF 100%)",
  backgroundSize: "200% 200%",
  animation: `${gradientAnimation} 5s ease infinite`,
  opacity: 0.9, // A bit more visible
});

// Animated gradient text
const MainText = styled(Typography)({
  color: "#2d2d2d",
  fontWeight: 600,
});

const AIAgentText = styled("span")({
  background:
    "linear-gradient(135deg, #4C52F3, #5A64F5, #6B38FA, #A435FA, #9B3EFA, #D01FFF)",
  textShadow: "0px 0px 1px rgba(102, 103, 241, 0.1)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    inset: -2,
    background: "inherit",
    opacity: 0.3, // Reduced opacity
    zIndex: -1,
  },
});

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg) scale(1);
  }
  to {
    transform: rotate(360deg) scale(1.2);
  }
`;

const Hero = () => {
  return (
    <Box
      sx={{
        minHeight: "80vh",
        display: "flex",
        alignItems: "center",
        pb: 8,
        pt: 10,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <GradientBackground />
      <Box sx={{ position: "relative", zIndex: 1, width: "100%" }}>
        <Container maxWidth="lg">
          <Stack
            alignItems="center"
            sx={{
              textAlign: "center",
              "& > .headlines": {
                mb: 4,
              },
              "& > .sub-headline": {
                mb: 6,
              },
            }}
          >
            {/* Main Headlines */}
            <Stack spacing={2} className="headlines">
              {" "}
              {/* Spacing between main headlines */}
              {/* <Typography
              variant="h1"
              sx={{
                fontSize: { xs: "3rem", md: "4.2rem" },
                fontWeight: 700,
                color: "#2d2d2d",
                lineHeight: 1.1,
              }}
            >
              LazyApply
            </Typography> */}
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "2rem", md: "3.2rem" },
                  fontWeight: 600,
                  color: "#000",
                  lineHeight: 1,
                }}
              >
                <MainText variant="h2">
                  Your <AIAgentText>Job Search</AIAgentText> AI Agent
                </MainText>
              </Typography>
            </Stack>

            {/* Sub-headline */}
            <Typography
              variant="h5"
              className="sub-headline"
              sx={{
                color: "#2d2d2d",
                maxWidth: "800px",
                fontSize: { xs: "1.1rem", md: "1.35rem" },
                lineHeight: 1.5,
                mb: 0, // Extra margin before buttons
              }}
            >
              Join thousands of smart job seekers saving 20+ hours weekly using
              LazyApply. Don't miss out on opportunities
            </Typography>

            {/* Add RatingProof here */}
            <RatingProof />

            {/* CTA Buttons */}
            <Stack direction="row" spacing={4} sx={{ mt: 4 }}>
              <Button
                variant="contained"
                disableRipple
                startIcon={
                  <SettingsIcon
                    sx={{
                      height: "24px",
                      width: "24px",
                      transition: "all 0.3s ease-in-out",
                      "button:hover &": {
                        height: "28px",
                        width: "28px",
                        animation: `${rotateAnimation} 0.8s ease-in-out infinite`,
                      },
                    }}
                  />
                }
                sx={{
                  bgcolor: "#2d2d2d",
                  color: "#ffffff",
                  px: 4,
                  py: 1.5,
                  fontSize: "1.2rem",
                  fontWeight: 550,
                  borderRadius: "8px",
                  textTransform: "none",
                  display: "flex",
                  alignItems: "center",
                  gap: "2px",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    bgcolor: "#404040",
                    transform: "translateY(-2px)",
                    boxShadow: "0 6px 20px rgba(0,0,0,0.5)",
                  },
                }}
              >
                Get Started
              </Button>

              <Button
                variant="outlined"
                size="large"
                disableRipple
                startIcon={
                  <PlayArrowIcon
                    sx={{
                      width: "30px",
                      height: "30px",
                    }}
                  />
                }
                sx={{
                  color: "#666666",
                  borderColor: "#666666",
                  px: 3,
                  py: 1.2,
                  fontSize: "1.2rem",
                  fontWeight: 550,
                  textTransform: "none",
                  border: "1.5px solid rgba(45, 45, 45, 0.2)",
                  borderRadius: "8px",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    border: "1.5px solid #2d2d2d",
                    transform: "translateY(-2px)",
                    backgroundColor: "rgba(45, 45, 45, 0.02)",
                    boxShadow: "0 6px 20px rgba(0,0,0,0.25)",
                  },
                }}
              >
                Watch Demo
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

export default Hero;
