// components/sections/WorkExperience/EditMode.jsx
import React from "react";
import {
  Grid,
  Box,
  TextField,
  Button,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    "&:hover fieldset": {
      borderColor: "#2D2D2D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2D2D2D",
      borderWidth: "1px",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E0E0E0",
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: "#2D2D2D",
    },
  },
};

const FormTextField = React.memo(
  ({ label, value, onChange, onBlur, error, helperText, ...props }) => (
    <TextField
      fullWidth
      label={label}
      value={value || ""}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      variant="outlined"
      sx={textFieldStyles}
      {...props}
    />
  )
);

const ExperienceForm = ({
  experience,
  index,
  errors = {},
  touched = {},
  onChange,
  onBlur,
  onDelete,
}) => {
  const handleChange = (field) => (event) => {
    const value =
      field === "currentlyWorkHere" ? event.target.checked : event.target.value;
    onChange(index, {
      ...experience,
      [field]: value,
    });
  };

  const handleBlur = (field) => () => {
    onBlur(index, field);
  };

  return (
    <Box
      sx={{
        mb: 4,
        pb: 4,
        borderBottom: "1px solid #E0E0E0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          mb: 2,
        }}
      >
        <Typography variant="h6" sx={{ color: "#2D2D2D" }}>
          Experience {index + 1}
        </Typography>
        <IconButton
          onClick={() => onDelete(index)}
          sx={{
            color: "#666666",
            "&:hover": {
              color: "#FF7875",
            },
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>

      <Grid container spacing={2.5}>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="Job Title"
            value={experience.jobTitle}
            onChange={handleChange("jobTitle")}
            onBlur={handleBlur("jobTitle")}
            error={touched?.jobTitle && !!errors?.jobTitle}
            helperText={touched?.jobTitle && errors?.jobTitle}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="Company"
            value={experience.company}
            onChange={handleChange("company")}
            onBlur={handleBlur("company")}
            error={touched?.company && !!errors?.company}
            helperText={touched?.company && errors?.company}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            label="Location"
            value={experience.workLocation}
            onChange={handleChange("workLocation")}
            onBlur={handleBlur("workLocation")}
            error={touched?.workLocation && !!errors?.workLocation}
            helperText={touched?.workLocation && errors?.workLocation}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="Start Date"
            type="month"
            value={experience.startDate}
            onChange={handleChange("startDate")}
            onBlur={handleBlur("startDate")}
            error={touched?.startDate && !!errors?.startDate}
            helperText={touched?.startDate && errors?.startDate}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="End Date"
            type="month"
            value={experience.endDate}
            onChange={handleChange("endDate")}
            onBlur={handleBlur("endDate")}
            error={touched?.endDate && !!errors?.endDate}
            helperText={touched?.endDate && errors?.endDate}
            disabled={experience.currentlyWorkHere}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={experience.currentlyWorkHere}
                onChange={handleChange("currentlyWorkHere")}
              />
            }
            label="I currently work here"
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            label="Description"
            value={experience.description}
            onChange={handleChange("description")}
            onBlur={handleBlur("description")}
            error={touched?.description && !!errors?.description}
            helperText={touched?.description && errors?.description}
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const EditMode = ({
  experiences,
  errors,
  touched,
  onChange,
  onBlur,
  onAdd,
  onDelete,
}) => {
  const handleFieldChange = (index, updatedExperience) => {
    const newExperiences = [...experiences]; // Create new array from current experiences
    newExperiences[index] = updatedExperience; // Update specific experience
    onChange(newExperiences); // Pass complete new array to parent
  };

  return (
    <Box>
      {experiences.map((experience, index) => (
        <ExperienceForm
          key={index}
          experience={experience}
          index={index}
          errors={errors[index]}
          touched={touched[index]}
          onChange={handleFieldChange}
          onBlur={onBlur}
          onDelete={onDelete}
        />
      ))}

      <Button
        startIcon={<AddIcon />}
        onClick={onAdd}
        sx={{
          mt: 2,
          color: "#2D2D2D",
          borderRadius: "8px",
          padding: "8px 16px",
          borderColor: "#2D2D2D",
          "&:hover": {
            borderColor: "#2D2D2D",
            backgroundColor: "rgba(45, 45, 45, 0.04)",
          },
        }}
        variant="outlined"
      >
        Add Another Experience
      </Button>
    </Box>
  );
};

export default EditMode;
