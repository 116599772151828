// DetailedView/ResumeModal.jsx
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from "@mui/material";

// Using the same sample data structure
const sampleExperience = [
  {
    title: "[Company Name Redacted] • Senior Software Engineer",
    description:
      "Led the development of scalable microservices architecture, improving system performance by 40%. Mentored junior developers and implemented best practices across the team.",
  },
  {
    title: "[Company Name Redacted] • Software Engineer",
    description:
      "Developed and maintained critical features for the main product. Collaborated with cross-functional teams to deliver high-impact projects on schedule.",
  },
  {
    title: "[Company Name Redacted] • Junior Developer",
    description:
      "Contributed to frontend development using React and TypeScript. Participated in code reviews and implemented unit tests for key functionalities.",
  },
];

const skills =
  "React • Node.js • Python • AWS • Docker • Kubernetes • GraphQL • TypeScript • MongoDB • Redis • CI/CD • System Design";

const ResumeModal = ({ open, onClose, data }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          minHeight: "70vh",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Resume Details
        </Typography>
      </DialogTitle>

      <DialogContent dividers>
        {/* Experience Section */}
        <Box mb={4}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              color: "text.secondary",
              mb: 2,
            }}
          >
            Experience
          </Typography>
          {data.resumeData.workExperience.map((exp, index) => (
            <Box
              key={index}
              sx={{
                p: 2,
                bgcolor: "grey.50",
                borderRadius: 1,
                mb: 2,
                "&:last-child": { mb: 0 },
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "text.primary", mb: 1, fontSize: "15px" }}
              >
                {exp.title}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: "text.secondary",
                  display: "block",
                  lineHeight: 1.5,
                  fontSize: "14px",
                }}
              >
                {exp.description}
              </Typography>
            </Box>
          ))}
        </Box>

        {/* Skills Section */}
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              color: "text.secondary",
              mb: 2,
            }}
          >
            Skills & Technologies
          </Typography>
          <Box
            sx={{
              p: 2,
              bgcolor: "grey.50",
              borderRadius: 1,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: "text.secondary",
                lineHeight: 1.5,
                display: "block",
                fontSize: "14px",
              }}
            >
              {data.resumeData.skills.join(" • ")}
            </Typography>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 2.5, borderTop: 1, borderColor: "grey.100" }}>
        <Button
          onClick={onClose}
          variant="contained"
          sx={{
            textTransform: "none",
            borderRadius: "8px",
            padding: "6px 24px",
            color: "#fff",
            bgcolor: "#2d2d2d",
            fontSize: "16px",
            "&:hover": { bgcolor: "#404040" },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResumeModal;
