import { linkedinFilters } from "./filters/linkedinFilters";
import { indeedFilters } from "./filters/indeedFilters";
import { glassdoorFilters } from "./filters/glassdoorFilters";
import { diceFilters } from "./filters/diceFilters";
import { careerBuilderFilters } from "./filters/careerBuilderFilters";
import { simplyHiredFilters } from "./filters/simplyhiredFilters";
import { ziprecruiterFilters } from "./filters/ziprecruiterFilters";
import { jobCountFilter } from "./JobCountSelector";

const profileFilter = {
  profileSelector: {
    label: "Select Profile",
    type: "profile",
    options: [],
  },
};

const locationFilter = {
  location: {
    label: "Location",
    type: "location",
    placeholder: "Enter city, state, or country",
    suggestions: [
      "Remote",
      "New York",
      "San Francisco",
      "London",
      "Austin",
      "Seattle",
    ],
  },
};

export const platformFilters = {
  all: {
    // Default filters shown when no specific platform is selected
    ...profileFilter,
    ...locationFilter,
    jobType: {
      label: "Job Type",
      options: [
        { label: "Full-time", value: "fulltime" },
        { label: "Part-time", value: "parttime" },
        { label: "Contract", value: "contract" },
        { label: "Internship", value: "internship" },
      ],
    },
    workType: {
      label: "Work Type",
      options: [
        { label: "Remote", value: "remote" },
        { label: "Hybrid", value: "hybrid" },
        { label: "On-site", value: "onsite" },
      ],
    },
    experienceLevel: {
      label: "Experience Level",
      options: [
        { label: "Entry Level", value: "Entry Level" },
        { label: "Associate", value: "Associate" },
        { label: "Executive", value: "Executive" },
      ],
    },
  },

  linkedin: {
    // jobCount: jobCountFilter,
    ...profileFilter,
    ...locationFilter,
    ...linkedinFilters,
  },
  indeed: {
    // jobCount: jobCountFilter,
    ...profileFilter,
    ...locationFilter,
    ...indeedFilters,
  },
  glassdoor: {
    // jobCount: jobCountFilter,
    ...profileFilter,
    ...locationFilter,
    ...glassdoorFilters,
  },
  dice: {
    // jobCount: jobCountFilter,
    ...profileFilter,
    ...locationFilter,
    ...diceFilters,
  },
  careerbuilder: {
    // jobCount: jobCountFilter,
    ...profileFilter,
    ...locationFilter,
    ...careerBuilderFilters,
  },
  simplyhired: {
    // jobCount: jobCountFilter,
    ...profileFilter,
    ...locationFilter,
    ...simplyHiredFilters,
  },
  ziprecruiter: {
    // jobCount: jobCountFilter,
    ...profileFilter,
    ...locationFilter,
    ...ziprecruiterFilters,
  },
};

// Helper function to get filters for a specific platform
export const getFiltersForPlatform = (platform) => {
  return platformFilters[platform] || platformFilters.all;
};

// Helper function to reset filters when platform changes
export const getEmptyFiltersForPlatform = (platform) => {
  const platformFilterSet = platformFilters[platform] || platformFilters.all;
  const emptyFilters = {};

  Object.keys(platformFilterSet).forEach((filterKey) => {
    // If the filter has a value property (like in CareerBuilder), preserve it
    if (platformFilterSet[filterKey].value) {
      emptyFilters[filterKey] = {
        value: platformFilterSet[filterKey].value,
        selected: "",
      };
    } else {
      emptyFilters[filterKey] = "";
    }
  });

  return emptyFilters;
};

// Helper function to check if a platform's filters are different from default
export const hasPlatformSpecificFilters = (platform) => {
  return platform !== "all" && platformFilters[platform];
};

export const shouldShowProfileSelector = (platform) => {
  return platform !== "all" && platformFilters[platform]?.profileSelector;
};
