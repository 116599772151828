import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  RadioGroup,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PersonIcon from "@mui/icons-material/Person";
import { useStateStore } from "../../../store";
import axios from "axios";

const TeamTypeModal = ({ open, handleClose, onSelectTeamType }) => {
  const { token } = useStateStore();
  const [selectedType, setSelectedType] = useState(null);

  const handleSubmit = async () => {
    if (!selectedType) return;

    try {
      const response = await axios.post(
        `https://backend.lazyapply.com/lazyapplyV2/update-model-name`,
        { modelName: selectedType },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        onSelectTeamType(selectedType);
        handleClose();
      } else {
        console.log("some error occured while updating team type");
      }
    } catch (error) {
      console.error("Error updating team type:", error);
    }
  };

  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "16px",
          p: 2,
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.12)",
        },
      }}
    >
      <DialogTitle sx={{ px: 3, pt: 3, pb: 1 }}>
        <Typography variant="h5" fontWeight={600}>
          How will your team use LazyApply?
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mt: 1, fontSize: "16px", mb: 4 }}
        >
          Choose the option that best suits your organization's needs
        </Typography>
        {/* <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: "grey.500",
          }}
        >
          <CloseIcon />
        </IconButton> */}
      </DialogTitle>

      <DialogContent sx={{ px: 3, py: 2 }}>
        <RadioGroup value={selectedType}>
          <Box
            sx={{
              border: "2px solid", // Thicker border to make selection more obvious
              borderColor: selectedType === "agency" ? "#2d2d2d" : "#e0e0e0",
              borderRadius: "12px",
              p: 3,
              mb: 3,
              cursor: "pointer",
              transition: "all 0.3s ease",
              backgroundColor:
                selectedType === "agency"
                  ? "rgba(45, 45, 45, 0.04)"
                  : "transparent",
              "&:hover": {
                borderColor: "#2d2d2d",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
              },
              position: "relative",
            }}
            onClick={() => setSelectedType("agency")}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <SupervisorAccountIcon
                fontSize="large"
                sx={{ mr: 2, color: "#2d2d2d" }}
              />
              <Typography variant="h6" fontWeight={600}>
                Operator-Managed Applications
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ ml: 6, fontSize: "15px" }}>
              Your team members will act as operators who apply for jobs on
              behalf of users
            </Typography>
            <Box sx={{ mt: 2, ml: 6 }}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "14px" }}
              >
                • Operators manage multiple user accounts
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "14px" }}
              >
                • Centralized control of job applications
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "14px" }}
              >
                • Ideal for recruitment agencies and HR teams
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              border: "2px solid",
              borderColor: selectedType === "school" ? "#2d2d2d" : "#e0e0e0",
              borderRadius: "12px",
              p: 3,
              cursor: "pointer",
              transition: "all 0.3s ease",
              backgroundColor:
                selectedType === "school"
                  ? "rgba(45, 45, 45, 0.04)"
                  : "transparent",
              "&:hover": {
                borderColor: "#2d2d2d",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
              },
              position: "relative",
            }}
            onClick={() => setSelectedType("school")}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <PersonIcon fontSize="large" sx={{ mr: 2, color: "#2d2d2d" }} />
              <Typography variant="h6" fontWeight={600}>
                Direct User Access
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ ml: 6, fontSize: "15px" }}>
              Users will apply for jobs themselves using their own accounts
            </Typography>
            <Box sx={{ mt: 2, ml: 6 }}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "14px" }}
              >
                • Each user manages their own job search
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "14px" }}
              >
                • Users have individual access to the platform
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "14px" }}
              >
                • Ideal for institutions providing job search tools to students
              </Typography>
            </Box>
          </Box>
        </RadioGroup>
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 3, pt: 1, justifyContent: "flex-end" }}>
        {/* <Button
          variant="outlined"
          onClick={handleClose}
          sx={{
            borderRadius: "12px",
            padding: "8px 24px",
            fontSize: "16px",
            textTransform: "none",
            fontWeight: 600,
            borderColor: "#e0e0e0",
            color: "#2d2d2d",
            "&:hover": {
              borderColor: "#2d2d2d",
              backgroundColor: "rgba(45, 45, 45, 0.04)",
            },
          }}
        >
          Cancel
        </Button> */}
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!selectedType}
          sx={{
            borderRadius: "12px",
            padding: "8px 24px",
            fontSize: "16px",
            textTransform: "none",
            fontWeight: 600,
            backgroundColor: "#2d2d2d",
            "&:hover": {
              backgroundColor: "#404040",
            },
            "&.Mui-disabled": {
              backgroundColor: "#e0e0e0",
              color: "#9e9e9e",
            },
          }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeamTypeModal;
