// components/ProfileOverview.jsx
import React, { useState } from "react";
import {
  Paper,
  Box,
  Typography,
  LinearProgress,
  Button,
  Grid,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DownloadIcon from "@mui/icons-material/Download";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ResumeUploadModal from "../ResumeUploadModal";
import { useStateStore } from "../../../../store";
import axios from "axios";

const OverviewCard = styled(Paper)(() => ({
  borderRadius: "16px",
  padding: "24px",
  marginBottom: "24px",
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
}));

const StyledProgress = styled(LinearProgress)(({ value }) => ({
  height: 8,
  borderRadius: 4,
  backgroundColor: "#F0F0F0",
  "& .MuiLinearProgress-bar": {
    borderRadius: 4,
    background:
      value <= 33
        ? "linear-gradient(90deg, #ff6666 0%, #ff9966 100%)"
        : value <= 66
        ? "linear-gradient(90deg, #ff9966 0%, #ffc107 100%)"
        : "linear-gradient(90deg, #4bce88 0%, #35a347 100%)",
    transition: "all 0.3s ease",
  },
}));

const ProfileOverview = ({
  completion = 0,
  profileTitle = "Developer",
  onExpandAll,
  isAllExpanded,
  onDownloadResume,
  onReplaceResume,
  resumeMetaInfo,
  setFileDetails,
}) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const { userData, token } = useStateStore();

  const handleUpload = async (file) => {
    console.log("Uploading file:", file, resumeMetaInfo);
    // setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("resumeName", resumeMetaInfo.resumeName);
      formData.append("resumeId", resumeMetaInfo.resumeId);
      formData.append("file", file);
      const v2Id = userData?.v2Id || "";
      let url = `https://backend.lazyapply.com/lazyapplyV2/resume/replace/${v2Id}`;
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        console.log("response", response);
        if (response?.data?.data?.mainFileDetails)
          setFileDetails(response.data.data.mainFileDetails);

        return Promise.resolve();
      } else {
        return Promise.reject(
          new Error(response.data.message || "Failed to replace resume")
        );
      }
    } catch (error) {
      console.error("Error replacing resume:", error);
      return Promise.reject(error);
    }
    // } finally {
    //   // setIsLoading(false);
    // }
  };

  return (
    <>
      <OverviewCard>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            mb: 2,
          }}
        >
          <Box>
            <Typography
              variant="h5"
              sx={{
                color: "#2D2D2D",
                fontWeight: 600,
                mb: 0.5,
              }}
            >
              Profile Overview
            </Typography>
            <Typography sx={{ color: "#404040", fontSize: "18px", mb: 1 }}>
              {resumeMetaInfo.resumeName}
            </Typography>
          </Box>

          <Button
            startIcon={isAllExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={onExpandAll}
            sx={{
              color: "#2D2D2D",
              padding: "8px 12px",
              textTransform: "none",
              borderRadius: "8px",
              fontSize: "17px",
              fontWeight: "500",
              "&:hover": {
                backgroundColor: "rgba(45, 45, 45, 0.2)",
              },
            }}
          >
            {isAllExpanded ? "Collapse All" : "Expand All"}
          </Button>
        </Box>

        <Typography
          variant="body1"
          sx={{
            color: "#666666",
            mb: 1,
          }}
        >
          Profile Completion: {completion}%
        </Typography>
        <StyledProgress variant="determinate" value={completion} />

        <Grid container spacing={2} sx={{ mt: 4, alignItems: "flex-start" }}>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: "#666666",
                fontSize: "16px",
              }}
            >
              Check the sections below to verify your parsed resume data and
              enhance your profile with additional information.
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
                startIcon={<DownloadIcon />}
                onClick={onDownloadResume}
                disableRipple
                sx={{
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: 550,
                  padding: "8px 12px",
                  marginTop: "-2px",
                  color: "#666666",
                  textTransform: "none",
                  borderColor: "#666666",
                  "&:hover": {
                    borderColor: "#2D2D2D",
                    backgroundColor: "rgba(45, 45, 45, 0.04)",
                  },

                  border: "1px solid rgba(45, 45, 45, 0.2)",
                }}
              >
                Download Resume
              </Button>
              <Button
                startIcon={<UploadFileIcon />}
                onClick={() => setIsUploadModalOpen(true)}
                variant="contained"
                disableRipple
                sx={{
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: 550,
                  padding: "8px 12px",
                  textTransform: "none",
                  backgroundColor: "#2D2D2D",
                  "&:hover": {
                    backgroundColor: "#404040",
                  },
                }}
              >
                Replace Resume
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </OverviewCard>
      <ResumeUploadModal
        open={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        onUpload={handleUpload}
      />
    </>
  );
};

export default ProfileOverview;
