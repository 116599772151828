// Mock data for testing frontend development

export const mockApplicationsData = {
  data: [
    {
      jobId: "JOB001",
      companyName: "Google",
      jobTitle: "Senior Software Engineer",
      jobLink: "https://careers.google.com/jobs/123",
      atsName: "Workday",
      resumeId: "RES001",
      resumeName: "Resume_S001.pdf",
      sessionId: "SESSION001",
      applicationStatus: "completed",
      emailStatus: "sent",
      date: "2024-12-12T10:30:00Z",
      v2Id: "USER123",
      email: "user@example.com",
    },
    {
      jobId: "JOB002",
      companyName: "Amazon",
      jobTitle: "Full Stack Developer",
      jobLink: "https://amazon.jobs/456",
      atsName: "Greenhouse",
      resumeId: "RES001",
      resumeName: "Resume_S001.pdf",
      sessionId: "SESSION001",
      applicationStatus: "completed",
      emailStatus: "failed",
      date: "2024-12-12T10:35:00Z",
      v2Id: "USER123",
      email: "user@example.com",
    },
    {
      jobId: "JOB001",
      companyName: "Google",
      jobTitle: "Senior Software Engineer",
      jobLink: "https://careers.google.com/jobs/123",
      atsName: "Workday",
      resumeId: "RES001",
      resumeName: "Resume_S002.pdf",
      sessionId: "SESSION001",
      applicationStatus: "completed",
      emailStatus: "sent",
      date: "2024-12-12T10:30:00Z",
      v2Id: "USER123",
      email: "user@example.com",
    },
    {
      jobId: "JOB001",
      companyName: "Google",
      jobTitle: "Senior Software Engineer",
      jobLink: "https://careers.google.com/jobs/123",
      atsName: "Workday",
      resumeId: "RES001",
      resumeName: "Resume_S002.pdf",
      sessionId: "SESSION001",
      applicationStatus: "success",
      emailStatus: "sent",
      date: "2024-12-12T10:30:00Z",
      v2Id: "USER123",
      email: "user@example.com",
    },
    {
      jobId: "JOB001",
      companyName: "Google",
      jobTitle: "Senior Software Engineer",
      jobLink: "https://careers.google.com/jobs/123",
      atsName: "Workday",
      resumeId: "RES001",
      resumeName: "Resume_S002.pdf",
      sessionId: "SESSION001",
      applicationStatus: "completed",
      emailStatus: "sent",
      date: "2024-12-12T10:30:00Z",
      v2Id: "USER123",
      email: "user@example.com",
    },
    {
      jobId: "JOB001",
      companyName: "Google",
      jobTitle: "Senior Software Engineer",
      jobLink: "https://careers.google.com/jobs/123",
      atsName: "Workday",
      resumeId: "RES001",
      resumeName: "Resume_S002.pdf",
      sessionId: "SESSION001",
      applicationStatus: "completed",
      emailStatus: "sent",
      date: "2024-12-12T10:30:00Z",
      v2Id: "USER123",
      email: "user@example.com",
    },
  ],
  totalCount: 2,
  successfulApplications: 2,
  successfulEmails: 1,
  applicationSuccessRate: 100,
  emailSuccessRate: 50,
  page: 1,
  limit: 10,
};

export const mockMetricsData = {
  daily: {
    totalApplications: 75,
    totalEmails: 15,
    successfulApplications: 12,
    successfulEmails: 10,
    successRate: 95,
    emailSuccessRate: 66.7,
    totalCompanies: 60,
    mostUsedResume: {
      name: "Resume_S001.pdf",
      applicationCount: 25,
    },
    atsBreakdown: [
      {
        atsName: "Workday",
        applications: 6,
        successfulApplications: 5,
        emails: 6,
        successfulEmails: 4,
      },
      {
        atsName: "Greenhouse",
        applications: 9,
        successfulApplications: 7,
        emails: 9,
        successfulEmails: 6,
      },
    ],
    timeframe: "daily",
    startDate: "2024-12-12T00:00:00Z",
    endDate: "2024-12-12T23:59:59Z",
  },
};

export const mockSessionsData = {
  data: [
    {
      sessionId: "SESSION001",
      startTime: "2024-12-12T10:30:00Z",
      endTime: "2024-12-12T11:30:00Z",
      totalApplications: 8,
      totalEmails: 8,
      successRate: 85,
      successfulApplications: 6,
      successfulEmails: 5,
      applicationSuccessRate: 75,
      emailSuccessRate: 62.5,
      applications: [
        {
          jobId: "JOB001",
          companyName: "Google",
          applicationStatus: "success",
          emailStatus: "sent",
          timestamp: "2024-12-12T10:30:00Z",
          atsName: "Workday",
        },
        {
          jobId: "JOB002",
          companyName: "Amazon",
          applicationStatus: "success",
          emailStatus: "failed",
          timestamp: "2024-12-12T10:35:00Z",
          atsName: "Greenhouse",
        },
      ],
    },
  ],
  totalCount: 1,
  page: 1,
  limit: 10,
};

export const mockResumesData = {
  data: [
    {
      resumeId: "RES001",
      resumeName: "Resume_S001.pdf",
      usageCount: 25,
      successfulApplications: 20,
      successfulEmails: 18,
      applicationSuccessRate: 80,
      emailSuccessRate: 72,
      topCompanies: [
        {
          companyName: "Google",
          applications: 8,
          successfulApplications: 7,
          emails: 8,
          successfulEmails: 6,
        },
        {
          companyName: "Amazon",
          applications: 6,
          successfulApplications: 5,
          emails: 6,
          successfulEmails: 5,
        },
      ],
    },
    {
      resumeId: "RES002",
      resumeName: "Resume_S002.pdf",
      usageCount: 75,
      successfulApplications: 71,
      successfulEmails: 18,
      applicationSuccessRate: 95,
      emailSuccessRate: 72,
      topCompanies: [
        {
          companyName: "Google",
          applications: 8,
          successfulApplications: 7,
          emails: 8,
          successfulEmails: 6,
        },
        {
          companyName: "Amazon",
          applications: 6,
          successfulApplications: 5,
          emails: 6,
          successfulEmails: 5,
        },
      ],
    },
  ],
};

export const mockCompanyStats = {
  topCompanies: [
    {
      companyName: "Google",
      totalApplications: 15,
      successfulApplications: 12,
      totalEmails: 15,
      successfulEmails: 11,
      applicationSuccessRate: 80,
      emailSuccessRate: 73.3,
      atsUsed: [
        {
          atsName: "Workday",
          count: 10,
          successRate: 80,
        },
        {
          atsName: "Greenhouse",
          count: 5,
          successRate: 80,
        },
      ],
    },
  ],
  atsStats: [
    {
      atsName: "Workday",
      totalApplications: 25,
      successfulApplications: 20,
      applicationSuccessRate: 80,
      companies: [
        {
          companyName: "Google",
          applications: 10,
          successRate: 80,
        },
        {
          companyName: "Amazon",
          applications: 15,
          successRate: 80,
        },
      ],
    },
  ],
  totalUniqueCompanies: 10,
};

// Helper function to simulate API calls
export const mockApiCall = (endpoint, params = {}) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      switch (endpoint) {
        case "/api/analytics/applications":
          const { page = 1, limit = 10 } = params;
          const startIndex = (page - 1) * limit;
          const endIndex = startIndex + limit;

          resolve({
            data: mockApplicationsData.data.slice(startIndex, endIndex),
            totalCount: mockApplicationsData.data.length,
            page: page,
            limit: limit,
          });
          break;
        case "/api/analytics/metrics":
          resolve(mockMetricsData.daily);
          break;
        case "/api/analytics/sessions":
          resolve(mockSessionsData);
          break;
        case "/api/analytics/resumes":
          resolve(mockResumesData);
          break;
        case "/api/analytics/companies":
          resolve(mockCompanyStats);
          break;
        default:
          resolve(null);
      }
    }, 500);
  });
};

export const mockOwnerApiCall = (params) => {
  return new Promise((resolve) => {
    // Simulate network delay
    // setTimeout(() => {
    // Mock data - in a real app, this would be fetched from a backend
    const ownerMetrics = {
      totalJobs: 0,
      totalEmails: 0,
      totalUsersAssigned: 0,
      maxUsers: 100,
      topOperator: {
        name: "None",
        email: "-",
        totalActions: 0,
      },
    };

    resolve(ownerMetrics);
    // }, 1000);
  });
};

export const mockOperatorDetailsApiCall = (operatorId, params) => {
  return new Promise((resolve) => {
    // Simulate network delay
    setTimeout(() => {
      // Mock operators data - this would normally come from a backend
      const operators = [
        {
          id: 1,
          name: "Jane Smith",
          email: "jane.smith@company.com",
          assignedUsers: 23,
          maxUsers: 100,
          analytics: {
            emailsSent: 437,
            jobsApplied: 156,
          },
          users: [
            {
              id: 101,
              name: "User 1",
              email: "user1@example.com",
              emailsSent: 20,
              jobsApplied: 7,
            },
            {
              id: 102,
              name: "User 2",
              email: "user2@example.com",
              emailsSent: 35,
              jobsApplied: 12,
            },
            // more users...
          ],
        },
        {
          id: 2,
          name: "Mike Johnson",
          email: "mike.j@company.com",
          assignedUsers: 45,
          maxUsers: 100,
          analytics: {
            emailsSent: 819,
            jobsApplied: 281,
          },
          users: [
            {
              id: 201,
              name: "User A",
              email: "usera@example.com",
              emailsSent: 18,
              jobsApplied: 6,
            },
            {
              id: 202,
              name: "User B",
              email: "userb@example.com",
              emailsSent: 25,
              jobsApplied: 9,
            },
            // more users...
          ],
        },
        // Additional operators...
      ];

      // Find the requested operator
      const operator = operators.find((op) => op.id === parseInt(operatorId));

      if (operator) {
        resolve({
          operator: {
            id: operator.id,
            name: operator.name,
            email: operator.email,
            assignedUsers: operator.assignedUsers,
            maxUsers: operator.maxUsers,
            analytics: operator.analytics,
          },
          users: operator.users,
          totalJobs: operator.analytics.jobsApplied,
          totalEmails: operator.analytics.emailsSent,
        });
      } else {
        // Operator not found
        resolve({
          error: "Operator not found",
        });
      }
    }, 1000);
  });
};

export const mockOperatorApiCall = (params) => {
  return new Promise((resolve) => {
    // Simulate network delay
    const operatorMetrics = {
      totalJobs: 0,
      totalEmails: 0,
      totalUsersAssigned: 0,
      maxUsers: 100,
      topUser: {
        name: "None",
        email: "-",
        totalActions: 0,
      },
      // Other operator data as needed
    };
    resolve(operatorMetrics);
  });
};

export const mockUserDetailsApiCall = (userId, params) => {
  return new Promise((resolve) => {
    // Simulate network delay
    setTimeout(() => {
      // Mock users data - this would normally come from a backend
      const users = [
        {
          id: 1,
          name: "Alex Williams",
          email: "alex.w@company.com",
          analytics: {
            emailsSent: 45,
            jobsApplied: 12,
            lastActivity: "2024-02-25T14:32:00Z",
            companies: ["Google", "Microsoft", "Amazon"],
            resumes: [
              { name: "Software_Dev_Resume.pdf", used: 7 },
              { name: "Full_Stack_Resume.pdf", used: 5 },
            ],
          },
        },
        {
          id: 2,
          name: "Sarah Chen",
          email: "sarah.c@company.com",
          analytics: {
            emailsSent: 78,
            jobsApplied: 27,
            lastActivity: "2024-03-01T09:15:00Z",
            companies: ["Facebook", "Apple", "Netflix", "Google"],
            resumes: [
              { name: "Product_Manager_Resume.pdf", used: 15 },
              { name: "Marketing_Resume.pdf", used: 12 },
            ],
          },
        },
        // More user data...
      ];

      // Find the requested user
      const user = users.find((u) => u.id === parseInt(userId));

      if (user) {
        resolve({
          user: {
            id: user.id,
            name: user.name,
            email: user.email,
            analytics: user.analytics,
          },
        });
      } else {
        // User not found
        resolve({
          error: "User not found",
        });
      }
    }, 1000);
  });
};

export const mockSchoolApiCall = (params) => {
  return new Promise((resolve) => {
    // Simulate network delay
    // setTimeout(() => {
      // Mock data - in a real app, this would be fetched from a backend
      const schoolMetrics = {
        totalJobs: 0,
        totalEmails: 0,
        totalUsersAssigned: 0,
        maxUsers: 100,
        topUser: {
          name: "None",
          email: "-",
          totalActions: 0,
        },
        departmentBreakdown: [
          {
            department: "Computer Science",
            users: 78,
            emailsSent: 382,
            jobsApplied: 134,
          },
          {
            department: "Business Administration",
            users: 65,
            emailsSent: 293,
            jobsApplied: 102,
          },
          {
            department: "Engineering",
            users: 52,
            emailsSent: 312,
            jobsApplied: 124,
          },
          {
            department: "Psychology",
            users: 34,
            emailsSent: 156,
            jobsApplied: 75,
          },
          {
            department: "Other",
            users: 16,
            emailsSent: 104,
            jobsApplied: 51,
          },
        ],
      };

      resolve(schoolMetrics);
    // }, 1000);
  });
};

export const mockDepartmentApiCall = (department, params) => {
  return new Promise((resolve) => {
    // Simulate network delay
    setTimeout(() => {
      // Department-specific data
      const departmentData = {
        "Computer Science": {
          students: 78,
          emailsSent: 382,
          jobsApplied: 134,
          topCompanies: ["Google", "Microsoft", "Amazon", "Apple", "Meta"],
          employmentRate: 92,
        },
        "Business Administration": {
          students: 65,
          emailsSent: 293,
          jobsApplied: 102,
          topCompanies: [
            "Deloitte",
            "JPMorgan Chase",
            "Goldman Sachs",
            "Amazon",
          ],
          employmentRate: 88,
        },
        Engineering: {
          students: 52,
          emailsSent: 312,
          jobsApplied: 124,
          topCompanies: ["Boeing", "Lockheed Martin", "Tesla", "Google"],
          employmentRate: 89,
        },
        Psychology: {
          students: 34,
          emailsSent: 156,
          jobsApplied: 75,
          topCompanies: [
            "Hospital Networks",
            "Academic Institutions",
            "Government",
          ],
          employmentRate: 78,
        },
      };

      if (department && departmentData[department]) {
        resolve({
          department,
          ...departmentData[department],
        });
      } else {
        // Return aggregate data
        resolve({
          totalStudents: 245,
          totalEmailsSent: 1247,
          totalJobsApplied: 486,
          averageEmploymentRate: 86,
          departmentBreakdown: Object.keys(departmentData).map((dept) => ({
            department: dept,
            ...departmentData[dept],
          })),
        });
      }
    }, 1000);
  });
};
