// ShareJourneyForm/index.jsx
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Snackbar, Alert, CircularProgress } from "@mui/material";
import { Box, Container, Typography, Button } from "@mui/material";
import StepProgressBar from "./StepProgressBar";
import BasicDetails from "./steps/BasicDetails";
import Metrics from "./steps/Metrics";
import Timeline from "./steps/Timeline";
import ResumeAndTemplate from "./steps/ResumeAndTemplate";
import Review from "./steps/Review";
import axios from "axios";
import { useStateStore } from "../../../../store/index";

const steps = [
  "Basic Details",
  "Metrics",
  "Timeline",
  "Resume & Template",
  "Review",
];

const initialFormState = {
  basicDetails: {
    role: "",
    companyType: "",
    experienceLevel: "",
    duration: "",
  },
  metrics: {
    applications: "",
    responseRate: "",
    offers: "",
  },
  timeline: [{ dateRange: "", stage: "", count: "" }],
  resume: {
    selectedResume: null,
    selectedSections: ["WorkExperience"],
  },
  emailTemplate: {
    selectedTemplate: null,
  },
  // agreement: false,
};

const ShareJourneyForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState(initialFormState);
  const history = useHistory();
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const { token, userData } = useStateStore();

  const updateFormData = (section, newData) => {
    setFormData((prev) => ({
      ...prev,
      [section]: newData,
    }));
  };

  const handleNext = () => {
    setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));
  };

  const handleBack = () => {
    setActiveStep((prev) => Math.max(prev - 1, 0));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setError(null);

    console.log(token, "hello", formData, userData);

    try {
      const response = await axios.post(
        "https://backend.lazyapply.com/lazyapplyV2/explore/share",

        {
          ...formData,
          v2id: userData.v2Id,
          email: userData.email,
        },

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.success) {
        setShowSuccess(true);
        setTimeout(() => {
          history.push("/dashboard/explore");
        }, 2000);
      }
    } catch (error) {
      setError(
        error.response?.data?.message || "Failed to submit. Please try again."
      );
      console.log(error, "e");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box sx={{ bgcolor: "#f8fafc", minHeight: "100vh", py: 4 }}>
      <Container maxWidth="lg">
        {/* Header */}
        <Box mb={6}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              color: "#2d2d2d",
              mb: 1,
            }}
          >
            Share Your Journey
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "text.secondary",
              fontSize: "1rem",
            }}
          >
            Help others learn from your successful job search experience
          </Typography>
        </Box>

        {/* Main Content */}
        <Box
          sx={{
            bgcolor: "white",
            borderRadius: 3,
            px: 8,
            py: 4,
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
          }}
        >
          <StepProgressBar steps={steps} activeStep={activeStep} />

          {/* Form Content will go here */}

          {activeStep === 0 && (
            <BasicDetails formData={formData} updateFormData={updateFormData} />
          )}

          {activeStep === 1 && (
            <Metrics formData={formData} updateFormData={updateFormData} />
          )}

          {activeStep === 2 && (
            <Timeline formData={formData} updateFormData={updateFormData} />
          )}

          {activeStep === 3 && (
            <ResumeAndTemplate
              formData={formData}
              updateFormData={updateFormData}
            />
          )}

          {activeStep === 4 && (
            <Review
              formData={formData}
              updateFormData={updateFormData}
              onEditSection={(stepIndex) => setActiveStep(stepIndex)}
            />
          )}

          {/* Navigation */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              mt: 4,
              pt: 4,
              borderTop: "1px solid",
              borderColor: "grey.100",
            }}
          >
            {activeStep > 0 && (
              <Button
                onClick={handleBack}
                variant="outlined"
                disableRipple
                sx={{
                  color: "#2d2d2d",
                  borderColor: "#2d2d2d",
                  "&:hover": {
                    borderColor: "#404040",
                    bgcolor: "grey.50",
                  },
                  borderRadius: 2,
                  textTransform: "none",
                  fontSize: "16px",
                  px: 2.5,
                }}
              >
                Back
              </Button>
            )}
            <Button
              onClick={
                activeStep === steps.length - 1 ? handleSubmit : handleNext
              }
              variant="contained"
              disabled={isSubmitting}
              disableRipple
              sx={{
                bgcolor: "#2d2d2d",
                color: "#fff",
                "&:hover": {
                  bgcolor: "#404040",
                },
                borderRadius: 2,
                textTransform: "none",
                px: 3,
                fontSize: "16px",
              }}
            >
              {isSubmitting ? (
                <CircularProgress size={24} color="inherit" />
              ) : activeStep === steps.length - 1 ? (
                "Submit"
              ) : (
                "Next"
              )}
            </Button>
          </Box>
        </Box>
      </Container>

      <Snackbar
        open={showSuccess}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="success"
          sx={{
            minWidth: "300px",
            borderRadius: 2,
            "& .MuiAlert-message": {
              fontSize: "0.9rem",
            },
          }}
        >
          Your journey has been submitted for approval!
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={!!error}
        autoHideDuration={4000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="error"
          sx={{
            minWidth: "300px",
            borderRadius: 2,
            "& .MuiAlert-message": {
              fontSize: "0.9rem",
            },
          }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ShareJourneyForm;
