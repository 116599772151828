/*global chrome*/
import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import { styled, keyframes } from "@mui/material/styles";
import {
  Card,
  Box,
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useStateStore } from "../../../store";

// Animations
const fadeOut = keyframes`
  from { opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(-20px); }
`;

const shimmer = keyframes`
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
`;

// Styled Components
const Container = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  pointerEvents: "none",
  zIndex: 10000,
});

const DraggableWrapper = styled(Box)(({ isClosing }) => ({
  position: "absolute",
  top: 20,
  right: 20,
  pointerEvents: "auto",
  animation: isClosing ? `${fadeOut} 0.5s ease forwards` : "none",
}));

const StyledCard = styled(Card)(({ minimized }) => ({
  width: minimized ? "120px" : "400px",
  height: minimized ? "40px" : "260px",
  backgroundColor: "#f8f8f8",
  borderRadius: "12px",
  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.08)",
  overflow: "hidden",
  transition: "all 0.3s ease",
  border: "1px solid rgba(0, 0, 0, 0.2)",
}));

const Header = styled(Box)({
  padding: "12px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#e8e8e8",
  cursor: "move",
});

const ProgressBar = styled(Box)(({ value }) => ({
  height: "10px",
  width: "100%",
  backgroundColor: "#eaeaea",
  borderRadius: "6px",
  position: "relative",
  overflow: "hidden",
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: `${value}%`,
    height: "100%",
    background: "linear-gradient(90deg, #8aedb9 0%, #4bce88 100%)",
    borderRadius: "6px",
    transition: "width 0.3s ease",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)",
    animation: `${shimmer} 2s infinite linear`,
    zIndex: 1,
  },
}));

const ContentBox = styled(Box)({
  padding: "16px 28px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
});

const ProgressCard = ({ onClose }) => {
  const { yourExtensionId } = useStateStore();
  const [minimized, setMinimized] = useState(false);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("running");
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (status === "running") {
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            return 0;
          }
          return prev + 5;
        });
      }, 200);
      return () => clearInterval(interval);
    }
  }, [status]);

  const handleStop = () => {
    chrome.runtime.sendMessage(
      yourExtensionId,
      {
        type: "STOP_AUTOMATION",
      },
      (response) => {
        var lastError = chrome.runtime?.lastError;
        if (lastError) {
          console.log("Error stopping automation");
        } else {
          console.log("stopped automation:", response);
        }
      }
    );
    setShowConfirmDialog(true);
  };

  const confirmStop = () => {
    setShowConfirmDialog(false);
    setStatus("stopped");

    // Start fade out animation after showing "Stopped" status
    setTimeout(() => {
      setIsClosing(true);
      // Call onClose after animation completes
      setTimeout(() => {
        if (onClose) onClose();
      }, 500);
    }, 2000);
  };

  return (
    <Container>
      <Draggable handle=".dragHandle">
        <DraggableWrapper isClosing={isClosing}>
          <StyledCard minimized={minimized}>
            <Header className="dragHandle">
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <Box sx={{ width: 20, height: 2, backgroundColor: "#666" }} />
                <Box sx={{ width: 20, height: 2, backgroundColor: "#666" }} />
              </Box>
              <IconButton
                onClick={() => setMinimized(!minimized)}
                sx={{ color: "#666", padding: "8px" }}
              >
                <Box sx={{ width: 20, height: 2, backgroundColor: "#666" }} />
              </IconButton>
            </Header>

            {!minimized && (
              <ContentBox>
                <Typography
                  variant="h6"
                  align="center"
                  sx={{
                    color: "#2d2d2d",
                    fontSize: "1.25rem",
                    fontWeight: 500,
                    letterSpacing: "-0.5px",
                  }}
                >
                  Automation {status === "running" ? "in Progress" : "Stopped"}
                </Typography>

                {/* <Typography
                  variant="h6"
                  align="center"
                  sx={{
                    color: "#2d2d2d",
                    fontSize: "1.1rem",
                    fontWeight: 550,
                    opacity: 0.9,
                    mt: 1,
                  }}
                >
                  {company} - {position}
                </Typography> */}

                <Box sx={{ position: "relative", mt: 3 }}>
                  <ProgressBar value={progress} />
                  <Typography
                    variant="body2"
                    align="center"
                    sx={{
                      mt: 1,
                      color: "#666",
                      fontSize: "0.9rem",
                    }}
                  >
                    {/* {progress}% Complete */}
                  </Typography>
                </Box>

                {status === "running" && (
                  <Box
                    sx={{
                      mt: "auto",
                      display: "flex",
                      //   justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={handleStop}
                      sx={{
                        backgroundColor: "#2d2d2d",
                        color: "#fff",
                        borderRadius: "8px",
                        padding: "8px 28px",
                        textTransform: "none",
                        marginTop: "10px",
                        fontSize: "18px",
                        "&:hover": {
                          backgroundColor: "#404040",
                        },
                      }}
                    >
                      Stop Automation
                    </Button>
                  </Box>
                )}
              </ContentBox>
            )}
          </StyledCard>
        </DraggableWrapper>
      </Draggable>

      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
      >
        <DialogTitle>Stop Automation?</DialogTitle>
        <DialogContent>
          Are you sure you want to stop the current automation process?
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setShowConfirmDialog(false)}
            sx={{
              color: "#2D2D2D",
              backgroundColor: "transparent",
              textTransform: "none",
              fontSize: "16px",
              padding: "4px 16px",
              borderRadius: "8px",
              borderColor: "#E0E0E0",
              "&:hover": {
                backgroundColor: "#E0E0E0",
                borderColor: "#E0E0E0",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={confirmStop}
            sx={{
              backgroundColor: "#2D2D2D",
              color: "white",
              textTransform: "none",
              borderRadius: "8px",
              padding: "4px 16px",
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "#404040",
              },
            }}
          >
            Stop
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ProgressCard;
