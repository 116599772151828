import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  Button,
  CardContent,
} from "@mui/material";
import { useStateStore } from "../../../store/index";
import { Skeleton } from "@mui/material";

// Sample data with multiple profiles
const successStories = [
  {
    id: 1,
    role: "Software Engineer",
    company: "FAANG Company",
    experience: "3-5 YOE",
    metrics: {
      applications: 45,
      responseRate: 35,
      offers: 3,
    },
    timeline: [
      { date: "Jan 15", stage: "Applied" },
      { date: "Feb 15", stage: "Interviews" },
      { date: "Feb 28", stage: "Offer" },
    ],
  },
  {
    id: 2,
    role: "Product Manager",
    company: "Fortune 500 Tech",
    experience: "4-6 YOE",
    metrics: {
      applications: 32,
      responseRate: 42,
      offers: 2,
    },
    timeline: [
      { date: "Mar 10", stage: "Applied" },
      { date: "Mar 25", stage: "Interviews" },
      { date: "Apr 15", stage: "Offer" },
    ],
  },
  {
    id: 3,
    role: "Data Scientist",
    company: "Tech Startup",
    experience: "2-4 YOE",
    metrics: {
      applications: 38,
      responseRate: 28,
      offers: 2,
    },
    timeline: [
      { date: "Feb 5", stage: "Applied" },
      { date: "Feb 20", stage: "Interviews" },
      { date: "Mar 10", stage: "Offer" },
    ],
  },
];

const LoadingCard = () => (
  <Card
    sx={{
      height: "100%",
      borderRadius: 2,
      border: "1px solid",
      borderColor: "grey.100",
    }}
  >
    <CardContent sx={{ p: 3 }}>
      {/* Header Loading */}
      <Box mb={3}>
        <Skeleton variant="text" width="60%" height={32} />
        <Skeleton variant="text" width="40%" height={24} />
      </Box>

      {/* Metrics Loading */}
      <Grid container spacing={2} mb={4}>
        {[1, 2, 3].map((item) => (
          <Grid item xs={4} key={item} sx={{ textAlign: "center" }}>
            <Skeleton
              variant="text"
              width="60%"
              height={40}
              sx={{ mx: "auto" }}
            />
            <Skeleton
              variant="text"
              width="80%"
              height={24}
              sx={{ mx: "auto" }}
            />
          </Grid>
        ))}
      </Grid>

      {/* Timeline Loading */}
      <Skeleton variant="rectangular" width="100%" height={80} />
    </CardContent>
  </Card>
);

// Timeline Point Component
const TimelinePoint = ({ date, stage, color }) => (
  <Box display="flex" flexDirection="column" alignItems="center" width="33%">
    <Typography
      variant="caption"
      sx={{
        color: "#000",
        mb: 1,
        fontSize: "0.85rem",
      }}
    >
      {date}
    </Typography>
    <Box
      width={10}
      height={10}
      sx={{
        borderRadius: "50%",
        backgroundColor: color,
        border: "2px solid white",
        boxShadow: "0 0 0 2px" + color + "40",
        transform: "translateY(50%)",
        position: "relative",
        zIndex: 2,
      }}
    />
    <Typography
      variant="caption"
      sx={{
        color: "#2d2d2d",
        mt: 2,
        fontSize: "0.85rem",
      }}
    >
      {stage}
    </Typography>
  </Box>
);

// Success Card Component
const SuccessCard = ({ story }) => {
  const history = useHistory();

  const handleCardClick = () => {
    history.push(`/dashboard/explore/${story._id}`);
  };

  const getStageColor = (stage) => {
    switch (stage) {
      case "Applied":
        return "#64B5F6";
      case "Interviews":
        return "#81C784";
      case "Offer":
        return "#4CAF50";
      default:
        return "#90A4AE";
    }
  };

  return (
    <Card
      onClick={handleCardClick}
      sx={{
        height: "100%",
        borderRadius: 2,
        background: "linear-gradient(to bottom right, #ffffff, #fafafa)",
        transition: "all 0.3s ease",
        cursor: "pointer",
        border: "1px solid",
        borderColor: "grey.100",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: "0 12px 24px rgba(0,0,0,0.1)",
        },
      }}
    >
      <CardContent sx={{ p: 3 }}>
        {/* Header */}
        <Box mb={3}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              color: "text.primary",
              mb: 1,
            }}
          >
            {story.role}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              fontSize: "0.875rem",
            }}
          >
            {story.companyType} • {story.experienceLevel}
          </Typography>
        </Box>

        {/* Metrics */}
        <Grid container spacing={2} mb={4}>
          <Grid item xs={4} sx={{ textAlign: "center" }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                color: "text.primary",
                mb: 0.5,
              }}
            >
              {story.totalApplications}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                fontSize: "0.8rem",
              }}
            >
              Applications
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "center" }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                color: "text.primary",
                mb: 0.5,
              }}
            >
              {story.responseRate}%
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                fontSize: "0.8rem",
              }}
            >
              Response Rate
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "center" }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                color: "text.primary",
                mb: 0.5,
              }}
            >
              {story.numberOfOffers}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                fontSize: "0.8rem",
              }}
            >
              Offers
            </Typography>
          </Grid>
        </Grid>

        {/* Timeline */}
        <Box position="relative">
          <Box
            position="absolute"
            left="0"
            right="0"
            top="50%"
            height="2px"
            bgcolor="grey.200"
            zIndex={1}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            position="relative"
            px={2}
          >
            {story.timeline.map((point, index) => (
              <TimelinePoint
                key={index}
                date={point.dateRange}
                stage={point.stage}
                color={getStageColor(point.stage)}
              />
            ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const ExploreSection = () => {
  const history = useHistory();
  const [profiles, setProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token, userData } = useStateStore();

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const response = await axios.get(
          "https://backend.lazyapply.com/lazyapplyV2/explore",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          setProfiles(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching profiles:", error);
        setError(error.response?.data?.message || "Failed to load profiles");
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfiles();
  }, []);

  return (
    <Box sx={{ bgcolor: "#fff", minHeight: "100vh", py: 6 }}>
      <Container maxWidth="lg">
        <Box
          mb={6}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography
              variant="h4"
              component="h1"
              sx={{
                fontWeight: 600,
                color: "text.primary",
                mb: 1,
              }}
            >
              Explore Success Stories
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "text.secondary",
                fontSize: "1rem",
              }}
            >
              Discover how others navigated their job search journey
            </Typography>
          </Box>

          <Typography
            onClick={() => history.push("/dashboard/explore/share")}
            sx={{
              color: "primary.main",
              fontSize: "1rem",
              fontWeight: 500,
              cursor: "pointer",
              position: "relative",
              "&:hover": {
                "&::after": {
                  width: "100%",
                },
              },
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: -1,
                left: 0,
                width: 0,
                height: "1px",
                bgcolor: "primary.main",
                transition: "width 0.2s ease",
              },
            }}
          >
            Share Your Journey
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {isLoading
            ? [1, 2, 3].map((item) => (
                <Grid item xs={12} md={6} lg={4} key={item}>
                  <LoadingCard />
                </Grid>
              ))
            : profiles.map((story) => (
                <Grid item xs={12} md={6} lg={4} key={story.id}>
                  <SuccessCard story={story} />
                </Grid>
              ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ExploreSection;
