import React, { useState } from "react";
import axios from "axios";
import { useStateStore } from "../store/index";
import CustomAlert from "./modal";
import { paddle } from "../index";
import { Button } from "@mui/material";
function PayPalButton({
  titlemain,
  amount,
  titleSecondary,
  plan_id,
  user_email,
  user_v2Id,
  company_name = "",
  account_email = "",
  no_of_seats = 1,
  tier,
  disabled,
}) {

  const token = useStateStore((state) => state.token);
  const userData = useStateStore((state) => state.userData);
  const openCheckout = (priceId, email, quantity = 1) => {
    paddle.Checkout.open({
      settings: {
        theme: "light",
        displayMode: "overlay",
        successUrl: "https://app.lazyapply.com/dashboard/payment-success",
      },
      customData: {
        account_email: email,
        enterprise_account_email: account_email,
        name: userData?.name || "",
        user_email: user_email,
        user_v2id: user_v2Id,
        company_name,
        plan_id,
        no_of_seats
      },
      customer: {
        email: email,
      },
      items: [
        {
          priceId: priceId,
          quantity: quantity,
        },
      ],
    });
  };
  const handlePaddleOrder = async () => {
    try {
      const response = await axios.post(
        "https://backend.lazyapply.com/lazyapplyV2/create-payment-link",
        {
          title: titlemain,
          amount: amount,
          titleSecondary: titleSecondary,
          tier,
          plan_id
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const product_id = response.data.product_id;
      openCheckout(product_id, user_email, no_of_seats);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Button
        disabled={disabled}
        variant="contained"
        size="large"
        fullWidth
        sx={{
          borderRadius: "28px",
          py: 1.25,
          fontSize: "17px",
          textTransform: "none",
          fontWeight: 600,
          backgroundColor: "#2D2D2D",
          "&:hover": {
            backgroundColor: "#404040",
          },
        }}
        className="select-plan-button"
        onClick={() => handlePaddleOrder()}
      >
        Select Plan
      </Button>
    </>
  );
}
export default PayPalButton;
