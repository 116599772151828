// DetailedView/EmailTemplateModal.jsx
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";

// Dummy template data - we'll move this to DB later
const emailTemplate = {
  subject: "Referral for Software Engineer Position",
  content: `Hi [Hiring Manager],

I hope this email finds you well. I'm reaching out to refer a highly skilled Software Engineer candidate who I believe would be a great addition to your team.

The candidate has a strong background in [specific technologies/skills] and has demonstrated excellent problem-solving abilities throughout their career. They have [X] years of experience in software development and have worked on significant projects including [brief examples].

What particularly stands out about this candidate is their [specific strength or achievement]. I believe they would be a valuable asset to your team, bringing both technical expertise and strong collaborative skills.

I've attached their resume for your review. They're very interested in the opportunity at [Company Name] and would welcome the chance to discuss how they could contribute to your team.

Please let me know if you need any additional information.

Best regards,
[Your Name]`,
};

const EmailTemplateModal = ({ open, onClose, data }) => {
  const [showCopyAlert, setShowCopyAlert] = useState(false);

  const handleCopy = async () => {
    try {
      const textToCopy = `Subject: ${data.templateData?.subject}\n\n${data.templateData?.content}`;

      await navigator.clipboard.writeText(textToCopy);
      setShowCopyAlert(true);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            minHeight: "60vh",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Email Template
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Subject
            </Typography>
            <Typography>{data.templateData?.subject}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Content
            </Typography>
            <Typography
              component="pre"
              sx={{
                whiteSpace: "pre-wrap",
                fontFamily: "inherit",
                fontSize: "0.875rem",
              }}
            >
              {data.templateData?.content}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, borderTop: 1, borderColor: "grey.100" }}>
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{
              mr: 1,
              textTransform: "none",
              borderRadius: "8px",
              padding: "4px 16px",
              color: "#000",
              borderColor: "#2d2d2d",
              fontSize: "16px",
              "&:hover": { bgcolor: "grey.100", borderColor: "#2d2d2d" },
            }}
          >
            Close
          </Button>
          <Button
            onClick={handleCopy}
            variant="contained"
            sx={{
              textTransform: "none",
              borderRadius: "8px",
              padding: "6px 16px",
              color: "#fff",
              bgcolor: "#2d2d2d",
              fontSize: "16px",
              "&:hover": { bgcolor: "#404040" },
            }}
          >
            Copy Template
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showCopyAlert}
        autoHideDuration={2000}
        onClose={() => setShowCopyAlert(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{
            minWidth: "200px",
            borderRadius: 2,
            bgcolor: "#66bb6a",
          }}
        >
          Template copied to clipboard!
        </Alert>
      </Snackbar>
    </>
  );
};

export default EmailTemplateModal;
