// ShareJourneyForm/steps/BasicDetails.jsx
import React from "react";
import { Box, MenuItem, Typography, Grid } from "@mui/material";
import StyledTextField from "../components/StyledTextField";

const EXPERIENCE_LEVELS = [
  "0-2 YOE",
  "2-4 YOE",
  "4-6 YOE",
  "6-8 YOE",
  "8+ YOE",
];

const COMPANY_TYPES = [
  "FAANG",
  "Fortune 500",
  "Startup",
  "Tech Giant",
  "Other",
];

const BasicDetails = ({ formData, updateFormData }) => {
  const handleChange = (field) => (event) => {
    updateFormData("basicDetails", {
      ...formData.basicDetails,
      [field]: event.target.value,
    });
  };

  return (
    <Box>
      <Box mb={4}>
        <Typography
          variant="h6"
          sx={{ fontWeight: 600, color: "#2d2d2d", mb: 3 }}
        >
          Basic Details
        </Typography>

        {/* Role - Full width */}
        <StyledTextField
          fullWidth
          label="Role"
          value={formData.basicDetails.role}
          onChange={handleChange("role")}
          placeholder="e.g., Software Engineer"
          sx={{ mb: 3 }}
        />

        {/* Company Type - Full width */}
        <StyledTextField
          fullWidth
          select
          label="Company Type"
          value={formData.basicDetails.companyType}
          onChange={handleChange("companyType")}
          sx={{ mb: 3 }}
        >
          {COMPANY_TYPES.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </StyledTextField>

        {/* Grid for side by side fields */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              fullWidth
              select
              label="Experience Level"
              value={formData.basicDetails.experienceLevel}
              onChange={handleChange("experienceLevel")}
            >
              {EXPERIENCE_LEVELS.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledTextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              fullWidth
              label="Duration"
              value={formData.basicDetails.duration}
              onChange={handleChange("duration")}
              placeholder="e.g., Jan-Mar 2024"
              helperText="Time period of your job search journey"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BasicDetails;
