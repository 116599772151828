/*global chrome*/
import create from "zustand";
import { devtools } from "zustand/middleware";
var yourExtensionId =
  process.env.NODE_ENV === "development"
    ? "ilelheadfopgpfnmdejngipjkafglndk"
    : process.env.REACT_APP_EXTENSION_ID;
const localStorageExtensionId = window.localStorage.getItem("extensionId");
if (localStorageExtensionId && localStorageExtensionId != "") {
  yourExtensionId = localStorageExtensionId;
}

window.localStorage.setItem("extensionId", yourExtensionId);

console.log("extensionid", yourExtensionId);
const store = (set, get) => ({
  mainLoadingState: 0,
  setMainLoadingState: (value) => {
    set({ mainLoadingState: value });
  },
  globalLogout: () => {
    console.log("global");
    localStorage.clear();
    set((state) => ({
      token: "",
      isUserAuthenticated: 0,
      imageSrc: null,
      userData: {},
      showMainBlock: true,
      currentGoalId: -1,
      backClick: 0,
      companies: [],
      searchValue: "",
      resumeReferralData: {},
      resumeValidation: 0,
      userPlanDetails: {},
      referralPrompts: [],
      sendEmail: false,
      installExtensionDuringAutomation: false,
      showEmailPermissionModal: false,
      emailList: {},
      buttonClick: false,
      pauseToChooseCustomTemplate: false,
      customTemplates: [],
      employeeList: {},
      initialString: "",
      saveButtonClickAndSuccess: false,
      showEmailSettings: false,
      editEmailSettings: false,
      referralResumeData: {},
      employeeSelected: {},
      showEmailSentSuccess: false,
      mainReferralPause: {
        status: false,
        text: "",
      },
      emailData: {},
      employees: [],
      showMainReferralModal: false,
      showReferralFeedback: false,
      stepNoReferralFeedback: 0,
      isOpenHamburger: true,
      showDemo: false,
      selectedPath: "Home",
      promptSelected: {
        form: {
          id: -1,
        },
        prompt: {
          id: -1,
        },
      },
      employeeQuery: "",
      stopClick: false,
      editEmail: false,
      emailDataString: "",
      emailAutomationReferral: false,
      selectedCompanies: [],
      emailAutomationLastSession: {},
      mainModalTitle: "",
      mainCompanySet: {},
      referralInitData: {},
      profileSelectedDuringAutomation: false,
    }));
  },
  extensionPresent: false,
  setExtensionPresent: (value) => {
    set((state) => ({
      extensionPresent: value,
    }));
  },
  yourExtensionId: yourExtensionId,
  isUserAuthenticated: 0,
  token: "",
  userData: {},
  chromeVersion: -1,
  availableChromeVersion: -1,
  timeZone: "",
  setTimeZone: (value) => {
    set({
      timeZone: value,
    });
  },
  setAvailableChromeVersion: (value) => {
    set({
      availableChromeVersion: value,
    });
  },
  setChromeVersion: (value) => {
    set({
      chromeVersion: value,
    });
  },
  globalTabId: null,
  setGlobalTabId: (value) => set((state) => ({ globalTabId: value })),
  userDataInit: (value) => set((state) => ({ userData: value })),

  updateUserResumeV3: (id, name) => {
    const resumev2 = get().userResumeV3;
    let x = resumev2.map((resumeObj) => {
      if (resumeObj.resumeId === id) {
        return {
          ...resumeObj,
          resumeId: id,
          resumename: name,
        };
      } else {
        return resumeObj;
      }
    });
    set((state) => ({
      userResumeV3: x,
    }));
  },
  userInvites: {},
  invites: {},
  setInvites: (value) => {
    set((state) => ({
      invites: { ...state.invites, ...value },
    }));
  },
  setUserInvites: (value) => {
    set((state) => ({
      userInvites: { ...state.userInvites, ...value },
    }));
  },
  resumeModifiedV2: 0,
  setResumeModifiedV2: (value) => {
    set(() => ({
      resumeModifiedV2: value,
    }));
  },
  resumeModifiedV3: 0,
  setResumeModifiedV3: (value) => {
    set(() => ({
      resumeModifiedV3: value,
    }));
  },
  userDataResumeUpdate: (value) => {
    const {
      race_ethnicity,
      disability,
      veteran,
      address,
      noticeperiod,
      coverletter,
      expectedsalary,
      website_blog_portfolio,
      message_to_the_hiring_manager,
      what_makes_you_unique,
      valid_driver_license,
      earliest_start_date,
    } = value.additionalResume;
    let newfields = {};
    if ("visa_status" in value.additionalResume) {
      newfields.visa_status = value.additionalResume.visa_status;
    }
    if ("phone_country_code" in value.additionalResume) {
      newfields.phone_country_code = value.additionalResume.phone_country_code;
    }
    if ("languages_you_know" in value.additionalResume) {
      newfields.languages_you_know = value.additionalResume.languages_you_know;
    }
    if ("linkedin_profile_url" in value.additionalResume) {
      newfields.linkedin_profile_url =
        value.additionalResume.linkedin_profile_url;
    }
    set((state) => ({
      userData: {
        ...state.userData,
        resume: {
          ...state.userData.resume,
          race_ethnicity,
          disability,
          veteran,
          address,
          coverletter,
          noticeperiod,
          ...newfields,
          ...{
            additionalInfo: {
              expectedsalary,
              website_blog_portfolio,
              message_to_the_hiring_manager,
              what_makes_you_unique,
              valid_driver_license,
              earliest_start_date,
            },
          },
        },
        additionalresumevalidation: value.additionalresumevalidation,
      },
    }));
  },
  scriptInjectedGlobal: false,
  setScriptInjectedGlobal: (value) =>
    set({
      scriptInjectedGlobal: value,
    }),
  sessionFetchLoadingState: 0,
  resumeFetchLoadingState: 0,
  setSessionFetchLoadingState: (value) =>
    set({
      sessionFetchLoadingState: value,
    }),
  setResumeFetchLoadingState: (value) =>
    set({
      resumeFetchLoadingState: value,
    }),
  resumefetch: 0,
  setresumefetch: (value) => set({ resumefetch: value }),
  resumesCompleted: {},
  setResumesComplete: (value) => set((state) => ({ resumesCompleted: value })),
  resumesCompletedV3: {},
  setResumesCompleteV3: (value) =>
    set((state) => ({ resumesCompletedV3: value })),
  updateToken: (value) => set((state) => ({ token: value })),
  imageSrc: null,
  userAuthentication: (value) =>
    set((state) => ({ isUserAuthenticated: value })),
  userImageSrc: (value) => set((state) => ({ imageSrc: value })),
  heightChange: 0,
  lazyapplyXData: {
    planStarted: 0,
    stepNo: -1,
    nextPage: 1,
  },
  showFeedbackPopup: false,
  setShowFeedbackPopup: (value) => {
    set((state) => ({
      showFeedbackPopup: value,
    }));
  },
  lazyapplyXCompaniesData: {},
  setLazyapplyXCompaniesData: (value) =>
    set((state) => ({
      lazyapplyXCompaniesData: value,
    })),
  setLazyapplyXData: (value) =>
    set((state) => ({
      lazyapplyXData: value,
    })),
  generatedSampleEmail: "",
  setGeneratedSampleEmail: (value) =>
    set((state) => ({
      generatedSampleEmail: value,
    })),
  globalStopReferral: false,
  setGlobalStopReferral: (value) => set({ globalStopReferral: value }),
  lazyapplyXSessions: [],
  setLazyapplyXSessions: (sessions) =>
    set((state) => ({
      lazyapplyXSessions: sessions,
    })),
  lazyapplyXAnalytics: {
    companiesCount: 0,
    totalsessionslength: 0,
  },
  setLazyapplyXAnalytics: (value) => {
    set((state) => ({
      lazyapplyXAnalytics: value,
    }));
  },
  relevantReferralJobTitles: ["software developer"],
  setRevelantReferralJobTitles: (value) =>
    set({
      relevantReferralJobTitles: value,
    }),
  setHeightChange: () =>
    set((state) => ({
      heightChange: !state.heightChange,
    })),
  settingsClick: false,
  setSettingsClick: (value) => {
    set({
      settingsClick: value,
    });
  },
  disableNextPage: false,
  setDisableNextPage: (value) => {
    set({
      disableNextPage: value,
    });
  },
  searchingJobsWithFilters: false,
  setSearchingJobsWithFilters: (value) => {
    set({
      searchingJobsWithFilters: value,
    });
  },
  paginationActivePageNumber: 1,
  setPaginationActivePageNumber: (value) => {
    set({
      paginationActivePageNumber: value,
    });
  },

  searchJobTitle: "",
  setSearchJobTitle: (value) => {
    set({
      searchJobTitle: value,
    });
  },
  searchCompanyName: "",
  setSearchCompanyName: (value) => {
    set({
      searchCompanyName: value,
    });
  },
  paginationDirection: 1,
  setPaginationDirection: (value) => {
    set({
      paginationDirection: value,
    });
  },
  sessionIdAnalytics: "",
  setSessionIdAnalytics: (value) => {
    set({
      sessionIdAnalytics: value,
    });
  },
  emailAutomationLastSession: {},
  setEmailAutomationLastSession: (value) => {
    set({
      emailAutomationLastSession: value,
    });
  },
  mainModalTitle: "",
  setMainModalTitle: (value) =>
    set({
      mainModalTitle: value,
    }),
  mainCompanySet: {},
  setMainCompanySet: (value) =>
    set({
      mainCompanySet: value,
    }),
  referralInitData: {},
  setReferralInitData: (value) => {
    set({ referralInitData: value });
  },
  profileSelectedDuringAutomation: false,
  setProfileSelectedDuringAutomation: (value) =>
    set({
      profileSelectedDuringAutomation: value,
    }),
  setReferralDataOtherDetails: (value) => {
    let referral_data = get().referralInitData;
    if (Object.keys(referral_data.referral).length > 0) {
      referral_data.referral.referralTitle = value.referralTitle;
      referral_data.referral.resumeId = value.selectedResumeId;
    } else {
      referral_data.referral = {
        referralTitle: value.referralTitle,
        resumeId: value.selectedResumeId,
      };
    }
    set({ referralInitData: referral_data });
  },
  referralPrompts: [],
  setReferralPrompts: (value) => set({ referralPrompts: value }),
  sendEmail: false,
  setSendEmail: (value) => {
    set({ sendEmail: value });
  },
  installExtensionDuringAutomation: false,
  setInstallExtensionDuringAutomation: (value) =>
    set({ installExtensionDuringAutomation: value }),
  showEmailPermissionModal: false,
  setShowEmailPermissionModal: (value) =>
    set({ showEmailPermissionModal: value }),
  emailList: {},
  setEmailList: (value) => {
    const linkedin = value.linkedin;
    if (linkedin) {
      const emailListMain = get().emailList;
      emailListMain[linkedin] = value.email;
      set({ emailList: emailListMain });
    }
  },
  buttonClick: false,
  setButtonClick: (value) =>
    set({
      buttonClick: value,
    }),
  pauseToChooseCustomTemplate: false,
  setPauseToChooseCustomTemplate: (value) =>
    set({ pauseToChooseCustomTemplate: value }),
  customTemplates: [],
  setCustomTemplates: (value) => set({ customTemplates: value }),
  employeeList: {},
  setEmployeeList: (value) => {
    const domain = value.domain;
    if (domain) {
      const employee_list = get().employeeList;
      employee_list[domain] = value.employees;
      set({ employeeList: employee_list });
    }
  },
  initialString: "",
  setInitialString: (value) => set({ initialString: value }),
  saveButtonClickAndSuccess: false,
  setSaveButtonClickAndSuccess: (value) =>
    set({ saveButtonClickAndSuccess: value }),
  showEmailSettings: false,
  setShowEmailSettings: (value) => set({ showEmailSettings: value }),
  editEmailSettings: false,
  setEditEmailSettings: (value) => set({ editEmailSettings: value }),
  referralResumeData: {},
  setReferralResumeData: (value) =>
    set({
      referralResumeData: value,
    }),
  employeeSelected: {},
  setEmployeeSelected: (value) =>
    set({
      employeeSelected: value,
    }),
  showEmailSentSuccess: false,
  setShowEmailSentSuccess: (value) =>
    set({
      showEmailSentSuccess: value,
    }),
  mainReferralPause: {
    status: false,
    text: "",
  },
  setMainReferralPause: (value) =>
    set({
      mainReferralPause: value,
    }),
  emailData: {},
  setEmailData: (value) => set({ emailData: value }),
  employees: [],
  setEmployees: (value) => set({ employees: value }),
  showMainReferralModal: false,
  setShowMainReferralModal: (value) =>
    set({
      showMainReferralModal: value,
    }),
  showReferralFeedback: false,
  setShowReferralFeedback: (value) => set({ showReferralFeedback: value }),
  stepNoReferralFeedback: 0,
  setStepNoReferralFeedback: (value) =>
    set({
      stepNoReferralFeedback: value,
    }),
  isOpenHamburger: true,
  setIsOpenHamburger: (value) =>
    set({
      isOpenHamburger: value,
    }),
  showDemo: false,
  setShowDemo: (value) => set({ showDemo: value }),
  selectedPath: "Home",
  setSelectedPath: (value) => set({ selectedPath: value }),
  promptSelected: {
    form: {
      id: -1,
    },
    prompt: {
      id: -1,
    },
  },
  employeeQuery: "",
  setEmployeeQuery: (value) => set({ employeeQuery: value }),
  stopClick: false,
  setStopClick: (value) => set({ stopClick: value }),
  editEmail: false,
  setEditEmail: (value) =>
    set({
      editEmail: value,
    }),
  emailDataString: "",
  setEmailDataString: (value) => set({ emailDataString: value }),
  setPromptSelected: (value) => set({ promptSelected: value }),
  emailAutomationReferral: false,
  setEmailAutomationReferral: (value) =>
    set({
      emailAutomationReferral: value,
    }),
  selectedCompanies: [],
  addCompany: (index) =>
    set((state) => ({
      selectedCompanies: [...state.selectedCompanies, index],
    })),
  removeCompany: (index) =>
    set((state) => ({
      selectedCompanies: state.selectedCompanies.filter(
        (companyIndex) => companyIndex !== index
      ),
    })),
  clearAll: () => set({ selectedCompanies: [] }),
  selectAll: (indexes) => set({ selectedCompanies: indexes }),
  showMainBlock: true,
  setShowMainBlock: (value) => set({ showMainBlock: value }),
  currentGoalId: -1,
  setCurrentGoalId: (value) => set({ currentGoalId: value }),
  backClick: 0,
  setBackClick: (value) => set({ backClick: value }),
  companies: [],
  setCompanies: (value) => set({ companies: value }),
  searchValue: "",
  setSearchValue: (value) => set({ searchValue: value }),
  resumeReferralData: {},
  setResumeReferralData: (value) => set({ resumeReferralData: value }),
  resumeValidation: 0,
  setResumeValidation: (value) => set({ resumeValidation: value }),
  userPlanDetails: {},
  setUserPlanDetails: (value) => set({ userPlanDetails: value }),
  // lazyapplyV2
  dailyLimit: 0,
  setDailyLimit: (value) => set({ dailyLimit: value }),
  applicationDaily: 50,
  setApplicationDailyLimit: (value) => set({ applicationDaily: value }),
  planDetails: { planStarted: 0, planName: "Free", applicationDailyLimit: 0, type: 'individual', modelName: 'user' },
  setPlanDetails: (value) => set({ planDetails: value }),
  updateLicenses: (licenseData) => set((state) => ({
    planDetails: {
      ...state.planDetails,
      licenses: licenseData
    }
  })),
  resumesMeta: [],
  setResumesMeta: (value) => set({ resumesMeta: value }),
  automationResults: {},
  setAutomationResults: (value) => set({ automationResults: value }),
  streakData: {
    streak: 0,
    streakAddedToday: 0,
    lastStreakCreatedDate: null,
    maxStreak: 0,
  },
  goalData: {
    jobTitle: "Software Developer",
    salary: "50k usd",
    country: "usa",
  },
  planDetailsFetched: 0,
  setPlanDetailsFetched: () => set({ planDetailsFetched: 1 }),
  setGoalData: (data) => set({ goalData: data }),
  setStreakData: (value) => set({ streakData: value }),
  globalLogoutV2: () => {
    console.log("global");
    localStorage.clear();
    set((state) => ({
      token: "",
      isUserAuthenticated: 0,
      imageSrc: null,
      userData: {},
    }));
  },
  enterpriseUser: {
    viewType: 'individual',
    users: [],
    model: null,
    header: null,
    headerValue: null,
    analyticsProfile: null,
    userProfile: null
  },
  setEnterpriseUser: (value) => set((state) => ({
    enterpriseUser: { ...state.enterpriseUser, ...value }
  })),
  selectedMode: "personal",
  selectedUserId: null,
  setSelectedAccessMode: (mode) => set({ selectedMode: mode }),
  setSelectedUserId: (userId) => set({ selectedUserId: userId }),
  navClickCount: 0,
  incrementNavClick: () => set((state) => ({ navClickCount: state.navClickCount + 1 })),
  routeHeaders: {},
  addRouteHeader: (route, headerName, headerValue) => {
    set((state) => {
      const updatedHeaders = { ...state.routeHeaders };

      if (!updatedHeaders[route]) {
        updatedHeaders[route] = {};
      }

      updatedHeaders[route][headerName] = headerValue;
      return { routeHeaders: updatedHeaders };
    });
  },
  removeRouteHeader: (route, headerName) => {
    set((state) => {
      const updatedHeaders = { ...state.routeHeaders };

      if (updatedHeaders[route] && updatedHeaders[route][headerName]) {
        delete updatedHeaders[route][headerName];
      }

      return { routeHeaders: updatedHeaders };
    });
  },
  clearAllHeaders: () => set({ routeHeaders: {} }),
  operatorListPage: 1,
  setOperatorListPage: (page) => set({ operatorListPage: page }),
});

export const useStateStore = create(devtools(store));
