import React, { useState, useEffect } from "react";
import { Box, Typography, Button, TextField, styled } from "@mui/material";
import axios from "axios";
import { useStateStore } from "../../../store";

const FeedbackCard = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1000px",
  margin: "0 auto",
  padding: "32px",
  backgroundColor: "#fff",
  borderRadius: "12px",
}));

const EmojiContainer = styled(Box)({
  display: "flex",
  gap: "32px", // Reduced from 80px
  marginTop: "24px",
  marginBottom: "32px",
  alignItems: "center", // Better alignment
});

const EmojiOption = styled(Box)(({ selected }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  opacity: selected ? 1 : 0.7,
  transition: "all 0.2s ease",
  "&:hover": {
    opacity: 1,
  },
}));

const SubmitButton = styled(Button)({
  backgroundColor: "#2D2D2D",
  color: "#fff",
  padding: "8px 32px",
  height: "50px", // Match textarea height
  borderRadius: "6px",
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 600,
  marginLeft: "20px",
  "&:hover": {
    backgroundColor: "#404040",
  },
  "&:disabled": {
    backgroundColor: "#A0A0A0",
    color: "#D3D3D3",
  },
});

const emojis = [
  { icon: "😞", label: "Very Poor" },
  { icon: "😕", label: "Poor" },
  { icon: "😐", label: "Neutral" },
  { icon: "🙂", label: "Good" },
  { icon: "😃", label: "Excellent" },
];

const FeedbackSection = () => {
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { token, userData } = useStateStore();

  const handleSubmit = async () => {
    if (rating === null) {
      console.log("Please select a rating");
      return;
    }
    console.log("=== Starting Submit ===");
    console.log("Data:", { rating, feedback });
    const v2Id = userData?.v2Id || "";
    try {
      await axios.post(
        `https://backend.lazyapply.com/lazyapplyV2/create-job-automation-feedback/${v2Id}`,
        {
          rating: rating + 1,
          feedback,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      console.log("some error occured in saving feedback", err);
    }
    setSubmitted(true);
    console.log("=== Submit Complete ===");
  };

  if (submitted) {
    return (
      <FeedbackCard sx={{ textAlign: "center", py: 6 }}>
        <Typography
          variant="h5"
          sx={{ fontWeight: 600, color: "#4bce88", mb: 2 }}
        >
          Thank You for Your Feedback!
        </Typography>
        <Typography color="text.secondary" sx={{ fontSize: "16px" }}>
          Your feedback helps us improve LazyApply for everyone.
        </Typography>
      </FeedbackCard>
    );
  }

  return (
    <FeedbackCard>
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        Share Your Feedback
      </Typography>
      <Typography color="text.secondary" sx={{ mt: 1 }}>
        How was your experience?
      </Typography>

      <EmojiContainer>
        {emojis.map((emoji, index) => (
          <EmojiOption
            key={index}
            selected={rating === index}
            onClick={() => {
              setRating(index);
            }}
          >
            <Typography sx={{ fontSize: "32px", mb: 1 }}>
              {emoji.icon}
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#666666" }}>
              {emoji.label}
            </Typography>
          </EmojiOption>
        ))}
      </EmojiContainer>

      <Box
        sx={{
          display: "flex",
          alignItems: "center", // This will center the button vertically
          width: "100%", // Take full width
        }}
      >
        <TextField
          multiline
          rows={2}
          placeholder="Share any additional feedback (optional)"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          sx={{
            flex: 1, // Take available space
            "& .MuiOutlinedInput-root": {
              backgroundColor: "#f8f9fa",
              "& fieldset": {
                borderColor: "#e0e0e0",
              },
            },
          }}
        />
        <SubmitButton disabled={rating === null} onClick={handleSubmit}>
          Submit
        </SubmitButton>
      </Box>
    </FeedbackCard>
  );
};

export default FeedbackSection;
