// api/axios-config.js
import axios from 'axios';

// Add a unique identifier
axios.__configuredInstance = true;

// Store for dynamic headers
const dynamicHeadersStore = {
  routeHeaders: new Map()
};

// Improved URL pattern matching function
const doesUrlMatchPattern = (url, pattern) => {
  // For wildcard patterns (ending with *)
  if (pattern.endsWith('*')) {
    const basePattern = pattern.slice(0, -1);
    return url.startsWith(basePattern);
  }
  // For exact matches
  return url === pattern;
};

// Add the headers interceptor
axios.interceptors.request.use((config) => {
  const url = config.url;
  console.log('Processing request URL:', url);
  
  // Check each route pattern
  dynamicHeadersStore.routeHeaders.forEach((headers, routePattern) => {
    if (doesUrlMatchPattern(url, routePattern)) {
      console.log(`Pattern ${routePattern} matched URL ${url}. Adding headers:`, headers);
      Object.assign(config.headers, headers);
    } else {
      console.log(`Pattern ${routePattern} did NOT match URL ${url}`);
    }
  });
  
  console.log('Final headers for request:', config.headers);
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const addDynamicHeader = (routePattern, headerName, headerValue) => {
  const existingHeaders = dynamicHeadersStore.routeHeaders.get(routePattern) || {};
  dynamicHeadersStore.routeHeaders.set(routePattern, {
    ...existingHeaders,
    [headerName]: headerValue
  });
  console.log(`Added header ${headerName}=${headerValue} for pattern ${routePattern}`);
};

export const removeDynamicHeader = (routePattern, headerName) => {
  const existingHeaders = dynamicHeadersStore.routeHeaders.get(routePattern);
  if (existingHeaders && headerName in existingHeaders) {
    const { [headerName]: removed, ...rest } = existingHeaders;
    dynamicHeadersStore.routeHeaders.set(routePattern, rest);
    console.log(`Removed header ${headerName} for pattern ${routePattern}`);
  }
};

export default axios;