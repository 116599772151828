// components/sections/Miscellaneous/EditMode.jsx
import React, { useState } from "react";
import {
  Grid,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
  Popover,
  Typography,
  Chip,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { SELECT_OPTIONS } from "./constants";

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    "&:hover fieldset": {
      borderColor: "#2D2D2D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2D2D2D",
      borderWidth: "1px",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E0E0E0",
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: "#2D2D2D",
    },
  },
};

const formatNumber = (value) => {
  if (!value) return "";

  const cleanValue = value
    .toString()
    .replace(/,/g, "")
    .replace(/[^\d.]/g, "");

  const [integerPart, decimalPart] = cleanValue.split(".");

  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};

const CountriesSelect = ({ value = [], onChange, options }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempSelection, setTempSelection] = useState(value);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setTempSelection(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDone = () => {
    onChange({ target: { value: tempSelection } });
    handleClose();
  };

  const handleSelectionChange = (selectedValue) => {
    const newSelection = tempSelection.includes(selectedValue)
      ? tempSelection.filter((v) => v !== selectedValue)
      : [...tempSelection, selectedValue];
    setTempSelection(newSelection);
  };

  const handleRemoveValue = (valueToRemove, event) => {
    event.stopPropagation();
    const newSelection = value.filter((v) => v !== valueToRemove);
    onChange({ target: { value: newSelection } });
  };

  const handleClearAll = (event) => {
    event.stopPropagation();
    onChange({ target: { value: [] } });
  };

  const open = Boolean(anchorEl);

  return (
    <FormControl fullWidth sx={textFieldStyles}>
      <InputLabel>Countries Authorized to Work</InputLabel>
      <OutlinedInput
        readOnly
        onClick={handleClick}
        label="Countries Authorized to Work"
        sx={{
          minHeight: "36px",
          alignItems: "flex-start",
          paddingY: "16px",
          flexWrap: "wrap",
          gap: "8px",
          cursor: "pointer",
        }}
        value=""
        endAdornment={
          value.length > 0 && (
            <Button
              onClick={handleClearAll}
              sx={{
                minWidth: "auto",
                color: "#666666",
                borderRadius: "8px",
                mr: 1,
                p: "4px 8px",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  color: "#2D2D2D",
                },
              }}
            >
              Clear All
            </Button>
          )
        }
        startAdornment={
          value.length > 0 ? (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                maxWidth: "calc(100% - 40px)",
                py: 0.5,
              }}
            >
              {value.map((v) => {
                const label = options.find((c) => c.value === v)?.label;
                return (
                  <Chip
                    key={v}
                    label={label}
                    onDelete={(event) => handleRemoveValue(v, event)}
                    onClick={(event) => event.stopPropagation()}
                    size="small"
                    sx={{
                      backgroundColor: "#f5f5f5",
                      "&:hover": {
                        backgroundColor: "#e0e0e0",
                      },
                    }}
                  />
                );
              })}
            </Box>
          ) : (
            <Typography color="text.secondary">Select countries</Typography>
          )
        }
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              width: anchorEl?.offsetWidth,
            },
          },
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            maxHeight: "500px",
          }}
        >
          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
              p: 2,
              pb: 0,
            }}
          >
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                disableRipple
                onClick={() => handleSelectionChange(option.value)}
                sx={{
                  backgroundColor: tempSelection.includes(option.value)
                    ? "#F0F8FF"
                    : "transparent",
                  "&:hover": {
                    backgroundColor: tempSelection.includes(option.value)
                      ? "rgba(0, 0, 0, 0.12)"
                      : "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Box>
          <Box
            sx={{
              p: 2,
              borderTop: "1px solid #E0E0E0",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              position: "sticky",
              bottom: 0,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setTempSelection([]);
                onChange({ target: { value: [] } });
                handleClose();
              }}
              sx={{
                borderColor: "#2D2D2D",
                color: "#2D2D2D",
                fontWeight: 550,
                borderRadius: "8px",
                "&:hover": {
                  borderColor: "#404040",
                  backgroundColor: "rgba(45, 45, 45, 0.04)",
                },
              }}
            >
              Clear All
            </Button>
            <Button
              variant="contained"
              onClick={handleDone}
              sx={{
                backgroundColor: "#2D2D2D",
                borderRadius: "8px",
                fontWeight: 550,
                "&:hover": {
                  backgroundColor: "#404040",
                },
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Popover>
    </FormControl>
  );
};

const SectionHeader = ({ title }) => (
  <Typography
    variant="h6"
    sx={{
      fontSize: "18px",
      fontWeight: 600,
      color: "#2D2D2D",
      mb: 2,
      mt: 1,
    }}
  >
    {title}
  </Typography>
);

const EditMode = ({ data, errors = {}, touched = {}, onChange, onBlur }) => {
  const handleCurrencyChange = (field) => (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/,/g, "").replace(/[^\d.]/g, "");
    event.target.value = formatNumber(inputValue);
    onChange(field)({ target: { value: numericValue } });
  };

  return (
    <Grid container spacing={2.5}>
      <Grid item xs={12}>
        <SectionHeader title="Professional Profiles & Portfolio" />
        <Grid container spacing={2.5}>
          {/* Professional Links */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="LinkedIn URL"
              value={data.linkedinUrl}
              onChange={onChange("linkedinUrl")}
              onBlur={() => onBlur("linkedinUrl")}
              variant="outlined"
              sx={textFieldStyles}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Twitter URL"
              value={data.twitterUrl}
              onChange={onChange("twitterUrl")}
              onBlur={() => onBlur("twitterUrl")}
              variant="outlined"
              sx={textFieldStyles}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="GitHub URL"
              value={data.githubUrl}
              onChange={onChange("githubUrl")}
              onBlur={() => onBlur("githubUrl")}
              variant="outlined"
              sx={textFieldStyles}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Portfolio URL"
              value={data.portfolioUrl}
              onChange={onChange("portfolioUrl")}
              onBlur={() => onBlur("portfolioUrl")}
              variant="outlined"
              sx={textFieldStyles}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Other URL"
              value={data.otherUrl}
              onChange={onChange("otherUrl")}
              onBlur={() => onBlur("otherUrl")}
              variant="outlined"
              sx={textFieldStyles}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <SectionHeader title="Compensation Details" />
        <Grid container spacing={2.5}>
          {/* Compensation Details */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              label="Current Salary"
              value={data.currentSalary ? formatNumber(data.currentSalary) : ""}
              onChange={handleCurrencyChange("currentSalary")}
              onBlur={() => onBlur("currentSalary")}
              error={touched?.currentSalary && !!errors?.currentSalary}
              helperText={touched?.currentSalary && errors?.currentSalary}
              variant="outlined"
              sx={textFieldStyles}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              required
              label="Current Salary Currency"
              value={data.currentSalaryCurrency}
              onChange={onChange("currentSalaryCurrency")}
              onBlur={() => onBlur("currentSalaryCurrency")}
              error={
                touched?.currentSalaryCurrency &&
                !!errors?.currentSalaryCurrency
              }
              helperText={
                touched?.currentSalaryCurrency && errors?.currentSalaryCurrency
              }
              variant="outlined"
              sx={textFieldStyles}
            >
              {SELECT_OPTIONS.currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              label="Expected Salary"
              value={
                data.expectedSalaryAmount
                  ? formatNumber(data.expectedSalaryAmount)
                  : ""
              }
              onChange={handleCurrencyChange("expectedSalaryAmount")}
              onBlur={() => onBlur("expectedSalaryAmount")}
              error={
                touched?.expectedSalaryAmount && !!errors?.expectedSalaryAmount
              }
              helperText={
                touched?.expectedSalaryAmount && errors?.expectedSalaryAmount
              }
              variant="outlined"
              sx={textFieldStyles}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              required
              label="Expected Salary Currency"
              value={data.expectedSalaryCurrency}
              onChange={onChange("expectedSalaryCurrency")}
              onBlur={() => onBlur("expectedSalaryCurrency")}
              error={
                touched?.expectedSalaryCurrency &&
                !!errors?.expectedSalaryCurrency
              }
              helperText={
                touched?.expectedSalaryCurrency &&
                errors?.expectedSalaryCurrency
              }
              variant="outlined"
              sx={textFieldStyles}
            >
              {SELECT_OPTIONS.currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      {/* Experience & Education */}

      <Grid item xs={12}>
        <SectionHeader title="Experience & Education" />
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              label="Total Experience (years)"
              value={data.totalExperience}
              onChange={onChange("totalExperience")}
              onBlur={() => onBlur("totalExperience")}
              error={touched?.totalExperience && !!errors?.totalExperience}
              helperText={touched?.totalExperience && errors?.totalExperience}
              variant="outlined"
              sx={textFieldStyles}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              label="Default Experience (years)"
              value={data.defaultExperience}
              onChange={onChange("defaultExperience")}
              onBlur={() => onBlur("defaultExperience")}
              error={touched?.defaultExperience && !!errors?.defaultExperience}
              helperText={
                touched?.defaultExperience && errors?.defaultExperience
              }
              variant="outlined"
              sx={textFieldStyles}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              label="Notice Period"
              value={data.noticePeriod}
              onChange={onChange("noticePeriod")}
              onBlur={() => onBlur("noticePeriod")}
              error={touched?.noticePeriod && !!errors?.noticePeriod}
              helperText={touched?.noticePeriod && errors?.noticePeriod}
              variant="outlined"
              sx={textFieldStyles}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              required
              label="Highest Education Level"
              value={data.highestEducationLevel}
              onChange={onChange("highestEducationLevel")}
              onBlur={() => onBlur("highestEducationLevel")}
              error={
                touched?.highestEducationLevel &&
                !!errors?.highestEducationLevel
              }
              helperText={
                touched?.highestEducationLevel && errors?.highestEducationLevel
              }
              variant="outlined"
              sx={textFieldStyles}
            >
              {SELECT_OPTIONS.educationLevel.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      {/* Location & Availability */}

      <Grid item xs={12}>
        <SectionHeader title="Location & Availability" />
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="month"
              label="Expected Joining Date"
              value={data.expectedDateOfJoining}
              onChange={onChange("expectedDateOfJoining")}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              sx={textFieldStyles}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              required
              label="Willing to Relocate"
              value={data.willingToRelocation}
              onChange={onChange("willingToRelocation")}
              onBlur={() => onBlur("willingToRelocation")}
              error={
                touched?.willingToRelocation && !!errors?.willingToRelocation
              }
              helperText={
                touched?.willingToRelocation && errors?.willingToRelocation
              }
              variant="outlined"
              sx={textFieldStyles}
            >
              {SELECT_OPTIONS.willingToRelocation.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              label="Driving License"
              value={data.drivingLicense}
              onChange={onChange("drivingLicense")}
              variant="outlined"
              sx={textFieldStyles}
            >
              {SELECT_OPTIONS.drivingLicense.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              label="Visa Requirement"
              value={data.visaRequirement}
              onChange={onChange("visaRequirement")}
              variant="outlined"
              sx={textFieldStyles}
            >
              {SELECT_OPTIONS.visaRequirement.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <CountriesSelect
              value={
                Array.isArray(data.authorisedToWork)
                  ? data.authorisedToWork
                  : []
              }
              onChange={onChange("authorisedToWork")}
              options={SELECT_OPTIONS.countries}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Background Information */}

      <Grid item xs={12}>
        <SectionHeader title="Background Information" />
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              label="Veteran Status"
              value={data.veteranStatus}
              onChange={onChange("veteranStatus")}
              variant="outlined"
              sx={textFieldStyles}
            >
              {SELECT_OPTIONS.veteranStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              label="Disability"
              value={data.disability}
              onChange={onChange("disability")}
              variant="outlined"
              sx={textFieldStyles}
            >
              {SELECT_OPTIONS.disability.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              label="Race/Ethnicity"
              value={data.race}
              onChange={onChange("race")}
              variant="outlined"
              sx={textFieldStyles}
            >
              {SELECT_OPTIONS.race.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              label="Active Security Clearance"
              value={data.securityClearanceActive}
              onChange={onChange("securityClearanceActive")}
              variant="outlined"
              sx={textFieldStyles}
            >
              {SELECT_OPTIONS.securityClearance.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      {/* Additional Information */}

      <Grid item xs={12}>
        <SectionHeader title="Additional Information" />
        <Grid container spacing={2.5}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Companies to Exclude"
              value={data.companiesToExclude}
              onChange={onChange("companiesToExclude")}
              multiline
              rows={2}
              variant="outlined"
              sx={textFieldStyles}
              placeholder="Enter company names separated by commas"
            />
          </Grid>

          {/* Cover Letter */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Cover Letter"
              value={data.coverLetter}
              onChange={onChange("coverLetter")}
              multiline
              rows={4}
              variant="outlined"
              sx={textFieldStyles}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditMode;
