import React from "react";
import { Stack, Typography, styled, Box, LinearProgress } from "@mui/material";
import { useStateStore } from "../../../store";

const AnalyticCard = styled(Stack)(({ variant }) => ({
  backgroundColor:
    variant === "blue"
      ? "rgb(235, 240, 255)"
      : variant === "pink"
        ? "rgb(255, 242, 242)"
        : variant === "yellow"
          ? "rgb(255, 248, 230)"
          : "#FFFFFF",
  borderRadius: "12px",
  padding: "18px 16px 24px",
  display: "flex",
  flexDirection: "column",
  gap: "6x",
  flex: 1,
  transition: "all 0.3s ease",
  border: "0.5px solid rgba(0, 0, 0, 0.05)",
}));

const Label = styled(Typography)(({ theme }) => ({
  color: "#666666",
  fontSize: "15px",
  fontWeight: 500,
  letterSpacing: "0.1px",
}));

const SecondaryLabel = styled(Typography)(({ theme }) => ({
  color: "#666666",
  fontSize: "15px", // Reduced from 14px
  fontWeight: 500,
  letterSpacing: "0.1px",
  textAlign: "right",
}));

const Value = styled(Typography)(({ theme }) => ({
  color: "#2D2D2D",
  fontSize: "22px", // Reduced from 28px
  fontWeight: 600,
  lineHeight: "32px", // Adjusted for new font size
}));

const SecondaryValue = styled(Typography)(({ theme }) => ({
  color: "#2D2D2D",
  fontSize: "17px", // Reduced from 16px
  fontWeight: 500,
  textAlign: "right",
}));

const CardContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  maxWidth: "950px",
  gap: "16px", // Reduced from 24px
  margin: "0 auto",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    gap: "12px",
    flexDirection: "column",
  },
}));

const LabelContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const ValueContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginTop: "10px", // Added small spacing
});

const ComingSoonText = styled(Typography)({
  background: "linear-gradient(45deg, #808080, #2D2D2D, #666666)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundSize: "200% auto",
  animation: "shine 4s linear infinite",
  fontWeight: 600,
  fontSize: "20px",
  "@keyframes shine": {
    "0%": {
      backgroundPosition: "0% center",
    },
    "100%": {
      backgroundPosition: "200% center",
    },
  },
});

// Rest of the component remains the same
const AnalyticsCards = () => {
  const { planDetails, applicationDaily, dailyLimit } = useStateStore();

  const formatDate = (isoDate) => {
    try {
      const date = new Date(isoDate);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
    } catch (error) {
      console.error("Error formatting date:", error);
      return isoDate;
    }
  };

  const oldplans = ['premium_plan_new', 'basic_plan_new', 'unlimited_plan']

  const secondaryValue = (planDetails) => {
    if (planDetails.planId && planDetails.planId !== "") {
      if (oldplans.includes(planDetails.planId)) {
        return 'Lifetime'
      }
    }

    return planDetails?.planEndDate
      ? formatDate(planDetails?.planEndDate)
      : "Never";
  }

  const analyticsData = [
    {
      id: 1,
      label: "Applied Today",
      secondaryLabel: "Daily Limit",
      value: dailyLimit,
      secondaryValue: applicationDaily,
      variant: "blue",
    },
    {
      id: 2,
      label: "Your Plan Details",
      secondaryLabel: "Plan Ending",
      value: planDetails?.planName,
      secondaryValue: secondaryValue(planDetails),
      variant: "pink",
    },
    {
      id: 3,
      label: "Referral Emails",
      secondaryLabel: "Upcoming",
      value: "",
      secondaryValue: "",
      variant: "yellow",
      comingSoonStyle: true,
    },
  ];

  return (
    <CardContainer direction="row">
      {analyticsData.map((item) => (
        <AnalyticCard key={item.id} variant={item.variant}>
          <LabelContainer>
            <Label>{item.label}</Label>
            <SecondaryLabel>{item.secondaryLabel}</SecondaryLabel>
          </LabelContainer>
          <ValueContainer>
            {item.comingSoonStyle ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <ComingSoonText>COMING SOON</ComingSoonText>
                <span
                  style={{
                    lineHeight: 1,
                    display: "inline-flex",
                    alignItems: "center",
                    fontSize: "22px",
                  }}
                >
                  🚀
                </span>
              </Box>
            ) : (
              <>
                <Value>{item.value}</Value>
                {item.secondaryValue && (
                  <SecondaryValue>{item.secondaryValue}</SecondaryValue>
                )}
              </>
            )}
          </ValueContainer>
        </AnalyticCard>
      ))}
    </CardContainer>
  );
};

export default AnalyticsCards;
