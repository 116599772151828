// components/sections/Miscellaneous/ViewMode.jsx
import React from "react";
import { Box, Typography, Link, Grid } from "@mui/material";

const ViewField = ({ label, value, isLink = false }) => {
  if (!value) return null;

  return (
    <Box
      sx={{
        display: "flex",
        mb: 1.5,
        alignItems: "flex-start",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          color: "#000",
          fontSize: "16px",
          width: "180px", // Made wider for longer labels
          flexShrink: 0,
          pt: 0.5,
          fontWeight: 600,
        }}
      >
        {label}
      </Typography>
      {isLink ? (
        <Link
          href={value}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: "#2D2D2D",
            fontSize: "15px",
            fontWeight: 500,
            ml: 2,
            flex: 1,
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {value}
        </Link>
      ) : (
        <Typography
          variant="body1"
          sx={{
            color: "#2D2D2D",
            fontSize: "15px",
            fontWeight: 500,
            ml: 2,
            flex: 1,
          }}
        >
          {value}
        </Typography>
      )}
    </Box>
  );
};

const ViewMode = ({ data }) => {
  const hasData = Object.values(data).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return value && value.toString().trim() !== "";
  });

  if (!hasData) {
    return (
      <Typography
        sx={{
          color: "#666666",
          fontStyle: "italic",
          textAlign: "center",
          py: 4,
        }}
      >
        No Job Preferences information added yet.
      </Typography>
    );
  }

  const formatNumber = (value) => {
    if (!value) return "";

    const cleanValue = value
      .toString()
      .replace(/,/g, "")
      .replace(/[^\d.]/g, "");

    const [integerPart, decimalPart] = cleanValue.split(".");

    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return decimalPart
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {/* Professional Links */}
        <ViewField label="LinkedIn" value={data.linkedinUrl} isLink />
        <ViewField label="Twitter" value={data.twitterUrl} isLink />
        <ViewField label="GitHub" value={data.githubUrl} isLink />
        <ViewField label="Portfolio" value={data.portfolioUrl} isLink />
        <ViewField label="Other URL" value={data.otherUrl} isLink />

        {/* Compensation */}
        <ViewField
          label="Current Salary"
          value={
            data.currentSalary &&
            `${formatNumber(data.currentSalary)} ${data.currentSalaryCurrency}`
          }
        />
        <ViewField
          label="Expected Salary"
          value={
            data.expectedSalaryAmount &&
            `${formatNumber(data.expectedSalaryAmount)} ${
              data.expectedSalaryCurrency
            }`
          }
        />

        {/* Work Details */}
        <ViewField label="Notice Period" value={data.noticePeriod} />
        <ViewField
          label="Total Experience"
          value={data.totalExperience && `${data.totalExperience} years`}
        />
        <ViewField
          label="Default Experience"
          value={data.defaultExperience && `${data.defaultExperience} years`}
        />
        <ViewField
          label="Highest Education"
          value={data.highestEducationLevel}
        />
        <ViewField
          label="Expected Joining Date"
          value={data.expectedDateOfJoining}
        />
        <ViewField
          label="Companies to Exclude"
          value={data.companiesToExclude}
        />
        <ViewField
          label="Willing to Relocate"
          value={data.willingToRelocation}
        />
        <ViewField label="Driving License" value={data.drivingLicense} />
        <ViewField label="Visa Requirement" value={data.visaRequirement} />
        <ViewField
          label="Authorized Work Countries"
          value={
            data.authorisedToWork?.length > 0
              ? data.authorisedToWork.join(", ")
              : null
          }
        />

        {/* Personal Details */}
        <ViewField label="Veteran Status" value={data.veteranStatus} />
        <ViewField label="Disability" value={data.disability} />
        <ViewField label="Race/Ethnicity" value={data.race} />
        <ViewField
          label="Security Clearance"
          value={data.securityClearanceActive}
        />

        {/* Cover Letter */}
        {data.coverLetter && (
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="body2"
              sx={{
                color: "#000",
                fontSize: "16px",
                fontWeight: 600,
                mb: 1,
              }}
            >
              Cover Letter
            </Typography>
            <Typography
              sx={{
                color: "#2D2D2D",
                fontSize: "15px",
                whiteSpace: "pre-wrap",
                p: 2,
                backgroundColor: "#F8F9FA",
                borderRadius: "8px",
              }}
            >
              {data.coverLetter}
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default ViewMode;
