import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  Skeleton,
  Avatar,
  TablePagination,
  TextField,
  InputAdornment,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import WorkIcon from "@mui/icons-material/Work";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SearchIcon from "@mui/icons-material/Search";
import SchoolIcon from "@mui/icons-material/School";
import { useStateStore } from "../../../../store";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// Mock data for students
const mockStudents = [
  {
    id: 1,
    name: "Taylor Johnson",
    email: "taylor.j@springfield.edu",
    department: "Computer Science",
    graduationYear: 2024,
    analytics: {
      emailsSent: 32,
      jobsApplied: 8,
      lastActivity: "2024-02-25T14:32:00Z",
    },
  },
  {
    id: 2,
    name: "Morgan Smith",
    email: "morgan.s@springfield.edu",
    department: "Business Administration",
    graduationYear: 2025,
    analytics: {
      emailsSent: 45,
      jobsApplied: 12,
      lastActivity: "2024-03-01T09:15:00Z",
    },
  },
  {
    id: 3,
    name: "Jamie Williams",
    email: "jamie.w@springfield.edu",
    department: "Engineering",
    graduationYear: 2024,
    analytics: {
      emailsSent: 18,
      jobsApplied: 5,
      lastActivity: "2024-02-28T16:45:00Z",
    },
  },
  {
    id: 4,
    name: "Casey Thompson",
    email: "casey.t@springfield.edu",
    department: "Computer Science",
    graduationYear: 2023,
    analytics: {
      emailsSent: 67,
      jobsApplied: 22,
      lastActivity: "2024-03-02T11:20:00Z",
    },
  },
  {
    id: 5,
    name: "Riley Garcia",
    email: "riley.g@springfield.edu",
    department: "Psychology",
    graduationYear: 2025,
    analytics: {
      emailsSent: 29,
      jobsApplied: 10,
      lastActivity: "2024-03-01T15:10:00Z",
    },
  },
  {
    id: 6,
    name: "Jordan Miller",
    email: "jordan.m@springfield.edu",
    department: "Engineering",
    graduationYear: 2024,
    analytics: {
      emailsSent: 53,
      jobsApplied: 15,
      lastActivity: "2024-02-27T10:05:00Z",
    },
  },
  {
    id: 7,
    name: "Avery Davis",
    email: "avery.d@springfield.edu",
    department: "Business Administration",
    graduationYear: 2023,
    analytics: {
      emailsSent: 41,
      jobsApplied: 14,
      lastActivity: "2024-02-26T13:30:00Z",
    },
  },
  {
    id: 8,
    name: "Quinn Martinez",
    email: "quinn.m@springfield.edu",
    department: "Psychology",
    graduationYear: 2025,
    analytics: {
      emailsSent: 25,
      jobsApplied: 7,
      lastActivity: "2024-03-02T09:45:00Z",
    },
  },
  {
    id: 9,
    name: "Cameron Lee",
    email: "cameron.l@springfield.edu",
    department: "Computer Science",
    graduationYear: 2024,
    analytics: {
      emailsSent: 36,
      jobsApplied: 11,
      lastActivity: "2024-02-29T14:20:00Z",
    },
  },
];

// Helper function to format date
const formatDate = (dateString) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

// Helper function to get activity status and color
const getActivityStatus = (dateString) => {
  const activityDate = new Date(dateString);
  const now = new Date();
  const diffDays = Math.floor((now - activityDate) / (1000 * 60 * 60 * 24));

  if (diffDays < 1) return { status: "Today", color: "success" };
  if (diffDays < 3) return { status: "Recent", color: "primary" };
  if (diffDays < 7) return { status: "This Week", color: "warning" };
  return { status: "Inactive", color: "error" };
};

// Helper function to get department color
const getDepartmentColor = (department) => {
  switch (department) {
    case "Computer Science":
      return { bg: "#E0F2FE", color: "#0369A1" };
    case "Business Administration":
      return { bg: "#DCFCE7", color: "#166534" };
    case "Engineering":
      return { bg: "#F1F5F9", color: "#475569" };
    case "Psychology":
      return { bg: "#FFE4E6", color: "#BE123C" };
    default:
      return { bg: "#F3F4F6", color: "#4B5563" };
  }
};

const StudentAnalyticsTable = ({ timeFrame, dateRange = null }) => {
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const { token } = useStateStore();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error", // can be "error", "warning", "info", "success"
  });

  useEffect(() => {
    setLoading(true);
    axios
      .post(
        "https://backend.lazyapply.com/lazyapplyV2/analytics/operator/users",
        {
          timeframe: timeFrame,
          startDate: dateRange?.start,
          endDate: dateRange?.end,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setStudents(response.data.users);
        } else {
          setStudents([]);
        }
      })
      .catch((error) => {
        openSnackbar("Error fetching users. Please try again.");
        console.error("Error fetching users:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [timeFrame, dateRange, token]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  // Filter students based on search term
  const filteredStudents = students.filter((student) => {
    if (!searchTerm) return true;

    const termLower = searchTerm.toLowerCase();
    return (
      student.name.toLowerCase().includes(termLower) ||
      student.email.toLowerCase().includes(termLower) ||
      student.department.toLowerCase().includes(termLower) ||
      student.graduationYear.toString().includes(termLower)
    );
  });

  const displayedStudents = filteredStudents.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const openSnackbar = (message, severity = "error") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  return (
    <Box>
      <Box
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 600, color: "#2d2d2d" }}>
            User Activity
          </Typography>
          <Typography variant="body2" sx={{ color: "#666" }}>
            View analytics for all enrolled users
          </Typography>
        </Box>

        <TextField
          placeholder="Search users..."
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ width: "240px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#666" }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          border: "1px solid #E0E0E0",
          borderRadius: "12px",
        }}
      >
        <Table>
          <TableHead sx={{ backgroundColor: "#F8F8F8" }}>
            <TableRow>
              <TableCell sx={{ fontWeight: 600, width: "40%" }}>User</TableCell>

              {/* <TableCell sx={{ fontWeight: 600 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <SchoolIcon fontSize="small" sx={{ mr: 0.5 }} />
                  Department
                </Box>
              </TableCell> */}

              <TableCell sx={{ fontWeight: 600, width: "20%" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <EmailIcon fontSize="small" sx={{ mr: 0.5 }} />
                  Emails
                </Box>
              </TableCell>
              <TableCell sx={{ fontWeight: 600, width: "20%" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <WorkIcon fontSize="small" sx={{ mr: 0.5 }} />
                  Jobs
                </Box>
              </TableCell>
              {/* <TableCell sx={{ fontWeight: 600, width: "20%" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CalendarTodayIcon fontSize="small" sx={{ mr: 0.5 }} />
                  Last Active
                </Box>
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              // Loading skeleton
              [...Array(5)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Skeleton
                        variant="circular"
                        width={32}
                        height={32}
                        sx={{ mr: 1 }}
                      />
                      <Box>
                        <Skeleton variant="text" width={120} />
                        <Skeleton variant="text" width={150} height={16} />
                      </Box>
                    </Box>
                  </TableCell>
                  {/* <TableCell>
                    <Skeleton variant="text" width={120} />
                  </TableCell> */}
                  <TableCell>
                    <Skeleton variant="text" width={80} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={80} />
                  </TableCell>
                  {/* <TableCell>
                    <Skeleton variant="text" width={120} />
                  </TableCell> */}
                </TableRow>
              ))
            ) : displayedStudents.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center" sx={{ py: 3 }}>
                  <Typography variant="body1" sx={{ color: "#666" }}>
                    {searchTerm
                      ? "No users found matching your search"
                      : "No users found"}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              displayedStudents.map((student) => {
                const activity = getActivityStatus(
                  student.analytics.lastActivity
                );
                const deptColor = getDepartmentColor(student.department);

                return (
                  <TableRow key={student.id} hover>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          sx={{
                            bgcolor: "#E0F2FE",
                            color: "#0369A1",
                            width: 32,
                            height: 32,
                            mr: 1,
                          }}
                        >
                          {student.name.charAt(0)}
                        </Avatar>
                        <Box>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            {student.name}
                          </Typography>
                          <Typography variant="caption" sx={{ color: "#666" }}>
                            {student.email}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    {/* <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Chip
                          label={student.department}
                          size="small"
                          sx={{
                            fontWeight: 500,
                            bgcolor: deptColor.bg,
                            color: deptColor.color,
                            mr: 1,
                          }}
                        />
                        <Typography variant="caption" sx={{ color: "#666" }}>
                          {student.graduationYear}
                        </Typography>
                      </Box>
                    </TableCell> */}
                    <TableCell>
                      <Typography variant="body2" sx={{ pl: 2 }}>
                        {student.analytics.emailsSent}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" sx={{ pl: 2 }}>
                        {student.analytics.jobsApplied}
                      </Typography>
                    </TableCell>
                    {/* <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Chip
                          label={activity.status}
                          size="small"
                          color={activity.color}
                          sx={{
                            fontWeight: 500,
                            mr: 1,
                          }}
                        />
                        <Typography variant="caption" sx={{ color: "#666" }}>
                          {formatDate(student.analytics.lastActivity)}
                        </Typography>
                      </Box>
                    </TableCell> */}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredStudents.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            borderTop: "1px solid #E0E0E0",
            ".MuiTablePagination-select": {
              borderRadius: "4px",
              backgroundColor: "#F8F8F8",
            },
            ".MuiTablePagination-displayedRows": {
              margin: 0,
            },
            ".MuiTablePagination-actions": {
              marginLeft: 2,
              marginRight: 0,
            },
            ".MuiTablePagination-selectLabel": {
              margin: 0,
            },
          }}
        />
      </TableContainer>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{
            width: "100%",
            fontWeight: 500,
            borderRadius: "8px",
            boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default StudentAnalyticsTable;
