// ShareJourneyForm/steps/Review.jsx
import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";

const Review = ({ formData, updateFormData, onEditSection }) => {
  const handleAgreementChange = (event) => {
    updateFormData("agreement", event.target.checked);
  };

  // Card Header Component for consistent styling
  const CardHeader = ({ title, onEdit, stepIndex }) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 2,
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 600, color: "#2d2d2d" }}>
        {title}
      </Typography>
      <IconButton
        onClick={() => onEditSection(stepIndex)}
        size="small"
        sx={{
          color: "text.secondary",
          "&:hover": { color: "#2d2d2d" },
        }}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    </Box>
  );

  return (
    <Box>
      <Typography
        variant="h6"
        sx={{ fontWeight: 600, color: "#2d2d2d", mb: 1 }}
      >
        Review Your Journey
      </Typography>
      <Typography
        sx={{
          color: "text.secondary",
          fontSize: "0.9rem",
          mb: 4,
        }}
      >
        Please review all information before submitting. You can edit any
        section by clicking the edit icon.
      </Typography>

      {/* Basic Details and Metrics Cards */}
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={6}>
          <Card
            elevation={0}
            sx={{
              height: "100%",
              border: "1px solid",
              borderColor: "grey.200",
              borderRadius: 2,
              bgcolor: "#FEF9DB",
            }}
          >
            <CardContent>
              <CardHeader
                title="Basic Details"
                stepIndex={0}
                onEdit={onEditSection}
              />
              <Box sx={{ color: "text.secondary" }}>
                <Typography sx={{ mb: 1 }}>
                  <strong>Role:</strong> {formData.basicDetails.role}
                </Typography>
                <Typography sx={{ mb: 1 }}>
                  <strong>Company Type:</strong>{" "}
                  {formData.basicDetails.companyType}
                </Typography>
                <Typography sx={{ mb: 1 }}>
                  <strong>Experience Level:</strong>{" "}
                  {formData.basicDetails.experienceLevel}
                </Typography>
                <Typography>
                  <strong>Duration:</strong> {formData.basicDetails.duration}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            elevation={0}
            sx={{
              height: "100%",
              border: "1px solid",
              borderColor: "grey.200",
              borderRadius: 2,
              bgcolor: "#F0FDF0",
            }}
          >
            <CardContent>
              <CardHeader
                title="Application Metrics"
                stepIndex={1}
                onEdit={onEditSection}
              />
              <Box sx={{ color: "text.secondary" }}>
                <Typography sx={{ mb: 1 }}>
                  <strong>Total Applications:</strong>{" "}
                  {formData.metrics.applications}
                </Typography>
                <Typography sx={{ mb: 1 }}>
                  <strong>Response Rate:</strong>{" "}
                  {formData.metrics.responseRate}%
                </Typography>
                <Typography>
                  <strong>Offers:</strong> {formData.metrics.offers}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Timeline Card */}
      <Card
        elevation={0}
        sx={{
          mb: 3,
          border: "1px solid",
          borderColor: "grey.200",
          borderRadius: 2,
          bgcolor: "#F0F4FF",
        }}
      >
        <CardContent>
          <CardHeader
            title="Application Timeline"
            stepIndex={2}
            onEdit={onEditSection}
          />
          <Box sx={{ color: "text.secondary" }}>
            {formData.timeline.map((point, index) =>
              point.dateRange && point.stage && point.count ? (
                <Typography
                  key={index}
                  sx={{ mb: index !== formData.timeline.length - 1 ? 1 : 0 }}
                >
                  <strong>{point.dateRange}:</strong> {point.stage} (
                  {point.count})
                </Typography>
              ) : null
            )}
          </Box>
        </CardContent>
      </Card>

      {/* Resume and Template Cards */}
      <Grid container spacing={3} mb={4}>
        <Grid item xs={12} md={6}>
          <Card
            elevation={0}
            sx={{
              height: "100%",
              border: "1px solid",
              borderColor: "grey.200",
              borderRadius: 2,
              bgcolor: "#F6F8FA",
            }}
          >
            <CardContent>
              <CardHeader
                title="Resume Details"
                stepIndex={3}
                onEdit={onEditSection}
              />
              <Box sx={{ color: "text.secondary" }}>
                <Typography sx={{ mb: 1 }}>
                  <strong>Selected Resume:</strong> SWE Resume - Updated Mar
                  2024
                </Typography>
                <Typography>
                  <strong>Selected Sections:</strong>
                  <br />
                  {formData.resume.selectedSections.join(", ")}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            elevation={0}
            sx={{
              height: "100%",
              border: "1px solid",
              borderColor: "grey.200",
              borderRadius: 2,
              bgcolor: "#FEF4F4",
            }}
          >
            <CardContent>
              <CardHeader
                title="Email Template"
                stepIndex={3}
                onEdit={onEditSection}
              />
              <Box sx={{ color: "text.secondary" }}>
                <Typography>
                  <strong>Selected Template:</strong> FAANG Referral Template
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Agreement Checkbox */}
      {/* <Box mb={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.agreement}
              onChange={handleAgreementChange}
              sx={{
                color: "grey.400",
                "&.Mui-checked": {
                  color: "#2d2d2d",
                },
              }}
            />
          }
          label={
            <Typography sx={{ color: "text.secondary", fontSize: "0.9rem" }}>
              I confirm that all information is accurate and properly
              anonymized. I understand that this will be shared with other users
              to help with their job search journey.
            </Typography>
          }
        />
      </Box> */}
    </Box>
  );
};

export default Review;
