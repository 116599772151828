// components/sections/PersonalInformation/ViewMode.jsx
import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { countryCodeOptions } from "./constants";

const ViewField = ({ label, value }) => (
  <Box
    sx={{
      display: "flex",
      mb: 1.5,
      alignItems: "flex-start",
    }}
  >
    <Typography
      variant="body2"
      sx={{
        color: "#000",
        fontSize: "16px",
        width: "120px",
        flexShrink: 0,
        pt: 0.5,
        fontWeight: 600,
      }}
    >
      {label}
    </Typography>
    <Typography
      variant="body1"
      sx={{
        color: "#2D2D2D",
        fontSize: "17px",
        fontWeight: 500,
        ml: 2,
        flex: 1,
        wordBreak: "break-word",
      }}
    >
      {value || "-"}
    </Typography>
  </Box>
);

const ViewMode = ({ data }) => {
  const getDialCode = (countryCodeValue) => {
    const country = countryCodeOptions.find(
      (option) => option.value === countryCodeValue
    );
    return country ? country.dialCode : countryCodeValue;
  };

  const formattedPhone =
    data.phoneNumber && data.countryCode
      ? `${getDialCode(data.countryCode)} ${data.phoneNumber}`
      : data.phoneNumber;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <ViewField label="First Name" value={data.firstName} />
        <ViewField label="Last Name" value={data.lastName} />
        <ViewField label="Gender" value={data.gender} />
        <ViewField label="Email" value={data.email} />
        <ViewField label="Phone" value={formattedPhone} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ViewField label="Address" value={data.address} />
        <ViewField label="City" value={data.city} />
        <ViewField label="State" value={data.state} />
        <ViewField label="Zip Code" value={data.zipCode} />
        <ViewField label="Country" value={data.country} />
        <ViewField label="Citizenship" value={data.citizenship} />
      </Grid>
    </Grid>
  );
};

export default ViewMode;
