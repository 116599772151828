// OnboardingFlow.jsx
import React, { useState } from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion, AnimatePresence } from "framer-motion";
import lazyapplylogo from "../../ats-dashboard/assets/panda1.png";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { v1 as uuidv1 } from "uuid";

// Import all screen components
import NameScreen from "./screens/NameScreen";
import SecondScreen from "./screens/SecondScreen";
import JobDetailsScreen from "./screens/JobDetailsScreen"; // Our new combined screen
import FinalScreen from "./screens/FinalScreen";
import { useStateStore } from "../../../store";

const MainContainer = styled(Box)({
  minHeight: "100vh",
  background: "linear-gradient(135deg, #F0F8FF 0%, #F5FAFF 50%, #F8FCFF 100%)",
  paddingTop: "40px",
});

const ContentBox = styled(motion.div)({
  backgroundColor: "white",
  borderRadius: "16px",
  padding: "50px 70px",
  minHeight: "650px",
  width: "100%",
  maxWidth: "1000px",
  margin: "0 auto",
  position: "relative",
  boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
  display: "flex",
  flexDirection: "column",
});

const ProgressBar = styled(Box)({
  width: "100%",
  display: "flex",
  gap: "24px",
  marginBottom: "28px",
});

const ProgressStep = styled(Box)(({ active, completed }) => ({
  flex: 1,
  height: "5px",
  backgroundColor: completed || active ? "#4F46E5" : "#E5E7EB",
  borderRadius: "2px",
  transition: "background-color 0.2s ease",
}));

const Logo = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  position: "absolute",
  top: "10px",
  left: "40px",
});

const NavigationButtons = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "auto",
  paddingTop: "30px",
});

const StyledButton = styled(Button)(({ theme }) => ({
  padding: "12px 24px",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "18px",
  fontWeight: 600,
  minWidth: "120px",
  "&.MuiButton-contained": {
    backgroundColor: "#000",
    "&:hover": {
      backgroundColor: "#404040",
    },
    "&.Mui-disabled": {
      backgroundColor: "#E5E7EB",
      color: "#9CA3AF",
    },
  },
  "&.MuiButton-outlined": {
    borderColor: "#E5E7EB",
    color: "#6B7280",
    "&:hover": {
      borderColor: "#000",
      backgroundColor: "rgba(79, 70, 229, 0.04)",
    },
  },
}));

const OnboardingFlow = () => {
  const { token, userData: globalUserData } = useStateStore();
  const [currentStep, setCurrentStep] = useState(1);
  const [userData, setUserData] = useState({
    name: "",
    applyingFor: "",
    role: "",
    jobTitle: "",
    expectedSalary: "",
    expectedSalaryCurrency: "USD",
    preferredCountry: "",
    jobType: "",
    experience: "",
    currentSalary: "",
    currentSalaryCurrency: "USD",
    source: "",
  });

  const history = useHistory();

  // Reduced from 5 to 4 steps
  const totalSteps = 4;

  const isNextDisabled = () => {
    switch (currentStep) {
      case 1:
        return !userData.name.trim();
      case 2:
        if (!userData.applyingFor) return true;
        if (userData.applyingFor === "Business" && !userData.role) return true;
        return false;
      case 3:
        // New validation for combined screen - only essential fields are required
        return (
          !userData.jobTitle.trim() ||
          !userData.expectedSalary ||
          !userData.jobType ||
          !userData.preferredCountry
        );
      case 4:
        return !userData.source;
      default:
        return false;
    }
  };

  const saveUserData = async () => {
    try {
      const formattedData = {
        id: uuidv1(),
        ...userData,
        v2Id: globalUserData?.v2Id || "",
        // Handle the new salary format (which will be ranges, not exact values with currency)
        expectedSalary: userData.expectedSalary,
        currentSalary: userData.currentSalary || "Not specified",
      };
      const response = await axios.post(
        "https://backend.lazyapply.com/lazyapplyV2/onboarding/users",
        formattedData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log(response, "hello");
    } catch (error) {
      console.error("Error saving onboarding data:", error);
    }
  };

  const handleNext = async () => {
    window.scrollTo(0, 0);

    if (currentStep < totalSteps && !isNextDisabled()) {
      setCurrentStep((prev) => prev + 1);
    } else if (currentStep === totalSteps) {
      await saveUserData();
      history.push("/dashboard");
    }
  };

  const handleBack = () => {
    window.scrollTo(0, 0);

    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  // Faster animation variants
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.2,
        ease: "easeOut",
        delay: 0.1,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
  };

  const getButtonLabel = () => {
    if (currentStep === totalSteps) {
      return "Get Started 🎉";
    }
    return "Next";
  };

  // Function to render current screen
  const renderScreen = () => {
    const commonProps = {
      onNext: handleNext,
      onBack: handleBack,
      userData,
      setUserData,
    };

    switch (currentStep) {
      case 1:
        return <NameScreen {...commonProps} />;
      case 2:
        return <SecondScreen {...commonProps} />;
      case 3:
        return <JobDetailsScreen {...commonProps} />;
      case 4:
        return <FinalScreen {...commonProps} />;
      default:
        return null;
    }
  };

  return (
    <MainContainer>
      <Logo>
        <img src={lazyapplylogo} alt="Logo" style={{ height: "80px" }} />
        <Typography fontSize="22px" fontWeight={700} color="#2d2d2d">
          LazyApply
        </Typography>
      </Logo>

      <Container maxWidth="lg" sx={{ mt: 8 }}>
        <ContentBox>
          <ProgressBar>
            {[...Array(totalSteps)].map((_, index) => (
              <ProgressStep
                key={index + 1}
                active={currentStep === index + 1}
                completed={currentStep > index + 1}
              />
            ))}
          </ProgressBar>

          <AnimatePresence mode="wait">
            <motion.div
              key={currentStep}
              initial="initial"
              animate="animate"
              exit="exit"
              variants={pageVariants}
              style={{ flex: 1 }}
            >
              {renderScreen()}
            </motion.div>
          </AnimatePresence>

          <NavigationButtons>
            <StyledButton
              variant="outlined"
              onClick={handleBack}
              sx={{
                visibility: currentStep === 1 ? "hidden" : "visible",
              }}
            >
              Back
            </StyledButton>
            <StyledButton
              variant="contained"
              onClick={handleNext}
              disabled={isNextDisabled()}
            >
              {getButtonLabel()}
            </StyledButton>
          </NavigationButtons>
        </ContentBox>
      </Container>
    </MainContainer>
  );
};

export default OnboardingFlow;
