import React, { useState, useEffect } from "react";
import { Grid, Box, Button } from "@mui/material";
import BaseSection from "../BaseSection";
import ViewMode from "./ViewMode";
import EditMode from "./EditMode";

const validateAchievements = (achievements) => {
  const errors = [];

  achievements.forEach((achievement, index) => {
    const achievementErrors = {};

    if (!achievement.awardTitle?.trim()) {
      achievementErrors.awardTitle = "Award title is required";
    }
    if (!achievement.issuer?.trim()) {
      achievementErrors.issuer = "Issuer is required";
    }

    // if (!achievement.description?.trim()) {
    //   achievementErrors.description = "Description is required";
    // }

    // Only add errors if there are any
    if (Object.keys(achievementErrors).length > 0) {
      errors[index] = achievementErrors;
    }
  });

  return errors;
};

const Achievements = ({ data = [], isExpanded, onToggleExpand, onUpdate }) => {
  const [achievements, setAchievements] = useState(data);
  const [errors, setErrors] = useState([]);
  const [touched, setTouched] = useState([]);
  const [sectionStatus, setSectionStatus] = useState("missing_optional");

  const validateField = (field, value) => {
    if (!value?.toString().trim()) {
      return `${field} is required`;
    }
    return "";
  };

  const handleChange = (newAchievements) => {
    setAchievements(newAchievements);

    // Clear errors for valid fields
    const newErrors = [...errors];
    newAchievements.forEach((achievement, index) => {
      if (newErrors[index]) {
        Object.keys(newErrors[index]).forEach((field) => {
          if (achievement[field]?.toString().trim()) {
            delete newErrors[index][field];
          }
        });

        // Remove the error object if empty
        if (Object.keys(newErrors[index]).length === 0) {
          delete newErrors[index];
        }
      }
    });
    setErrors(newErrors);
  };

  const handleBlur = (index, field) => {
    const newTouched = [...touched];
    if (!newTouched[index]) newTouched[index] = {};
    newTouched[index][field] = true;
    setTouched(newTouched);

    // Validate the field
    const fieldError = validateField(field, achievements[index][field]);
    const newErrors = [...errors];
    if (!newErrors[index]) newErrors[index] = {};

    if (fieldError) {
      newErrors[index][field] = fieldError;
    } else {
      delete newErrors[index][field];
      // Remove empty error objects
      if (Object.keys(newErrors[index]).length === 0) {
        delete newErrors[index];
      }
    }
    setErrors(newErrors);
  };

  const handleAddAchievement = () => {
    setAchievements((prev) => [
      ...prev,
      {
        awardTitle: "",
        issuer: "",
        issuingDate: "",
        description: "",
      },
    ]);
  };

  const handleSave = () => {
    const validationErrors = validateAchievements(achievements);

    if (validationErrors.length === 0) {
      const newStatus = getSectionStatus(achievements);
      setSectionStatus(newStatus);
      onUpdate(achievements);
      setErrors([]);
      setTouched([]);
      return true;
    }

    setErrors(validationErrors);
    const touchedFields = achievements.map(() => ({
      awardTitle: true,
      issuer: true,
      // description: true,
    }));
    setTouched(touchedFields);
    return false;
  };

  const handleCancel = () => {
    setAchievements(data);
    setErrors([]);
    setTouched([]);
    setSectionStatus(getSectionStatus(data));
  };

  const handleDelete = (indexToDelete) => {
    const updatedAchievements = achievements.filter(
      (_, index) => index !== indexToDelete
    );
    setAchievements(updatedAchievements);
    setErrors(errors.filter((_, index) => index !== indexToDelete));
    setTouched(touched.filter((_, index) => index !== indexToDelete));
    onUpdate(updatedAchievements);
  };

  const getSectionStatus = (achievementsData) => {
    // If no achievements, it's optional
    if (achievementsData.length === 0) {
      return "missing_optional";
    }

    // If there are achievements, check if they're properly filled
    const hasAllRequired = achievementsData.every(
      (achievement) =>
        achievement.awardTitle?.trim() && achievement.issuer?.trim()
      // && achievement.description?.trim()
    );

    // Check for any validation errors
    const hasErrors = errors.some(
      (error) => error && Object.keys(error).length > 0
    );

    if (hasAllRequired && !hasErrors) {
      return "complete";
    } else {
      return "missing_required"; // If started but not complete
    }
  };

  useEffect(() => {
    const initialStatus = getSectionStatus(data);
    setSectionStatus(initialStatus);
  }, [data]);

  // Check if form is valid (no errors present)
  const isFormValid = !errors.some(
    (error) => error && Object.keys(error).length > 0
  );

  return (
    <BaseSection
      title="Achievements"
      isExpanded={isExpanded}
      onToggleExpand={onToggleExpand}
      onSave={handleSave}
      onCancel={handleCancel}
      canSave={isFormValid}
      sectionStatus={sectionStatus}
    >
      {({ isEditing, handleSave, handleCancel }) => (
        <Grid container>
          {isEditing ? (
            <>
              <EditMode
                achievements={achievements}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                onAdd={handleAddAchievement}
                onDelete={handleDelete}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  mt: 3,
                  borderTop: "1px solid #E0E0E0",
                  pt: 3,
                  width: "100%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  sx={{
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: 600,
                    padding: "4px 12px",
                    color: "#666666",
                    borderColor: "#666666",
                    "&:hover": {
                      borderColor: "#2D2D2D",
                      backgroundColor: "rgba(45, 45, 45, 0.04)",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={!isFormValid}
                  sx={{
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: 600,
                    padding: "6px 18px",
                    backgroundColor: isFormValid ? "#2D2D2D" : "#E0E0E0",
                    color: isFormValid ? "#FFFFFF" : "#666666",
                    "&:hover": {
                      backgroundColor: isFormValid ? "#404040" : "#E0E0E0",
                    },
                  }}
                >
                  Save
                </Button>
              </Box>
            </>
          ) : (
            <ViewMode achievements={achievements} />
          )}
        </Grid>
      )}
    </BaseSection>
  );
};

export default Achievements;
