// components/ProfileControls.jsx
import React from "react";
import { Box, Button, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";

const ProfileControls = () => {
  const history = useHistory();

  return (
    <Box
      sx={{
        mb: 4,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => history.push("/dashboard/profile")}
        sx={{
          mb: 2,
          color: "#666",
          borderRadius: "8px",
          padding: "8px 12px",
          fontSize: "17px",
          fontWeight: "500",
          textTransform: "none",
          "&:hover": {
            color: "#000",
            background: "none",
          },
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          Back to Profiles
        </Typography>
      </Button>

      <Button
        variant="contained"
        onClick={() => history.push("/dashboard/pricing")}
        sx={{
          backgroundColor: "#2D2D2D",
          mb: 0.5,
          color: "#fff",
          borderRadius: "8px",
          padding: "6px 16px",
          fontSize: "17px",
          fontWeight: "500",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#404040",
          },
        }}
      >
        Explore Plans
      </Button>
    </Box>
  );
};

export default ProfileControls;
