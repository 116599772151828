import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  IconButton,
  TextField,
  Button,
  CircularProgress,
  Switch,
  DialogContent,
  Dialog,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";

const variables = [
  { name: "Job Title", color: "#4A90E2" },
  { name: "Company Name", color: "#50B83C" },
  { name: "Role", color: "#9B51E0" },
  { name: "Recipient Name", color: "#F5A623" },
];

const VariableChip = styled(Box)(({ color }) => ({
  display: "inline-flex",
  alignItems: "center",
  padding: "8px 16px",
  borderRadius: "20px",
  backgroundColor: `${color}10`,
  border: `1px solid ${color}`,
  color: color,
  fontSize: "14px",
  cursor: "pointer",
  transition: "all 0.2s ease",
  "&:hover": {
    backgroundColor: `${color}20`,
    transform: "translateY(-1px)",
  },
}));

const EditorCard = styled(Paper)({
  padding: "24px",
  borderRadius: "12px",
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  height: "550px",
  display: "flex",
  flexDirection: "column",
});

const StyledTextArea = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#f8f9fa",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
    "& textarea": {
      backgroundColor: "#f8f9fa",
    },
  },
  "& .MuiInputBase-input": {
    lineHeight: "1.8",
    fontSize: "16px",
    "& [data-variable]": {
      backgroundColor: "red",
    },
    height: "100%",
  },
}));

const ContentArea = styled(Box)({
  flex: 1,
  overflow: "auto",
  marginTop: "16px",
});

const PreviewText = styled(Typography)({
  whiteSpace: "pre-wrap",
  lineHeight: "1.8",
  padding: "16px",
  backgroundColor: "#f8f9fa",
  borderRadius: "8px",
  height: "100%",
  overflow: "auto",
});

const VariableInput = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#fff",
  },
});

const TemplateEditor = ({ template, onSave, onBack, isNewTemplate }) => {
  const [content, setContent] = useState(template?.content || "");
  const [tempVariableValues, setTempVariableValues] = useState({
    "Job Title": "Software Engineer",
    "Company Name": "Google",
    Role: "Frontend Developer",
    "Recipient Name": "John Smith",
  });
  const [variableValues, setVariableValues] = useState({
    "Job Title": "Software Engineer",
    "Company Name": "Google",
    Role: "Frontend Developer",
    "Recipient Name": "John Smith",
  });
  const editorRef = useRef(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [attachResume, setAttachResume] = useState(
    template?.attachResume || true
  );

  const [templateName, setTemplateName] = useState(
    template?.name || "Untitled Template"
  );
  const [nameError, setNameError] = useState("");
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [subject, setSubject] = useState(template?.subject || "");
  const [lastFocusedInput, setLastFocusedInput] = useState(null);

  const subjectRef = useRef(null);
  const contentRef = useRef(null);

  const handleVariableValueChange = (variable, value) => {
    setTempVariableValues((prev) => ({
      ...prev,
      [variable]: value,
    }));
  };

  const handleNameChange = (e) => {
    setTemplateName(e.target.value);
    setNameError("");
    setHasChanges(true);
  };

  const saveVariableValues = () => {
    setVariableValues(tempVariableValues);
  };

  const handleFocus = (inputType) => {
    setLastFocusedInput(inputType);
  };

  const handleVariableClick = (variable) => {
    const newVariable = `[${variable.name}]`;

    if (lastFocusedInput === "subject") {
      const input = subjectRef.current;
      if (!input) return;

      const start = input.selectionStart;
      const end = input.selectionEnd;
      const newSubject =
        subject.substring(0, start) + newVariable + subject.substring(end);
      setSubject(newSubject);
      setHasChanges(true);

      setTimeout(() => {
        input.focus();
        const newPosition = start + newVariable.length;
        input.setSelectionRange(newPosition, newPosition);
      }, 0);
    } else if (lastFocusedInput === "content") {
      const input = contentRef.current;
      if (!input) return;

      const start = input.selectionStart;
      const end = input.selectionEnd;
      const newContent =
        content.substring(0, start) + newVariable + content.substring(end);
      setContent(newContent);
      setHasChanges(true);

      setTimeout(() => {
        input.focus();
        const newPosition = start + newVariable.length;
        input.setSelectionRange(newPosition, newPosition);
      }, 0);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      const activeElement = document.activeElement;
      const isSubjectField = activeElement === subjectRef.current;
      const isContentField = activeElement === contentRef.current;

      if (isSubjectField || isContentField) {
        const start = activeElement.selectionStart;
        const end = activeElement.selectionEnd;
        const currentText = isSubjectField ? subject : content;

        // Check if cursor is right after a variable
        const beforeCursor = currentText.substring(0, start);
        const match = beforeCursor.match(/\[[^\]]*\]$/);

        if (match && start === end) {
          e.preventDefault();
          const newText =
            currentText.substring(0, start - match[0].length) +
            currentText.substring(end);

          if (isSubjectField) {
            setSubject(newText);
          } else {
            setContent(newText);
          }
          setHasChanges(true);

          setTimeout(() => {
            activeElement.focus();
            const newPosition = start - match[0].length;
            activeElement.setSelectionRange(newPosition, newPosition);
          }, 0);
        }
      }
    }
  };

  const handleContentChange = (e) => {
    setContent(e.target.value);
    setHasChanges(true);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    setHasChanges(true);
  };

  const handleSave = () => {
    if (!templateName.trim()) {
      setShowSuccess("error");
      return;
    }

    setIsSaving(true);

    setShowSuccess("success");

    setTimeout(() => {
      setShowSuccess(false);
      onSave({
        ...template,
        name: templateName.trim(),
        content: content,
        subject: subject,
        attachResume: attachResume,
      });
      setIsSaving(false);
      setHasChanges(false);
    }, 1000);
  };

  const renderColoredContent = () => {
    let processedContent = content;
    variables.forEach((variable) => {
      const regex = new RegExp(`\\[${variable.name}\\]`, "g");
      processedContent = processedContent.replace(
        regex,
        `<span style="color: ${variable.color}; background-color: ${variable.color}10; border: 1px solid ${variable.color}; border-radius: 12px; padding: 2px 8px; margin: 0 2px; display: inline-block; font-size: 14px;">[${variable.name}]</span>`
      );
    });
    return processedContent;
  };

  const renderPreview = (text) => {
    if (!text) return null;

    const elements = [];
    let lastIndex = 0;
    const regex = /\[(.*?)\]/g;
    let match;

    while ((match = regex.exec(text)) !== null) {
      if (match.index > lastIndex) {
        elements.push(
          <span key={`text-${lastIndex}`}>
            {text.slice(lastIndex, match.index)}
          </span>
        );
      }

      const variableName = match[1];
      const variable = variables.find((v) => v.name === variableName);
      const color = variable ? variable.color : "#666666";

      elements.push(
        <span
          key={`var-${match.index}`}
          style={{
            color: color,
            backgroundColor: `${color}10`,
            border: `1px solid ${color}`,
            borderRadius: "12px",
            padding: "1px 8px",
            margin: "0 2px",
            display: "inline-block",
            fontSize: "14px",
          }}
        >
          {variableValues[variableName] || variableName}
        </span>
      );

      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < text.length) {
      elements.push(
        <span key={`text-${lastIndex}`}>{text.slice(lastIndex)}</span>
      );
    }

    return elements;
  };

  return (
    <Box>
      <Box sx={{ mb: 4 }}>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={onBack} sx={{ mr: 2 }}>
              <ArrowBackIcon />
            </IconButton>
            <Box
              onClick={() => {
                const input = document.querySelector(
                  `input[value="${templateName}"]`
                );
                if (input) input.focus();
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                backgroundColor: "#f8f9fa",
                borderRadius: "6px",
                p: "6px 12px",
                cursor: "pointer",
                transition: "background-color 0.2s",
                "&:hover": {
                  backgroundColor: "#f0f2f5",
                },
              }}
            >
              <TextField
                value={templateName}
                onChange={handleNameChange}
                onFocus={() => setIsNameFocused(true)}
                onBlur={() => setIsNameFocused(false)}
                error={!!nameError}
                helperText={nameError}
                placeholder="Enter template name"
                variant="standard"
                sx={{
                  "& .MuiInput-root": {
                    fontSize: "20px",
                    fontWeight: 600,
                    "&:before": {
                      borderBottom: "none",
                    },
                    "&:hover:before, &.Mui-focused:before": {
                      borderBottom: "1px solid rgba(0,0,0,0.42) !important",
                    },
                    "&:after": {
                      borderBottom: "none",
                    },
                    backgroundColor: "transparent",
                  },
                  "& .MuiFormHelperText-root": {
                    position: "absolute",
                    top: "100%",
                    color: (theme) => theme.palette.error.main,
                  },
                }}
              />
              <EditIcon
                className="edit-icon"
                sx={{
                  opacity: isNameFocused ? 0.6 : 0.4,
                  transition: "opacity 0.2s",
                  color: "#666",
                  fontSize: 18,
                }}
              />
            </Box>
          </Box>
          <Typography
            variant="caption"
            sx={{
              color: "#666",
              mt: 0.5,
              ml: 8,
              display: "block",
              fontSize: "13px",
            }}
          >
            Click to edit template name
          </Typography>
        </Box>

        <Box sx={{ mb: 3, mt: 6 }}>
          <Typography sx={{ mb: 3, color: "#666", fontSize: "17px" }}>
            Enter the actual values for your variables below. Click save to
            update the preview section:
          </Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            {variables.map((variable) => (
              <Grid item xs={12} sm={6} md={3} key={`input-${variable.name}`}>
                <VariableInput
                  fullWidth
                  size="small"
                  label={variable.name}
                  value={tempVariableValues[variable.name]}
                  onChange={(e) =>
                    handleVariableValueChange(variable.name, e.target.value)
                  }
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={saveVariableValues}
                sx={{
                  mb: 3,
                  fontSize: "15px",
                  fontWeight: 500,
                  padding: "6px 20px",
                  borderRadius: "8px",
                  textTransform: "none",
                  color: "#2d2d2d",
                  backgroundColor: "#f8f9fa",
                  "&:hover": {
                    backgroundColor: "rgba(45, 45, 45, 0.04)",
                  },
                }}
              >
                Apply Values
              </Button>
            </Grid>
          </Grid>
          <Typography sx={{ mb: 2, color: "#666", fontSize: "17px" }}>
            Click on any variable below to insert it at cursor position. Use
            backspace to remove a variable. Variables will be replaced with
            their values in the preview.
          </Typography>
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            {variables.map((variable) => (
              <VariableChip
                key={variable.name}
                color={variable.color}
                onClick={() => handleVariableClick(variable)}
              >
                {variable.name}
              </VariableChip>
            ))}
          </Box>
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <EditorCard>
            <Typography sx={{ color: "#666" }}>Template Content</Typography>
            <Box sx={{ mb: 2 }}>
              <Typography
                sx={{ color: "#666", mb: 1, mt: 1.5, fontSize: "14px" }}
              >
                Subject:
              </Typography>
              <StyledTextArea
                fullWidth
                size="small"
                value={subject}
                onChange={handleSubjectChange}
                onKeyDown={handleKeyDown}
                placeholder="Enter email subject"
                variant="outlined"
                inputRef={subjectRef}
                onFocus={() => handleFocus("subject")}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "40px",
                  },
                }}
              />
            </Box>
            <ContentArea>
              <StyledTextArea
                ref={editorRef}
                multiline
                fullWidth
                value={content}
                onChange={handleContentChange}
                onKeyDown={handleKeyDown}
                variant="outlined"
                inputRef={contentRef}
                onFocus={() => handleFocus("content")}
                sx={{ height: "100%" }}
                InputProps={{
                  sx: { height: "100%", alignItems: "flex-start" },
                }}
              />
            </ContentArea>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 1,
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={attachResume}
                    onChange={(e) => {
                      setAttachResume(e.target.checked);
                      setHasChanges(true);
                    }}
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#2D2D2D",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        {
                          backgroundColor: "#2D2D2D",
                        },
                    }}
                  />
                }
                label="Attach Resume"
                sx={{
                  "& .MuiFormControlLabel-label": {
                    color: "#666",
                    fontSize: "15px",
                  },
                }}
              />
            </Box>
            <Button
              fullWidth
              variant="contained"
              disableRipple
              onClick={handleSave}
              disabled={(!hasChanges && !isNewTemplate) || isSaving}
              sx={{
                mt: 1,
                padding: "8px 16px",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: 550,
                borderRadius: "8px",
                backgroundColor:
                  hasChanges || isNewTemplate ? "#2D2D2D" : "#666",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor:
                    hasChanges || isNewTemplate ? "#404040" : "#666",
                },
              }}
            >
              {isSaving ? (
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              ) : hasChanges || isNewTemplate ? (
                "Save Changes"
              ) : (
                "No Changes"
              )}
            </Button>
          </EditorCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <EditorCard>
            <Typography sx={{ color: "#666" }}>Email Preview</Typography>
            <Box sx={{ mb: 1, mt: 1.5 }}>
              <Typography sx={{ color: "#666", mb: 1, fontSize: "14px" }}>
                Subject:
              </Typography>
              <PreviewText sx={{ px: 2, py: 1, mb: 1, height: "auto" }}>
                {renderPreview(subject)}
              </PreviewText>
            </Box>
            <ContentArea>
              <PreviewText>{renderPreview(content)}</PreviewText>
            </ContentArea>
            {attachResume && (
              <Typography
                sx={{
                  mt: 2,
                  color: "#666",
                  fontSize: "16px",
                  fontStyle: "italic",
                }}
              >
                Resume will be attached to this email
              </Typography>
            )}
          </EditorCard>
        </Grid>
      </Grid>

      <Dialog
        open={!!showSuccess}
        onClose={() => setShowSuccess(false)}
        PaperProps={{
          sx: {
            minWidth: "320px",
            borderRadius: "12px",
            p: 2,
            textAlign: "center",
            position: "relative",
            backgroundColor: "#F8FCFF",
          },
        }}
      >
        <DialogContent sx={{ p: 2 }}>
          {showSuccess === "success" ? (
            <Box sx={{ color: "#2D2D2D" }}>
              <CheckCircleIcon
                sx={{
                  fontSize: 40,
                  animation: "scale-in 0.3s ease-out",
                  "@keyframes scale-in": {
                    "0%": { transform: "scale(0)" },
                    "50%": { transform: "scale(1.2)" },
                    "100%": { transform: "scale(1)" },
                  },
                }}
              />
              <Typography sx={{ mt: 1, fontSize: "16px" }}>
                Changes saved successfully!
              </Typography>
            </Box>
          ) : (
            <Box>
              <IconButton
                onClick={() => setShowSuccess(false)}
                size="small"
                sx={{
                  position: "absolute",
                  right: 2,
                  top: 2,
                  p: 1,
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
              >
                <CloseIcon sx={{ fontSize: 20, color: "#d32f2f" }} />
              </IconButton>
              <Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    color: "#404040",
                    fontWeight: 600,
                    p: 1,
                  }}
                >
                  Please enter a template name
                </Typography>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default TemplateEditor;
