import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// Styled components
const CardWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  borderRadius: "16px",
  padding: "28px", // Increased padding
  height: "280px", // Increased height
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  maxWidth: "100%",
  margin: "0 auto",
}));
const StyledButton = styled(Button)(({ variant }) => ({
  textTransform: "none",
  padding: "8px 28px", // Slightly larger buttons
  borderRadius: "12px",
  fontSize: "16px", // Slightly larger font
  fontWeight: 600,
  ...(variant === "contained" && {
    backgroundColor: "#2D2D2D",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#404040",
    },
  }),
  ...(variant === "outlined" && {
    borderColor: "#2D2D2D",
    color: "#2D2D2D",
    "&:hover": {
      borderColor: "#FF4D4F",
      backgroundColor: "rgba(255, 77, 79, 0.04)",
      color: "#FF4D4F",
    },
  }),
}));
const StyledLinearProgress = styled(LinearProgress)(({ value }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: "#F0F0F0",
  "& .MuiLinearProgress-bar": {
    borderRadius: 5,
    background:
      value <= 33
        ? "linear-gradient(90deg, #FF6666 0%, #FF9966 100%)"
        : value <= 66
        ? "linear-gradient(90deg, #FF9966 0%, #FFC107 100%)"
        : "linear-gradient(90deg, #4BCE88 0%, #35A347 100%)",
    transition: "all 0.3s ease",
  },
}));
const DialogButton = styled(Button)(({ variant }) => ({
  textTransform: "none",
  padding: "8px 28px",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: 600,
  ...(variant === "contained" && {
    backgroundColor: "#FF4D4F",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#E54042",
    },
  }),
}));
const ProfileCard = ({ profile, onEdit, onDelete }) => {
  const { title, lastModified, completionPercentage } = profile;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleConfirmDelete = () => {
    onDelete();
    setOpenDeleteModal(false);
  };
  return (
    <CardWrapper>
      {/* Title and Last Modified */}
      <Box>
        <Typography
          variant="h6"
          sx={{
            color: "#2D2D2D",
            fontWeight: 600,
            fontSize: "22px",
            mb: 2,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#666666",
            fontSize: "16px", // Increased font size
          }}
        >
          Last modified: {lastModified}
        </Typography>
      </Box>
      {/* Progress Section */}
      <Box sx={{ my: 3 }}>
        {" "}
        {/* <LinearProgress
          variant="determinate"
          value={completionPercentage}
          sx={{
            height: 10, // Slightly thicker progress bar
            borderRadius: 5,
            backgroundColor: "#F0F0F0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#2D2D2D",
              borderRadius: 5,
            },
          }}
        /> */}
        <StyledLinearProgress
          variant="determinate"
          value={completionPercentage}
        />
        <Typography
          variant="body2"
          sx={{
            color: "#666666",
            fontSize: "16px", // Increased font size
            mt: 1.5, // Increased margin
          }}
        >
          {completionPercentage}% Complete
        </Typography>
      </Box>
      {/* Action Buttons */}
      <Stack direction="row" spacing={2}>
        <StyledButton variant="contained" onClick={onEdit}>
          Edit
        </StyledButton>
        <StyledButton variant="outlined" onClick={handleOpenDeleteModal}>
          Delete
        </StyledButton>
      </Stack>
      {/* Delete Confirmation Modal */}
      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "16px",
            padding: "8px",
          },
        }}
      >
        <DialogTitle
          id="delete-dialog-title"
          sx={{ fontSize: "20px", fontWeight: 600 }}
        >
          Delete Resume Profile
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="delete-dialog-description"
            sx={{ color: "#666666" }}
          >
            Are you sure you want to delete the profile "{title}"? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "16px 24px" }}>
          <StyledButton variant="contained" onClick={handleCloseDeleteModal}>
            Cancel
          </StyledButton>
          <DialogButton variant="contained" onClick={handleConfirmDelete}>
            Delete
          </DialogButton>
        </DialogActions>
      </Dialog>
    </CardWrapper>
  );
};
export default ProfileCard;
