import React, { useEffect } from "react";
import { styled, keyframes } from "@mui/material/styles";
import { Box, Typography, Button, Container } from "@mui/material";
import { useHistory } from "react-router-dom";
import lazyapplylogo from "./assets/panda1.png";

// Animations
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const drawCheck = keyframes`
  from { stroke-dashoffset: 100; }
  to { stroke-dashoffset: 0; }
`;

const pulseCircle = keyframes`
  0% { transform: scale(1); opacity: 0.3; }
  50% { transform: scale(1.1); opacity: 0.1; }
  100% { transform: scale(1); opacity: 0.3; }
`;

// Styled Components
const PageContainer = styled(Container)({
  minHeight: "90vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px",
});

const SuccessCard = styled(Box)({
  width: "100%",
  maxWidth: "600px",
  backgroundColor: "#fff",
  borderRadius: "12px",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
  animation: `${fadeIn} 0.6s ease-out`,
});

const Header = styled(Box)({
  background: "#2d2d2d",
  padding: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const LogoContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "6px",
});

const LogoImage = styled("img")({
  width: "120px", // Adjusted size for better proportion with text
  height: "auto",
});

const LogoText = styled(Typography)({
  color: "#fff",
  fontSize: "36px",
  fontWeight: 600,
  marginRight: "50px",
});

const ContentSection = styled(Box)({
  padding: "44px 24px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const CheckmarkContainer = styled(Box)({
  position: "relative",
  width: "100px",
  height: "100px",
  marginBottom: "12px",
});

const HomeButton = styled(Button)({
  backgroundColor: "#2d2d2d",
  marginTop: "36px",
  marginBottom: "6px",
  color: "#fff",
  padding: "12px 32px",
  borderRadius: "25px",
  textTransform: "none",
  fontSize: "16px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#404040",
  },
});

const PaymentSuccess = ({ planType = "Premium" }) => {
  const history = useHistory();

  // Optional: Auto-redirect after a delay
  useEffect(() => {
    const timer = setTimeout(() => {
      // navigate('/'); // Uncomment to enable auto-redirect
    }, 10000);
    return () => clearTimeout(timer);
  }, [history]);

  return (
    <PageContainer>
      <SuccessCard>
        <Header>
          <LogoContainer>
            <LogoText>LazyApply</LogoText>
            <LogoImage src={lazyapplylogo} alt="LazyApply" />
          </LogoContainer>
        </Header>

        <ContentSection>
          <CheckmarkContainer>
            <svg viewBox="0 0 100 100" width="100" height="100">
              {/* Outer pulse circle */}
              <circle
                cx="50"
                cy="50"
                r="45"
                fill="#4bce88"
                opacity="0.1"
                style={{
                  animation: `${pulseCircle} 2s infinite`,
                  transformOrigin: "center",
                }}
              />
              {/* Main circle */}
              <circle cx="50" cy="50" r="40" fill="#4bce88" />
              {/* Checkmark */}
              <path
                d="M30 50 L45 65 L70 35"
                stroke="#fff"
                strokeWidth="6"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{
                  strokeDasharray: 100,
                  animation: `${drawCheck} 0.8s ease forwards`,
                }}
              />
            </svg>
          </CheckmarkContainer>

          <Typography
            variant="h4"
            align="center"
            sx={{
              color: "#2d2d2d",
              fontWeight: 500,
              fontSize: { xs: "24px", sm: "28px" },
              mb: 2,
            }}
          >
            Plan Payment Successfull!
          </Typography>

          <Typography
            variant="body1"
            align="center"
            sx={{
              color: "#666",
              fontSize: { xs: "16px", sm: "18px" },
            }}
          >
            Plan benefits will be added in next 5-10 minutes
          </Typography>

          <HomeButton
            variant="contained"
            onClick={() => history.push("/dashboard")}
          >
            Go to home page
          </HomeButton>
        </ContentSection>
      </SuccessCard>
    </PageContainer>
  );
};

export default PaymentSuccess;
