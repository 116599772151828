import React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Stack,
  Card,
  CardContent,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";

// Styled components
const GradientBorderCard = styled(Card)({
  position: "relative",
  background: "white",
  border: "2px solid #2d2d2d",
  borderRadius: "16px",
  padding: "24px",
  height: "100%",
  transform: "translateY(-30px)", // Slightly more elevation for Pro plan
  boxShadow: "0 12px 40px rgba(0,0,0,0.12)",
  transition: "all 0.3s ease",
  zIndex: 2, // Higher z-index to stand out
  "&:hover": {
    transform: "translateY(-34px)",
    boxShadow: "0 16px 45px rgba(0,0,0,0.15)",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: -4,
    left: -4,
    right: -4,
    bottom: -4,
    border: "2px solid #2d2d2d",
    borderRadius: "18px",
    opacity: 0.1,
  },
});

const RegularCard = styled(Card)({
  position: "relative",
  padding: "24px",
  border: "1px solid #e0e0e0",
  borderRadius: "16px",
  height: "100%",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 8px 30px rgba(0,0,0,0.1)",
  },
});

const FeatureIcon = styled(CheckIcon)({
  color: "#2d2d2d",
  fontSize: "1.2rem",
});

const PricingCard = ({
  plan,
  price,
  features,
  interviews,
  isPopular,
  value,
}) => {
  const CardComponent = isPopular ? GradientBorderCard : RegularCard;

  return (
    <Box position="relative">
      <CardComponent>
        <CardContent
          sx={{ p: 2, height: "100%", position: "relative", zIndex: 1 }}
        >
          <Stack>
            {/* Top Label */}
            <Box
              sx={{
                bgcolor: "#2d2d2d",
                color: "white",
                py: 1.8,
                px: 2,
                mb: 6,
                borderRadius: "8px",
                textAlign: "center",
                transition: "all 0.3s ease",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 550,
                  fontSize: "1.1rem",
                }}
              >
                Good for {interviews}+ interviews
              </Typography>
            </Box>

            {/* Plan Name */}
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                textAlign: "center",
                mb: 1,
              }}
            >
              {plan}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                textAlign: "center",
                color: "#4F46E5",
                fontWeight: 500,
                mb: 3,
              }}
            >
              {value}
            </Typography>

            {/* Price */}
            <Stack alignItems="center" spacing={1}>
              <Typography variant="h3" sx={{ fontWeight: 700 }}>
                ${price}
              </Typography>
              <Typography
                color="text.secondary"
                sx={{
                  fontSize: "0.9rem",
                  opacity: 0.8,
                }}
              >
                Billed annually
              </Typography>
            </Stack>

            {/* Features */}
            <Stack spacing={2.5} sx={{ my: 6 }}>
              {features.map((feature, index) => (
                <Stack
                  key={index}
                  direction="row"
                  spacing={2}
                  alignItems="center"
                >
                  <FeatureIcon />
                  <Typography sx={{ fontSize: "0.95rem" }}>
                    {feature}
                  </Typography>
                </Stack>
              ))}
            </Stack>

            {/* CTA Button */}
            <Button
              variant="contained"
              sx={{
                mt: "auto",
                mb: 2,
                py: 1.8,
                borderRadius: "8px",
                fontWeight: 600,
                fontSize: "0.95rem",
                bgcolor: "#2d2d2d",
                color: "white",
                "&:hover": {
                  bgcolor: "#404040",
                },
              }}
            >
              Select {plan}
            </Button>
          </Stack>
        </CardContent>
      </CardComponent>
    </Box>
  );
};

const Pricing = () => {
  const plans = [
    {
      plan: "Basic Plan",
      price: "99",
      interviews: "5",
      features: [
        "50 Applications Per Day",
        "1 User - 1 Resume Profile",
        "Basic Support",
      ],
      value: "",
      isPopular: false,
    },
    {
      plan: "Pro Plan",
      price: "129",
      interviews: "15",
      features: [
        "150 Applications Per Day",
        "1 User - 3 Resume Profile",
        "Priority Support",
      ],
      value: "The best value for most users",
      isPopular: true,
    },
    {
      plan: "Ultimate Plan",
      price: "249",
      interviews: "25",
      features: [
        "300 Applications Per Day",
        "1 User - 5 Resume Profile",
        "Priority Support",
      ],
      value: "",
      isPopular: false,
    },
  ];

  return (
    <Box
      id="pricing"
      sx={{
        py: { xs: 8, md: 15 },
        bgcolor: "#fff",
      }}
    >
      {/* Rest of the component remains the same */}
      <Container maxWidth={false} sx={{ maxWidth: "1400px" }}>
        <Stack spacing={3} alignItems="center" mb={{ xs: 6, md: 10 }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "1.75rem", md: "2.5rem" },
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            Ready to Get Started?
          </Typography>
          <Typography
            sx={{
              color: "#666666",
              textAlign: "center",
              fontSize: { xs: "1rem", md: "1.1rem" },
              maxWidth: "600px",
            }}
          >
            Choose a plan that suits your needs
          </Typography>
        </Stack>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              md: "repeat(3, 380px)",
            },
            gap: { xs: 4, md: 6 },
            position: "relative",
            justifyContent: "center",
            mx: "auto",
            px: { xs: 2, md: 0 },
          }}
        >
          {plans.map((plan, index) => (
            <PricingCard key={index} {...plan} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Pricing;
