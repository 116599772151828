import React, { useEffect, useState } from "react";
import { Box, Typography, Button, styled } from "@mui/material";
// import { Google as GoogleIcon } from "@mui/icons-material";
import lazyapplylogo from "./assets/panda1.png";
import { useStateStore } from "../../store";
import axios from "axios";
import { useGoogleOneTapLogin, GoogleOAuthProvider } from "@react-oauth/google";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const PageContainer = styled(Box)({
  minHeight: "100vh",
  backgroundColor: "#F8F9FA",
  display: "flex",
});

const LeftSection = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "40px",
});

const RightContentBox = styled(Box)({
  width: "700px",
  height: "750px",
  backgroundColor: "#fff",
  borderRadius: "24px",
  padding: "120px 50px",
  margin: "40px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const Decorator = styled(Box)({
  marginBottom: "32px",
  display: "flex",
  gap: "4px",
  "& .main-part": {
    width: "24px",
    height: "24px",
    backgroundColor: "#2D2D2D",
    borderRadius: "4px",
  },
  "& .small-part": {
    width: "16px",
    height: "24px",
    backgroundColor: "#2D2D2D",
    borderRadius: "4px",
    opacity: 0.7,
  },
});

const GoogleButton = styled(Button)({
  backgroundColor: "#2D2D2D",
  color: "#fff",
  borderRadius: "36px",
  padding: "15px 40px",
  textTransform: "none",
  fontSize: "17px",
  fontWeight: 500,
  marginTop: "80px",
  display: "flex",
  alignItems: "center",
  gap: "16px",
  width: "400px", // Not full width
  "&:hover": {
    backgroundColor: "#404040",
  },
  "& .MuiButton-startIcon": {
    margin: 0,
    marginTop: "-2px",
    display: "flex",
    alignItems: "center",
  },
});

const ErrorMessage = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  marginTop: "16px",
  animation: "fadeIn 0.3s ease-in-out",
  "@keyframes fadeIn": {
    from: { opacity: 0, transform: "translateY(-10px)" },
    to: { opacity: 1, transform: "translateY(0)" },
  },
});

const GoogleIcon = () => (
  <svg
    width="22"
    height="22"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
  >
    <path
      fill="#EA4335"
      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
    />
    <path
      fill="#4285F4"
      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
    />
    <path
      fill="#FBBC05"
      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
    />
    <path
      fill="#34A853"
      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
    />
  </svg>
);

const Login = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const token_main = searchParams.get("token");
  const {
    userDataInit,
    userAuthentication,
    updateToken,
    userImageSrc,
    isUserAuthenticated,
  } = useStateStore();
  const [signInError, setSignInError] = useState(false);

  function handleLogin(googleData, typeOfButton) {
    setSignInError(false);
    console.log("googledata", googleData);
    if (googleData) {
      axios
        .post(
          "https://backend.lazyapply.com/lazyapplyV2/create",
          {
            token: googleData,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          console.log("response", response);
          userAuthentication(1);
          userImageSrc(response.data.user.picture);
          updateToken(response.data.token);
          window.localStorage.setItem("token", response.data.token);
          window.localStorage.setItem(
            "user",
            JSON.stringify(response.data.user)
          );
          userDataInit(response.data.user);
        })
        .catch(function (error) {
          console.log("err", error);
          setSignInError(true);
        });
    }
  }

  useGoogleOneTapLogin({
    disabled: isUserAuthenticated === 1,
    onSuccess: (credentialResponse) => {
      console.log(credentialResponse);
      handleLogin(credentialResponse.credential, "one_tap");
    },
    onError: () => {
      console.log("Login Failed");
      setSignInError(true);
    },
  });

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    script.onload = () => {
      window.gapi.load("auth2", () => {
        window.gapi.auth2.init({
          client_id:
            "715818145189-7geg3j3td9u21nc9qe8eujb2l4dcbt88.apps.googleusercontent.com",
        });
      });
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const signIn = () => {
    setSignInError(false);
    if (window.gapi && window.gapi.auth2) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      auth2.signIn().then(
        (googleUser) => {
          const id_token = googleUser.getAuthResponse().id_token;
          handleLogin(id_token, "main_button");
        },
        (error) => {
          console.log("Sign-in error:", error);
          setSignInError(true);
        }
      );
    }
  };

  useEffect(() => {
    if (token_main && token_main != "") {
      handleLogin(token_main, "refer_from_website");
    }
  }, [token_main]);

  return (
    <PageContainer>
      <LeftSection>
        <Box sx={{ mb: 4 }}>
          <img src={lazyapplylogo} alt="LazyApply" style={{ width: "400px" }} />
        </Box>
      </LeftSection>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <RightContentBox>
          <Decorator>
            <Box className="main-part" />
            <Box className="small-part" />
          </Decorator>

          <Typography
            sx={{
              fontWeight: 600,
              mb: 1,
              mt: 5,
              letterSpacing: 1,
              color: "#2D2D2D",
              fontSize: "34px",
            }}
          >
            Welcome back!
          </Typography>

          <Typography
            sx={{
              color: "#666666",
              textAlign: "center",
            }}
          >
            One Click Away From Your Next Career Opportunity
          </Typography>

          <GoogleButton
            variant="contained"
            startIcon={<GoogleIcon />}
            onClick={signIn}
          >
            Continue with Google
          </GoogleButton>

          {signInError && (
            <ErrorMessage>
              <ErrorOutlineIcon sx={{ color: "#d32f2f", fontSize: "22px" }} />
              <Typography
                sx={{
                  color: "#d32f2f",
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Unable to sign in with Google. Please try again.
              </Typography>
            </ErrorMessage>
          )}

          <Box
            sx={{
              mt: 8, // More space above sign up
              textAlign: "center",
            }}
          >
            <Typography
              component="span"
              sx={{ color: "#666666", fontSize: "18px" }}
            >
              Don't have an account?{" "}
            </Typography>
            <Typography
              component="span"
              onClick={signIn}
              sx={{
                color: "#2D2D2D",
                fontWeight: 500,
                fontSize: "18px",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Sign up
            </Typography>
          </Box>
        </RightContentBox>
      </Box>
    </PageContainer>
  );
};

const LoginPage = () => {
  const CLIENT_ID = encodeURIComponent(
    "715818145189-7geg3j3td9u21nc9qe8eujb2l4dcbt88.apps.googleusercontent.com"
  );
  return (
    <>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <Login />
      </GoogleOAuthProvider>
    </>
  );
};

export default LoginPage;
