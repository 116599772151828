// screens/JobDetailsScreen.jsx
import React, { useRef } from "react";
import { Box, Typography, TextField, Card, Radio } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import { Autocomplete } from "@mui/material";
import { countryOptions, currencyOptions } from "./countryOptions";

const QuestionContainer = styled(Box)({
  marginBottom: "30px",
  textAlign: "center",
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: "20px",
  width: "100%",
  maxWidth: "500px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    fontSize: "16px",
    "& fieldset": {
      borderColor: "#E5E7EB",
      borderWidth: "2px",
    },
    "&:hover fieldset": {
      borderColor: "#4F46E5",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#4F46E5",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "16px",
    "&::placeholder": {
      color: "#9CA3AF",
      opacity: 1,
    },
  },
}));

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    fontSize: "16px",
    padding: "0",
    "& fieldset": {
      borderColor: "#E5E7EB",
      borderWidth: "2px",
    },
    "&:hover fieldset": {
      borderColor: "#4F46E5",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#4F46E5",
    },
  },
  "& .MuiAutocomplete-input": {
    padding: "16px !important",
  },
});

const OptionCard = styled(Card)(({ selected }) => ({
  padding: "14px 20px",
  cursor: "pointer",
  width: "100%",
  maxWidth: "500px",
  margin: "0 auto 10px",
  borderRadius: "12px",
  border: "2px solid",
  borderColor: selected ? "#4F46E5" : "#E5E7EB",
  backgroundColor: selected ? "rgba(79, 70, 229, 0.03)" : "white",
  transition: "all 0.15s ease",
  boxShadow: "none",
  minHeight: "60px", // Fixed minimum height
  display: "flex",
  alignItems: "center",
  "&:hover": {
    borderColor: selected ? "#4F46E5" : "#4F46E5",
    backgroundColor: selected
      ? "rgba(79, 70, 229, 0.03)"
      : "rgba(79, 70, 229, 0.02)",
  },
}));

const RadioOption = styled(Radio)({
  color: "#E5E7EB",
  "&.Mui-checked": {
    color: "#4F46E5",
  },
});

const GridContainer = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(140px, 1fr))",
  gap: "10px",
  width: "100%",
  maxWidth: "500px",
  margin: "15px auto 0",
});

const SalaryGridContainer = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)", // 2 columns per row
  gap: "10px",
  width: "100%",
  maxWidth: "500px",
  margin: "15px auto 0",
});

const JobDetailsScreen = ({ userData, setUserData, onNext }) => {
  // Refs for auto-scrolling
  const countryRef = useRef(null);
  const jobTypeRef = useRef(null);
  const experienceRef = useRef(null);
  const expectedSalaryRef = useRef(null);
  const currentSalaryRef = useRef(null);

  const handleJobTitleChange = (e) => {
    setUserData((prev) => ({
      ...prev,
      jobTitle: e.target.value,
    }));
  };

  const handleCountrySelect = (country) => {
    setUserData((prev) => ({
      ...prev,
      preferredCountry: country ? country.value : "",
    }));

    // Scroll to job type section
    setTimeout(() => {
      jobTypeRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 200);
  };

  const handleJobTypeChange = (value) => {
    setUserData((prev) => ({
      ...prev,
      jobType: value,
    }));

    // Scroll to experience section
    setTimeout(() => {
      experienceRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 200);
  };

  const handleExperienceChange = (value) => {
    setUserData((prev) => ({
      ...prev,
      experience: value,
    }));

    // Scroll to expected salary section
    setTimeout(() => {
      expectedSalaryRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 200);
  };

  const handleSalaryChange = (type, value) => {
    if (type === "current") {
      setUserData((prev) => ({
        ...prev,
        currentSalary: value,
      }));

      // Auto-advance to next screen
      setTimeout(() => onNext(), 400);
    } else {
      setUserData((prev) => ({
        ...prev,
        expectedSalary: value,
      }));

      // Scroll to current salary section
      setTimeout(() => {
        currentSalaryRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 200);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && userData.jobTitle.trim()) {
      // Scroll to country section
      countryRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const jobTypes = [
    { value: "fullTime", label: "Full Time" },
    { value: "partTime", label: "Part Time" },
    { value: "freelancing", label: "Freelancing" },
    { value: "internship", label: "Internship" },
    { value: "c2c", label: "C2C" },
    { value: "contract", label: "Contract" },
  ];

  const experienceOptions = [
    { value: "0-1", label: "0-1 years" },
    { value: "1-3", label: "1-3 years" },
    { value: "3-5", label: "3-5 years" },
    { value: "5-10", label: "5-10 years" },
    { value: "10+", label: "10+ years" },
  ];

  const salaryRanges = [
    { value: "0-30000", label: "Under $30,000" },
    { value: "30000-50000", label: "$30,000 - $50,000" },
    { value: "50000-75000", label: "$50,000 - $75,000" },
    { value: "75000-100000", label: "$75,000 - $100,000" },
    { value: "100000-150000", label: "$100,000 - $150,000" },
    { value: "150000+", label: "Over $150,000" },
  ];

  const fieldsVariants = {
    initial: {
      opacity: 0,
      y: 10,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        style={{ width: "100%" }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: { xs: "28px", md: "30px" },
            fontWeight: 600,
            color: "#111827",
            textAlign: "center",
            mb: 4,
          }}
        >
          Tell us about your career goals
        </Typography>

        {/* Job Title Section */}
        <QuestionContainer>
          <Typography
            variant="h5"
            sx={{
              fontSize: "22px",
              fontWeight: 500,
              color: "#374151",
            }}
          >
            Desired job title
          </Typography>
          <StyledTextField
            fullWidth
            placeholder="Enter your desired job title"
            value={userData.jobTitle || ""}
            onChange={handleJobTitleChange}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        </QuestionContainer>

        {/* Preferred Country Section */}
        <motion.div
          ref={countryRef}
          variants={fieldsVariants}
          initial="initial"
          animate="visible"
        >
          <QuestionContainer>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: 500,
                color: "#374151",
                mb: 1,
                mt: 10,
              }}
            >
              Preferred country
            </Typography>
            <StyledAutocomplete
              fullWidth
              options={countryOptions}
              getOptionLabel={(option) => option.label}
              value={
                countryOptions.find(
                  (option) => option.value === userData.preferredCountry
                ) || null
              }
              onChange={(_, newValue) => handleCountrySelect(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder="Select your preferred country"
                />
              )}
              sx={{ mt: 2, maxWidth: "500px", margin: "15px auto 0" }}
            />
          </QuestionContainer>
        </motion.div>

        {/* Job Type Section */}
        <motion.div
          ref={jobTypeRef}
          variants={fieldsVariants}
          initial="initial"
          animate="visible"
        >
          <QuestionContainer>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: 500,
                color: "#374151",
                mb: 2,
                mt: 10,
              }}
            >
              Job Type
            </Typography>
            <GridContainer>
              {jobTypes.map((type) => (
                <OptionCard
                  key={type.value}
                  selected={userData.jobType === type.value}
                  onClick={() => handleJobTypeChange(type.value)}
                  sx={{ margin: 0, maxWidth: "none" }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: userData.jobType === type.value ? 600 : 400,
                      textAlign: "center",
                    }}
                  >
                    {type.label}
                  </Typography>
                </OptionCard>
              ))}
            </GridContainer>
          </QuestionContainer>
        </motion.div>

        {/* Years of Experience Section */}
        <motion.div
          ref={experienceRef}
          variants={fieldsVariants}
          initial="initial"
          animate="visible"
        >
          <QuestionContainer>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: 500,
                color: "#374151",
                mb: 2,
                mt: 10,
              }}
            >
              Years of experience
            </Typography>
            <GridContainer>
              {experienceOptions.map((option) => (
                <OptionCard
                  key={option.value}
                  selected={userData.experience === option.value}
                  onClick={() => handleExperienceChange(option.value)}
                  sx={{ margin: 0, maxWidth: "none" }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight:
                        userData.experience === option.value ? 600 : 400,
                      textAlign: "center",
                    }}
                  >
                    {option.label}
                  </Typography>
                </OptionCard>
              ))}
            </GridContainer>
          </QuestionContainer>
        </motion.div>

        {/* Expected Salary Section */}
        <motion.div
          ref={expectedSalaryRef}
          variants={fieldsVariants}
          initial="initial"
          animate="visible"
        >
          <QuestionContainer>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: 500,
                color: "#374151",
                mb: 2,
                mt: 10,
              }}
            >
              Expected salary range
            </Typography>
            <SalaryGridContainer>
              {salaryRanges.map((range) => (
                <OptionCard
                  key={range.value}
                  selected={userData.expectedSalary === range.value}
                  onClick={() => handleSalaryChange("expected", range.value)}
                  sx={{ margin: 0, maxWidth: "none", minHeight: "70px" }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight:
                        userData.expectedSalary === range.value ? 600 : 400,
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {range.label}
                  </Typography>
                </OptionCard>
              ))}
            </SalaryGridContainer>
          </QuestionContainer>
        </motion.div>

        {/* Current Salary Section */}
        <motion.div
          ref={currentSalaryRef}
          variants={fieldsVariants}
          initial="initial"
          animate="visible"
        >
          <QuestionContainer>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: 500,
                color: "#374151",
                mb: 2,
                mt: 10,
              }}
            >
              Current salary range
            </Typography>
            <SalaryGridContainer>
              {salaryRanges.map((range) => (
                <OptionCard
                  key={range.value}
                  selected={userData.currentSalary === range.value}
                  onClick={() => handleSalaryChange("current", range.value)}
                  sx={{ margin: 0, maxWidth: "none", minHeight: "70px" }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight:
                        userData.currentSalary === range.value ? 600 : 400,
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {range.label}
                  </Typography>
                </OptionCard>
              ))}
            </SalaryGridContainer>
          </QuestionContainer>
        </motion.div>
      </motion.div>
    </Box>
  );
};

export default JobDetailsScreen;
