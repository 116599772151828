// ShareJourneyForm/steps/ResumeAndTemplate.jsx
import React from "react";
import {
  Box,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";

const RESUME_SECTIONS = [
  "PersonalInformation",
  "WorkExperience",
  "Education",
  "Skills",
];

// Sample data (will come from API/props later)
const sampleResumes = [
  { id: 1, name: "SWE Resume - Updated Mar 2024" },
  { id: 2, name: "SWE Resume - Updated Feb 2024" },
  { id: 3, name: "SWE Resume - Updated Jan 2024" },
];

const sampleTemplates = [
  { id: 1, name: "FAANG Referral Template" },
  { id: 2, name: "Standard Referral Template" },
  { id: 3, name: "Custom Referral Template" },
];

const ResumeAndTemplate = ({ formData, updateFormData }) => {
  const handleResumeChange = (event) => {
    updateFormData("resume", {
      ...formData.resume,
      selectedResume: event.target.value,
    });
  };

  const handleTemplateChange = (event) => {
    updateFormData("emailTemplate", {
      ...formData.emailTemplate,
      selectedTemplate: event.target.value,
    });
  };

  const handleSectionsChange = (event) => {
    const {
      target: { value },
    } = event;
    updateFormData("resume", {
      ...formData.resume,
      selectedSections: typeof value === "string" ? value.split(",") : value,
    });
  };

  return (
    <Box>
      <Grid container spacing={4}>
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          {/* Resume Selection */}
          <Box mb={4}>
            <Typography variant="h6" sx={{ fontWeight: 600, color: "#2d2d2d" }}>
              Select Resume
            </Typography>
            <Typography
              sx={{
                color: "text.secondary",
                fontSize: "0.9rem",
                mb: 2,
              }}
            >
              Choose the resume that helped you land the job.
            </Typography>
            <FormControl fullWidth>
              <Select
                value={formData.resume.selectedResume || "1"} // Pre-select first resume
                onChange={handleResumeChange}
                disableRipple
                sx={{
                  borderRadius: 2,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "grey.300",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2d2d2d",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2d2d2d",
                  },
                }}
              >
                {sampleResumes.map((resume) => (
                  <MenuItem key={resume.id} value={resume.id}>
                    {resume.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* Resume Sections */}
          <Box mb={4}>
            <Typography variant="h6" sx={{ fontWeight: 600, color: "#2d2d2d" }}>
              Select Sections to Share
            </Typography>
            <Typography
              sx={{
                color: "text.secondary",
                fontSize: "0.9rem",
                mb: 2,
              }}
            >
              Choose which sections of your resume to include.
            </Typography>
            <FormControl fullWidth>
              <Select
                multiple
                value={
                  formData.resume.selectedSections || [
                    "WorkExperience",
                    "Skills",
                  ]
                } // Pre-select some sections
                onChange={handleSectionsChange}
                input={<OutlinedInput />}
                renderValue={(selected) => selected.join(", ")}
                sx={{
                  borderRadius: 2,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "grey.300",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2d2d2d",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2d2d2d",
                  },
                }}
              >
                {RESUME_SECTIONS.map((section) => (
                  <MenuItem key={section} value={section} disableRipple>
                    <Checkbox
                      checked={
                        formData.resume.selectedSections?.indexOf(section) > -1
                      }
                      sx={{
                        color: "grey.400",
                        "&.Mui-checked": {
                          color: "#2d2d2d",
                        },
                      }}
                    />
                    <ListItemText primary={section} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6}>
          {/* Email Template Selection */}
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 600, color: "#2d2d2d" }}>
              Select Email Template
            </Typography>
            <Typography
              sx={{
                color: "text.secondary",
                fontSize: "0.9rem",
                mb: 2,
              }}
            >
              Choose the referral email template you used.
            </Typography>
            <FormControl fullWidth>
              <Select
                value={formData.emailTemplate.selectedTemplate || "1"} // Pre-select first template
                onChange={handleTemplateChange}
                disableRipple
                sx={{
                  borderRadius: 2,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "grey.300",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2d2d2d",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2d2d2d",
                  },
                }}
              >
                {sampleTemplates.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Box mt={4}>
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: "0.9rem",
          }}
        >
          Note: Your personal information will be automatically anonymized
          before sharing. Company names and specific project details will be
          redacted to maintain privacy. Only the selected sections and
          anonymized content will be visible to others.
        </Typography>
      </Box>
    </Box>
  );
};

export default ResumeAndTemplate;
