import React from "react";
import { Box, Typography, styled } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import FavoriteIcon from "@mui/icons-material/Favorite";
import RepeatIcon from "@mui/icons-material/Repeat";

const TweetCard = styled(Box)({
  backgroundColor: "#fff",
  borderRadius: "12px",
  padding: "20px",
  width: "100%",
  boxShadow: "0 2px 12px rgba(0,0,0,0.08)",
  border: "1px solid #eee",
});

const ProfilePic = styled(Box)({
  width: "48px",
  height: "48px",
  borderRadius: "50%",
  backgroundColor: "#1DA1F2",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontSize: "16px",
  fontWeight: 500,
  flexShrink: 0,
});

const TwitterPost = ({ tweet }) => {
  return (
    <TweetCard>
      {/* Profile Section */}
      <Box sx={{ display: "flex", gap: 2, mb: 2.5, alignItems: "flex-start" }}>
        <ProfilePic>{tweet.initials}</ProfilePic>
        <Box sx={{ mt: 1 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 0.5,
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                color: "#14171A",
                fontSize: "15px",
                lineHeight: 1.2,
              }}
            >
              {tweet.name}
            </Typography>
            <VerifiedIcon sx={{ fontSize: 16, color: "#1DA1F2", mt: "-2px" }} />
          </Box>
          <Typography
            sx={{
              color: "#657786",
              fontSize: "14px",
              lineHeight: 1.2,
            }}
          >
            @{tweet.handle}
          </Typography>
        </Box>
      </Box>

      {/* Tweet Content */}
      <Typography
        sx={{
          fontSize: "15px",
          color: "#14171A",
          lineHeight: 1.5,
          mb: 2.5,
        }}
      >
        {tweet.content}
      </Typography>

      {/* Metrics */}
      <Box sx={{ display: "flex", gap: 5, color: "#657786" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <FavoriteIcon sx={{ fontSize: 16 }} />
          <Typography sx={{ fontSize: "13px", color: "#657786" }}>
            {tweet.likes}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <RepeatIcon sx={{ fontSize: 16 }} />
          <Typography sx={{ fontSize: "13px", color: "#657786" }}>
            {tweet.retweets}
          </Typography>
        </Box>
      </Box>
    </TweetCard>
  );
};

export default TwitterPost;
