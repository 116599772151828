// src/components/analytics/dashboard/MetricsCards.js
import React from "react";
import { Grid, Paper, Typography, Skeleton, Box } from "@mui/material";
import OwnerMetricsCards from "../ownerView/OwnerMetricsCards";
import OperatorMetricsCards from "../operatorView/OperatorMetricsCards";
import SchoolMetricsCards from "../educationOwnerView/SchoolMetricsCards";

const MetricCard = ({ title, value, subtitle, loading }) => (
  <Paper
    elevation={0}
    sx={{
      p: 3,
      height: "100%",
      borderRadius: "12px",
      border: "1px solid #E0E0E0",
      backgroundColor: "#fff",
      "&:hover": {
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
      },
      transition: "box-shadow 0.3s ease-in-out",
    }}
  >
    {loading ? (
      <Box>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="40%" height={40} />
        <Skeleton variant="text" width="80%" />
      </Box>
    ) : (
      <>
        <Typography
          variant="body1"
          sx={{
            color: "#666",
            fontWeight: 500,
            mb: 1,
          }}
        >
          {title}
        </Typography>
        <Typography
          component="div"
          sx={{
            fontSize: "26px",
            fontWeight: 600,
            color: "#2D2D2D",
            mb: 1,
          }}
        >
          {value}
        </Typography>
        {subtitle && (
          <Typography
            variant="body2"
            sx={{
              color: "#666",
              fontWeight: 500,
            }}
          >
            {subtitle}
          </Typography>
        )}
      </>
    )}
  </Paper>
);

const MetricsCards = ({ metrics, loading, viewType = "owner" }) => {
  // Render different metrics cards based on view type
  if (viewType === "owner") {
    return <OwnerMetricsCards metrics={metrics} loading={loading} />;
  } else if (viewType === "operator") {
    return <OperatorMetricsCards metrics={metrics} loading={loading} />;
  } else if (viewType === "school") {
    return <SchoolMetricsCards metrics={metrics} loading={loading} />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={3}>
        <MetricCard
          title="Total Applications"
          value={metrics?.totalApplications || 0}
          subtitle={`${metrics?.successRate || 0}% Success Rate`}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <MetricCard
          title="Total Referral Emails"
          value={"Coming Soon"}
          subtitle={"100 % Success Rate"}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <MetricCard
          title="Total Companies"
          value={metrics?.totalCompanies || 0}
          subtitle="Companies Applied"
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <MetricCard
          title="Most Used Resume"
          value={metrics?.mostUsedResume?.name || "-"}
          subtitle={`${
            metrics?.mostUsedResume?.applicationCount || 0
          } Applications`}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default MetricsCards;
