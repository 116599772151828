// src/components/analytics/dashboard/TimeFrameSelector.js
import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  DialogTitle,
  TextField,
  Popover,
  Typography,
} from "@mui/material";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const TimeFrameSelector = ({
  value,
  onChange,
  onCustomDateSelect,
  selectedRange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(selectedRange?.start || "");
  const [endDate, setEndDate] = useState(selectedRange?.end || "");
  const buttonRef = useRef(null);

  const timeFrames = [
    { label: "Today's Applications", value: "daily" },
    { label: "Last 7 Days", value: "weekly" },
    { label: "All Applications", value: "all-time" },
  ];

  const handleClick = () => {
    setAnchorEl(buttonRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateSelect = () => {
    onCustomDateSelect({ start: startDate, end: endDate });
    handleClose();
  };

  const formatDateRange = (start, end) => {
    if (!start || !end) return "Custom Range";
    return `${new Date(start).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    })} - ${new Date(end).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    })}`;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
        }}
      >
        {timeFrames.map((timeFrame) => (
          <Button
            key={timeFrame.value}
            onClick={() => onChange(timeFrame.value)}
            variant={value === timeFrame.value ? "contained" : "outlined"}
            disableRipple
            sx={{
              backgroundColor:
                value === timeFrame.value ? "#2D2D2D" : "transparent",
              color: value === timeFrame.value ? "white" : "#2D2D2D",
              "&:hover": {
                backgroundColor:
                  value === timeFrame.value ? "#2D2D2D" : "#E0E0E0",
                borderColor: value === timeFrame.value ? "#2D2D2D" : "#E0E0E0",
              },
              textTransform: "none",
              borderRadius: "8px",
              borderColor: value === timeFrame.value ? "#2D2D2D" : "#E0E0E0",
              minWidth: "120px",
              fontSize: "15px",
              fontWeight: 500,
              padding: "6px 16px",
              "&.MuiButton-outlined": {
                "&:focus": {
                  backgroundColor: "transparent",
                },
              },
            }}
          >
            {timeFrame.label}
          </Button>
        ))}

        <Button
          ref={buttonRef}
          onClick={handleClick}
          variant={value === "custom" ? "contained" : "outlined"}
          startIcon={<CalendarTodayIcon />}
          disableRipple
          sx={{
            backgroundColor: value === "custom" ? "#2D2D2D" : "transparent",
            color: value === "custom" ? "white" : "#2D2D2D",
            "&:hover": {
              backgroundColor: value === "custom" ? "#2D2D2D" : "#E0E0E0",
              borderColor: value === "custom" ? "#2D2D2D" : "#E0E0E0",
            },
            textTransform: "none",
            borderRadius: "8px",
            borderColor: value === "custom" ? "#2D2D2D" : "#E0E0E0",
            minWidth: "160px",
            fontSize: "15px",
            fontWeight: 500,
            padding: "6px 16px",
          }}
        >
          {value === "custom" && selectedRange
            ? formatDateRange(selectedRange.start, selectedRange.end)
            : "Custom Range"}
        </Button>
      </Box>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              mt: 1,
              p: 3,
              borderRadius: "12px",
              boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
              width: "400px",
            },
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 600,
            color: "#2D2D2D",
            mb: 2,
          }}
        >
          Select Date Range
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Box sx={{ mb: 2 }}>
            <Typography
              sx={{
                mb: 1,
                fontSize: "15px",
                color: "#666666",
                fontWeight: 500,
              }}
            >
              From
            </Typography>
            <TextField
              type="date"
              fullWidth
              size="small"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              inputProps={{
                max: endDate || new Date().toISOString().split("T")[0],
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
                "& input": {
                  padding: "10px 14px",
                },
              }}
            />
          </Box>
          <Box sx={{}}>
            <Typography
              sx={{
                mb: 1,
                fontSize: "15px",
                color: "#666666",
                fontWeight: 500,
              }}
            >
              To
            </Typography>
            <TextField
              type="date"
              fullWidth
              size="small"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              inputProps={{
                min: startDate,
                max: new Date().toISOString().split("T")[0],
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
                "& input": {
                  padding: "10px 14px",
                },
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            mt: 2,
          }}
        >
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              color: "#2D2D2D",
              backgroundColor: "transparent",
              textTransform: "none",
              fontSize: "14px",
              borderRadius: "8px",
              borderColor: "#E0E0E0",
              "&:hover": {
                backgroundColor: "#E0E0E0",
                borderColor: "#E0E0E0",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDateSelect}
            variant="contained"
            disabled={!startDate || !endDate}
            sx={{
              backgroundColor: "#2D2D2D",
              color: "white",
              textTransform: "none",
              borderRadius: "8px",
              padding: "4px 16px",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#404040",
              },
              "&.Mui-disabled": {
                backgroundColor: "#cccccc",
                color: "#666666",
              },
            }}
          >
            Apply
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default TimeFrameSelector;
