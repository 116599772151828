// components/sections/Skills/EditMode.jsx
import React from "react";
import { Grid, Box, TextField, Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    "&:hover fieldset": {
      borderColor: "#2D2D2D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2D2D2D",
      borderWidth: "1px",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E0E0E0",
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: "#2D2D2D",
    },
  },
};

const FormTextField = React.memo(
  ({ label, value, onChange, onBlur, error, helperText, ...props }) => (
    <TextField
      fullWidth
      label={label}
      value={value || ""}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      variant="outlined"
      sx={textFieldStyles}
      {...props}
    />
  )
);

const SkillForm = ({
  skill,
  index,
  errors = {},
  touched = {},
  onChange,
  onBlur,
  onDelete,
}) => {
  const handleChange = (field) => (event) => {
    const value = event.target.value;
    onChange(index, {
      ...skill,
      [field]: value,
    });
  };

  const handleBlur = (field) => () => {
    onBlur(index, field);
  };

  return (
    <Box
      sx={{
        mb: 2.5,
        display: "flex",
        alignItems: "flex-start",
        gap: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <FormTextField
            label="Skill"
            value={skill.skill}
            onChange={handleChange("skill")}
            onBlur={handleBlur("skill")}
            error={touched?.skill && !!errors?.skill}
            helperText={touched?.skill && errors?.skill}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            label="Years of Experience"
            value={skill.experience}
            onChange={handleChange("experience")}
            onBlur={handleBlur("experience")}
            error={touched?.experience && !!errors?.experience}
            helperText={touched?.experience && errors?.experience}
            type="number"
          />
        </Grid>
      </Grid>
      <IconButton
        onClick={() => onDelete(index)}
        sx={{
          color: "#666666",
          mt: 1,
          "&:hover": {
            color: "#FF7875",
          },
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

const EditMode = ({
  skills,
  errors,
  touched,
  onChange,
  onBlur, // Added this prop
  onAdd,
  onDelete,
}) => {
  const handleFieldChange = (index, newValue) => {
    const newSkills = [...skills];
    newSkills[index] = newValue;
    onChange(newSkills);
  };

  return (
    <Box>
      {skills.map((skill, index) => (
        <SkillForm
          key={index}
          skill={skill}
          index={index}
          errors={errors[index]}
          touched={touched[index]}
          onChange={handleFieldChange}
          onBlur={onBlur} // Pass through to SkillForm
          onDelete={onDelete}
        />
      ))}

      <Button
        startIcon={<AddIcon />}
        onClick={onAdd}
        sx={{
          mt: 2,
          color: "#2D2D2D",
          borderRadius: "8px",
          padding: "8px 16px",
          borderColor: "#2D2D2D",
          "&:hover": {
            borderColor: "#2D2D2D",
            backgroundColor: "rgba(45, 45, 45, 0.04)",
          },
        }}
        variant="outlined"
      >
        Add Another Skill
      </Button>
    </Box>
  );
};

export default EditMode;
