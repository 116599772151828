// DetailedView/EmailTemplatePreview.jsx
import React, { useState } from "react";
import { Box, Card, CardContent, Typography, IconButton } from "@mui/material";
import { Info } from "@mui/icons-material";

const emailTemplate = {
  subject: "Referral for Software Engineer Position",
  content: `Hi [Hiring Manager],

I hope this email finds you well. I'm reaching out to refer a highly skilled Software Engineer candidate who I believe would be a great addition to your team.The candidate has a strong background in [specific technologies/skills] and has demonstrated excellent problem-solving abilities throughout their career. They have [X] years of experience in software development and have worked on significant projects including [brief examples].

Best regards,
[Your Name]`,
};

const EmailTemplatePreview = ({ data, onOpenModal }) => {
  const truncateText = (text, limit = 200) => {
    if (!text) return "";
    if (text.length <= limit) return text;
    return text.slice(0, limit) + "...";
  };

  return (
    <Card
      elevation={0}
      sx={{
        border: 1,
        borderColor: "grey.100",
        borderRadius: 2,
        mt: 3,
      }}
    >
      <CardContent sx={{ p: 3 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Referral Email Template
          </Typography>
          <IconButton
            onClick={onOpenModal}
            size="small"
            sx={{
              color: "text.secondary",
              "&:hover": {
                background: "none",
                color: "#64b5f6",
              },
            }}
          >
            <Info sx={{ fontSize: 24 }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            p: 2,
            bgcolor: "grey.50",
            borderRadius: 1,
            fontSize: "0.875rem",
            color: "text.secondary",
          }}
        >
          <Box mb={2}>
            <Typography component="span" sx={{ color: "text.primary" }}>
              Subject:{" "}
            </Typography>

            {data.templateData?.subject}
          </Box>
          <Box>{truncateText(data.templateData?.content)}</Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default EmailTemplatePreview;
