import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Avatar,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import { useHistory, useLocation } from "react-router-dom";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    overflow: "visible",
    maxWidth: "460px",
    margin: "16px",
    width: "100%",
    transition: "transform 0.3s ease-out, opacity 0.3s ease-out",
    opacity: 1,
    transform: "translateY(0)",
    "&.MuiDialog-paperEntering": {
      opacity: 0,
      transform: "translateY(-20px)",
    },
  },
}));

const AccessModeConfirmationModal = ({
  open,
  onClose,
  userName,
  userEmail,
  accessMode,
}) => {
  const history = useHistory();
  const location = useLocation();

  const isAnalyticsPage = location.pathname === "/dashboard/analytics";

  const getInitials = (name) => {
    if (!name) return "";
    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  const getModalContent = () => {
    switch (accessMode) {
      case "user":
        return {
          title: "User Access Activated",
          description: `You are now browsing and applying on behalf of ${userName}. All actions will be performed as this user.`,
          icon: (
            <Avatar
              sx={{
                bgcolor: "#4F46E5",
                width: 70,
                height: 70,
                fontSize: "28px",
                fontWeight: "600",
                position: "absolute",
                top: -35,
                left: "50%",
                transform: "translateX(-50%)",
                border: "4px solid white",
                boxShadow: "0 4px 14px rgba(0,0,0,0.1)",
              }}
            >
              {getInitials(userName)}
            </Avatar>
          ),
          gradient: "linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)",
        };
      case "role":
        return {
          title: "Team Access Activated",
          description:
            "You are now using the product with team administrator privileges. You have access to team-wide analytics and settings.",
          icon: (
            <Avatar
              sx={{
                bgcolor: "#059669",
                width: 70,
                height: 70,
                position: "absolute",
                top: -35,
                left: "50%",
                transform: "translateX(-50%)",
                border: "4px solid white",
                boxShadow: "0 4px 14px rgba(0,0,0,0.1)",
              }}
            >
              <PeopleIcon sx={{ fontSize: 34 }} />
            </Avatar>
          ),
          gradient: "linear-gradient(135deg, #059669 0%, #10B981 100%)",
        };
      default:
        return {
          title: "Personal Access Activated",
          description:
            "You are now using the product as yourself. All applications and actions will be performed with your personal account.",
          icon: (
            <Avatar
              sx={{
                bgcolor: "#F59E0B",
                width: 70,
                height: 70,
                position: "absolute",
                top: -35,
                left: "50%",
                transform: "translateX(-50%)",
                border: "4px solid white",
                boxShadow: "0 4px 14px rgba(0,0,0,0.1)",
              }}
            >
              <PersonIcon sx={{ fontSize: 34 }} />
            </Avatar>
          ),
          gradient: "linear-gradient(135deg, #F59E0B 0%, #FBBF24 100%)",
        };
    }
  };

  const handleContinue = () => {
    onClose();

    if (!isAnalyticsPage) {
      // history.push("/dashboard");
    }
  };

  const content = getModalContent();

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="access-mode-confirmation-dialog"
    >
      <Box sx={{ position: "relative", pt: 5, px: 3, pb: 3 }}>
        {content.icon}

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 12,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle
          id="access-mode-confirmation-dialog"
          sx={{
            textAlign: "center",
            pb: 1,
            pt: 3,
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 700 }}>
            {content.title}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Typography
            variant="body1"
            sx={{ textAlign: "center", color: "#6B7280" }}
          >
            {content.description}
          </Typography>

          {accessMode === "user" && (
            <Box
              sx={{
                mt: 3,
                p: 3,
                borderRadius: "12px",
                background: "linear-gradient(135deg, #F9FAFB 0%, #F3F4F6 100%)",
                border: "1px solid #E5E7EB",
                boxShadow:
                  "0 4px 6px -1px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.03)",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Avatar
                  sx={{
                    bgcolor: "#E0E0E0",
                    color: "#2D2D2D",
                    width: 45,
                    height: 45,
                  }}
                >
                  {getInitials(userName)}
                </Avatar>
                <Box>
                  <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                    {userName}
                  </Typography>
                  <Typography sx={{ color: "#6B7280", fontSize: "14px" }}>
                    {userEmail}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
          <Button
            onClick={handleContinue}
            variant="contained"
            disableElevation
            sx={{
              background: content.gradient,
              py: 1.25,
              px: 5,
              borderRadius: "8px",
              textTransform: "none",
              fontWeight: 600,
              fontSize: "16px",
              boxShadow:
                "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                background: content.gradient,
                transform: "translateY(-2px)",
                boxShadow:
                  "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
              },
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Box>
    </StyledDialog>
  );
};

export default AccessModeConfirmationModal;
