// components/sections/Skills/ViewMode.jsx
import React from "react";
import { Box, Typography, Chip, Stack } from "@mui/material";

const ViewMode = ({ skills }) => {
  if (skills.length === 0) {
    return (
      <Typography
        sx={{
          color: "#666666",
          fontStyle: "italic",
          textAlign: "center",
          py: 4,
        }}
      >
        No skills added yet.
      </Typography>
    );
  }

  return (
    <Box>
      <Stack direction="row" spacing={1.5} flexWrap="wrap" gap={1.5}>
        {skills.map((skill, index) => (
          <Chip
            key={index}
            label={
              <Typography
                sx={{
                  fontWeight: 500,
                }}
                component="span"
              >
                {skill.skill}
                <Typography
                  component="span"
                  sx={{
                    fontSize: "15px",
                    color: "#666666",
                    ml: 1,
                  }}
                >
                  • {skill.experience}{" "}
                  {skill.experience === 1 ? "year" : "years"}
                </Typography>
              </Typography>
            }
            sx={{
              backgroundColor: "#F8F9FA",
              borderRadius: "16px",
              padding: "20px 20px",
              px: 1,
              "& .MuiChip-label": {
                px: 1,
                color: "#2D2D2D",
                fontWeight: 500,
              },
            }}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default ViewMode;
