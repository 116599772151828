import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  Grid,
  IconButton,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { useStateStore } from "../../../store";
import axios from "axios";

const defaultPositions = [
  {
    id: 1,
    title: "Hiring Manager",
  },
  {
    id: 2,
    title: "Department Head",
  },
  {
    id: 3,
    title: "Team Lead",
  },
];

const PositionCard = ({ position, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(position.title);

  useEffect(() => {
    setTitle(position.title);
  }, [position.title]);

  const handleSave = () => {
    if (title.trim()) {
      onSave(position.id, title);
      setIsEditing(false);
    }
  };

  return (
    <Paper
      sx={{
        p: 3,
        height: "80px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        borderRadius: "16px",
        transition: "transform 0.2s ease",
        "&:hover": {
          transform: "translateY(-2px)",
        },
      }}
    >
      {isEditing ? (
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <TextField
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            size="small"
            placeholder="Enter position title"
            autoFocus
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
            }}
          />
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{
              bgcolor: "#2D2D2D",
              "&:hover": { bgcolor: "#404040" },
              textTransform: "none",
              padding: "6px 16px",
              borderRadius: "8px",
              fontSize: "16px",
              fontWeight: 600,
              minWidth: "80px",
            }}
          >
            Save
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ fontSize: "18px", fontWeight: 600, color: "#2D2D2D" }}
          >
            {title}
          </Typography>
          <IconButton
            onClick={() => setIsEditing(true)}
            size="small"
            sx={{
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Paper>
  );
};

const RecipientPositions = () => {
  const [positions, setPositions] = useState(defaultPositions);
  const { token, userData } = useStateStore();

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const baseUrl = "https://backend.lazyapply.com/lazyapplyV2";
        const v2Id = userData.v2Id;

        const response = await axios.get(`${baseUrl}/${v2Id}/positions`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.data?.positions) {
          setPositions(response.data.positions);
        }
      } catch (error) {
        console.error("Error fetching positions:", error);
      }
    };

    fetchPositions();
  }, [userData.v2Id, token]);

  const handleSavePosition = async (id, newTitle) => {
    try {
      const baseUrl = "https://backend.lazyapply.com/lazyapplyV2";
      const v2Id = userData.v2Id;

      const updatedPositions = positions.map((pos) =>
        pos.id === id ? { ...pos, title: newTitle } : pos
      );
      setPositions(updatedPositions);

      const { data } = await axios.put(
        `${baseUrl}/${v2Id}/positions`,
        {
          positions: updatedPositions,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (data?.positions) {
        setPositions(data.positions);
      }
    } catch (error) {
      console.error("Error updating position:", error);
      setPositions(positions);
    }
  };

  return (
    <Box sx={{ maxWidth: 1200, mx: "auto", mt: 4 }}>
      <Typography
        variant="h5"
        sx={{
          mb: 4,
          fontWeight: 600,
          color: "#2D2D2D",
        }}
      >
        Email Recipient Positions
      </Typography>

      <Grid container spacing={3}>
        {positions.map((position) => (
          <Grid item xs={12} md={4} key={position.id}>
            <PositionCard position={position} onSave={handleSavePosition} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RecipientPositions;
