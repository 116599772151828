import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Tab,
  Tabs,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Divider,
  IconButton,
  InputAdornment,
  Slider,
  Paper,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PayPalButton from "../PaypalButtonV2";
import FAQSection from "./components/FAQSection";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useStateStore } from "../../store/index";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// Styled Components
const StyledCard = styled(Card)(({ theme, highlighted }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: "16px",
  boxShadow: highlighted
    ? "0 8px 32px rgba(0, 0, 0, 0.12)"
    : "0 4px 12px rgba(0, 0, 0, 0.05)",
  transition: "all 0.3s ease",
  border: highlighted
    ? `1px solid ${theme.palette.primary.main}`
    : "1px solid #e0e0e0",
  overflow: "visible",
  position: "relative",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 12px 40px rgba(0, 0, 0, 0.15)",
  },
}));

const PlanCardContent = styled(CardContent)({
  flexGrow: 1,
  padding: "28px",
});

const PlanCardActions = styled(CardActions)({
  padding: "20px 28px 28px",
  justifyContent: "center",
});

const FeatureItem = styled(Box)(({ theme, included }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "12px",
  color: included ? theme.palette.text.primary : theme.palette.text.secondary,
}));

const TabsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: "40px",
}));

const TabButton = styled(Button)(({ theme, active }) => ({
  padding: "12px 32px",
  borderRadius: "30px",
  fontWeight: 600,
  backgroundColor: active ? "#2d2d2d" : "#f0f0f0",
  color: active ? "#ffffff" : "#666666",
  "&:hover": {
    backgroundColor: active ? "#2d2d2d" : "#e0e0e0",
  },
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  transition: "all 0.3s ease",
}));

const PricingBanner = styled(Box)(({ theme, color }) => ({
  backgroundColor: color === "green" ? "#e6f7e6" : "#fff8e6",
  color: color === "green" ? "#2d8a2d" : "#e6ac00",
  padding: "6px 16px",
  borderRadius: "20px",
  display: "inline-block",
  fontWeight: 600,
  fontSize: "14px",
  marginBottom: "16px",
}));

const PriceWrapper = styled(Box)({
  display: "flex",
  alignItems: "baseline",
  marginBottom: "8px",
});

const StyledSlider = styled(Slider)(({ theme }) => ({
  marginTop: "40px",
  marginBottom: "40px",
  color: "#2d2d2d",
  "& .MuiSlider-rail": {
    height: "8px",
    borderRadius: "4px",
    backgroundColor: "#e0e0e0",
  },
  "& .MuiSlider-track": {
    height: "8px",
    borderRadius: "4px",
  },
  "& .MuiSlider-thumb": {
    width: "24px",
    height: "24px",
    backgroundColor: "#2d2d2d",
    "&:hover, &.Mui-focusVisible, &.Mui-active": {
      boxShadow: "0 0 0 8px rgba(45, 45, 45, 0.16)",
    },
  },
  "& .MuiSlider-mark": {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: "#404040",
    border: "2px solid #2d2d2d",
    marginLeft: "-2px",
  },
  "& .MuiSlider-markActive": {
    backgroundColor: "#2d2d2d",
  },
  "& .MuiSlider-markLabel": {
    fontWeight: 600,
    fontSize: "14px",
    color: "#2d2d2d",
    marginTop: "8px",
  },
  "& .MuiSlider-valueLabel": {
    fontSize: "14px",
    fontWeight: 600,
    backgroundColor: "#2d2d2d",
  },
}));

const BillSummary = styled(Box)({
  backgroundColor: "#f8f9fa",
  borderRadius: "12px",
  padding: "20px",
  marginTop: "24px",
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "30px",
  padding: "12px 24px",
  fontWeight: 600,
  textTransform: "none",
  backgroundColor: "#2d2d2d",
  color: "#ffffff",
  "&:hover": {
    backgroundColor: "#404040",
  },
}));

// Mock data for demo purposes
const mockUserData = {
  hasPlan: true, // Set to true to show the "Current Plan" view
  currentPlan: {
    type: "free",
    startDate: "January 15, 2024",
    endDate: "January 15, 2026",
    features: [
      "150 Applications/Day",
      "5 Resume Profiles",
      "Basic Analytics",
      "Email Support",
    ],
    price: 179,
  },
};

const PricingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // State
  const { token, userData, planDetails } = useStateStore();
  const [activeTab, setActiveTab] = useState("individual");
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [openWaitlistModal, setOpenWaitlistModal] = useState(false);
  const [selectedTeamPlan, setSelectedTeamPlan] = useState(null);
  const [showPlans, setShowPlans] = useState(false);
  const [viewingFromCurrentPlan, setViewingFromCurrentPlan] = useState(false);
  const [teamPlanDetails, setTeamPlanDetails] = useState({
    companyName: "",
    email: "",
    seats: 5, // Minimum 5 seats
    isPremium: false,
  });
  const [waitlistDetails, setWaitlistDetails] = useState({
    companyName: "",
    email: "",
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error", // can be "error", "warning", "info", "success"
  });

  const openSnackbar = (message, severity = "error") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const isExpired = () => {
    if (planDetails.planStarted == 0) return true
    if (!planDetails || !planDetails.planEndDate) return true;
    const end = new Date(planDetails.planEndDate);
    const now = new Date();
    return now > end;
  }

  console.log(userData, planDetails, "user data and plan details");

  const PLAN_NAMES = {
    individual: {
      free: "free_plan",
      basic: "basic_plan",
      premium: "premium_plan",
      basic_v2: "basic_plan_v2",
      premium_v2: "premium_plan_v2",
      ultimate_v2: "ultimate_plan_v2",
      basic_v3: "basic_plan_v3",
      premium_v3: "premium_plan_v3",
      ultimate_v3: "ultimate_plan_v3"
    },
    enterprise: {
      basic: "basic_enterprise_plan",
      premium: "premium_enterprise_plan",
      ultimate: "ultimate_enterprise_plan",
    },
  };

  // Helper functions
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Use real data instead of mock data
  const getUserPlanData = () => {
    // If planDetails exists and has planId, user has a plan
    const hasPlan = !!(
      planDetails &&
      (planDetails.planId || planDetails.planName)
    );

    if (!hasPlan) return { hasPlan: false };

    const getPlanType = () => {
      // If planId exists, use it directly
      if (planDetails.planId) return planDetails.planId;

      // Otherwise infer from planName
      switch (planDetails.planName?.toLowerCase()) {
        case "free":
          return PLAN_NAMES.individual.free;
        case "basic":
          return PLAN_NAMES.individual.basic;
        case "premium":
          return PLAN_NAMES.individual.premium;
        // Add cases for enterprise plans if needed
        default:
          return PLAN_NAMES.individual.free; // Default to free plan
      }
    };

    // Map features based on plan type
    const getPlanFeatures = (planType) => {
      switch (planType) {
        // Individual plans
        case PLAN_NAMES.individual.free:
          return ["AI Job Search", "View Job Listings"];

        case PLAN_NAMES.individual.basic_v2:
        case PLAN_NAMES.individual.basic_v3:
          return [
            `${planDetails.applicationDailyLimit || 15} Applications/Day`,
            "1 Resume Profiles",
            "Basic Analytics",
            "Email Support",
          ];

        case PLAN_NAMES.individual.basic:
        case PLAN_NAMES.individual.premium_v2:
        case PLAN_NAMES.individual.premium_v3:
          return [
            `${planDetails.applicationDailyLimit || 150} Applications/Day`,
            "5 Resume Profiles",
            "Basic Analytics",
            "Email Support",
          ];

        case PLAN_NAMES.individual.premium:
        case PLAN_NAMES.individual.ultimate_v2:
        case PLAN_NAMES.individual.ultimate_v3:
          return [
            "1500 Applications/ Day",
            "20 Resume Profiles",
            "Advanced Analytics",
            "Priority Support",
          ];

        // Enterprise plans
        case PLAN_NAMES.enterprise.basic:
          return [
            `${planDetails.applicationDailyLimit || 150} Applications/Day`,
            "5 Resume Profiles per User",
            "Personal Analytics",
            "Team Management",
            `${planDetails.licenses?.total || 5} Seats`,
            "Priority Support",
          ];
        case PLAN_NAMES.enterprise.premium:
          return [
            `${planDetails.applicationDailyLimit || 150} Applications/Day`,
            "5 Resume Profiles per User",
            "Full User Analytics",
            "Team Management",
            `${planDetails.licenses?.total || 5} Seats`,
            "Priority Support",
          ];
        case PLAN_NAMES.enterprise.ultimate:
          return [
            "Unlimited Applications",
            "Unlimited Resume Profiles",
            "Advanced Analytics Dashboard",
            "2 Custom Job Board Integrations",
            `${planDetails.licenses?.total || 100} Seats`,
            "Dedicated Support",
          ];

        // Default case
        default:
          return [
            `${planDetails.applicationDailyLimit || 150} Applications/Day`,
            "5 Resume Profiles",
            "Basic Analytics",
            "Email Support",
          ];
      }
    };

    // Format date to readable format
    const formatDate = (dateString) => {
      if (!dateString) return "N/A";

      try {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      } catch (e) {
        return "N/A";
      }
    };

    // Get plan price based on plan ID
    const getPlanPrice = (planId) => {
      switch (planId) {
        // Individual plans
        case PLAN_NAMES.individual.free:
          return 0;
        case PLAN_NAMES.individual.basic_v2:
          return 79;
        case PLAN_NAMES.individual.basic_v3:
          return 99;
        case PLAN_NAMES.individual.premium_v3:
          return 149;
        case PLAN_NAMES.individual.basic:
        case PLAN_NAMES.individual.premium_v2:
          return 179;
        case PLAN_NAMES.individual.premium:
        case PLAN_NAMES.individual.ultimate_v2:
          return 1790;
        case PLAN_NAMES.individual.ultimate_v3:
          return 999

        // Enterprise plans
        case PLAN_NAMES.enterprise.basic:
          return 179; // Per seat
        case PLAN_NAMES.enterprise.premium:
          return 199; // Per seat
        case PLAN_NAMES.enterprise.ultimate:
          return 25000; // Total for 100 seats

        // Default case
        default:
          return 179;
      }
    };

    const planType = getPlanType();

    return {
      hasPlan,
      currentPlan: {
        type: planType,
        startDate: formatDate(planDetails.planStartDate),
        endDate: formatDate(planDetails.planEndDate),
        features: getPlanFeatures(planType),
        price: getPlanPrice(planType),
      },
    };
  };

  const userPlanData = getUserPlanData();

  const handleTeamPlanSelect = (planType) => {
    setSelectedTeamPlan(planType);
    setTeamPlanDetails({
      ...teamPlanDetails,
      isPremium: planType === "premium",
    });
    setOpenTeamModal(true);
  };

  const handleWaitlistSelect = () => {
    setOpenWaitlistModal(true);
  };

  const handleSeatChange = (event, newValue) => {
    setTeamPlanDetails({
      ...teamPlanDetails,
      seats: newValue,
    });
  };

  const handleSeatInputChange = (event) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value) && value >= 5) {
      setTeamPlanDetails({
        ...teamPlanDetails,
        seats: value,
      });
    }
  };

  const incrementSeats = () => {
    setTeamPlanDetails({
      ...teamPlanDetails,
      seats: teamPlanDetails.seats + 1,
    });
  };

  const decrementSeats = () => {
    if (teamPlanDetails.seats > 5) {
      setTeamPlanDetails({
        ...teamPlanDetails,
        seats: teamPlanDetails.seats - 1,
      });
    }
  };

  const calculateTeamPrice = () => {
    // const basePrice = teamPlanDetails.isPremium ? 199 : 179;
    // const seats = teamPlanDetails.seats;
    // let totalPrice = 0;

    // if (seats <= 10) {
    //   totalPrice = seats * basePrice;
    // } else if (seats <= 50) {
    //   const discountPrice = teamPlanDetails.isPremium ? 169 : 149;
    //   totalPrice = 10 * basePrice + (seats - 10) * discountPrice;
    // } else {
    //   const tier1Price = teamPlanDetails.isPremium ? 169 : 149;
    //   const tier2Price = teamPlanDetails.isPremium ? 149 : 129;
    //   totalPrice = 10 * basePrice + 40 * tier1Price + (seats - 50) * tier2Price;
    // }

    // return totalPrice;

    const seats = teamPlanDetails.seats;
    let pricePerSeat;

    if (seats <= 10) {
      pricePerSeat = teamPlanDetails.isPremium ? 199 : 179;
    } else if (seats <= 50) {
      pricePerSeat = teamPlanDetails.isPremium ? 169 : 149;
    } else {
      pricePerSeat = teamPlanDetails.isPremium ? 149 : 129;
    }

    return seats * pricePerSeat;
  };

  const calculateRegularPrice = () => {
    // const basePrice = teamPlanDetails.isPremium ? 199 : 179;
    // return teamPlanDetails.seats * basePrice;

    return calculateTeamPrice();
  };

  const calculateSavings = () => {
    // return calculateRegularPrice() - calculateTeamPrice();

    const seats = teamPlanDetails.seats;
    const tierOnePrice = teamPlanDetails.isPremium ? 199 : 179;

    const regularPrice = seats * tierOnePrice;
    const actualPrice = calculateTeamPrice();

    return regularPrice - actualPrice;
  };

  const getTierPrice = (tier) => {
    if (tier === 1) return teamPlanDetails.isPremium ? 199 : 179;
    if (tier === 2) return teamPlanDetails.isPremium ? 169 : 149;
    if (tier === 3) return teamPlanDetails.isPremium ? 149 : 129;
    return 0;
  };

  // Calculate the number of seats in each tier based on total seats
  const calculateTierSeats = (totalSeats) => {
    // let tier1 = 0;
    // let tier2 = 0;
    // let tier3 = 0;

    // if (totalSeats <= 10) {
    //   tier1 = totalSeats;
    // } else if (totalSeats <= 50) {
    //   tier1 = 10;
    //   tier2 = totalSeats - 10;
    // } else {
    //   tier1 = 10;
    //   tier2 = 40;
    //   tier3 = totalSeats - 50;
    // }

    // return [tier1, tier2, tier3];

    if (totalSeats <= 10) {
      return [totalSeats, 0, 0]; // All seats at tier 1 price
    } else if (totalSeats <= 50) {
      return [0, totalSeats, 0]; // All seats at tier 2 price
    } else {
      return [0, 0, totalSeats]; // All seats at tier 3 price
    }
  };

  const calculateUpgradePrice = () => {
    const fullPremiumPrice = 999;
    return fullPremiumPrice
    const remainingMonths = calculateRemainingMonths(planDetails?.planEndDate);
    const proRatedCredit =
      (userPlanData.currentPlan.price * remainingMonths) / 12;

    console.log(proRatedCredit, "hi", remainingMonths);
    return fullPremiumPrice - proRatedCredit;
  };

  const calculateRemainingMonths = (endDate) => {
    const end = new Date(endDate);
    const now = new Date();
    const diffTime = end - now;

    if (diffTime <= 0) return 0;

    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    return Math.max(0, Math.round(diffDays / 30));
  };

  const oldplans = ['premium_plan_new', 'basic_plan_new', 'unlimited_plan']

  const formatDate = (isoDate) => {
    try {
      const date = new Date(isoDate);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
    } catch (error) {
      console.error("Error formatting date:", error);
      return isoDate;
    }
  };

  const secondaryValue = (endDate) => {

    if (planDetails.planId && planDetails.planId !== "") {
      if (oldplans.includes(planDetails.planId)) {
        return 'Lifetime'
      }
    }

    return endDate
  }

  const handleWaitlistSubmit = async () => {
    // Validate required fields
    if (!waitlistDetails.companyName || !waitlistDetails.email) {
      openSnackbar("Please fill in all required fields.");
      return;
    }

    try {
      if (!waitlistDetails.companyName || !waitlistDetails.email) {
        openSnackbar("Please fill in all required fields.");
        return;
      }
      // Prepare the waitlist data
      const waitlistData = {
        company_name: waitlistDetails.companyName,
        account_email: waitlistDetails.email,
        plan_id: PLAN_NAMES.enterprise.ultimate,
        user_email: userData?.email || "",
        user_v2Id: userData?.v2Id || "",
      };

      const response = await axios.post(
        "https://backend.lazyapply.com/lazyapplyV2/plans-enterprise-waitlist",
        waitlistData
      );

      if (response.data.success) {
        console.log("Waitlist submission successful");
        openSnackbar(
          "Thank you for joining our waitlist. We'll contact you soon!",
          "success"
        );
      } else {
        openSnackbar("Error submitting your request. Please try again later.");
      }

      setWaitlistDetails({
        companyName: "",
        email: "",
      });
      setOpenWaitlistModal(false);
    } catch (error) {
      // Handle error
      console.error("Error submitting waitlist request", error);
      openSnackbar("Error submitting your request. Please try again later.");
      setWaitlistDetails({
        companyName: "",
        email: "",
      });
      setOpenWaitlistModal(false);
    }
  };

  const renderIndividualPlans = () => (
    <Grid container spacing={4} sx={{ mb: 8 }}>
      {/* Free Plan */}
      {/* <Grid item xs={12} md={4}>
        <StyledCard>
          <PlanCardContent>
            <Typography
              variant="h5"
              component="h2"
              fontWeight={600}
              gutterBottom
            >
              Free
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, height: "40px", fontSize: "15px" }}
            >
              Get started with job search
            </Typography>
            <PriceWrapper>
              <Typography variant="h3" component="span" fontWeight={700}>
                $0
              </Typography>
            </PriceWrapper>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, fontSize: "17px" }}
            >
              Forever free
            </Typography>

            <Divider sx={{ my: 3 }} />

            <Box sx={{ mb: 3 }}>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  AI Job Search
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  View Job Listings
                </Typography>
              </FeatureItem>
              <FeatureItem included={false}>
                <CloseIcon
                  color="disabled"
                  fontSize="small"
                  sx={{ mr: 1, fontSize: "28px", fontWeight: 600 }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Apply for Jobs
                </Typography>
              </FeatureItem>
              <FeatureItem included={false}>
                <CloseIcon
                  color="disabled"
                  fontSize="small"
                  sx={{ mr: 1, fontSize: "28px", fontWeight: 600 }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Multiple Resumes
                </Typography>
              </FeatureItem>
              <FeatureItem included={false}>
                <CloseIcon
                  color="disabled"
                  fontSize="small"
                  sx={{ mr: 1, fontSize: "28px", fontWeight: 600 }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Analytics
                </Typography>
              </FeatureItem>
            </Box>
          </PlanCardContent>
          <PlanCardActions>
            <Button
              variant="contained"
              fullWidth
              disabled={
                userPlanData.hasPlan &&
                userPlanData.currentPlan.type === PLAN_NAMES.individual.free
              }
              sx={{
                borderRadius: "28px",
                py: 1.25,
                fontSize: "17px",
                textTransform: "none",
                fontWeight: 600,
                backgroundColor:
                  userPlanData.hasPlan &&
                  userPlanData.currentPlan.type === PLAN_NAMES.individual.free
                    ? "#f0f0f0"
                    : "#2d2d2d",
                "&:hover": {
                  backgroundColor:
                    userPlanData.hasPlan &&
                    userPlanData.currentPlan.type === PLAN_NAMES.individual.free
                      ? "#f0f0f0"
                      : "#404040",
                },
              }}
            >
              {userPlanData.hasPlan &&
              userPlanData.currentPlan.type === PLAN_NAMES.individual.free
                ? "Current Plan"
                : "Start For Free"}
            </Button>
          </PlanCardActions>
        </StyledCard>
      </Grid> */}

      <Grid item xs={12} md={4}>
        <StyledCard
          highlighted={
            userPlanData.hasPlan &&
            userPlanData.currentPlan.type === PLAN_NAMES.individual.basic
          }
        >
          <PlanCardContent>
            <Typography
              variant="h5"
              component="h2"
              fontWeight={600}
              gutterBottom
            >
              Basic
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, height: "40px", fontSize: "15px" }}
            >
              Get started with job search
            </Typography>
            <PriceWrapper>
              <Typography variant="h3" component="span" fontWeight={700}>
                $99
              </Typography>
            </PriceWrapper>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, fontSize: "17px" }}
            >
              per year
            </Typography>

            <Divider sx={{ my: 3 }} />

            <Box sx={{ mb: 3 }}>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  15 Applications/Day
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  1 Resume Profiles
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Basic Analytics
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Email Support
                </Typography>
              </FeatureItem>
            </Box>
          </PlanCardContent>
          <PlanCardActions>
            {userPlanData.hasPlan && [PLAN_NAMES.individual.basic_v2, PLAN_NAMES.individual.basic_v3].includes(userPlanData.currentPlan.type)
              ? (
                <Button
                  variant="contained"
                  fullWidth
                  disabled
                  sx={{
                    borderRadius: "28px",
                    py: 1.25,
                    fontSize: "17px",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Current Plan
                </Button>
              ) : (
                <PayPalButton
                  titlemain={"basic"}
                  amount={99}
                  titleSecondary={""}
                  plan_id={PLAN_NAMES.individual.basic_v3}
                  user_email={userData?.email || ""}
                  user_v2Id={userData?.v2Id || ""}
                />
              )}
          </PlanCardActions>
        </StyledCard>
      </Grid>

      {/* Basic Plan */}
      <Grid item xs={12} md={4}>
        <StyledCard
          highlighted={
            userPlanData.hasPlan &&
            userPlanData.currentPlan.type === PLAN_NAMES.individual.basic
          }
        >
          <PlanCardContent>
            <Typography
              variant="h5"
              component="h2"
              fontWeight={600}
              gutterBottom
            >
              Premium
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, height: "40px", fontSize: "15px" }}
            >
              Perfect for active job seekers
            </Typography>
            <PriceWrapper>
              <Typography variant="h3" component="span" fontWeight={700}>
                $149
              </Typography>
            </PriceWrapper>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, fontSize: "17px" }}
            >
              per year
            </Typography>

            <Divider sx={{ my: 3 }} />

            <Box sx={{ mb: 3 }}>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  150 Applications/Day
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  5 Resume Profiles
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Basic Analytics
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Email Support
                </Typography>
              </FeatureItem>
            </Box>
          </PlanCardContent>
          <PlanCardActions>
            {userPlanData.hasPlan && [PLAN_NAMES.individual.basic, PLAN_NAMES.individual.premium_v2, PLAN_NAMES.individual.premium_v3].includes(userPlanData.currentPlan.type) ? (
              <Button
                variant="contained"
                fullWidth
                disabled
                sx={{
                  borderRadius: "28px",
                  py: 1.25,
                  fontSize: "17px",
                  textTransform: "none",
                  fontWeight: 600,
                }}
              >
                Current Plan
              </Button>
            ) : (
              <PayPalButton
                titlemain={"basic"}
                amount={149}
                titleSecondary={""}
                plan_id={PLAN_NAMES.individual.premium_v3}
                user_email={userData?.email || ""}
                user_v2Id={userData?.v2Id || ""}
              />
            )}
          </PlanCardActions>
        </StyledCard>
      </Grid>

      {/* Premium Plan */}
      <Grid item xs={12} md={4}>
        <StyledCard
          highlighted={
            userPlanData.hasPlan &&
            userPlanData.currentPlan.type === PLAN_NAMES.individual.premium
          }
        >
          {/* Value Banner */}
          <Box
            sx={{
              position: "absolute",
              top: -14,
              left: "70%",
              transform: "translateX(-50%)",
              zIndex: 2,
            }}
          >
            <PricingBanner
              color="green"
              sx={{ fontSize: "16px", fontWeight: 600 }}
            >
              10X Value
            </PricingBanner>
          </Box>

          <PlanCardContent>
            <Typography
              variant="h5"
              component="h2"
              fontWeight={600}
              gutterBottom
            >
              Ultimate
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, height: "40px", fontSize: "15px" }}
            >
              For serious career advancement
            </Typography>
            <PriceWrapper>
              <Typography variant="h3" component="span" fontWeight={700}>
                $999
              </Typography>
            </PriceWrapper>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, fontSize: "17px" }}
            >
              per year
            </Typography>

            <Divider sx={{ my: 3 }} />

            <Box sx={{ mb: 3 }}>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  1500 Applications/Day
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  20 Resume Profiles
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Advanced Analytics
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Priority Support
                </Typography>
              </FeatureItem>
            </Box>
          </PlanCardContent>
          <PlanCardActions>
            {userPlanData.hasPlan &&
              [PLAN_NAMES.individual.premium, PLAN_NAMES.individual.ultimate_v2, PLAN_NAMES.individual.ultimate_v3].includes(userPlanData.currentPlan.type)
              ? (
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  disabled
                  sx={{
                    borderRadius: "28px",
                    py: 1.25,
                    fontSize: "17px",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Current Plan
                </Button>
              ) : (
                <PayPalButton
                  titlemain={"premium"}
                  amount={
                    userPlanData.hasPlan &&
                      userPlanData.currentPlan.type === PLAN_NAMES.individual.basic
                      ? calculateUpgradePrice()
                      : 999
                  }
                  titleSecondary={""}
                  plan_id={PLAN_NAMES.individual.ultimate_v3}
                  user_email={userData?.email || ""}
                  user_v2Id={userData?.v2Id || ""}
                />
              )}
          </PlanCardActions>
        </StyledCard>
      </Grid>
    </Grid>
  );

  const renderEnterprisePlans = () => (
    <Grid container spacing={4} sx={{ mb: 8 }}>
      {/* Team Basic Plan */}
      <Grid item xs={12} md={4}>
        <StyledCard>
          <PlanCardContent>
            <Typography
              variant="h5"
              component="h2"
              fontWeight={600}
              gutterBottom
            >
              Team Basic
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, height: "40px", fontSize: "15px" }}
            >
              For small teams
            </Typography>
            <PriceWrapper>
              <Typography variant="h3" component="span" fontWeight={700}>
                $149
              </Typography>
            </PriceWrapper>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 1, fontSize: "16px" }}
            >
              per seat / year
            </Typography>
            <Typography
              variant="body2"
              color="success.main"
              fontWeight={500}
              sx={{ mb: 3, fontSize: "16px" }}
            >
              Volume discounts available
            </Typography>

            <Divider sx={{ my: 3 }} />

            <Box sx={{ mb: 3 }}>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  150 Applications/Day
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  5 Resume Profiles per User
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Personal Analytics
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Team Management
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Minimum 5 Seats
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Priority Support
                </Typography>
              </FeatureItem>
            </Box>
          </PlanCardContent>
          <PlanCardActions>
            <Button
              variant="contained"
              fullWidth
              sx={{
                borderRadius: "28px",
                py: 1.25,
                fontSize: "17px",
                textTransform: "none",
                fontWeight: 600,
                backgroundColor: "#2d2d2d",
                "&:hover": {
                  backgroundColor: "#404040",
                },
              }}
              onClick={() => handleTeamPlanSelect("basic")}
            >
              Select Plan
            </Button>
          </PlanCardActions>
        </StyledCard>
      </Grid>

      {/* Team Premium Plan */}
      <Grid item xs={12} md={4}>
        <StyledCard>
          <PlanCardContent>
            <Typography
              variant="h5"
              component="h2"
              fontWeight={600}
              gutterBottom
            >
              Team Premium
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, height: "40px", fontSize: "15px" }}
            >
              For recruitment teams
            </Typography>
            <PriceWrapper>
              <Typography variant="h3" component="span" fontWeight={700}>
                $199
              </Typography>
            </PriceWrapper>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 1, fontSize: "16px" }}
            >
              per seat / year
            </Typography>
            <Typography
              variant="body2"
              color="success.main"
              fontWeight={500}
              sx={{ mb: 3, fontSize: "16px" }}
            >
              Volume discounts available
            </Typography>

            <Divider sx={{ my: 3 }} />

            <Box sx={{ mb: 3 }}>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  150 Applications/Day
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  5 Resume Profiles per User
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  <strong>Full User Analytics</strong>
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Team Management
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Minimum 5 Seats
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Priority Support
                </Typography>
              </FeatureItem>
            </Box>
          </PlanCardContent>
          <PlanCardActions>
            <Button
              variant="contained"
              fullWidth
              sx={{
                borderRadius: "28px",
                py: 1.25,
                fontSize: "17px",
                textTransform: "none",
                fontWeight: 600,
                backgroundColor: "#2d2d2d",
                "&:hover": {
                  backgroundColor: "#404040",
                },
              }}
              onClick={() => handleTeamPlanSelect("premium")}
            >
              Select Plan
            </Button>
          </PlanCardActions>
        </StyledCard>
      </Grid>

      {/* Enterprise Custom Plan */}
      <Grid item xs={12} md={4}>
        <StyledCard>
          <PlanCardContent>
            <Typography
              variant="h5"
              component="h2"
              fontWeight={600}
              gutterBottom
            >
              Enterprise
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, height: "40px", fontSize: "15px" }}
            >
              For large organizations
            </Typography>
            <PriceWrapper>
              <Typography variant="h3" component="span" fontWeight={700}>
                $25,000
              </Typography>
            </PriceWrapper>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, fontSize: "16px" }}
            >
              for 5 years / 100 seats
            </Typography>

            <Divider sx={{ my: 3 }} />

            <Box sx={{ mb: 3 }}>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Unlimited Applications
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Unlimited Resume Profiles
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Advanced Analytics Dashboard
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  2 Custom Job Board Integrations
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  100 Seats
                </Typography>
              </FeatureItem>
              <FeatureItem included={true}>
                <CheckIcon
                  color="success"
                  fontSize="small"
                  sx={{
                    mr: 1,
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#28a745",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "15px" }}>
                  Dedicated Support
                </Typography>
              </FeatureItem>
            </Box>
          </PlanCardContent>
          <PlanCardActions>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              sx={{
                borderRadius: "28px",
                py: 1.25,
                fontSize: "17px",
                textTransform: "none",
                fontWeight: 600,
                backgroundColor: "#2d2d2d",
                "&:hover": {
                  backgroundColor: "#404040",
                },
              }}
              onClick={handleWaitlistSelect}
            >
              Join Waitlist
            </Button>
          </PlanCardActions>
        </StyledCard>
      </Grid>

      {/* Tiered Pricing Section */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          sx={{
            p: 4,
            borderRadius: "16px",
            border: "1px solid #e0e0e0",
            mt: 4,
          }}
        >
          <Typography variant="h5" fontWeight={600} gutterBottom>
            Volume Discounts
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 4, fontSize: "16px" }}
          >
            Save more with larger teams
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" fontWeight={600} gutterBottom>
                Team Basic
              </Typography>
              <Box sx={{ mb: 3 }}>
                <Typography variant="body2" sx={{ mb: 1, fontSize: "16px" }}>
                  1-10 seats: $179 per seat
                </Typography>
                <Typography variant="body2" sx={{ mb: 1, fontSize: "16px" }}>
                  11-50 seats: $149 per seat for all seats
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  51+ seats: $129 per seat for all seats
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="h6" fontWeight={600} gutterBottom>
                Team Premium
              </Typography>
              <Box sx={{ mb: 3 }}>
                <Typography variant="body2" sx={{ mb: 1, fontSize: "16px" }}>
                  1-10 seats: $199 per seat
                </Typography>
                <Typography variant="body2" sx={{ mb: 1, fontSize: "16px" }}>
                  11-50 seats: $169 per seat for all seats
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  51+ seats: $149 per seat for all seats
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="h6" fontWeight={600} gutterBottom>
                Enterprise Custom
              </Typography>
              <Box sx={{ mb: 3 }}>
                <Typography variant="body2" sx={{ mb: 1, fontSize: "16px" }}>
                  Contact us for custom pricing
                </Typography>
                <Typography variant="body2" sx={{ mb: 1, fontSize: "16px" }}>
                  for more than 100 seats or
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  additional integrations.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );

  const renderCurrentPlan = () => (
    <>
      {/* Current Plan Card */}
      <Paper
        elevation={0}
        sx={{
          p: 4,
          borderRadius: "16px",
          border: "1px solid #e0e0e0",
          mb: 6,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h5" fontWeight={600} gutterBottom>
              {planDetails.planName ||
                userPlanData.currentPlan.type.charAt(0).toUpperCase() +
                userPlanData.currentPlan.type.slice(1)}{" "}
              Plan
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Activated on: {userPlanData.currentPlan.startDate}
            </Typography>

            <Divider sx={{ my: 3 }} />

            <Grid container spacing={2}>
              {userPlanData.currentPlan.features.map((feature, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <FeatureItem included={true}>
                    <CheckIcon
                      color="success"
                      fontSize="small"
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body2">{feature}</Typography>
                  </FeatureItem>
                </Grid>
              ))}
            </Grid>

            <Typography variant="body2" sx={{ mt: 3 }}>
              Plan expires on: {secondaryValue(userPlanData.currentPlan.endDate)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-start",
            }}
          >
            <Chip
              label={isExpired() ? "Expired" : "Active"}
              color={isExpired() ? "error" : "success"}
              sx={{
                borderRadius: "16px",
                fontSize: "14px",
                fontWeight: 600,
                py: 1,
                px: 2,
              }}
            />
          </Grid>
        </Grid>
      </Paper>

      {/* Upgrade Section */}
      <Typography variant="h4" fontWeight={600} textAlign="center" gutterBottom>
        Upgrade Your Plan
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        textAlign="center"
        sx={{ mb: 4 }}
      >
        Get more features and capabilities
      </Typography>

      {userPlanData.currentPlan.type === PLAN_NAMES.individual.basic && (
        <Paper
          elevation={0}
          sx={{
            p: 4,
            borderRadius: "16px",
            border: "1px solid #e0e0e0",
            mb: 4,
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
            transition: "all 0.3s ease",
            "&:hover": {
              transform: "translateY(-5px)",
              boxShadow: "0 12px 40px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h5" fontWeight={600} gutterBottom>
                Ultimate Plan
              </Typography>
              <Typography variant="body2" sx={{ mb: 3 }}>
                For serious career advancement
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FeatureItem included={true}>
                    <CheckIcon
                      color="success"
                      fontSize="small"
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body2">
                      1500 Applications/ Day
                    </Typography>
                  </FeatureItem>
                  <FeatureItem included={true}>
                    <CheckIcon
                      color="success"
                      fontSize="small"
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body2">20 Resume Profiles</Typography>
                  </FeatureItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FeatureItem included={true}>
                    <CheckIcon
                      color="success"
                      fontSize="small"
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body2">Advanced Analytics</Typography>
                  </FeatureItem>
                  <FeatureItem included={true}>
                    <CheckIcon
                      color="success"
                      fontSize="small"
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body2">Priority Support</Typography>
                  </FeatureItem>
                </Grid>
              </Grid>

              {/* <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Pro-rated upgrade price with credit for remaining time on your
                  Basic plan:
                </Typography>
                <Typography
                  variant="body2"
                  color="success.main"
                  fontWeight={600}
                  sx={{ mt: 1, fontSize: "16px" }}
                >
                  You save $
                  {(
                    (userPlanData.currentPlan.price *
                      calculateRemainingMonths(planDetails?.planEndDate)) /
                    12
                  ).toFixed(2)}
                </Typography>
              </Box> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ mb: 2, textAlign: "center" }}>
                <Typography variant="h3" component="div" fontWeight={700}>
                  ${calculateUpgradePrice().toFixed(2)}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: "17px" }}
                >
                  per year
                </Typography>
              </Box>
              <PayPalButton
                titlemain={"premium-upgrade"}
                amount={calculateUpgradePrice()}
                titleSecondary={""}
                plan_id={PLAN_NAMES.individual.ultimate_v3}
                user_email={userData?.email || ""}
                user_v2Id={userData?.v2Id || ""}
              />
            </Grid>
          </Grid>
        </Paper>
      )}

      {[PLAN_NAMES.individual.basic_v2, PLAN_NAMES.individual.basic_v3].includes(userPlanData.currentPlan.type)
       && (
        <Paper
          elevation={0}
          sx={{
            p: 4,
            borderRadius: "16px",
            border: "1px solid #e0e0e0",
            mb: 4,
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
            transition: "all 0.3s ease",
            "&:hover": {
              transform: "translateY(-5px)",
              boxShadow: "0 12px 40px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h5" fontWeight={600} gutterBottom>
                Premium Plan
              </Typography>
              <Typography variant="body2" sx={{ mb: 3 }}>
                Perfect for active job seekers
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FeatureItem included={true}>
                    <CheckIcon
                      color="success"
                      fontSize="small"
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body2">
                      150 Applications/ Day
                    </Typography>
                  </FeatureItem>
                  <FeatureItem included={true}>
                    <CheckIcon
                      color="success"
                      fontSize="small"
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body2">5 Resume Profiles</Typography>
                  </FeatureItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FeatureItem included={true}>
                    <CheckIcon
                      color="success"
                      fontSize="small"
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body2">Basic Analytics</Typography>
                  </FeatureItem>
                  <FeatureItem included={true}>
                    <CheckIcon
                      color="success"
                      fontSize="small"
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body2">Email Support</Typography>
                  </FeatureItem>
                </Grid>
              </Grid>

              {/* <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Pro-rated upgrade price with credit for remaining time on your
                  Basic plan:
                </Typography>
                <Typography
                  variant="body2"
                  color="success.main"
                  fontWeight={600}
                  sx={{ mt: 1, fontSize: "16px" }}
                >
                  You save $
                  {(
                    (userPlanData.currentPlan.price *
                      calculateRemainingMonths(planDetails?.planEndDate)) /
                    12
                  ).toFixed(2)}
                </Typography>
              </Box> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ mb: 2, textAlign: "center" }}>
                <Typography variant="h3" component="div" fontWeight={700}>
                  $149
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: "17px" }}
                >
                  per year
                </Typography>
              </Box>
              <PayPalButton
                titlemain={"premium-upgrade"}
                amount={149}
                titleSecondary={""}
                plan_id={PLAN_NAMES.individual.premium_v3}
                user_email={userData?.email || ""}
                user_v2Id={userData?.v2Id || ""}
              />
            </Grid>
          </Grid>
        </Paper>
      )}

      {[PLAN_NAMES.individual.premium_v2, PLAN_NAMES.individual.premium_v3].includes(userPlanData.currentPlan.type) && (
        <Paper
          elevation={0}
          sx={{
            p: 4,
            borderRadius: "16px",
            border: "1px solid #e0e0e0",
            mb: 4,
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
            transition: "all 0.3s ease",
            "&:hover": {
              transform: "translateY(-5px)",
              boxShadow: "0 12px 40px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h5" fontWeight={600} gutterBottom>
                Ultimate Plan
              </Typography>
              <Typography variant="body2" sx={{ mb: 3 }}>
                For serious career advancement
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FeatureItem included={true}>
                    <CheckIcon
                      color="success"
                      fontSize="small"
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body2">
                      1500 Applications/ Day
                    </Typography>
                  </FeatureItem>
                  <FeatureItem included={true}>
                    <CheckIcon
                      color="success"
                      fontSize="small"
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body2">20 Resume Profiles</Typography>
                  </FeatureItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FeatureItem included={true}>
                    <CheckIcon
                      color="success"
                      fontSize="small"
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body2">Advanced Analytics</Typography>
                  </FeatureItem>
                  <FeatureItem included={true}>
                    <CheckIcon
                      color="success"
                      fontSize="small"
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body2">Priority Support</Typography>
                  </FeatureItem>
                </Grid>
              </Grid>

              {/* <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Pro-rated upgrade price with credit for remaining time on your
                  Basic plan:
                </Typography>
                <Typography
                  variant="body2"
                  color="success.main"
                  fontWeight={600}
                  sx={{ mt: 1, fontSize: "16px" }}
                >
                  You save $
                  {(
                    (userPlanData.currentPlan.price *
                      calculateRemainingMonths(planDetails?.planEndDate)) /
                    12
                  ).toFixed(2)}
                </Typography>
              </Box> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ mb: 2, textAlign: "center" }}>
                <Typography variant="h3" component="div" fontWeight={700}>
                  ${calculateUpgradePrice().toFixed(2)}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: "17px" }}
                >
                  per year
                </Typography>
              </Box>
              <PayPalButton
                titlemain={"premium-upgrade"}
                amount={calculateUpgradePrice()}
                titleSecondary={""}
                plan_id={PLAN_NAMES.individual.ultimate_v3}
                user_email={userData?.email || ""}
                user_v2Id={userData?.v2Id || ""}
              />
            </Grid>
          </Grid>
        </Paper>
      )}

      {userPlanData.currentPlan.type === PLAN_NAMES.individual.free && (
        <Paper
          elevation={0}
          sx={{
            p: 4,
            borderRadius: "16px",
            border: "1px solid #e0e0e0",
            mb: 4,
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
            transition: "all 0.3s ease",
            "&:hover": {
              transform: "translateY(-5px)",
              boxShadow: "0 12px 40px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={8}>
              <Typography variant="h5" fontWeight={600} gutterBottom>
                Upgrade Your Plan
              </Typography>
              <Typography variant="body2" sx={{ fontSize: "15px" }}>
                Get access to more features by upgrading to one of our paid
                plans
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "28px",
                  py: 1.25,
                  px: 3,
                  fontSize: "16px",
                  textTransform: "none",
                  fontWeight: 600,
                  backgroundColor: "#2d2d2d",
                  "&:hover": {
                    backgroundColor: "#404040",
                  },
                }}
                onClick={() => {
                  setActiveTab("individual");
                  setShowPlans(true);
                  setViewingFromCurrentPlan(true);
                }}
              >
                View Paid Plans
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}

      {/* Enterprise Option */}
      <Paper
        elevation={0}
        sx={{
          p: 4,
          borderRadius: "16px",
          border: "1px solid #e0e0e0",
          mb: 4,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={8}>
            <Typography variant="h6" fontWeight={600} gutterBottom>
              Looking for team solutions?
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: "15px" }}
            >
              Explore our enterprise plans for teams and organizations
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="outlined"
              sx={{
                borderRadius: "28px",
                py: 1.25,
                px: 3,
                fontSize: "16px",
                textTransform: "none",
                fontWeight: 600,
                borderColor: "#2d2d2d",
                color: "#2d2d2d",
                "&:hover": {
                  borderColor: "#2d2d2d",
                  backgroundColor: "rgba(45, 45, 45, 0.04)",
                },
              }}
              onClick={() => {
                setActiveTab("enterprise");
                setShowPlans(true);
                setViewingFromCurrentPlan(true);
              }}
            >
              View Enterprise Plans
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );

  // Team Plan Modal
  const renderTeamPlanModal = () => (
    <Dialog
      open={openTeamModal}
      onClose={() => setOpenTeamModal(false)}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "16px",
          p: 2,
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.12)",
        },
      }}
    >
      <DialogTitle sx={{ px: 3, pt: 3, pb: 1 }}>
        <Typography variant="h5" fontWeight={600}>
          Complete Your Team Plan
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mt: 1, mb: 3, fontSize: "16px" }}
        >
          Enter your details to set up your team account
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => setOpenTeamModal(false)}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: "grey.500",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 3, py: 2 }}>
        <StyledTextField
          label="Company Name"
          fullWidth
          required
          variant="outlined"
          value={teamPlanDetails.companyName}
          onChange={(e) =>
            setTeamPlanDetails({
              ...teamPlanDetails,
              companyName: e.target.value,
            })
          }
        />
        <StyledTextField
          label="Account Email Address"
          fullWidth
          required
          variant="outlined"
          type="email"
          value={teamPlanDetails.email}
          onChange={(e) =>
            setTeamPlanDetails({
              ...teamPlanDetails,
              email: e.target.value,
            })
          }
        />

        <Box sx={{ mb: 3, mt: 1 }}>
          <Typography variant="body1" fontWeight={600} gutterBottom>
            Number of Seats
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1,
              mt: 2,
            }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={decrementSeats}
              disabled={teamPlanDetails.seats <= 5}
              sx={{
                minWidth: "36px",
                height: "36px",
                borderRadius: "8px",
                p: 0,
              }}
            >
              <RemoveIcon />
            </Button>
            <TextField
              variant="outlined"
              value={teamPlanDetails.seats}
              onChange={handleSeatInputChange}
              inputProps={{
                style: { textAlign: "center" },
                min: 5,
              }}
              sx={{ mx: 2, width: "80px" }}
            />
            <Button
              variant="outlined"
              size="small"
              onClick={incrementSeats}
              sx={{
                minWidth: "36px",
                height: "36px",
                borderRadius: "8px",
                p: 0,
              }}
            >
              <AddIcon />
            </Button>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2, fontSize: "15px" }}
            >
              Minimum 5 seats
            </Typography>
          </Box>

          <StyledSlider
            value={teamPlanDetails.seats}
            onChange={handleSeatChange}
            min={5}
            max={100}
            step={1}
            marks={[
              { value: 5, label: "5" },
              { value: 10, label: "10", style: { fontWeight: 700 } },
              { value: 50, label: "50", style: { fontWeight: 700 } },
              { value: 100, label: "100" },
            ]}
            valueLabelDisplay="auto"
          />
        </Box>

        <BillSummary>
          <Typography variant="h6" fontWeight={600} gutterBottom>
            Bill Summary
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Typography variant="body2">
              {teamPlanDetails.seats} seats × $
              {teamPlanDetails.isPremium ? 199 : 179}/seat/year (regular price)
            </Typography>
            <Typography variant="body2" fontWeight={600}>
              ${teamPlanDetails.seats * (teamPlanDetails.isPremium ? 199 : 179)}
            </Typography>
          </Box>

          {calculateSavings() > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 1,
                color: "success.main",
              }}
            >
              <Typography variant="body2" fontWeight={500}>
                Volume discount savings
              </Typography>
              <Typography variant="body2" fontWeight={600}>
                -${calculateSavings()}
              </Typography>
            </Box>
          )}

          <Divider sx={{ my: 2 }} />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body1" fontWeight={600}>
              Total
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              ${calculateTeamPrice()}/year
            </Typography>
          </Box>
        </BillSummary>
      </DialogContent>
      <DialogActions
        sx={{ px: 3, pb: 3, pt: 1, mt: 2, justifyContent: "space-between" }}
      >
        <Button
          variant="outlined"
          onClick={() => setOpenTeamModal(false)}
          sx={{
            borderRadius: "28px",
            padding: "8px 40px",
            fontSize: "16px",
            textTransform: "none",
            fontWeight: 600,
            borderColor: "#e0e0e0",
            "&:hover": {
              background: "none",
              borderColor: "#000",
            },

            color: "#2d2d2d",
          }}
        >
          Cancel
        </Button>
        <Box sx={{ width: "20%" }}>
          <PayPalButton
            disabled={!teamPlanDetails.companyName || !teamPlanDetails.email}
            titlemain={
              teamPlanDetails.isPremium ? "team-premium" : "team-basic"
            }
            amount={calculateTeamPrice()}
            titleSecondary={teamPlanDetails.seats.toString()}
            plan_id={
              teamPlanDetails.isPremium
                ? PLAN_NAMES.enterprise.premium
                : PLAN_NAMES.enterprise.basic
            }
            user_email={userData?.email || ""}
            user_v2Id={userData?.v2Id || ""}
            company_name={teamPlanDetails.companyName}
            account_email={teamPlanDetails.email}
            no_of_seats={teamPlanDetails.seats}
            tier={calculateTierSeats(teamPlanDetails.seats)}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );

  // Waitlist Modal
  const renderWaitlistModal = () => (
    <Dialog
      open={openWaitlistModal}
      onClose={() => setOpenWaitlistModal(false)}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "16px",
          p: 2,
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.12)",
        },
      }}
    >
      <DialogTitle sx={{ px: 3, pt: 3, pb: 1 }}>
        <Typography variant="h5" fontWeight={600}>
          Join Enterprise Waitlist
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mt: 1, mb: 3 }}
        >
          We'll contact you with more information
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => setOpenWaitlistModal(false)}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: "grey.500",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 3, py: 2 }}>
        <StyledTextField
          label="Company Name"
          fullWidth
          required
          variant="outlined"
          value={waitlistDetails.companyName}
          onChange={(e) =>
            setWaitlistDetails({
              ...waitlistDetails,
              companyName: e.target.value,
            })
          }
        />
        <StyledTextField
          label="Account Email Address"
          fullWidth
          required
          variant="outlined"
          type="email"
          value={waitlistDetails.email}
          onChange={(e) =>
            setWaitlistDetails({
              ...waitlistDetails,
              email: e.target.value,
            })
          }
        />
      </DialogContent>
      <DialogActions
        sx={{ px: 3, pb: 3, pt: 1, justifyContent: "space-between" }}
      >
        <Button
          variant="outlined"
          onClick={() => setOpenWaitlistModal(false)}
          sx={{
            borderRadius: "12px",
            padding: "8px 24px",
            fontSize: "16px",
            textTransform: "none",
            fontWeight: 600,
            borderColor: "#e0e0e0",
            "&:hover": {
              background: "none",
              borderColor: "#000",
            },
            color: "#2d2d2d",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleWaitlistSubmit}
          disabled={!waitlistDetails.companyName || !waitlistDetails.email}
          sx={{
            borderRadius: "12px",
            padding: "8px 24px",
            fontSize: "16px",
            textTransform: "none",
            backgroundColor: "#2d2d2d",
            "&:hover": {
              backgroundColor: "#404040",
            },
            fontWeight: 600,
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Update the main container to check plan status first
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      {/* Show plans directly for free or expired plans */}
      {(userPlanData.currentPlan.type === PLAN_NAMES.individual.free ||
        isExpired()) &&
        !viewingFromCurrentPlan ? (
        <>
          {/* Header */}
          <Box sx={{ textAlign: "center", mb: 6 }}>
            <Typography
              variant="h3"
              component="h1"
              fontWeight={700}
              gutterBottom
            >
              Choose a Plan That's Right for You
            </Typography>
            <Typography variant="h6" color="text.secondary" sx={{ mb: 4 }}>
              Get access to LazyApply's powerful features and start landing more
              interviews
            </Typography>

            {/* Plan Type Toggle */}
            <TabsContainer>
              <TabButton
                active={activeTab === "individual"}
                onClick={() => handleTabChange("individual")}
              >
                Individual
              </TabButton>
              <TabButton
                active={activeTab === "enterprise"}
                onClick={() => handleTabChange("enterprise")}
              >
                Enterprise
              </TabButton>
            </TabsContainer>
          </Box>

          {/* Main Content */}
          {activeTab === "individual"
            ? renderIndividualPlans()
            : renderEnterprisePlans()}
        </>
      ) : (
        <>
          {/* Header */}
          <Box sx={{ textAlign: "center", mb: 6 }}>
            <Typography
              variant="h3"
              component="h1"
              fontWeight={700}
              gutterBottom
            >
              {userPlanData.hasPlan && !showPlans
                ? "Your Current Plan"
                : viewingFromCurrentPlan
                  ? activeTab === "individual"
                    ? "Available Plans"
                    : "Enterprise Solutions"
                  : "Choose a Plan That's Right for You"}
            </Typography>

            {!userPlanData.hasPlan && (
              <Typography variant="h6" color="text.secondary" sx={{ mb: 4 }}>
                Get access to LazyApply's powerful features and start landing
                more interviews
              </Typography>
            )}

            {/* Plan Type Toggle for non-current plan view */}
            {(!userPlanData.hasPlan || showPlans) && (
              <TabsContainer>
                <TabButton
                  active={activeTab === "individual"}
                  onClick={() => handleTabChange("individual")}
                >
                  Individual
                </TabButton>
                <TabButton
                  active={activeTab === "enterprise"}
                  onClick={() => handleTabChange("enterprise")}
                >
                  Enterprise
                </TabButton>
              </TabsContainer>
            )}

            {viewingFromCurrentPlan && (
              <Box
                sx={{ display: "flex", justifyContent: "flex-start", mb: 4 }}
              >
                <Button
                  startIcon={<ArrowBackIcon />}
                  variant="text"
                  onClick={() => {
                    setShowPlans(false);
                    setViewingFromCurrentPlan(false);
                  }}
                  sx={{
                    color: "#666",
                    mt: 4,
                    fontWeight: 500,
                    textTransform: "none",
                    fontSize: "16px",
                    "&:hover": {
                      color: "#000",
                      background: "none",
                    },
                  }}
                >
                  Back to Current Plan
                </Button>
              </Box>
            )}
          </Box>

          {/* Main Content */}
          {userPlanData.hasPlan && !showPlans
            ? renderCurrentPlan()
            : activeTab === "individual"
              ? renderIndividualPlans()
              : renderEnterprisePlans()}
        </>
      )}

      {/* Modals */}
      {renderTeamPlanModal()}
      {renderWaitlistModal()}

      {/* FAQ Section */}
      <FAQSection />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{
            width: "100%",
            fontWeight: 500,
            borderRadius: "8px",
            boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PricingPage;
