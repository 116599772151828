export const countryOptions = [
  { value: "United States", label: "United States" },
  { value: "India", label: "India" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "American Samoa", label: "American Samoa" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Anguilla", label: "Anguilla" },
  { value: "Armenia", label: "Armenia" },
  { value: "Aruba", label: "Aruba" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bermuda", label: "Bermuda" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Brazil", label: "Brazil" },
  { value: "Brunei", label: "Brunei" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Cape Verde", label: "Cape Verde" },
  { value: "Cayman Islands", label: "Cayman Islands" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  { value: "Cook Islands", label: "Cook Islands" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czech Republic", label: "Czech Republic" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Faroe Islands", label: "Faroe Islands" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Gibraltar", label: "Gibraltar" },
  { value: "Greece", label: "Greece" },
  { value: "Greenland", label: "Greenland" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guadeloupe", label: "Guadeloupe" },
  { value: "Guam", label: "Guam" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Korea South", label: "Korea South" },
  { value: "Korea North", label: "Korea North" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Laos", label: "Laos" },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Macao", label: "Macao" },
  { value: "Macedonia", label: "Macedonia" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Martinique", label: "Martinique" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mexico", label: "Mexico" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Caledonia", label: "New Caledonia" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Niue", label: "Niue" },
  { value: "Norfolk Island", label: "Norfolk Island" },
  { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Palestine", label: "Palestine" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Qatar", label: "Qatar" },
  { value: "Reunion", label: "Reunion" },
  { value: "Romania", label: "Romania" },
  { value: "Russia", label: "Russia" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Swaziland", label: "Swaziland" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syria", label: "Syria" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Togo", label: "Togo" },
  { value: "Tokelau", label: "Tokelau" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Virgin Islands (British)", label: "Virgin Islands (British)" },
  { value: "Virgin Islands (U.S.)", label: "Virgin Islands (U.S.)" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
];

export const currencyOptions = [
  { value: "", label: "Select an option" },
  { value: "AED", label: "AED - UAE Dirham" },
  { value: "AFN", label: "AFN - Afghan Afghani" },
  { value: "ALL", label: "ALL - Albanian Lek" },
  { value: "AMD", label: "AMD - Armenian Dram" },
  { value: "ANG", label: "ANG - Netherlands Antillean Guilder" },
  { value: "AOA", label: "AOA - Angolan Kwanza" },
  { value: "ARS", label: "ARS - Argentine Peso" },
  { value: "AUD", label: "AUD - Australian Dollar" },
  { value: "AWG", label: "AWG - Aruban Florin" },
  { value: "AZN", label: "AZN - Azerbaijani Manat" },
  { value: "BAM", label: "BAM - Bosnia-Herzegovina Convertible Mark" },
  { value: "BBD", label: "BBD - Barbadian Dollar" },
  { value: "BDT", label: "BDT - Bangladeshi Taka" },
  { value: "BGN", label: "BGN - Bulgarian Lev" },
  { value: "BHD", label: "BHD - Bahraini Dinar" },
  { value: "BIF", label: "BIF - Burundian Franc" },
  { value: "BMD", label: "BMD - Bermudan Dollar" },
  { value: "BND", label: "BND - Brunei Dollar" },
  { value: "BOB", label: "BOB - Bolivian Boliviano" },
  { value: "BRL", label: "BRL - Brazilian Real" },
  { value: "BSD", label: "BSD - Bahamian Dollar" },
  { value: "BWP", label: "BWP - Botswanan Pula" },
  { value: "BYN", label: "BYN - Belarusian Ruble" },
  { value: "BZD", label: "BZD - Belize Dollar" },
  { value: "CAD", label: "CAD - Canadian Dollar" },
  { value: "CHF", label: "CHF - Swiss Franc" },
  { value: "CLP", label: "CLP - Chilean Peso" },
  { value: "CNY", label: "CNY - Chinese Yuan" },
  { value: "COP", label: "COP - Colombian Peso" },
  { value: "CRC", label: "CRC - Costa Rican Colón" },
  { value: "CZK", label: "CZK - Czech Koruna" },
  { value: "DKK", label: "DKK - Danish Krone" },
  { value: "DOP", label: "DOP - Dominican Peso" },
  { value: "DZD", label: "DZD - Algerian Dinar" },
  { value: "EGP", label: "EGP - Egyptian Pound" },
  { value: "EUR", label: "EUR - Euro" },
  { value: "FJD", label: "FJD - Fijian Dollar" },
  { value: "GBP", label: "GBP - British Pound" },
  { value: "GEL", label: "GEL - Georgian Lari" },
  { value: "GHS", label: "GHS - Ghanaian Cedi" },
  { value: "GTQ", label: "GTQ - Guatemalan Quetzal" },
  { value: "HKD", label: "HKD - Hong Kong Dollar" },
  { value: "HNL", label: "HNL - Honduran Lempira" },
  { value: "HRK", label: "HRK - Croatian Kuna" },
  { value: "HUF", label: "HUF - Hungarian Forint" },
  { value: "IDR", label: "IDR - Indonesian Rupiah" },
  { value: "ILS", label: "ILS - Israeli New Shekel" },
  { value: "INR", label: "INR - Indian Rupee" },
  { value: "IQD", label: "IQD - Iraqi Dinar" },
  { value: "ISK", label: "ISK - Icelandic Króna" },
  { value: "JMD", label: "JMD - Jamaican Dollar" },
  { value: "JOD", label: "JOD - Jordanian Dinar" },
  { value: "JPY", label: "JPY - Japanese Yen" },
  { value: "KES", label: "KES - Kenyan Shilling" },
  { value: "KHR", label: "KHR - Cambodian Riel" },
  { value: "KRW", label: "KRW - South Korean Won" },
  { value: "KWD", label: "KWD - Kuwaiti Dinar" },
  { value: "KZT", label: "KZT - Kazakhstani Tenge" },
  { value: "LBP", label: "LBP - Lebanese Pound" },
  { value: "LKR", label: "LKR - Sri Lankan Rupee" },
  { value: "MAD", label: "MAD - Moroccan Dirham" },
  { value: "MDL", label: "MDL - Moldovan Leu" },
  { value: "MKD", label: "MKD - Macedonian Denar" },
  { value: "MMK", label: "MMK - Myanmar Kyat" },
  { value: "MNT", label: "MNT - Mongolian Tugrik" },
  { value: "MOP", label: "MOP - Macanese Pataca" },
  { value: "MUR", label: "MUR - Mauritian Rupee" },
  { value: "MVR", label: "MVR - Maldivian Rufiyaa" },
  { value: "MXN", label: "MXN - Mexican Peso" },
  { value: "MYR", label: "MYR - Malaysian Ringgit" },
  { value: "NAD", label: "NAD - Namibian Dollar" },
  { value: "NGN", label: "NGN - Nigerian Naira" },
  { value: "NIO", label: "NIO - Nicaraguan Córdoba" },
  { value: "NOK", label: "NOK - Norwegian Krone" },
  { value: "NPR", label: "NPR - Nepalese Rupee" },
  { value: "NZD", label: "NZD - New Zealand Dollar" },
  { value: "OMR", label: "OMR - Omani Rial" },
  { value: "PEN", label: "PEN - Peruvian Nuevo Sol" },
  { value: "PGK", label: "PGK - Papua New Guinean Kina" },
  { value: "PHP", label: "PHP - Philippine Peso" },
  { value: "PKR", label: "PKR - Pakistani Rupee" },
  { value: "PLN", label: "PLN - Polish Złoty" },
  { value: "PYG", label: "PYG - Paraguayan Guarani" },
  { value: "QAR", label: "QAR - Qatari Rial" },
  { value: "RON", label: "RON - Romanian Leu" },
  { value: "RSD", label: "RSD - Serbian Dinar" },
  { value: "RUB", label: "RUB - Russian Ruble" },
  { value: "SAR", label: "SAR - Saudi Riyal" },
  { value: "SCR", label: "SCR - Seychellois Rupee" },
  { value: "SEK", label: "SEK - Swedish Krona" },
  { value: "SGD", label: "SGD - Singapore Dollar" },
  { value: "THB", label: "THB - Thai Baht" },
  { value: "TND", label: "TND - Tunisian Dinar" },
  { value: "TRY", label: "TRY - Turkish Lira" },
  { value: "TTD", label: "TTD - Trinidad and Tobago Dollar" },
  { value: "TWD", label: "TWD - New Taiwan Dollar" },
  { value: "TZS", label: "TZS - Tanzanian Shilling" },
  { value: "UAH", label: "UAH - Ukrainian Hryvnia" },
  { value: "UGX", label: "UGX - Ugandan Shilling" },
  { value: "USD", label: "USD - US Dollar" },
  { value: "UYU", label: "UYU - Uruguayan Peso" },
  { value: "UZS", label: "UZS - Uzbekistani Som" },
  { value: "VEF", label: "VEF - Venezuelan Bolívar" },
  { value: "VND", label: "VND - Vietnamese Dong" },
  { value: "XAF", label: "XAF - Central African CFA Franc" },
  { value: "XOF", label: "XOF - West African CFA Franc" },
  { value: "YER", label: "YER - Yemeni Rial" },
  { value: "ZAR", label: "ZAR - South African Rand" },
  { value: "ZMW", label: "ZMW - Zambian Kwacha" },
];
