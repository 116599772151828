// src/components/analytics/ResumeAnalytics.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";
import { mockApiCall } from "./services/analyticsApi";
import axios from "axios";
import { useStateStore } from "../../../store";

const ResumeAnalytics = () => {
  const { token, userData, planDetails, enterpriseUser, navClickCount } =
    useStateStore();
  const { viewType } = enterpriseUser;
  const [resumes, setResumes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedResume, setSelectedResume] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);

  useEffect(() => {
    fetchResumes();
  }, [planDetails, viewType, navClickCount]);

  const fetchResumes = async () => {
    setLoading(true);
    try {
      if (!planDetails?.planStarted) {
        const response = await mockApiCall("/api/analytics/resumes");
        setResumes(response.data);
      } else {
        const v2Id = userData?.v2Id || "";
        const { data } = await axios.get(
          `https://backend.lazyapply.com/lazyapplyV2/resume-analytics/${v2Id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setResumes(data);
      }
    } catch (error) {
      console.error("Error fetching resumes:", error);
    }
    setLoading(false);
  };

  const handleViewDetails = (resume) => {
    setSelectedResume(resume);
    setDetailsOpen(true);
  };

  const ResumeDetailsDialog = () => (
    <Dialog
      open={detailsOpen}
      onClose={() => setDetailsOpen(false)}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#F8F8F8",
          borderBottom: "1px solid #E0E0E0",
          padding: "20px 24px",
          fontSize: "18px",
          fontWeight: 600,
        }}
      >
        Resume Details
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        {selectedResume && (
          <Box>
            {/* Resume Overview */}
            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{ mb: 2, mt: 2, fontWeight: 600, color: "#2D2D2D" }}
              >
                Resume Overview
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Resume Name
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {selectedResume.resumeName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Total Usage
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {selectedResume.usageCount} applications
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/* Success Metrics */}
            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{ mb: 2, fontWeight: 600, color: "#2D2D2D" }}
              >
                Success Metrics
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Application Success Rate
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {selectedResume.applicationSuccessRate}%
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Referral Email Success Rate
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    Coming Soon
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/* Company Performance */}
            {/* <Box>
              <Typography
                variant="h6"
                sx={{ mb: 2, fontWeight: 600, color: "#2D2D2D" }}
              >
                Top Companies
              </Typography>
              <Grid container spacing={3}>
                {selectedResume.topCompanies.map((company, index) => (
                  <Grid item xs={12} key={index}>
                    <Card variant="outlined" sx={{ mb: 1 }}>
                      <CardContent sx={{ py: 2, "&:last-child": { pb: 2 } }}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 500 }}
                            >
                              {company.companyName}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} sx={{ textAlign: "center" }}>
                            <Typography variant="body2" color="textSecondary">
                              {company.applications} apps
                            </Typography>
                          </Grid>
                          <Grid item xs={2} sx={{ textAlign: "center" }}>
                            <Typography variant="body2" color="textSecondary">
                              {company.successRate}% success
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box> */}
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ borderTop: "1px solid #E0E0E0", p: 2 }}>
        <Button
          onClick={() => setDetailsOpen(false)}
          sx={{
            backgroundColor: "#2D2D2D",
            color: "white",
            borderRadius: "8px",
            padding: "8px 16px",
            px: 3,
            "&:hover": {
              backgroundColor: "#2D2D2D",
              opacity: 0.9,
            },
            textTransform: "none",
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box sx={{ width: "100%", p: 3 }}>
      <Grid container spacing={3}>
        {loading ? (
          <Grid item xs={12}>
            <Typography color="textSecondary">Loading...</Typography>
          </Grid>
        ) : resumes.length === 0 ? (
          <Grid item xs={12}>
            <Typography color="textSecondary">No resumes found</Typography>
          </Grid>
        ) : (
          resumes.map((resume) => (
            <Grid item xs={12} md={6} lg={4} key={resume.resumeId}>
              <Card
                sx={{
                  height: "100%",
                  borderRadius: "10px",
                  border: "1px solid rgba(0,0,0,0.12)",
                  "&:hover": {
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
                  },
                  transition: "box-shadow 0.3s ease-in-out",
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 600, color: "#2D2D2D" }}
                    >
                      {resume.resumeName}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => handleViewDetails(resume)}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Total Applications
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {resume.usageCount}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Application Success Rate
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {resume.applicationSuccessRate}%
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Total Referral Emails
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        Coming Soon
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Emails Success Rate
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        Coming Soon
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* <Box sx={{ mt: 2 }}>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      gutterBottom
                    >
                      Top Company
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {resume.topCompanies[0]?.companyName || "N/A"}
                    </Typography>
                  </Box> */}
                </CardContent>
              </Card>
            </Grid>
          ))
        )}
      </Grid>

      <ResumeDetailsDialog />
    </Box>
  );
};

export default ResumeAnalytics;
