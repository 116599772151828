const countryOptions = [
  { label: "United States" },
  { label: "United Kingdom" },
  { label: "Canada" },
  { label: "Australia" },
  { label: "India" },
  { label: "Singapore" },
  { label: "New Zealand" },
  { label: "Ireland" },
];

export const glassdoorFilters = {
  Country: {
    options: countryOptions,
  },
  "Date posted": {
    options: [
      {
        label: "Any time",
      },
      {
        label: "Last day",
      },
      {
        label: "Last 3 days",
      },
      {
        label: "Last week",
      },
      {
        label: "Last 2 weeks",
      },
      {
        label: "Last month",
      },
    ],
  },
  "Job types": {
    options: [
      {
        label: "Full-time",
      },
      {
        label: "Contract",
      },
      {
        label: "Permanent",
      },
      {
        label: "Part-time",
      },
      {
        label: "Temporary",
      },
    ],
  },
  "Seniority levels": {
    options: [
      {
        label: "All seniority levels",
      },
      {
        label: "Internship",
      },
      {
        label: "Entry level",
      },
      {
        label: "Mid senior level",
      },
      {
        label: "Director",
      },
      {
        label: "Executive",
      },
    ],
  },
};
