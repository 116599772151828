import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Container,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ProfileManagement from "./ProfileManagement";
import EmailSettings from "./EmailSettings";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: "#F5F5F5",
  borderRadius: "20px",
  padding: "5px",
  "& .MuiToggleButton-root": {
    border: "none",
    borderRadius: "16px",
    padding: "8px 28px",
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    color: "#666666",
    "&.Mui-selected": {
      backgroundColor: "#2D2D2D",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#404040",
      },
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    "&:not(:last-child)": {
      marginRight: "4px",
    },
  },
}));

const ProfileAndEmailManager = () => {
  const history = useHistory();
  const location = useLocation();

  const isEmailSettings =
    location.pathname === "/dashboard/profile/email-settings";
  const [activeView, setActiveView] = useState(
    isEmailSettings ? "email" : "profiles"
  );

  useEffect(() => {
    const isEmailRoute =
      location.pathname === "/dashboard/profile/email-settings";
    setActiveView(isEmailRoute ? "email" : "profiles");
  }, [location.pathname]);

  const handleViewChange = (event, newValue) => {
    if (newValue === null) return;

    if (newValue === "email") {
      history.push("/dashboard/profile/email-settings");
    } else {
      history.push("/dashboard/profile");
    }
    setActiveView(newValue);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          mx: "auto",
          mt: 3,
          maxWidth: "80%",
        }}
      >
        <Typography
          sx={{
            color: "#000",
            fontWeight: 550,
            fontSize: "28px",
          }}
        >
          Profile Management
        </Typography>

        {/* <StyledToggleButtonGroup
          value={activeView}
          exclusive
          onChange={handleViewChange}
          aria-label="view selection"
        >
          <ToggleButton disableRipple value="profiles">
            My Profiles
          </ToggleButton>
          <ToggleButton disableRipple value="email">
            Email Settings
          </ToggleButton>
        </StyledToggleButtonGroup> */}
      </Box>

      {activeView === "profiles" ? <ProfileManagement /> : <EmailSettings />}
    </>
  );
};

export default ProfileAndEmailManager;
