// components/sections/PersonalInformation/EditMode.jsx
import React from "react";
import { Grid, TextField, MenuItem, Box, Typography } from "@mui/material";
import {
  genderOptions,
  countryOptions,
  textFieldStyles,
  countryCodeOptions,
} from "./constants";

const FieldGroup = ({ title, children }) => (
  <Box sx={{ mb: 3 }}>
    <Typography
      variant="subtitle1"
      sx={{
        color: "#2D2D2D",
        fontWeight: 600,
        mb: 2,
        fontSize: "16px",
        pb: 0.5,
        borderBottom: "1px solid #E0E0E0",
      }}
    >
      {title}
    </Typography>
    {children}
  </Box>
);

const FormTextField = React.memo(
  ({
    label,
    value,
    onChange,
    onBlur,
    error,
    helperText,
    select = false,
    options = [],
    ...props
  }) => (
    <TextField
      fullWidth
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      select={select}
      variant="outlined"
      sx={textFieldStyles}
      {...props}
    >
      {select &&
        options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  )
);

const EditMode = ({ data, errors = {}, touched = {}, onChange, onBlur }) => {
  const handleBlur = (field) => () => {
    onBlur(field);
  };

  return (
    <Box>
      <FieldGroup title="Basic Information">
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={4}>
            <FormTextField
              label="First Name"
              value={data.firstName}
              onChange={onChange("firstName")}
              onBlur={handleBlur("firstName")}
              error={touched.firstName && !!errors.firstName}
              helperText={touched.firstName && errors.firstName}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormTextField
              label="Last Name"
              value={data.lastName}
              onChange={onChange("lastName")}
              onBlur={handleBlur("lastName")}
              error={touched.lastName && !!errors.lastName}
              helperText={touched.lastName && errors.lastName}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormTextField
              label="Gender"
              value={data.gender}
              onChange={onChange("gender")}
              onBlur={handleBlur("gender")}
              error={touched.gender && !!errors.gender}
              helperText={touched.gender && errors.gender}
              select
              options={genderOptions}
            />
          </Grid>
        </Grid>
      </FieldGroup>

      <FieldGroup title="Contact Information">
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={4}>
            <FormTextField
              label="Email"
              value={data.email}
              onChange={onChange("email")}
              onBlur={handleBlur("email")}
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
              type="email"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormTextField
              label="Country Code"
              value={data.countryCode}
              onChange={onChange("countryCode")}
              onBlur={handleBlur("countryCode")}
              error={touched.countryCode && !!errors.countryCode}
              helperText={touched.countryCode && errors.countryCode}
              select
              options={countryCodeOptions}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormTextField
              label="Phone Number"
              value={data.phoneNumber}
              onChange={onChange("phoneNumber")}
              onBlur={handleBlur("phoneNumber")}
              error={touched.phoneNumber && !!errors.phoneNumber}
              helperText={touched.phoneNumber && errors.phoneNumber}
            />
          </Grid>
        </Grid>
      </FieldGroup>

      <FieldGroup title="Address">
        <Grid container spacing={2.5}>
          <Grid item xs={12}>
            <FormTextField
              label="Address"
              value={data.address}
              onChange={onChange("address")}
              onBlur={handleBlur("address")}
              error={touched.address && !!errors.address}
              helperText={touched.address && errors.address}
              multiline
              rows={1}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormTextField
              label="City"
              value={data.city}
              onChange={onChange("city")}
              onBlur={handleBlur("city")}
              error={touched.city && !!errors.city}
              helperText={touched.city && errors.city}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormTextField
              label="State"
              value={data.state}
              onChange={onChange("state")}
              onBlur={handleBlur("state")}
              error={touched.state && !!errors.state}
              helperText={touched.state && errors.state}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormTextField
              label="Zip Code"
              value={data.zipCode}
              onChange={onChange("zipCode")}
              onBlur={handleBlur("zipCode")}
              error={touched.zipCode && !!errors.zipCode}
              helperText={touched.zipCode && errors.zipCode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              label="Country"
              value={data.country}
              onChange={onChange("country")}
              onBlur={handleBlur("country")}
              error={touched.country && !!errors.country}
              helperText={touched.country && errors.country}
              select
              options={countryOptions}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormTextField
              label="Citizenship"
              value={data.citizenship}
              onChange={onChange("citizenship")}
              onBlur={handleBlur("citizenship")}
              error={touched.citizenship && !!errors.citizenship}
              helperText={touched.citizenship && errors.citizenship}
              select
              options={countryOptions}
            />
          </Grid>
        </Grid>
      </FieldGroup>
    </Box>
  );
};

export default EditMode;
