import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";
import { CircularProgress, Box } from "@mui/material";
import { useStateStore } from "../../store";

const OnBoardingProtectedRoute = ({ children, ...rest }) => {
  console.log("OnBoardingProtectedRoute component mounted");

  const [isLoading, setIsLoading] = useState(true);
  const [hasGoal, setHasGoal] = useState(null);
  const { token, userData } = useStateStore(); // Destructure token and userData

  useEffect(() => {
    const source = axios.CancelToken.source();

    const checkGoal = async () => {
      console.log("Checking goal...");
      try {
        // Use v2Id from userData in the URL
        const v2Id = userData?.v2Id;
        if (!v2Id) {
          console.log("No v2Id found in userData");
          setHasGoal(false);
          return;
        }

        const url = `https://backend.lazyapply.com/lazyapplyV2/onboarding/users/${v2Id}`;
        const {
          data: { success },
        } = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        });

        console.log("onboarding data", success);

        if (success) {
          console.log("onboarding data", success);
          setHasGoal(true);
        } else {
          setHasGoal(false);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request cancelled:", error.message);
        } else {
          console.error("Error checking goal:", error);
          setHasGoal(false);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (token && userData?.v2Id) {
      checkGoal();
    }

    return () => {
      source.cancel("Component unmounted");
    };
  }, [token, userData?.v2Id]); // Added userData.v2Id as dependency

  if (isLoading || hasGoal === null) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        hasGoal ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard/onboarding",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default OnBoardingProtectedRoute;
