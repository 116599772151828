import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  Link,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useStateStore } from "../../../../store";

const GoogleIcon = () => (
  <svg
    width="22"
    height="22"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
  >
    <path
      fill="#EA4335"
      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
    />
    <path
      fill="#4285F4"
      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
    />
    <path
      fill="#FBBC05"
      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
    />
    <path
      fill="#34A853"
      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
    />
  </svg>
);

const GmailPermissionModal = ({
  open,
  onClose,
  onConnect,
  onFailure,
  email,
}) => {
  const CLIENT_ID =
    "715818145189-7geg3j3td9u21nc9qe8eujb2l4dcbt88.apps.googleusercontent.com";
  const [agreements, setAgreements] = useState({
    googlePolicy: false,
    dataPolicy: false,
  });
  const { token, userData } = useStateStore();

  const history = useHistory();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const canConnect = agreements.googlePolicy && agreements.dataPolicy;

  const handleClose = () => {
    setAgreements({ googlePolicy: false, dataPolicy: false });
    onClose();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleConnect = () => {
    onConnect();
    setAgreements({ googlePolicy: false, dataPolicy: false });
  };

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        console.log("code", codeResponse.code);

        const baseUrl = "https://backend.lazyapply.com/lazyapplyV2";
        const response = await axios.post(
          `${baseUrl}/${userData.v2Id}/config/gmail`,
          {
            code: codeResponse.code,
            email_to_be_configured: email.email,
            location: window.location.href,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.message === "Successfully Linked") {
          setSnackbar({
            open: true,
            message: "Gmail connected successfully!",
            severity: "success",
          });
          onConnect();
        }
      } catch (error) {
        console.error("Failed to connect Gmail:", error);

        // Handle different error scenarios
        if (error.response) {
          // Server responded with error
          const errorMessage =
            error.response.data.message || "Failed to connect Gmail";

          setSnackbar({
            open: true,
            message: errorMessage,
            severity: "error",
          });

          onFailure(errorMessage);
        } else if (error.request) {
          // Request made but no response

          const errorMessage = "Network error. Please check your connection.";
          setSnackbar({
            open: true,
            message: errorMessage,
            severity: "error",
          });

          onFailure(errorMessage);
        } else {
          // Something else went wrong

          const errorMessage = "Failed to connect Gmail. Please try again.";
          setSnackbar({
            open: true,
            message: errorMessage,
            severity: "error",
          });
          onFailure(errorMessage);
        }
      }
    },
    onError: (err) => {
      console.log("code err", err.error);

      // Map Google OAuth errors to user-friendly messages
      const errorMessages = {
        popup_closed_by_user: "Gmail connection cancelled.",
        access_denied:
          "Gmail access denied. Please grant all required permissions.",
        immediate_failed:
          "Failed to connect Gmail automatically. Please try again.",
        // Add more error mappings as needed
      };

      const errorMessage =
        errorMessages[err.error] ||
        "Failed to connect Gmail. Please try again.";

      setSnackbar({
        open: true,
        message: errorMessage,
        severity: "error",
      });

      onFailure(errorMessage);
    },
    clientId: CLIENT_ID,
    access_type: "offline",
    flow: "auth-code",
    scope:
      "https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly",
    uxMode: "popup",
  });

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="gmail-permission-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            borderRadius: "16px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            variant="h6"
            sx={{ mb: 4, fontWeight: 550, fontSize: "26px" }}
          >
            Connect Gmail Account
          </Typography>

          <Box sx={{ mb: 4, bgcolor: "#f8f8f8", p: 3, borderRadius: "8px" }}>
            <Typography
              sx={{ color: "#666", fontSize: "16px", lineHeight: 1.6 }}
            >
              LazyApply confirms that it does not use data obtained through
              Google APIs for the purpose of developing, improving, or training
              generalized artificial intelligence or machine learning models. We
              are committed to complying with Google's API Services User Data
              Policy, including the Limited Use requirements.
            </Typography>
          </Box>

          <Box sx={{ mb: 4 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreements.googlePolicy}
                  onChange={() =>
                    setAgreements((prev) => ({
                      ...prev,
                      googlePolicy: !prev.googlePolicy,
                    }))
                  }
                />
              }
              label={
                <Typography sx={{ fontSize: "15px", color: "#666" }}>
                  Lazyapply's use and transfer of information received from
                  Google APIs to any other app will adhere to{" "}
                  <Link
                    component="button"
                    onClick={() => {
                      window.open(
                        "https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes",
                        "_blank"
                      );
                    }}
                    sx={{
                      textDecoration: "underline",
                      color: "#404040",
                      fontWeight: 550,
                    }}
                  >
                    Google API Services User Data Policy
                  </Link>
                  , including the Limited Use requirements.
                </Typography>
              }
              sx={{ mb: 2 }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={agreements.dataPolicy}
                  onChange={() =>
                    setAgreements((prev) => ({
                      ...prev,
                      dataPolicy: !prev.dataPolicy,
                    }))
                  }
                />
              }
              label={
                <Typography sx={{ fontSize: "15px", color: "#666" }}>
                  Lazyapply utilizes a third-party AI model to process job and
                  resume details provided by the user, with the sole purpose of
                  generating personalized emails for HR outreach. Rest assured,
                  this data remains confidential and is not shared with any
                  external entity, apart from the AI model processing. For a
                  detailed understanding, please refer to our{" "}
                  <Link
                    component="button"
                    onClick={() => {
                      onClose();
                      window.open("/dashboard/privacy-policy", "_blank");
                    }}
                    sx={{
                      textDecoration: "underline",
                      color: "#404040",
                      fontWeight: 550,
                    }}
                  >
                    Data Usage and Privacy Explanation
                  </Link>
                </Typography>
              }
            />
          </Box>

          <Button
            startIcon={<GoogleIcon />}
            disabled={!canConnect}
            onClick={login}
            fullWidth
            sx={{
              textTransform: "none",
              bgcolor: "#2D2D2D",
              color: "#fff",
              py: 1.5,
              borderRadius: "8px",
              fontWeight: 500,
              "&:hover": {
                bgcolor: "#404040",
              },
              "&:disabled": {
                bgcolor: "#e0e0e0",
                color: "#666",
              },
            }}
          >
            Give permissions to send email
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%", borderRadius: "8px" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GmailPermissionModal;
