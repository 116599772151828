// DetailedView/RightSection.jsx
import React, { useState } from "react";
import { Box } from "@mui/material";
import ResumePreview from "./ResumePreview";
import ResumeModal from "./ResumeModal";
import EmailTemplatePreview from "./EmailTemplatePreview";
import EmailTemplateModal from "./EmailTemplateModal";

const RightSection = ({ data }) => {
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);

  return (
    <Box>
      <ResumePreview
        data={data}
        onOpenModal={() => setIsResumeModalOpen(true)}
      />

      <EmailTemplatePreview
        data={data}
        onOpenModal={() => setIsEmailModalOpen(true)}
      />

      <ResumeModal
        open={isResumeModalOpen}
        onClose={() => setIsResumeModalOpen(false)}
        data={data}
      />

      <EmailTemplateModal
        open={isEmailModalOpen}
        onClose={() => setIsEmailModalOpen(false)}
        data={data}
      />
    </Box>
  );
};

export default RightSection;
