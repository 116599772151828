import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Container,
  ThemeProvider,
  createTheme,
  CssBaseline,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBack from "@mui/icons-material/ArrowBack";
import GmailConfiguration from "./emailSettings-sections/GmailConfiguration";
import RecipientPositions from "./emailSettings-sections/RecipientPositions";
import EmailTemplates from "./emailSettings-sections/EmailTemplates";
import { useStateStore } from "../../store";
import axios from "axios";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#fff",
          minHeight: "100%",
        },
      },
    },
  },
});

const CardWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  borderRadius: "16px",
  padding: "28px",
  height: "280px",
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  maxWidth: "100%",
  margin: "0 auto",
  cursor: "pointer",
}));

const StyledButton = styled(Button)({
  textTransform: "none",
  padding: "8px 16px",
  borderRadius: "8px",
  fontSize: "15px",
  fontWeight: 550,
  backgroundColor: "#2D2D2D",
  color: "#FFFFFF",
  width: "100%",
  "&:hover": {
    backgroundColor: "#404040",
  },
});

const EmailSettings = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [emails, setEmails] = useState([
    // {
    //   id: 1,
    //   email: "default@gmail.com",
    //   isConnected: false,
    //   isDefault: true,
    // },
  ]);

  const { token, userData } = useStateStore();

  // Add useEffect to fetch emails when component mounts
  useEffect(() => {
    const fetchGmailConnections = async () => {
      try {
        const baseUrl = "https://backend.lazyapply.com/lazyapplyV2";
        const { data } = await axios.get(
          `${baseUrl}/${userData.v2Id}/config/gmail`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        // Set emails from the gmailConnections array in response
        if (data?.gmailConnections) {
          setEmails(data.gmailConnections);
        }
      } catch (error) {
        console.error("Error fetching Gmail connections:", error);
      }
    };

    fetchGmailConnections();
  }, [userData.v2Id, token]);

  const handleAddEmail = (newEmail) => {
    setEmails([
      ...emails,
      {
        id: Date.now(),
        email: newEmail,
        isConnected: false,
        isDefault: false,
      },
    ]);
  };

  const handleSetDefault = (emailId) => {
    setEmails(
      emails.map((email) => ({
        ...email,
        isDefault: email.id === emailId,
      }))
    );
  };

  const handleEditEmail = (id, newEmail) => {
    setEmails(
      emails.map((email) =>
        email.id === id
          ? { ...email, email: newEmail, isConnected: false }
          : email
      )
    );
  };

  const handleConnect = (id, email_details) => {
    setEmails(
      emails.map((email) =>
        email.id === id ? { ...email, isConnected: true } : email
      )
    );
  };

  const handleFailure = (message, id) => {
    console.log("failure message", message, id);
  };

  const getDefaultEmail = () => emails.find((email) => email.isDefault);
  const defaultEmail = getDefaultEmail();

  const handleDeleteEmail = (emailId) => {
    setEmails(
      emails.filter((email) => {
        if (email.isDefault) return true;
        return email.id !== emailId;
      })
    );
  };

  const renderContent = () => {
    switch (activeSection) {
      case "gmail":
        return (
          <GmailConfiguration
            emails={emails}
            onAddEmail={handleAddEmail}
            onSetDefault={handleSetDefault}
            onEditEmail={handleEditEmail}
            onConnect={handleConnect}
            onFailure={handleFailure}
            onDelete={handleDeleteEmail}
          />
        );
      case "recipients":
        return <RecipientPositions />;
      case "templates":
        return <EmailTemplates />;
      default:
        return (
          <Grid container spacing={3}>
            {/* Gmail Connection Card */}
            <Grid item xs={12} md={4}>
              <CardWrapper onClick={() => setActiveSection("gmail")}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#2D2D2D",
                        fontWeight: 600,
                        fontSize: "22px",
                      }}
                    >
                      Gmail Connection
                    </Typography>
                    <Typography
                      sx={{
                        backgroundColor: "#ff4444",
                        color: "#fff",
                        px: 1,
                        py: 0.5,
                        borderRadius: "12px",
                        fontSize: "12px",
                        fontWeight: 600,
                      }}
                    >
                      Required
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ color: "#666666", fontSize: "16px", mb: 2 }}
                  >
                    {defaultEmail?.email || "No email configured"}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Box
                      sx={{
                        width: 8,
                        height: 8,
                        borderRadius: "50%",
                        backgroundColor: defaultEmail?.isConnected
                          ? "#4CAF50"
                          : "#ff4444",
                      }}
                    />
                    <Typography
                      sx={{
                        color: defaultEmail?.isConnected
                          ? "#4CAF50"
                          : "#ff4444",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      {defaultEmail?.isConnected
                        ? "Connected"
                        : "Not Connected"}
                    </Typography>
                  </Box>
                </Box>
                <StyledButton>
                  {defaultEmail?.isConnected ? "Configure" : "Connect Gmail"}
                </StyledButton>
              </CardWrapper>
            </Grid>

            {/* Email Recipient Positions Card */}
            <Grid item xs={12} md={4}>
              <CardWrapper onClick={() => setActiveSection("recipients")}>
                <Box>
                  <Typography
                    sx={{
                      color: "#2D2D2D",
                      fontWeight: 600,
                      fontSize: "22px",
                      mb: 2,
                    }}
                  >
                    Email Recipient Positions
                  </Typography>
                  <Typography sx={{ color: "#666666", fontSize: "16px" }}>
                    Customize the positions and roles of the people who will
                    receive your referral email (e.g., Hiring Manager, Senior
                    Developer, Tech Lead).
                  </Typography>
                </Box>
                <StyledButton>Manage Positions</StyledButton>
              </CardWrapper>
            </Grid>

            {/* Templates Card */}
            <Grid item xs={12} md={4}>
              <CardWrapper onClick={() => setActiveSection("templates")}>
                <Box>
                  <Typography
                    sx={{
                      color: "#2D2D2D",
                      fontWeight: 600,
                      fontSize: "22px",
                      mb: 2,
                    }}
                  >
                    Email Templates
                  </Typography>
                  <Typography sx={{ color: "#666666", fontSize: "16px" }}>
                    Create and customize templates to generate personalized and
                    professional referral emails
                  </Typography>
                </Box>
                <StyledButton>Manage Templates</StyledButton>
              </CardWrapper>
            </Grid>
          </Grid>
        );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            mt: 4,
            mb: 4,
            flex: 1,
          }}
        >
          <Box sx={{ mb: 3 }}>
            {activeSection ? (
              <StyledButton
                startIcon={<ArrowBack />}
                onClick={() => setActiveSection(null)}
                sx={{
                  textTransform: "none",
                  width: "auto",
                  color: "#666",
                  fontSize: "16px",
                  fontWeight: 500,
                  cursor: "pointer",
                  background: "none",
                  mb: 3,
                  ml: -2,
                  "&:hover": {
                    color: "#000",
                    background: "none",
                  },
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "1",
                }}
              >
                Back to Email Settings
              </StyledButton>
            ) : (
              <Typography
                sx={{
                  color: "#2D2D2D",
                  fontWeight: 550,
                  fontSize: "24px",
                  mb: 5,
                }}
              >
                Email Settings
              </Typography>
            )}
          </Box>

          {renderContent()}
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default EmailSettings;
