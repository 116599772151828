import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  Button,
  Skeleton,
  Avatar,
  TablePagination,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import WorkIcon from "@mui/icons-material/Work";
import BarChartIcon from "@mui/icons-material/BarChart";
import axios from "axios";
import { useStateStore } from "../../../../store";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// Mock data for users
const mockUsers = [
  {
    id: 1,
    name: "Alex Williams",
    email: "alex.w@company.com",
    analytics: {
      emailsSent: 45,
      jobsApplied: 12,
      lastActivity: "2024-02-25T14:32:00Z",
    },
  },
  {
    id: 2,
    name: "Sarah Chen",
    email: "sarah.c@company.com",
    analytics: {
      emailsSent: 78,
      jobsApplied: 27,
      lastActivity: "2024-03-01T09:15:00Z",
    },
  },
  {
    id: 3,
    name: "David Garcia",
    email: "david.g@company.com",
    analytics: {
      emailsSent: 56,
      jobsApplied: 18,
      lastActivity: "2024-02-28T16:45:00Z",
    },
  },
  {
    id: 4,
    name: "Maria Rodriguez",
    email: "maria.r@company.com",
    analytics: {
      emailsSent: 102,
      jobsApplied: 35,
      lastActivity: "2024-03-02T11:20:00Z",
    },
  },
  {
    id: 5,
    name: "James Wilson",
    email: "james.w@company.com",
    analytics: {
      emailsSent: 91,
      jobsApplied: 29,
      lastActivity: "2024-03-01T15:10:00Z",
    },
  },
  {
    id: 6,
    name: "Jennifer Lee",
    email: "jennifer.l@company.com",
    analytics: {
      emailsSent: 63,
      jobsApplied: 21,
      lastActivity: "2024-02-27T10:05:00Z",
    },
  },
  {
    id: 7,
    name: "Michael Brown",
    email: "michael.b@company.com",
    analytics: {
      emailsSent: 37,
      jobsApplied: 14,
      lastActivity: "2024-02-26T13:30:00Z",
    },
  },
];

// Helper function to format date
const formatDate = (dateString) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

// Helper function to get activity status and color
const getActivityStatus = (dateString) => {
  const activityDate = new Date(dateString);
  const now = new Date();
  const diffDays = Math.floor((now - activityDate) / (1000 * 60 * 60 * 24));

  if (diffDays < 1) return { status: "Today", color: "success" };
  if (diffDays < 3) return { status: "Recent", color: "primary" };
  if (diffDays < 7) return { status: "This Week", color: "warning" };
  return { status: "Inactive", color: "error" };
};

const UserAnalyticsTable = ({
  timeFrame,
  onApplyAsUser,
  selectedUser = null,
  onBackToOverall,
  dateRange = null,
}) => {
  const { token } = useStateStore();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error", // can be "error", "warning", "info", "success"
  });

  useEffect(() => {
    setLoading(true);

    axios
      .post(
        "https://backend.lazyapply.com/lazyapplyV2/analytics/operator/users",
        {
          timeframe: timeFrame,
          startDate: dateRange?.start,
          endDate: dateRange?.end,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setUsers(response.data.users);
        } else {
          setUsers([]);
        }
      })
      .catch((error) => {
        openSnackbar("Error fetching users. Please try again.");
        console.error("Error fetching users:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [timeFrame, dateRange, token]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle apply as user
  const handleApplyAsUser = (userId) => {
    if (onApplyAsUser) {
      onApplyAsUser(userId);
    } else {
      // For testing purposes
      console.log(`Applying as user ${userId}`);
      alert(`Now applying as user ID: ${userId}`);
    }
  };

  const displayedUsers = users.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const openSnackbar = (message, severity = "error") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        {selectedUser ? (
          // When viewing a specific user's stats
          <Box sx={{ mb: 4 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 0,
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 600, color: "#2d2d2d" }}
              >
                Viewing Stats: {selectedUser.name}
              </Typography>
              <Button
                variant="outlined"
                size="small"
                onClick={onBackToOverall}
                sx={{
                  textTransform: "none",
                  borderColor: "#e0e0e0",
                  padding: "4px 12px",
                  fontSize: "15px",
                  color: "#2d2d2d",
                  mt: 1,
                  borderRadius: "8px",
                  "&:hover": {
                    borderColor: "#d0d0d0",
                    backgroundColor: "#f8f8f8",
                  },
                }}
              >
                ← Back to Overall Stats
              </Button>
            </Box>
            <Typography
              variant="body2"
              sx={{ color: "#666", fontSize: "15px" }}
            >
              The metrics now show the detailed analytics for{" "}
              {selectedUser.email}. You can also see the date-wise analytics for
              this user.
            </Typography>
          </Box>
        ) : (
          <>
            <Typography variant="h6" sx={{ fontWeight: 600, color: "#2d2d2d" }}>
              User Activity
            </Typography>
            <Typography variant="body2" sx={{ color: "#666" }}>
              View analytics for all users assigned to you
            </Typography>
          </>
        )}
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          border: "1px solid #E0E0E0",
          borderRadius: "12px",
        }}
      >
        <Table>
          <TableHead sx={{ backgroundColor: "#F8F8F8" }}>
            <TableRow>
              <TableCell sx={{ fontWeight: 600, width: "40%" }}>User</TableCell>
              <TableCell sx={{ fontWeight: 600, width: "20%" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <EmailIcon fontSize="small" sx={{ mr: 0.5 }} />
                  Emails Sent
                </Box>
              </TableCell>
              <TableCell sx={{ fontWeight: 600, width: "20%" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <WorkIcon fontSize="small" sx={{ mr: 0.5 }} />
                  Jobs Applied
                </Box>
              </TableCell>
              {/* <TableCell sx={{ fontWeight: 600, width: "20%" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <BarChartIcon fontSize="small" sx={{ mr: 0.5 }} />
                  Activity
                </Box>
              </TableCell> */}
              {/* <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              // Loading skeleton
              [...Array(5)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Skeleton
                        variant="circular"
                        width={32}
                        height={32}
                        sx={{ mr: 1 }}
                      />
                      <Box>
                        <Skeleton variant="text" width={120} />
                        <Skeleton variant="text" width={150} height={16} />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={80} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={80} />
                  </TableCell>
                  {/* <TableCell>
                    <Skeleton variant="text" width={80} />
                  </TableCell> */}
                  {/* <TableCell>
                    <Skeleton variant="rectangular" width={100} height={30} />
                  </TableCell> */}
                </TableRow>
              ))
            ) : displayedUsers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center" sx={{ py: 3 }}>
                  <Typography variant="body1" sx={{ color: "#666" }}>
                    No users found
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              displayedUsers.map((user) => {
                const activity = getActivityStatus(user.analytics.lastActivity);

                return (
                  <TableRow key={user.id}>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          sx={{
                            bgcolor: "#e0f2fe",
                            color: "#0369a1",
                            width: 32,
                            height: 32,
                            mr: 1,
                          }}
                        >
                          {user.name.charAt(0)}
                        </Avatar>
                        <Box>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            {user.name}
                          </Typography>
                          <Typography variant="caption" sx={{ color: "#666" }}>
                            {user.email}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" sx={{ pl: 2 }}>
                        {user.analytics.emailsSent}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" sx={{ pl: 2 }}>
                        {user.analytics.jobsApplied}
                      </Typography>
                    </TableCell>
                    {/* <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Chip
                          label={activity.status}
                          size="small"
                          color={activity.color}
                          sx={{
                            fontWeight: 500,
                            mr: 1,
                          }}
                        />
                        <Typography variant="caption" sx={{ color: "#666" }}>
                          {formatDate(user.analytics.lastActivity)}
                        </Typography>
                      </Box>
                    </TableCell> */}
                    {/* <TableCell>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleApplyAsUser(user.id)}
                        sx={{
                          textTransform: "none",
                          padding: "4px 8px",
                          borderRadius: "6px",
                          bgcolor: "#2d2d2d",
                          "&:hover": {
                            bgcolor: "#404040",
                          },
                        }}
                      >
                        View User Stats
                      </Button>
                    </TableCell> */}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            borderTop: "1px solid #E0E0E0",
            ".MuiTablePagination-select": {
              borderRadius: "4px",
              backgroundColor: "#F8F8F8",
            },
            ".MuiTablePagination-displayedRows": {
              margin: 0,
            },
            ".MuiTablePagination-actions": {
              marginLeft: 2,
              marginRight: 0,
            },
            ".MuiTablePagination-selectLabel": {
              margin: 0,
            },
          }}
        />
      </TableContainer>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{
            width: "100%",
            fontWeight: 500,
            borderRadius: "8px",
            boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserAnalyticsTable;
