const cities = [
  // Existing North American cities
  { label: "New York, USA", value: "New York, USA" },
  { label: "Los Angeles, USA", value: "Los Angeles, USA" },
  { label: "Chicago, USA", value: "Chicago, USA" },
  { label: "Miami, USA", value: "Miami, USA" },
  { label: "San Francisco, USA", value: "San Francisco, USA" },
  { label: "Houston, USA", value: "Houston, USA" },
  { label: "Boston, USA", value: "Boston, USA" },
  { label: "Seattle, USA", value: "Seattle, USA" },
  { label: "Philadelphia, USA", value: "Philadelphia, USA" },
  { label: "Denver, USA", value: "Denver, USA" },
  { label: "Atlanta, USA", value: "Atlanta, USA" },
  { label: "Dallas, USA", value: "Dallas, USA" },
  { label: "Austin, USA", value: "Austin, USA" },
  { label: "San Diego, USA", value: "San Diego, USA" },
  { label: "Portland, USA", value: "Portland, USA" },
  { label: "Las Vegas, USA", value: "Las Vegas, USA" },
  { label: "Phoenix, USA", value: "Phoenix, USA" },
  { label: "Minneapolis, USA", value: "Minneapolis, USA" },
  { label: "Detroit, USA", value: "Detroit, USA" },
  { label: "Salt Lake City, USA", value: "Salt Lake City, USA" },
  { label: "Toronto, Canada", value: "Toronto, Canada" },
  { label: "Vancouver, Canada", value: "Vancouver, Canada" },
  { label: "Montreal, Canada", value: "Montreal, Canada" },
  { label: "Calgary, Canada", value: "Calgary, Canada" },
  { label: "Ottawa, Canada", value: "Ottawa, Canada" },
  { label: "Edmonton, Canada", value: "Edmonton, Canada" },
  { label: "Quebec City, Canada", value: "Quebec City, Canada" },
  { label: "Winnipeg, Canada", value: "Winnipeg, Canada" },
  { label: "Halifax, Canada", value: "Halifax, Canada" },
  { label: "Mexico City, Mexico", value: "Mexico City, Mexico" },
  { label: "Guadalajara, Mexico", value: "Guadalajara, Mexico" },
  { label: "Monterrey, Mexico", value: "Monterrey, Mexico" },

  // Existing UK and Ireland cities
  { label: "London, UK", value: "London, UK" },
  { label: "Manchester, UK", value: "Manchester, UK" },
  { label: "Birmingham, UK", value: "Birmingham, UK" },
  { label: "Edinburgh, UK", value: "Edinburgh, UK" },
  { label: "Glasgow, UK", value: "Glasgow, UK" },
  { label: "Bristol, UK", value: "Bristol, UK" },
  { label: "Liverpool, UK", value: "Liverpool, UK" },
  { label: "Leeds, UK", value: "Leeds, UK" },
  { label: "Belfast, UK", value: "Belfast, UK" },
  { label: "Cardiff, UK", value: "Cardiff, UK" },
  { label: "Dublin, Ireland", value: "Dublin, Ireland" },
  { label: "Cork, Ireland", value: "Cork, Ireland" },
  { label: "Galway, Ireland", value: "Galway, Ireland" },
  { label: "Limerick, Ireland", value: "Limerick, Ireland" },

  // Existing Western European cities
  { label: "Berlin, Germany", value: "Berlin, Germany" },
  { label: "Munich, Germany", value: "Munich, Germany" },
  { label: "Frankfurt, Germany", value: "Frankfurt, Germany" },
  { label: "Hamburg, Germany", value: "Hamburg, Germany" },
  { label: "Cologne, Germany", value: "Cologne, Germany" },
  { label: "Stuttgart, Germany", value: "Stuttgart, Germany" },
  { label: "Düsseldorf, Germany", value: "Düsseldorf, Germany" },
  { label: "Paris, France", value: "Paris, France" },
  { label: "Lyon, France", value: "Lyon, France" },
  { label: "Marseille, France", value: "Marseille, France" },
  { label: "Nice, France", value: "Nice, France" },
  { label: "Bordeaux, France", value: "Bordeaux, France" },
  { label: "Toulouse, France", value: "Toulouse, France" },
  { label: "Strasbourg, France", value: "Strasbourg, France" },
  { label: "Madrid, Spain", value: "Madrid, Spain" },
  { label: "Barcelona, Spain", value: "Barcelona, Spain" },
  { label: "Valencia, Spain", value: "Valencia, Spain" },
  { label: "Seville, Spain", value: "Seville, Spain" },
  { label: "Bilbao, Spain", value: "Bilbao, Spain" },
  { label: "Málaga, Spain", value: "Málaga, Spain" },
  { label: "Rome, Italy", value: "Rome, Italy" },
  { label: "Milan, Italy", value: "Milan, Italy" },
  { label: "Naples, Italy", value: "Naples, Italy" },
  { label: "Turin, Italy", value: "Turin, Italy" },
  { label: "Florence, Italy", value: "Florence, Italy" },
  { label: "Bologna, Italy", value: "Bologna, Italy" },
  { label: "Venice, Italy", value: "Venice, Italy" },
  { label: "Amsterdam, Netherlands", value: "Amsterdam, Netherlands" },
  { label: "Rotterdam, Netherlands", value: "Rotterdam, Netherlands" },
  { label: "The Hague, Netherlands", value: "The Hague, Netherlands" },
  { label: "Utrecht, Netherlands", value: "Utrecht, Netherlands" },
  { label: "Eindhoven, Netherlands", value: "Eindhoven, Netherlands" },
  { label: "Brussels, Belgium", value: "Brussels, Belgium" },
  { label: "Antwerp, Belgium", value: "Antwerp, Belgium" },
  { label: "Ghent, Belgium", value: "Ghent, Belgium" },
  { label: "Vienna, Austria", value: "Vienna, Austria" },
  { label: "Salzburg, Austria", value: "Salzburg, Austria" },
  { label: "Innsbruck, Austria", value: "Innsbruck, Austria" },
  { label: "Zurich, Switzerland", value: "Zurich, Switzerland" },
  { label: "Geneva, Switzerland", value: "Geneva, Switzerland" },
  { label: "Basel, Switzerland", value: "Basel, Switzerland" },
  { label: "Bern, Switzerland", value: "Bern, Switzerland" },
  { label: "Lausanne, Switzerland", value: "Lausanne, Switzerland" },
  { label: "Lisbon, Portugal", value: "Lisbon, Portugal" },
  { label: "Porto, Portugal", value: "Porto, Portugal" },

  // Existing Nordic cities
  { label: "Stockholm, Sweden", value: "Stockholm, Sweden" },
  { label: "Gothenburg, Sweden", value: "Gothenburg, Sweden" },
  { label: "Malmö, Sweden", value: "Malmö, Sweden" },
  { label: "Oslo, Norway", value: "Oslo, Norway" },
  { label: "Bergen, Norway", value: "Bergen, Norway" },
  { label: "Trondheim, Norway", value: "Trondheim, Norway" },
  { label: "Copenhagen, Denmark", value: "Copenhagen, Denmark" },
  { label: "Aarhus, Denmark", value: "Aarhus, Denmark" },
  { label: "Helsinki, Finland", value: "Helsinki, Finland" },
  { label: "Tampere, Finland", value: "Tampere, Finland" },
  { label: "Reykjavik, Iceland", value: "Reykjavik, Iceland" },

  // Existing Eastern European cities
  { label: "Warsaw, Poland", value: "Warsaw, Poland" },
  { label: "Krakow, Poland", value: "Krakow, Poland" },
  { label: "Wroclaw, Poland", value: "Wroclaw, Poland" },
  { label: "Gdansk, Poland", value: "Gdansk, Poland" },
  { label: "Prague, Czech Republic", value: "Prague, Czech Republic" },
  { label: "Brno, Czech Republic", value: "Brno, Czech Republic" },
  { label: "Budapest, Hungary", value: "Budapest, Hungary" },
  { label: "Bratislava, Slovakia", value: "Bratislava, Slovakia" },
  { label: "Vienna, Austria", value: "Vienna, Austria" },
  { label: "Bucharest, Romania", value: "Bucharest, Romania" },
  { label: "Cluj-Napoca, Romania", value: "Cluj-Napoca, Romania" },
  { label: "Sofia, Bulgaria", value: "Sofia, Bulgaria" },
  { label: "Zagreb, Croatia", value: "Zagreb, Croatia" },
  { label: "Belgrade, Serbia", value: "Belgrade, Serbia" },
  { label: "Ljubljana, Slovenia", value: "Ljubljana, Slovenia" },
  { label: "Riga, Latvia", value: "Riga, Latvia" },
  { label: "Tallinn, Estonia", value: "Tallinn, Estonia" },
  { label: "Vilnius, Lithuania", value: "Vilnius, Lithuania" },
  { label: "Kyiv, Ukraine", value: "Kyiv, Ukraine" },
  { label: "Lviv, Ukraine", value: "Lviv, Ukraine" },

  // Existing Mediterranean and Middle Eastern cities
  { label: "Istanbul, Turkey", value: "Istanbul, Turkey" },
  { label: "Ankara, Turkey", value: "Ankara, Turkey" },
  { label: "Izmir, Turkey", value: "Izmir, Turkey" },
  { label: "Athens, Greece", value: "Athens, Greece" },
  { label: "Thessaloniki, Greece", value: "Thessaloniki, Greece" },
  { label: "Moscow, Russia", value: "Moscow, Russia" },
  { label: "Saint Petersburg, Russia", value: "Saint Petersburg, Russia" },
  { label: "Kazan, Russia", value: "Kazan, Russia" },
  { label: "Novosibirsk, Russia", value: "Novosibirsk, Russia" },
  { label: "Dubai, UAE", value: "Dubai, UAE" },
  { label: "Abu Dhabi, UAE", value: "Abu Dhabi, UAE" },
  { label: "Doha, Qatar", value: "Doha, Qatar" },
  { label: "Riyadh, Saudi Arabia", value: "Riyadh, Saudi Arabia" },
  { label: "Jeddah, Saudi Arabia", value: "Jeddah, Saudi Arabia" },
  { label: "Tel Aviv, Israel", value: "Tel Aviv, Israel" },
  { label: "Jerusalem, Israel", value: "Jerusalem, Israel" },
  { label: "Beirut, Lebanon", value: "Beirut, Lebanon" },
  { label: "Amman, Jordan", value: "Amman, Jordan" },

  // Existing African cities
  { label: "Cairo, Egypt", value: "Cairo, Egypt" },
  { label: "Alexandria, Egypt", value: "Alexandria, Egypt" },
  { label: "Johannesburg, South Africa", value: "Johannesburg, South Africa" },
  { label: "Cape Town, South Africa", value: "Cape Town, South Africa" },
  { label: "Durban, South Africa", value: "Durban, South Africa" },
  { label: "Lagos, Nigeria", value: "Lagos, Nigeria" },
  { label: "Abuja, Nigeria", value: "Abuja, Nigeria" },
  { label: "Nairobi, Kenya", value: "Nairobi, Kenya" },
  { label: "Accra, Ghana", value: "Accra, Ghana" },
  { label: "Casablanca, Morocco", value: "Casablanca, Morocco" },
  { label: "Marrakech, Morocco", value: "Marrakech, Morocco" },
  { label: "Tunis, Tunisia", value: "Tunis, Tunisia" },
  { label: "Addis Ababa, Ethiopia", value: "Addis Ababa, Ethiopia" },
  { label: "Dar es Salaam, Tanzania", value: "Dar es Salaam, Tanzania" },
  { label: "Kampala, Uganda", value: "Kampala, Uganda" },
  { label: "Kigali, Rwanda", value: "Kigali, Rwanda" },

  // Existing Indian cities
  { label: "Mumbai, India", value: "Mumbai, India" },
  { label: "Delhi, India", value: "Delhi, India" },
  { label: "Bangalore, India", value: "Bangalore, India" },
  { label: "Chennai, India", value: "Chennai, India" },
  { label: "Hyderabad, India", value: "Hyderabad, India" },
  { label: "Kolkata, India", value: "Kolkata, India" },
  { label: "Pune, India", value: "Pune, India" },
  { label: "Ahmedabad, India", value: "Ahmedabad, India" },
  { label: "Jaipur, India", value: "Jaipur, India" },
  { label: "Lucknow, India", value: "Lucknow, India" },
  { label: "Chandigarh, India", value: "Chandigarh, India" },

  // Existing Southeast Asian cities
  { label: "Jakarta, Indonesia", value: "Jakarta, Indonesia" },
  { label: "Bali, Indonesia", value: "Bali, Indonesia" },
  { label: "Surabaya, Indonesia", value: "Surabaya, Indonesia" },
  { label: "Bangkok, Thailand", value: "Bangkok, Thailand" },
  { label: "Chiang Mai, Thailand", value: "Chiang Mai, Thailand" },
  { label: "Phuket, Thailand", value: "Phuket, Thailand" },
  { label: "Kuala Lumpur, Malaysia", value: "Kuala Lumpur, Malaysia" },
  { label: "Penang, Malaysia", value: "Penang, Malaysia" },
  { label: "Singapore, Singapore", value: "Singapore, Singapore" },
  { label: "Manila, Philippines", value: "Manila, Philippines" },
  { label: "Cebu, Philippines", value: "Cebu, Philippines" },
  { label: "Ho Chi Minh City, Vietnam", value: "Ho Chi Minh City, Vietnam" },
  { label: "Hanoi, Vietnam", value: "Hanoi, Vietnam" },
  { label: "Da Nang, Vietnam", value: "Da Nang, Vietnam" },
  { label: "Phnom Penh, Cambodia", value: "Phnom Penh, Cambodia" },
  { label: "Siem Reap, Cambodia", value: "Siem Reap, Cambodia" },
  { label: "Vientiane, Laos", value: "Vientiane, Laos" },
  { label: "Yangon, Myanmar", value: "Yangon, Myanmar" },

  // Existing East Asian cities
  { label: "Seoul, South Korea", value: "Seoul, South Korea" },
  { label: "Busan, South Korea", value: "Busan, South Korea" },
  { label: "Tokyo, Japan", value: "Tokyo, Japan" },
  { label: "Osaka, Japan", value: "Osaka, Japan" },
  { label: "Kyoto, Japan", value: "Kyoto, Japan" },
  { label: "Yokohama, Japan", value: "Yokohama, Japan" },
  { label: "Fukuoka, Japan", value: "Fukuoka, Japan" },
  { label: "Sapporo, Japan", value: "Sapporo, Japan" },
  { label: "Beijing, China", value: "Beijing, China" },
  { label: "Shanghai, China", value: "Shanghai, China" },
  { label: "Shenzhen, China", value: "Shenzhen, China" },
  { label: "Hong Kong, China", value: "Hong Kong, China" },
  { label: "Guangzhou, China", value: "Guangzhou, China" },
  { label: "Chengdu, China", value: "Chengdu, China" },
  { label: "Hangzhou, China", value: "Hangzhou, China" },
  { label: "Xi'an, China", value: "Xi'an, China" },
  { label: "Taipei, Taiwan", value: "Taipei, Taiwan" },
  { label: "Kaohsiung, Taiwan", value: "Kaohsiung, Taiwan" },
  { label: "Taichung, Taiwan", value: "Taichung, Taiwan" },
  { label: "Ulaanbaatar, Mongolia", value: "Ulaanbaatar, Mongolia" },

  // Existing Oceania cities
  { label: "Sydney, Australia", value: "Sydney, Australia" },
  { label: "Melbourne, Australia", value: "Melbourne, Australia" },
  { label: "Brisbane, Australia", value: "Brisbane, Australia" },
  { label: "Perth, Australia", value: "Perth, Australia" },
  { label: "Adelaide, Australia", value: "Adelaide, Australia" },
  { label: "Gold Coast, Australia", value: "Gold Coast, Australia" },
  { label: "Canberra, Australia", value: "Canberra, Australia" },
  { label: "Hobart, Australia", value: "Hobart, Australia" },
  { label: "Auckland, New Zealand", value: "Auckland, New Zealand" },
  { label: "Wellington, New Zealand", value: "Wellington, New Zealand" },
  { label: "Christchurch, New Zealand", value: "Christchurch, New Zealand" },
  { label: "Queenstown, New Zealand", value: "Queenstown, New Zealand" },

  // South American cities
  { label: "São Paulo, Brazil", value: "São Paulo, Brazil" },
  { label: "Rio de Janeiro, Brazil", value: "Rio de Janeiro, Brazil" },
  { label: "Brasília, Brazil", value: "Brasília, Brazil" },
  { label: "Buenos Aires, Argentina", value: "Buenos Aires, Argentina" },
  { label: "Santiago, Chile", value: "Santiago, Chile" },
  { label: "Lima, Peru", value: "Lima, Peru" },
  { label: "Bogotá, Colombia", value: "Bogotá, Colombia" },
  { label: "Medellín, Colombia", value: "Medellín, Colombia" },
  { label: "Cartagena, Colombia", value: "Cartagena, Colombia" },
  { label: "Quito, Ecuador", value: "Quito, Ecuador" },
  { label: "Caracas, Venezuela", value: "Caracas, Venezuela" },
  { label: "Montevideo, Uruguay", value: "Montevideo, Uruguay" },
  { label: "La Paz, Bolivia", value: "La Paz, Bolivia" },
  { label: "Asunción, Paraguay", value: "Asunción, Paraguay" },

  // Caribbean cities
  { label: "San Juan, Puerto Rico", value: "San Juan, Puerto Rico" },
  { label: "Havana, Cuba", value: "Havana, Cuba" },
  {
    label: "Santo Domingo, Dominican Republic",
    value: "Santo Domingo, Dominican Republic",
  },
  { label: "Kingston, Jamaica", value: "Kingston, Jamaica" },
  { label: "Port-au-Prince, Haiti", value: "Port-au-Prince, Haiti" },
  { label: "Nassau, Bahamas", value: "Nassau, Bahamas" },
];

export default cities;
