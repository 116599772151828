// UpgradeModal.js
import React from "react";
import { Modal, Box, Typography, Button, Stack } from "@mui/material";
import UpgradeIcon from "@mui/icons-material/Upgrade";

const UpgradeModal = ({ open, onClose, currentPlan, limit }) => {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="upgrade-modal-title">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          borderRadius: "16px",
          p: 4,
          outline: "none",
          boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        }}
      >
        <Box sx={{ textAlign: "center", mb: 3 }}>
          <UpgradeIcon
            sx={{
              fontSize: 50,
              color: "#000",
              mb: 2,
            }}
          />
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: "#2D2D2D",
              mb: 2,
              fontSize: "28px",
            }}
          >
            Resume Limit Reached
          </Typography>
          <Typography
            sx={{
              color: "#666666",
              fontSize: "16px",
              mb: 4,
            }}
          >
            You've reached the limit of {limit} resumes on your {currentPlan}{" "}
            plan. Upgrade to create more profiles and unlock additional
            features.
          </Typography>
        </Box>

        <Stack direction="row" spacing={2} justifyContent="center">
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2D2D2D",
              color: "#fff",
              px: 3,
              py: 1.5,
              fontSize: "16px",
              fontWeight: 550,
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#404040",
              },
            }}
            onClick={() => (window.location.href = "/dashboard/pricing")}
          >
            Upgrade Plan
          </Button>
          <Button
            variant="outlined"
            sx={{
              px: 3,
              py: 1.5,
              fontSize: "16px",
              fontWeight: 550,
              borderRadius: "8px",
              borderColor: "#E0E0E0",
              color: "#666666",
              "&:hover": {
                borderColor: "#666666",
                bgcolor: "transparent",
              },
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default UpgradeModal;
