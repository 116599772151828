// components/sections/WorkExperience/ViewMode.jsx
import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

const TimelineContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    left: "11px",
    top: 0,
    bottom: 0,
    width: "2px",
    backgroundColor: "#E0E0E0",
  },
}));

const TimelineItem = styled(Box)(({ theme }) => ({
  position: "relative",
  paddingLeft: "40px",
  paddingBottom: "32px",
  "&:last-child": {
    paddingBottom: 0,
  },
  "&::before": {
    content: '""',
    position: "absolute",
    left: "4px",
    top: "6px",
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    backgroundColor: "#2D2D2D",
  },
}));

const ViewMode = ({ experiences }) => {
  if (experiences.length === 0) {
    return (
      <Typography
        sx={{
          color: "#666666",
          fontStyle: "italic",
          textAlign: "center",
          py: 4,
          fontWeight: 500,
        }}
      >
        No work experience added yet.
      </Typography>
    );
  }

  return (
    <TimelineContainer>
      {experiences.map((experience, index) => (
        <TimelineItem key={index}>
          <Stack spacing={1}>
            <Typography
              variant="h6"
              sx={{
                color: "#2D2D2D",
                fontSize: "17px",
                fontWeight: 600,
              }}
            >
              {experience.jobTitle}
            </Typography>

            <Typography
              sx={{
                color: "#2D2D2D",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              {experience.company}
              {experience.workLocation && ` • ${experience.workLocation}`}
            </Typography>

            <Typography
              sx={{
                color: "#666666",
                fontSize: "14px",
              }}
            >
              {experience.startDate} -{" "}
              {experience.currentlyWorkHere ? "Present" : experience.endDate}
              {experience.experienceType && ` • ${experience.experienceType}`}
            </Typography>

            <Typography
              sx={{
                color: "#2D2D2D",
                fontSize: "15px",
                whiteSpace: "pre-wrap",
                mt: 1,
              }}
            >
              {experience.description}
            </Typography>
          </Stack>
        </TimelineItem>
      ))}
    </TimelineContainer>
  );
};

export default ViewMode;
