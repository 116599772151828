import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Card, Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import TimerIcon from "@mui/icons-material/Timer";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ExtensionIcon from "@mui/icons-material/Extension";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { LocationOn as LocationOnIcon } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

const CardContainer = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 9999,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
});

const StyledCard = styled(Card)({
  width: "380px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
  overflow: "hidden",
});

const Header = styled(Box)({
  background: "#2d2d2d",
  padding: "12px 16px",
  display: "flex",
  justifyContent: "flex-end",
});

const ContentBox = styled(Box)({
  padding: "24px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const IconWrapper = styled(Box)({
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  background: "rgba(45, 45, 45, 0.1)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "16px",
  "& svg": {
    fontSize: "28px",
    color: "#2d2d2d",
  },
});

const ActionButton = styled(Button)({
  backgroundColor: "#2d2d2d",
  color: "#fff",
  padding: "8px 24px",
  borderRadius: "24px",
  textTransform: "none",
  fontSize: "16px",
  fontWeight: "550",
  marginTop: "24px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#404040",
  },
});

const BenefitItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "12px",
  marginBottom: "8px",
  width: "100%",
  "& .bullet": {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: "#4bce88",
  },
});

const ErrorCard = ({ type = "profile", onClose }) => {
  const history = useHistory();

  useEffect(() => {
    if (type === "profile" || type === "purchase" || type === "no_profile") {
      const timer = setTimeout(() => {
        onClose();
        const redirectPath =
          type === "profile" || type === "no_profile"
            ? "/dashboard/profile"
            : "/dashboard/pricing";
        history.push(redirectPath);
      }, 3000); // 3 seconds before redirect

      return () => clearTimeout(timer);
    }
  }, [type, history, onClose]);

  const handleAction = (type) => {
    switch (type) {
      case "profile":
      case "no_profile":
        history.push("/dashboard/profile");
        break;
      case "limit":
      case "purchase":
      case "plan":
        history.push("/dashboard/pricing");
        break;
      case "extension":
        window.open(
          "https://chrome.google.com/webstore/detail/lazyapply-job-application/pgnfaifdbfoiehcndkoeemaifhhbgkmm",
          "_blank"
        );
        break;
      case "profile_selection":
      case "country_selection":
        onClose();
        break;
      default:
        break;
    }
    // Close the card after action
    onClose();
  };

  const getCardContent = () => {
    switch (type) {
      case "profile":
        return {
          icon: <PersonOutlineIcon />,
          title: "Profile Not Completed",
          message: "Please complete your profile to continue",
          buttonText: "Complete Profile",
        };
      case "limit":
        return {
          icon: <TimerIcon />,
          title: "Daily Limit Reached",
          message: "Upgrade your plan for unlimited applications",
          buttonText: "Upgrade Plan",
        };
      case "plan":
        return {
          icon: <CalendarTodayIcon />,
          title: "Plan Expired",
          message: "Your plan has expired. Renew to continue",
          buttonText: "Renew Plan",
        };
      case "purchase":
        return {
          icon: <ShoppingCartIcon />,
          title: "Plan Not Purchased",
          message: "Purchase a plan to start applying to jobs",
          buttonText: "Purchase Plan",
        };
      case "extension":
        return {
          icon: <ExtensionIcon />,
          title: "Install LazyApply Extension",
          message: "",
          buttonText: "Add to Chrome",
          benefits: [
            "Auto-fill job applications",
            "Send referral emails for your applications",
            "Only 1 click is required to install.",
          ],
        };
      case "profile_selection":
        return {
          icon: <PersonOutlineIcon />,
          title: "Select a Profile",
          message:
            "Please select a profile before starting the application process",
          buttonText: "Got it",
        };
      case "no_profile":
        return {
          icon: <PersonOutlineIcon />,
          title: "Create Your Profile",
          message: "You need to create a profile to start applying for jobs",
          buttonText: "Create Profile",
        };
      case "country_selection":
        return {
          icon: <LocationOnIcon />,
          title: "Select Country",
          message: "Please select a country to continue with your job search",
          buttonText: "Got it",
        };
      default:
        return {
          icon: <PersonOutlineIcon />,
          title: "Action Required",
          message: "Please take action to continue",
          buttonText: "Take Action",
        };
    }
  };

  const content = getCardContent();

  return (
    <CardContainer>
      <StyledCard>
        <Header>
          <IconButton onClick={onClose} sx={{ color: "#fff", padding: "4px" }}>
            <CloseIcon />
          </IconButton>
        </Header>

        <ContentBox>
          <IconWrapper>{content.icon}</IconWrapper>

          <Typography
            variant="h6"
            align="center"
            sx={{
              color: "#2d2d2d",
              fontSize: "20px",
              fontWeight: 550,
              marginBottom: "12px",
            }}
          >
            {content.title}
          </Typography>

          {content.message && (
            <Typography
              variant="body2"
              align="center"
              sx={{
                color: "#666",
                fontSize: "17px",
                marginBottom: content.benefits ? "16px" : "0",
              }}
            >
              {content.message}
            </Typography>
          )}

          {content.benefits && (
            <Box sx={{ width: "100%", marginTop: "16px" }}>
              {content.benefits.map((benefit, index) => (
                <BenefitItem key={index}>
                  <div className="bullet" />
                  <Typography
                    variant="body2"
                    sx={{ color: "#666", fontSize: "16px" }}
                  >
                    {benefit}
                  </Typography>
                </BenefitItem>
              ))}
            </Box>
          )}

          <ActionButton onClick={() => handleAction(type)}>
            {content.buttonText}
          </ActionButton>
        </ContentBox>
      </StyledCard>
    </CardContainer>
  );
};

export default ErrorCard;
