import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { v1 as uuidv1 } from "uuid";
import axios from "../../apis/axios-config";
import {
  Box,
  Container,
  Typography,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Snackbar,
  Alert,
  Skeleton,
} from "@mui/material";
import ProfileGrid from "./profile-components/ProfileGrid";
import CreateProfileModal from "./profile-components/CreateProfileModal";
import { useStateStore } from "../../store";
import UpgradeModal from "./profile-components/UpgradeModal";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#fff",
          minHeight: "100%",
        },
      },
    },
  },
});

const ProfileManagement = () => {
  const history = useHistory();
  const [profiles, setProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const { token, userData, planDetails, enterpriseUser, navClickCount, updateLicenses } = useStateStore();

  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  const [userNamesExist, setUserNamesExist] = useState(false);
  const viewType = enterpriseUser?.viewType || "individual";

  useEffect(() => {
    const fetchResumeMetas = async () => {
      try {
        const url = `https://backend.lazyapply.com/lazyapplyV2/resumes`;
        const {
          data: { data },
        } = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        // Transform the resume metas to match profile structure
        const transformedProfiles = data.map((meta) => ({
          id: meta.resumeId,
          title: meta.resumeName,
          lastModified: new Date(meta.lastModified).toLocaleDateString(),
          completionPercentage: meta.percentageCompleted || 0,
        }));

        setProfiles(transformedProfiles);
      } catch (error) {
        console.error("Error fetching profiles:", error);
        showNotification(
          "Failed to load profiles. Please refresh the page.",
          "error"
        );
      } finally {
        setIsInitialLoading(false);
      }
    };

    fetchResumeMetas();
  }, [viewType, navClickCount]);

  const showNotification = (message, severity = "success") => {
    setNotification({
      open: true,
      message,
      severity,
    });
  };

  const checkUserNames = async () => {
    try {
      const response = await axios.get(
        `https://backend.lazyapply.com/lazyapplyV2/user-names/${userData.v2Id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserNamesExist(response.data.exists);
      return response.data.exists;
    } catch (error) {
      console.error("Error checking user names:", error);
      return false;
    }
  };

  const getPlanLimit = (planName) => {
    console.log("planname", planName);
    const planLimitsByPlanId = {

      'free_plan': 1,
      'basic_plan_new': 1,
      'premium_plan_new': 5,
      'unlimited_plan': 10,
      'basic_plan_v2': 1,
      'premium_plan_v2': 5,
      'ultimate_plan_v2': 15,
      'basic_plan_v3': 1,
      'premium_plan_v3': 5,
      'ultimate_plan_v3': 15,
      'basic_plan': 5,
      'premium_plan': 5,
      'ultimate_plan': 5,
      'premium_enterprise_plan': 5,
      'basic_enterprise_plan': 5
    }
    // const planLimits = {
    //   Basic: 1,
    //   Premium: 3,
    //   Ultimate: 5,
    //   'Basic Enterprise': 5,
    //   'Premium Enterprise': 5,
    //   'Enterprise': 5
    // };

    const planId = planDetails?.planId || "free_plan"
    return planLimitsByPlanId[planId] || 1;
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ ...notification, open: false });
  };

  const handleOpenCreateModal = async () => {
    const isPlanStarted = planDetails.planStarted;

    let currentPlan = "Basic";

    if (isPlanStarted) {
      currentPlan = planDetails?.planName;
    }

    const allowedResumes = getPlanLimit(currentPlan);

    console.log(allowedResumes, profiles.length, "profiles");

    if (profiles.length >= allowedResumes) {
      setIsUpgradeModalOpen(true);
      return;
    }

    await checkUserNames();
    setIsCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleCreateProfile = async (profileData) => {
    setIsLoading(true);
    try {
      const resumeId = uuidv1();
      const formData = new FormData();
      
      formData.append("subscriptionId", planDetails?.subscriptionId || "")

      formData.append("resumeName", profileData.title);
      formData.append("resumeId", resumeId);
      formData.append("file", profileData.file);

      if (profileData.firstName) {
        formData.append("firstName", profileData.firstName);
      }
      if (profileData.lastName) {
        formData.append("lastName", profileData.lastName);
      }

      if (profileData.firstName && profileData.lastName) {
        try {
          await axios.post(
            "https://backend.lazyapply.com/lazyapplyV2/user-names/create",
            {
              v2Id: userData.v2Id,
              email: userData.email,
              firstName: profileData.firstName,
              lastName: profileData.lastName,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } catch (error) {
          console.error("Error saving user names:", error);
        }
      }

      const v2Id = userData?.v2Id || "";
      let url = `https://backend.lazyapply.com/lazyapplyV2/resume/${v2Id}`;
      const {
        data: { data },
      } = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const licenseData = data?.license;
      if (licenseData) {
        updateLicenses(licenseData);
      }

      const resumeMeta = data?.resumeMeta;
      if (!resumeMeta) {
        throw new Error("Resume metadata not found in response");
      }

      const newProfile = {
        id: resumeId,
        title: profileData.title,
        lastModified: new Date(
          resumeMeta.lastModified || Date.now()
        ).toLocaleDateString(),
        completionPercentage: resumeMeta.percentageCompleted || 0,
      };

      setProfiles([...profiles, newProfile]);
      handleCloseCreateModal();
      showNotification("Profile created successfully");

      return resumeId;
    } catch (error) {
      console.error("Error creating profile:", error);
      showNotification("Failed to create profile. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteProfile = async (profileId) => {
    setIsDeleting(true);
    try {
      const url = `https://backend.lazyapply.com/lazyapplyV2/resume/${profileId}/hide`;
      await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setProfiles(profiles.filter((profile) => profile.id !== profileId));
      showNotification("Profile deleted successfully");
    } catch (error) {
      console.error("Error deleting profile:", error);
      showNotification("Failed to delete profile. Please try again.", "error");
    } finally {
      setIsDeleting(false);
    }
  };

  const handleEditProfile = (profileId) => {
    history.push(`/dashboard/profile/${profileId}`);
  };

  // Loading skeleton for profiles
  const LoadingSkeleton = () => (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", gap: 3, flexWrap: "wrap" }}>
        {[1, 2, 3].map((n) => (
          <Box
            key={n}
            sx={{
              width: "calc(33.333% - 16px)",
              height: "240px",
              borderRadius: "16px",
              overflow: "hidden",
            }}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              animation="wave"
            />
          </Box>
        ))}
      </Box>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            mt: 4,
            mb: 4,
            flex: 1,
          }}
        >
          <Typography
            sx={{
              color: "#2D2D2D",
              fontWeight: 550,
              fontSize: "24px",
              mb: 5,
            }}
          >
            My Profiles
          </Typography>

          {isInitialLoading ? (
            <LoadingSkeleton />
          ) : (
            <ProfileGrid
              profiles={profiles}
              onCreateNew={handleOpenCreateModal}
              onEdit={handleEditProfile}
              onDelete={handleDeleteProfile}
              isDeleting={isDeleting}
            />
          )}

          <CreateProfileModal
            open={isCreateModalOpen}
            onClose={handleCloseCreateModal}
            onCreate={handleCreateProfile}
            showNameFields={!!(!userNamesExist && planDetails.planStarted)}
          />

          <Snackbar
            open={notification.open}
            autoHideDuration={6000}
            onClose={handleCloseNotification}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseNotification}
              severity={notification.severity}
              sx={{ width: "100%" }}
            >
              {notification.message}
            </Alert>
          </Snackbar>
        </Container>
      </Box>

      <UpgradeModal
        open={isUpgradeModalOpen}
        onClose={() => setIsUpgradeModalOpen(false)}
        currentPlan={planDetails?.planName || "Basic"}
        limit={getPlanLimit(planDetails?.planName || "Basic")}
      />
    </ThemeProvider>
  );
};

export default ProfileManagement;
