import React from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  useScrollTrigger,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import lazyapplylogo from "../../ats-dashboard/assets/panda1.png";

const StyledAppBar = styled(AppBar)({
  backgroundColor: "transparent",
  boxShadow: "none",
  position: "fixed",
});

const NavLink = styled(Button)({
  color: "#666",
  fontSize: "17px",
  fontWeight: 500,
  textTransform: "none",
  "&:hover": {
    backgroundColor: "transparent",
    color: "#000000",
  },
});

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    style: {
      backgroundColor: trigger ? "#fff" : "transparent",
      transition: "0.3s",
    },
  });
}

const Navbar = ({ onLoginClick }) => {
  const navLinks = [
    { title: "How It Works", path: "#how-it-works" },
    { title: "Pricing", path: "#pricing" },
    { title: "Reviews", path: "#success" },
  ];

  return (
    <ElevationScroll>
      <StyledAppBar>
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{
              minHeight: "80px",
              px: { lg: 2, xl: 15 },
            }}
          >
            {/* Logo */}
            <Box
              sx={{
                mr: "auto",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img src={lazyapplylogo} alt="Logo" style={{ height: "80px" }} />
              <Typography
                fontSize="20px"
                fontWeight={700}
                color="#2d2d2d"
                sx={{
                  letterSpacing: "0.3px",
                }}
              >
                LazyApply
              </Typography>
            </Box>

            {/* Navigation Links */}
            <Stack
              direction="row"
              spacing={6}
              sx={{
                flex: 1,
                justifyContent: "center",
              }}
            >
              {navLinks.map((item) => (
                <NavLink key={item.title} href={item.path} disableRipple>
                  {item.title}
                </NavLink>
              ))}
            </Stack>

            {/* Action Buttons */}
            <Stack direction="row" spacing={2} sx={{ ml: "auto" }}>
              <Button
                onClick={onLoginClick}
                disableRipple
                sx={{
                  color: "#2d2d2d",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: 550,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#000000",
                  },
                }}
              >
                Login
              </Button>
              <Button
                variant="outlined"
                disableRipple
                sx={{
                  color: "#2d2d2d",
                  borderColor: "#666666",
                  px: 2.5,
                  py: 0.5,
                  fontSize: "18px",
                  fontWeight: 550,
                  borderRadius: "8px",
                  textTransform: "none",
                  border: "1.5px solid rgba(45, 45, 45, 0.2)",
                  "&:hover": {
                    border: "1.5px solid #2d2d2d",
                    boxShadow: "0 6px 20px rgba(0,0,0,0.25)",
                  },
                }}
              >
                Get Started
              </Button>
            </Stack>
          </Toolbar>
        </Container>
      </StyledAppBar>
    </ElevationScroll>
  );
};

export default Navbar;
