import React, { useState, useEffect, useRef } from "react";
import { Box, InputBase, Button, styled, Typography } from "@mui/material";
import {
  Search as SearchIcon,
  LocationOn as LocationIcon,
  FormatListNumbered as FormatListNumberedIcon,
  History as HistoryIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import {
  Paper,
  Divider,
  Popper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  CircularProgress,
} from "@mui/material";
import { useStateStore } from "../../../store";
import axios from "axios";

const SearchContainer = styled(Box)(({ focus }) => ({
  padding: "14px 16px",
  display: "flex",
  margin: "0 auto",
  alignItems: "center",
  width: "85%",
  backgroundColor: "#fff",
  borderRadius: "36px",
  position: "relative",
  border: "2px solid transparent",
  backgroundImage: `linear-gradient(white, white), 
    linear-gradient(90deg, 
     rgba(45, 45, 45, 0.6),     
     rgba(45, 45, 45, 0.6) 
    )`,
  boxShadow: "0 8px 20px rgba(45, 45, 45, 0.2)",
  backgroundOrigin: "border-box",
  backgroundClip: "padding-box, border-box",
  transition: "all 0.3s ease",
  "&:hover": {
    boxShadow: `
      0 8px 20px rgba(45, 45, 45, 0.3)
    `,
    backgroundImage: `linear-gradient(white, white), 
      linear-gradient(90deg, 
         rgba(45, 45, 45, 0.8),     
         rgba(45, 45, 45, 0.8)  
      )`,
  },
}));

const SearchInput = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flex: 1,
  position: "relative",
  height: "48px",
}));

const SearchButton = styled(Button)({
  backgroundColor: "#2D2D2D",
  color: "#FFFFFF",
  padding: "0 28px",
  borderRadius: "12px",
  marginLeft: "16px",
  textTransform: "none",
  fontSize: "17px",
  fontWeight: 600,
  height: "48px",
  minWidth: "140px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#404040",
  },
});

const RecentSearchPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: "100%",
  maxWidth: "300px",
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  borderRadius: "8px",
}));

const StyledInputBase = styled(InputBase)(({ theme, isDropdown }) => ({
  flex: 1,
  "& .MuiInputBase-input": {
    padding: "16px",
    height: "24px",
    fontSize: "18px",
    fontWeight: "500",
    cursor: isDropdown ? "pointer" : "text",
    "&::placeholder": {
      color: "#666666",
      opacity: 0.8,
    },
    ...(isDropdown && {
      backgroundColor: "transparent",
    }),
  },
}));

const SearchForm = styled("form")({
  width: "100%",
  display: "flex",
  alignItems: "center",
});

const SearchBar = ({ onSearch, isSearching }) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [location, setLocation] = useState("");
  const [showJobRecent, setShowJobRecent] = useState(false);
  const [showLocationRecent, setShowLocationRecent] = useState(false);
  const [recentSearches, setRecentSearches] = useState({
    jobs: [],
    locations: [],
  });
  const [jobAnchorEl, setJobAnchorEl] = useState(null);
  const [locationAnchorEl, setLocationAnchorEl] = useState(null);
  const [error, setError] = useState("");
  const jobInputRef = useRef(null);
  const locationInputRef = useRef(null);

  const { userData, token, planDetails } = useStateStore();

  // job count

  const [jobCount, setJobCount] = useState("");
  const [showJobCountOptions, setShowJobCountOptions] = useState(false);
  const [jobCountAnchorEl, setJobCountAnchorEl] = useState(null);
  const jobCountInputRef = useRef(null);

  // Predefined job count options
  const jobCountOptions = [
    { value: "50 jobs", label: "50 jobs", count: 50 },
    { value: "100 jobs", label: "100 jobs", count: 100 },
    { value: "150 jobs", label: "150 jobs", count: 150 },
    { value: "200 jobs", label: "200 jobs", count: 200 },
    { value: "300 jobs", label: "300 jobs", count: 300 },
  ];

  useEffect(() => {
    const fetchOnboardingData = async () => {
      // Only fetch onboarding data if user is on free plan

      if (!planDetails?.planStarted) {
        try {
          const { data } = await axios.get(
            `https://backend.lazyapply.com/lazyapplyV2/onboarding/users/${userData?.v2Id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // If onboarding data contains a job title, set it
          if (data?.user?.jobTitle) {
            setJobTitle(data.user.jobTitle);
          }
        } catch (error) {
          console.error("Error fetching onboarding data:", error);
        }
      }
    };

    if (userData?.v2Id && token) {
      fetchOnboardingData();
    }
  }, [userData?.v2Id, token, planDetails?.planStarted]);

  // Load recent searches
  useEffect(() => {
    const savedSearches = localStorage.getItem("search_bar_recent_searches");
    if (savedSearches) {
      setRecentSearches(JSON.parse(savedSearches));
    }
  }, []);

  const saveSearch = (type, value) => {
    if (!value.trim()) return;

    const newSearches = { ...recentSearches };
    const searchArray = newSearches[type];

    const index = searchArray.indexOf(value);
    if (index > -1) {
      searchArray.splice(index, 1);
    }
    searchArray.unshift(value);

    if (searchArray.length > 5) {
      searchArray.pop();
    }

    setRecentSearches(newSearches);
    localStorage.setItem(
      "search_bar_recent_searches",
      JSON.stringify(newSearches)
    );
  };

  const handleJobTitleChange = (e) => {
    setJobTitle(e.target.value);
    if (error) setError("");
  };

  const handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (isSearching) return;

    setError("");

    if (!jobTitle || !jobTitle.trim()) {
      setError("Please enter a job title");
      return;
    }

    if (jobTitle) saveSearch("jobs", jobTitle);

    // if (location) saveSearch("locations", location);

    document.activeElement?.blur();

    let numericJobCount = 100;
    const selectedOption = jobCountOptions.find(
      (option) => option.value === jobCount
    );
    if (selectedOption) {
      numericJobCount = selectedOption.count;
    }

    onSearch({
      jobTitle,
      jobCount: numericJobCount,
    });
  };

  const handleRecentSelect = (type, value) => {
    if (type === "jobs") {
      setJobTitle(value);
      setShowJobRecent(false);
    } else {
      setLocation(value);
      setShowLocationRecent(false);
    }
  };

  // Handler for job count changes
  const handleJobCountChange = (e) => {
    setJobCount(e.target.value);
  };

  const handleJobCountSelect = (option) => {
    setJobCount(option.value);
    setShowJobCountOptions(false);
  };

  return (
    <Box sx={{ pb: 2 }}>
      <SearchContainer
        focus={isFocused}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        <SearchForm onSubmit={handleSearch}>
          <SearchInput>
            <SearchIcon
              sx={{
                color: "#666666",
                fontSize: "24px",
                mr: 1.5,
                ml: 1,
              }}
            />

            <StyledInputBase
              inputRef={jobInputRef}
              placeholder="Search job title, keywords, or company"
              fullWidth
              value={jobTitle}
              onChange={handleJobTitleChange}
              onFocus={(e) => {
                setShowJobRecent(true);
                setJobAnchorEl(e.currentTarget);
                setShowLocationRecent(false);
              }}
            />
            {showJobRecent && recentSearches.jobs.length > 0 && (
              <Popper
                open={showJobRecent}
                anchorEl={jobAnchorEl}
                placement="bottom-start"
                style={{ width: "300px", zIndex: 1000 }}
              >
                <ClickAwayListener
                  onClickAway={() => {
                    setTimeout(() => {
                      if (
                        !jobInputRef.current?.contains(document.activeElement)
                      ) {
                        setShowJobRecent(false);
                      }
                    }, 100);
                  }}
                >
                  <RecentSearchPaper>
                    <List>
                      {recentSearches.jobs.map((search, index) => (
                        <ListItem
                          key={index}
                          button
                          onClick={() => handleRecentSelect("jobs", search)}
                        >
                          <ListItemIcon>
                            <HistoryIcon color="action" />
                          </ListItemIcon>
                          <ListItemText primary={search} />
                        </ListItem>
                      ))}
                    </List>
                  </RecentSearchPaper>
                </ClickAwayListener>
              </Popper>
            )}
          </SearchInput>

          <Divider
            orientation="vertical"
            flexItem
            sx={{
              mx: 2,
              height: "35px",
              my: "auto",
            }}
          />

          {/* Job Count Ui */}

          <SearchInput>
            <FormatListNumberedIcon
              sx={{
                color: "#666666",
                fontSize: "24px",
                mr: 1.5,
                ml: 1,
              }}
            />
            <StyledInputBase
              inputRef={jobCountInputRef}
              placeholder="Number of jobs"
              value={jobCount}
              readOnly
              isDropdown={true}
              onFocus={(e) => {
                setShowJobCountOptions(true);
                setJobCountAnchorEl(e.currentTarget);
                setShowJobRecent(false);
              }}
              onClick={(e) => {
                setShowJobCountOptions(true);
                setJobCountAnchorEl(e.currentTarget);
                setShowJobRecent(false);
              }}
              sx={{
                cursor: "pointer",
              }}
            />

            {showJobCountOptions && (
              <Popper
                open={showJobCountOptions}
                anchorEl={jobCountAnchorEl}
                placement="bottom-start"
                style={{ width: "200px", zIndex: 1000 }}
              >
                <ClickAwayListener
                  onClickAway={() => {
                    setTimeout(() => {
                      if (
                        !jobCountInputRef.current?.contains(
                          document.activeElement
                        )
                      ) {
                        setShowJobCountOptions(false);
                      }
                    }, 100);
                  }}
                >
                  <RecentSearchPaper>
                    <List>
                      {jobCountOptions.map((option) => (
                        <ListItem
                          key={option.value}
                          button
                          onClick={() => handleJobCountSelect(option)}
                          selected={option.value === jobCount}
                          sx={{
                            py: 1,
                            "&:hover": {
                              backgroundColor: "#f5f5f5",
                            },
                            "&.Mui-selected": {
                              backgroundColor: "#e0e7ff",
                              "&:hover": {
                                backgroundColor: "#d5dfff",
                              },
                            },
                          }}
                        >
                          <ListItemText primary={option.label} />
                        </ListItem>
                      ))}
                    </List>
                  </RecentSearchPaper>
                </ClickAwayListener>
              </Popper>
            )}
          </SearchInput>

          {/* <SearchInput>
            <LocationIcon
              sx={{
                color: "#666666",
                fontSize: "24px",
                mr: 1.5,
                ml: 1,
              }}
            />
            <StyledInputBase
              inputRef={locationInputRef}
              placeholder="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              onFocus={(e) => {
                setShowLocationRecent(true);
                setLocationAnchorEl(e.currentTarget);
                setShowJobRecent(false);
              }}
            />

            {showLocationRecent && recentSearches.locations.length > 0 && (
              <Popper
                open={showLocationRecent}
                anchorEl={locationAnchorEl}
                placement="bottom-start"
                style={{ width: "300px", zIndex: 1000 }}
              >
                <ClickAwayListener
                  onClickAway={() => {
                    setTimeout(() => {
                      if (
                        !locationInputRef.current?.contains(
                          document.activeElement
                        )
                      ) {
                        setShowLocationRecent(false);
                      }
                    }, 100);
                  }}
                >
                  <RecentSearchPaper>
                    <List>
                      {recentSearches.locations.map((search, index) => (
                        <ListItem
                          key={index}
                          button
                          onClick={() =>
                            handleRecentSelect("locations", search)
                          }
                        >
                          <ListItemIcon>
                            <HistoryIcon color="action" />
                          </ListItemIcon>
                          <ListItemText primary={search} />
                        </ListItem>
                      ))}
                    </List>
                  </RecentSearchPaper>
                </ClickAwayListener>
              </Popper>
            )}
          </SearchInput> */}

          <SearchButton type="submit" disabled={isSearching}>
            {isSearching ? (
              <>
                <Typography
                  sx={{ color: "#fff", fontSize: "17px", fontWeight: 600 }}
                >
                  Searching...
                </Typography>

                <CircularProgress
                  size={16}
                  sx={{
                    ml: 1,
                    color: "#fff",
                  }}
                />
              </>
            ) : (
              "Find Jobs"
            )}
          </SearchButton>
        </SearchForm>
      </SearchContainer>
      {error && (
        <Box
          sx={{
            color: "#d32f2f",
            fontSize: "1rem",
            mt: 1,
            ml: 24,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography color="error">{error}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default SearchBar;
