import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  Button,
  Skeleton,
  Avatar,
  TablePagination,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import WorkIcon from "@mui/icons-material/Work";
import axios from "axios";
import { useStateStore } from "../../../../store";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// Mock data for operators
const mockOperators = [
  {
    id: 1,
    name: "Jane Smith",
    email: "jane.smith@company.com",
    assignedUsers: 23,
    maxUsers: 100,
    analytics: {
      emailsSent: 437,
      jobsApplied: 156,
    },
  },
  {
    id: 2,
    name: "Mike Johnson",
    email: "mike.j@company.com",
    assignedUsers: 45,
    maxUsers: 100,
    analytics: {
      emailsSent: 819,
      jobsApplied: 281,
    },
  },
  {
    id: 3,
    name: "Emily Chen",
    email: "emily.c@company.com",
    assignedUsers: 0,
    maxUsers: 100,
    analytics: {
      emailsSent: 0,
      jobsApplied: 0,
    },
  },
  {
    id: 4,
    name: "Robert Taylor",
    email: "robert.t@company.com",
    assignedUsers: 5,
    maxUsers: 100,
    analytics: {
      emailsSent: 112,
      jobsApplied: 48,
    },
  },
  {
    id: 5,
    name: "Michael Brown",
    email: "michael.b@company.com",
    assignedUsers: 17,
    maxUsers: 100,
    analytics: {
      emailsSent: 324,
      jobsApplied: 94,
    },
  },
  {
    id: 6,
    name: "Sarah Wilson",
    email: "sarah.w@company.com",
    assignedUsers: 32,
    maxUsers: 100,
    analytics: {
      emailsSent: 527,
      jobsApplied: 203,
    },
  },
];

// Helper function to calculate user count color
const getUserCountColor = (count, max) => {
  const percentage = (count / max) * 100;
  if (percentage < 50) return "success";
  if (percentage < 80) return "warning";
  return "error";
};

// Helper function to calculate performance ratio
const getPerformanceRatio = (emailsSent, jobsApplied, userCount) => {
  if (userCount === 0) return 0;
  return ((emailsSent + jobsApplied) / userCount).toFixed(1);
};

const OperatorAnalyticsTable = ({
  timeFrame,
  selectedOperator,
  onSelectOperator,
  dateRange = null,
}) => {
  console.log("timeframe", timeFrame, dateRange);
  const { token } = useStateStore();
  const [loading, setLoading] = useState(true);
  const [operators, setOperators] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error", // can be "error", "warning", "info", "success"
  });

  useEffect(() => {
    setLoading(true);
    axios
      .post(
        "https://backend.lazyapply.com/lazyapplyV2/analytics/owner/operators",
        {
          timeframe: timeFrame,
          startDate: dateRange?.start,
          endDate: dateRange?.end,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setOperators(response.data.operators);
        } else {
          setOperators([]);
        }
      })
      .catch((error) => {
        openSnackbar("Error fetching operators. Please try again.");
        console.error("Error fetching users:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [timeFrame, dateRange, token]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle operator selection
  const handleSelectOperator = (operatorId) => {
    if (onSelectOperator) {
      onSelectOperator(operatorId);
    }
  };

  const displayedOperators = operators.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const openSnackbar = (message, severity = "error") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 600, color: "#2d2d2d" }}>
          Operator Performance
        </Typography>
        <Typography variant="body2" sx={{ color: "#666" }}>
          View analytics for all operators and their assigned users
        </Typography>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          border: "1px solid #E0E0E0",
          borderRadius: "12px",
        }}
      >
        <Table>
          <TableHead sx={{ backgroundColor: "#F8F8F8" }}>
            <TableRow>
              <TableCell sx={{ fontWeight: 600 }}>Operator</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <PersonIcon fontSize="small" sx={{ mr: 0.5 }} />
                  Assigned Users
                </Box>
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <EmailIcon fontSize="small" sx={{ mr: 0.5 }} />
                  Emails Sent
                </Box>
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <WorkIcon fontSize="small" sx={{ mr: 0.5 }} />
                  Jobs Applied
                </Box>
              </TableCell>
              {/* <TableCell sx={{ fontWeight: 600 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <InfoIcon fontSize="small" sx={{ mr: 0.5 }} />
                  Performance
                </Box>
              </TableCell> */}
              {/* <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              // Loading skeleton
              [...Array(5)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Skeleton
                        variant="circular"
                        width={32}
                        height={32}
                        sx={{ mr: 1 }}
                      />
                      <Box>
                        <Skeleton variant="text" width={120} />
                        <Skeleton variant="text" width={150} height={16} />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={80} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={80} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={80} />
                  </TableCell>
                  {/* <TableCell>
                    <Skeleton variant="text" width={80} />
                  </TableCell> */}
                  {/* <TableCell>
                    <Skeleton variant="rectangular" width={100} height={30} />
                  </TableCell> */}
                </TableRow>
              ))
            ) : displayedOperators.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center" sx={{ py: 3 }}>
                  <Typography variant="body1" sx={{ color: "#666" }}>
                    No operators found
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              displayedOperators.map((operator) => {
                const userCountColor = getUserCountColor(
                  operator.assignedUsers,
                  operator.maxUsers
                );
                const performanceRatio = getPerformanceRatio(
                  operator.analytics.emailsSent,
                  operator.analytics.jobsApplied,
                  operator.assignedUsers
                );

                return (
                  <TableRow key={operator.id} hover>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          sx={{
                            bgcolor: "#e6f7e6",
                            color: "#2d8a2d",
                            width: 32,
                            height: 32,
                            mr: 1,
                          }}
                        >
                          {operator.name.charAt(0)}
                        </Avatar>
                        <Box>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            {operator.name}
                          </Typography>
                          <Typography variant="caption" sx={{ color: "#666" }}>
                            {operator.email}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={`${operator.assignedUsers} / ${operator.maxUsers}`}
                        size="small"
                        color={userCountColor}
                        sx={{
                          fontWeight: 500,
                          bgcolor:
                            userCountColor === "success"
                              ? "#e6f7e6"
                              : userCountColor === "warning"
                              ? "#fff8e6"
                              : "#ffeded",
                          color:
                            userCountColor === "success"
                              ? "#2d8a2d"
                              : userCountColor === "warning"
                              ? "#e6ac00"
                              : "#d73a3a",
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" sx={{ pl: 2 }}>
                        {operator.analytics.emailsSent}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" sx={{ pl: 2 }}>
                        {operator.analytics.jobsApplied}
                      </Typography>
                    </TableCell>
                    {/* <TableCell>
                      <Chip
                        label={`${performanceRatio} / user`}
                        size="small"
                        sx={{
                          fontWeight: 500,
                          bgcolor: "#e0f2fe",
                          color: "#0369a1",
                        }}
                      />
                    </TableCell> */}
                    {/* <TableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleSelectOperator(operator.id)}
                        sx={{
                          textTransform: "none",
                          borderColor: "#2d2d2d",
                          color: "#2d2d2d",
                          "&:hover": {
                            borderColor: "#000",
                            bgcolor: "rgba(0,0,0,0.04)",
                          },
                        }}
                      >
                        View Details
                      </Button>
                    </TableCell> */}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={operators.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            borderTop: "1px solid #E0E0E0",
            ".MuiTablePagination-select": {
              borderRadius: "4px",
              backgroundColor: "#F8F8F8",
            },
            ".MuiTablePagination-displayedRows": {
              margin: 0,
            },
            ".MuiTablePagination-actions": {
              marginLeft: 2,
              marginRight: 0,
            },
            ".MuiTablePagination-selectLabel": {
              margin: 0,
            },
          }}
        />
      </TableContainer>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{
            width: "100%",
            fontWeight: 500,
            borderRadius: "8px",
            boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default OperatorAnalyticsTable;
