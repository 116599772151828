import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import EmailCard from "./components/EmailCard";
import AddNewEmailCard from "./components/AddNewEmailCard";

const GmailConfiguration = ({
  emails,
  onAddEmail,
  onSetDefault,
  onEditEmail,
  onConnect,
  onFailure,
  onDelete,
}) => {
  return (
    <Box sx={{ maxWidth: 1200, mx: "auto", mt: 4 }}>
      <Typography variant="h5" sx={{ mb: 4, fontWeight: 600 }}>
        Email Configuration
      </Typography>

      <Grid container spacing={3}>
        {emails.map((email) => (
          <Grid item xs={12} md={4} key={email.id}>
            <EmailCard
              email={email}
              onSetDefault={() => onSetDefault(email.id)}
              onConnect={() => onConnect(email.id)}
              onFailure={(message)=> onFailure(message, email.id)}
              onEdit={onEditEmail}
              onDelete={onDelete}
            />
          </Grid>
        ))}
        <Grid item xs={12} md={4}>
          <AddNewEmailCard onAdd={onAddEmail} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GmailConfiguration;
