import React from "react";
import { Box, Typography, styled, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import MicIcon from "@mui/icons-material/Mic";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";

const ChatContainer = styled(Box)({
  backgroundColor: "#fff",
  borderRadius: "12px",
  overflow: "hidden",
  boxShadow: "0 2px 12px rgba(0,0,0,0.08)",
  width: "100%",
  border: "1px solid #eee",
  minHeight: "660px",
  display: "flex",
  flexDirection: "column",
});

const TopBar = styled(Box)({
  backgroundColor: "#075e54",
  padding: "10px 16px",
  display: "flex",
  alignItems: "center",
  gap: "12px",
  height: "60px",
});

const ProfilePic = styled(Box)({
  width: "38px",
  height: "38px",
  borderRadius: "50%",
  backgroundColor: "#128c7e",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontSize: "16px",
  fontWeight: 500,
});

const ChatBody = styled(Box)({
  backgroundColor: "#e5ddd5",
  backgroundImage: `url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACkSURBVEiJ7ZbBCcMwDEVfnUFyzzBZINts0GySBdJL7x0hs0SHyABeQbYVuTY99AcBkX4+UhSBGBHRCaiM6P2WuI2IAbgAh4XQHrhL2k6c/GxO0k1SY2ZTwzCzVtJJUguUwPk7dGapO0n3DIBGUvUzYAQ6YASqT8B4Nh1gppKfErshHZDM7KGAHvhExI2FnKQX8ABa4Aq8JfULOQHEiBgwXZgPWRpgHA+B4FAAAAAASUVORK5CYII=")`,
  padding: "20px",
  flexGrow: 1,
});

const MessageBubble = styled(Box)({
  backgroundColor: "#2d2d2d",
  borderRadius: "12px 12px 0 12px",
  padding: "8px 12px 10px",
  maxWidth: "85%",
  marginLeft: "auto",
  position: "relative",
  marginBottom: "8px",
  "&::after": {
    content: '""',
    position: "absolute",
    right: "-8px",
    bottom: 0,
    width: "16px",
    height: "16px",
    backgroundColor: "#2d2d2d",
    clipPath: "polygon(0 0, 0 100%, 100% 100%)",
  },
});

const TimeStamp = styled(Typography)({
  fontSize: "11px",
  color: "#8eb9f1",
  display: "inline-flex",
  alignItems: "center",
  gap: "4px",
});

const InputArea = styled(Box)({
  backgroundColor: "#202c33",
  padding: "8px 16px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

const InputBox = styled(Box)({
  backgroundColor: "#2a3942",
  borderRadius: "8px",
  padding: "9px 12px",
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
  gap: "8px",
  color: "#8696a0",
  fontSize: "15px",
});

const WhatsAppChat = ({ chat }) => {
  return (
    <ChatContainer>
      <TopBar>
        <ArrowBackIcon
          sx={{ color: "white", fontSize: 22, cursor: "pointer" }}
        />
        <ProfilePic>{chat.initials}</ProfilePic>
        <Box sx={{ flexGrow: 1 }}>
          <Typography color="white" fontWeight={500} fontSize="16px">
            {chat.name}
          </Typography>
          <Typography color="rgba(255,255,255,0.7)" fontSize="13px">
            online
          </Typography>
        </Box>
        <IconButton size="small">
          <SearchIcon sx={{ color: "white", fontSize: 22 }} />
        </IconButton>
        <IconButton size="small">
          <MoreVertIcon sx={{ color: "white", fontSize: 22 }} />
        </IconButton>
      </TopBar>

      <ChatBody>
        {/* Date Divider */}
        <Box sx={{ textAlign: "center", mb: 3 }}>
          <Typography
            sx={{
              display: "inline-block",
              bgcolor: "rgba(225, 245, 254, 0.92)",
              px: 2,
              py: 0.5,
              borderRadius: "8px",
              color: "#667781",
              fontSize: "12px",
              fontWeight: 500,
            }}
          >
            TODAY
          </Typography>
        </Box>

        {/* Messages */}
        {chat.messages.map((message, index) => (
          <Box key={index} sx={{ mb: 2.5 }}>
            <MessageBubble>
              <Typography
                sx={{
                  color: "white",
                  fontSize: "14px",
                  lineHeight: 1.45,
                  mb: 0.5,
                  wordBreak: "break-word",
                }}
              >
                {message.text}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  mt: "2px",
                }}
              >
                <TimeStamp>
                  {message.time}
                  <DoneAllIcon
                    sx={{
                      fontSize: 16,
                      color: "#34B7F1",
                      ml: 0.5,
                    }}
                  />
                </TimeStamp>
              </Box>
            </MessageBubble>
          </Box>
        ))}
      </ChatBody>

      {/* Input Area */}
      <InputArea>
        <IconButton size="small">
          <InsertEmoticonIcon sx={{ color: "#8696a0", fontSize: 24 }} />
        </IconButton>
        <IconButton
          size="small"
          sx={{
            transform: "rotate(45deg)",
            mr: -0.5,
          }}
        >
          <AttachFileIcon sx={{ color: "#8696a0", fontSize: 24 }} />
        </IconButton>
        <InputBox>Type a message</InputBox>
        <IconButton size="small">
          <MicIcon sx={{ color: "#8696a0", fontSize: 24 }} />
        </IconButton>
      </InputArea>
    </ChatContainer>
  );
};

export default WhatsAppChat;
