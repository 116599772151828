import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Checkbox,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { useStateStore } from "../../../../store";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// Styled components
const CardWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  borderRadius: "16px",
  padding: "28px",
  height: "280px",
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  maxWidth: "100%",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 6px 16px rgba(0,0,0,0.12)",
  },
}));

const AddCardWrapper = styled(CardWrapper)(() => ({
  alignItems: "center",
  justifyContent: "center",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "6px",
    fontSize: "1rem",
    backgroundColor: "#fff",
    "& fieldset": {
      borderColor: "#e0e0e0",
      borderWidth: "1px",
      transition: "all 0.2s ease",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2d2d2d",
      borderWidth: "1px",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "16px",
    "&::placeholder": {
      color: "#9ca3af",
      opacity: 1,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#6b7280",
    "&.Mui-focused": {
      color: "#2d2d2d",
    },
  },
  "& .MuiSelect-select": {
    padding: "16px",
  },
}));

const IconWrapper = styled(Box)(() => ({
  width: "70px",
  height: "70px",
  borderRadius: "50%",
  backgroundColor: "#F0F2F5",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "24px",
  "& .MuiSvgIcon-root": {
    fontSize: "32px",
    color: "#666666",
  },
}));

const OperatorCardContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

const OperatorCardHeader = styled(Box)(() => ({
  marginBottom: "auto",
}));

const OperatorCardFooter = styled(Box)(() => ({
  display: "flex",
  gap: "10px",
  marginTop: "16px",
}));

const UserCountChip = styled(({ color, ...other }) => <Chip {...other} />)(
  ({ theme, color }) => ({
    borderRadius: "25px",
    padding: "2px",
    fontSize: "16px",
    height: "32px",
    backgroundColor:
      color === "green"
        ? "#e6f7e6"
        : color === "yellow"
          ? "#fff8e6"
          : "#ffeded",
    color:
      color === "green"
        ? "#2d8a2d"
        : color === "yellow"
          ? "#e6ac00"
          : "#d73a3a",
    fontWeight: "bold",
    marginLeft: "8px",
  })
);

const OwnerChip = styled(Chip)(() => ({
  borderRadius: "16px",
  height: "36px",
  backgroundColor: "#8884d8",
  color: "#ffffff",
  fontWeight: "bold",
  fontSize: "16px",
  padding: "2px 8px",
  marginLeft: "8px",
}));

const Divider = styled(Box)(() => ({
  height: "1px",
  width: "100%",
  backgroundColor: "#eaeaea",
  margin: "16px 0",
}));

const StyledButton = styled(Button)(({ variant }) => ({
  borderRadius: "12px",
  padding: "8px 32px",
  fontSize: "15px",
  textTransform: "none",
  fontWeight: 550,
  boxShadow: "none",
  backgroundColor:
    variant === "delete"
      ? "#ffeded"
      : variant === "primary"
        ? "#2d2d2d"
        : "#f8f9fa",
  color:
    variant === "delete"
      ? "#d73a3a"
      : variant === "primary"
        ? "#ffffff"
        : "#2d2d2d",
  "&:hover": {
    backgroundColor:
      variant === "delete"
        ? "#ffe6e6"
        : variant === "primary"
          ? "#3a3a3a"
          : "#f0f0f0",
    boxShadow: "none",
  },
}));

const OperatorsList = () => {
  const history = useHistory();
  const { token, userData, planDetails, operatorListPage, setOperatorListPage } = useStateStore();
  const ownerV2Id = userData?.v2Id;
  const [loading, setLoading] = useState(true);
  const [operators, setOperators] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentOperator, setCurrentOperator] = useState(null);
  const [actAsOperator, setActAsOperator] = useState(false);
  const [newOperator, setNewOperator] = useState({
    name: "",
    email: "",
    permissions: {
      canApplyJobs: true,
      canViewAnalytics: false,
    },
  });

  const [pagination, setPagination] = useState({
    currentPage: operatorListPage || 1,
    totalPages: 1,
    itemsPerPage: 6,
  });

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.totalPages) {
      const nextPage = pagination.currentPage + 1;
      setOperatorListPage(nextPage);
      fetchOperators(nextPage);
    }
  };

  const handlePrevPage = () => {
    if (pagination.currentPage > 1) {
      const prevPage = pagination.currentPage - 1;
      setOperatorListPage(prevPage);
      fetchOperators(prevPage);
    }
  };

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error", // can be "error", "warning", "info", "success"
  });

  // Configuration
  const maxUsers = planDetails?.licenses?.total || 100;

  // Total users across all operators
  const totalUsers = operators.reduce((sum, op) => sum + op.assignedUsers, 0);

  const openSnackbar = (message, severity = "error") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    setActAsOperator(false);
    setNewOperator({
      name: "",
      email: "",
      permissions: {
        canApplyJobs: true,
        canViewAnalytics: false,
      },
    });
  };

  const handleOpenDeleteModal = (operator, e) => {
    e.stopPropagation(); // Prevent clicking through to the card
    setCurrentOperator(operator);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setCurrentOperator(null);
  };

  const handleViewUsers = (operatorId) => {
    history.push(`/dashboard/user-management/operator/${operatorId}`);
  };

  const handleDeleteOperator = async () => {
    if (!currentOperator) return;

    try {
      await axios.delete(
        `https://backend.lazyapply.com/lazyapplyV2/operator/${currentOperator.operatorId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      let pageToFetch = pagination.currentPage;
      if (operators.length === 1 && pagination.currentPage > 1) {
        pageToFetch = pagination.currentPage - 1;
      }

      fetchOperators(pageToFetch);
      handleCloseDeleteModal();
      openSnackbar("Operator deleted successfully", "success");
    } catch (error) {
      console.error("Error deleting operator:", error);
      openSnackbar("Failed to delete operator. Please try again.");
    }
  };

  const [error, setError] = useState(null);

  const handleAddOperator = async () => {
    if (!newOperator.name || !newOperator.email) {
      console.error("Name and email are required");
      openSnackbar("Name and email are required", "error");
      return;
    }

    // Add operator to state
    try {
      const response = await axios.post(
        `https://backend.lazyapply.com/lazyapplyV2/owner/create-operator`,
        {
          email: newOperator.email,
          name: newOperator.name,
          permissions: newOperator.permissions || [],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        fetchOperators(1);
        handleCloseAddModal();
        openSnackbar("Operator added successfully", "success");
      } else {
        openSnackbar(response.data.message || "Failed to create operator");
        setError(response.data.message || "Failed to create operator");
        handleCloseAddModal();
      }
    } catch (error) {
      console.error("Error creating operator:", error);
      openSnackbar("Failed to create operator. Please try again later.");
      if (error.response && error.response.data) {
        setError(error.response.data.message || "Failed to create operator");
      } else {
        setError("Failed to create operator. Please try again later.");
      }
      handleCloseAddModal();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewOperator({
      ...newOperator,
      [name]: value,
    });
  };

  const handlePermissionChange = (e) => {
    const { name, checked } = e.target;
    setNewOperator({
      ...newOperator,
      permissions: {
        ...newOperator.permissions,
        [name]: checked,
      },
    });
  };

  const handleActAsOperatorChange = (e) => {
    const checked = e.target.checked;
    setActAsOperator(checked);

    if (checked) {
      // Fill in the form with owner's information
      setNewOperator({
        ...newOperator,
        name: userData.name,
        email: userData.email,
      });
    } else {
      // Clear the form fields if unchecked
      setNewOperator({
        ...newOperator,
        name: "",
        email: "",
      });
    }
  };

  const fetchOperators = async (page = pagination.currentPage) => {
    if (!token) return;

    try {
      setLoading(true);
      const response = await axios.get(
        `https://backend.lazyapply.com/lazyapplyV2/owner/operators?page=${page}&limit=${pagination.itemsPerPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        const currentPage = response.data.pagination.currentPage;
        setOperatorListPage(currentPage);
        const fetchedOperators = response.data.data;
        setOperators(fetchedOperators);
        setPagination({
          currentPage: response.data.pagination.currentPage,
          totalPages: response.data.pagination.totalPages,
          itemsPerPage: response.data.pagination.itemsPerPage,
        });
      } else {
        openSnackbar(response.data.message || "Error fetching operators");
        setOperators([]);
        setPagination({
          ...pagination,
          totalPages: 1,
        });
      }
    } catch (error) {
      openSnackbar("Error fetching operators");
      console.error("Error fetching operators:", error);
      setOperators([]);
      setPagination({
        ...pagination,
        totalPages: 1,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOperators(operatorListPage);
  }, [token, operatorListPage]);

  // Determine user count color
  const getUserCountColor = (count) => {
    if (count === 0) return "red";
    if (count < 30) return "green";
    return "yellow";
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4, minHeight: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 5,
          }}
        >
          <Box>
            <Typography
              variant="h4"
              component="h1"
              sx={{ fontWeight: "bold", color: "#2d2d2d" }}
            >
              User Management
            </Typography>

            <Typography
              variant="body1"
              sx={{ color: "#666666", mt: 0.5, mb: 2, fontSize: "16px" }}
            >
              Manage operators and assign users to them for efficient job
              applications.
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <UserCountChip
              label={`${totalUsers} / ${maxUsers}`}
              color={getUserCountColor(totalUsers)}
              sx={{ fontSize: "20px", height: "36px" }}
            />
            <Typography variant="body1" sx={{ ml: 1 }}>
              Total users assigned
            </Typography>
          </Box>
        </Box>

        <Grid container spacing={4}>
          {pagination.currentPage === 1 && (
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <AddCardWrapper onClick={handleOpenAddModal}>
                <IconWrapper>
                  <AddIcon />
                </IconWrapper>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#2D2D2D",
                    fontWeight: 600,
                    fontSize: "22px",
                    textAlign: "center",
                  }}
                >
                  Add New Operator
                </Typography>
              </AddCardWrapper>
            </Grid>
          )}

          {operators
            .sort((a, b) => {
              // Sort operators with the owner at the top
              const aIsOwner = a.operatorId === ownerV2Id;
              const bIsOwner = b.operatorId === ownerV2Id;

              if (aIsOwner && !bIsOwner) return -1;
              if (!aIsOwner && bIsOwner) return 1;
              return 0;
            })
            .map((operator) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={operator.id}>
                <CardWrapper
                  onClick={() => handleViewUsers(operator.operatorId)}
                >
                  <OperatorCardContent>
                    <OperatorCardHeader>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "bold",
                            color: "#2d2d2d",
                            fontSize: "20px",
                          }}
                        >
                          {operator.name}
                        </Typography>
                        {operator.operatorId === ownerV2Id && (
                          <OwnerChip label="You" icon={<PersonIcon />} />
                        )}
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{ color: "#666666", mb: 2.5, fontSize: "16px" }}
                      >
                        {operator.email}
                      </Typography>
                      <Box
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: "#2d2d2d", fontSize: "15px" }}
                        >
                          Assigned Users:
                        </Typography>
                        <UserCountChip
                          label={`${operator.assignedUsers} / ${maxUsers}`}
                          color={getUserCountColor(operator.assignedUsers)}
                        />
                      </Box>
                    </OperatorCardHeader>

                    <Divider />

                    <OperatorCardFooter>
                      <StyledButton
                        variant="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleViewUsers(operator.operatorId);
                        }}
                      >
                        View Users
                      </StyledButton>
                      <StyledButton
                        variant="delete"
                        onClick={(e) => handleOpenDeleteModal(operator, e)}
                      >
                        Delete
                      </StyledButton>
                    </OperatorCardFooter>
                  </OperatorCardContent>
                </CardWrapper>
              </Grid>
            ))}
        </Grid>

        {/* Pagination controls */}
        {pagination.totalPages > 1 && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Button
              variant="outlined"
              disabled={pagination.currentPage === 1 || loading}
              onClick={handlePrevPage}
              sx={{
                borderColor: "#eaeaea",
                color: "#2d2d2d",
                borderRadius: "12px",
                textTransform: "none",
                mx: 1,
                "&:hover": {
                  borderColor: "#d0d0d0",
                  backgroundColor: "#f8f9fa",
                },
              }}
            >
              ← Previous
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#eaeaea",
                color: "#2d2d2d",
                borderRadius: "12px",
                textTransform: "none",
                "&:hover": {
                  borderColor: "#d0d0d0",
                  backgroundColor: "#f8f9fa",
                },
              }}
            >
              Page {pagination.currentPage} of {pagination.totalPages}
            </Button>
            <Button
              variant="outlined"
              disabled={
                pagination.currentPage === pagination.totalPages || loading
              }
              onClick={handleNextPage}
              sx={{
                borderColor: "#eaeaea",
                color: "#2d2d2d",
                borderRadius: "12px",
                textTransform: "none",
                mx: 1,
                "&:hover": {
                  borderColor: "#d0d0d0",
                  backgroundColor: "#f8f9fa",
                },
              }}
            >
              Next →
            </Button>
          </Box>
        )}
      </Box>

      {/* Add Operator Modal */}
      <Dialog
        open={openAddModal}
        onClose={handleCloseAddModal}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            padding: "16px",
            boxShadow: "0 10px 25px rgba(0,0,0,0.2)",
            width: "500px",
            maxWidth: "90vw",
          },
        }}
      >
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "22px", color: "#2d2d2d", pb: 1 }}
        >
          Add New Operator
        </DialogTitle>
        <DialogContent sx={{ pb: 2 }}>
          <StyledTextField
            autoFocus
            margin="normal"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            value={newOperator.name}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />

          <StyledTextField
            margin="normal"
            id="email"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={newOperator.email}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />

          <Box sx={{ mt: 2, mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={actAsOperator}
                  onChange={handleActAsOperatorChange}
                  name="actAsOperator"
                />
              }
              label="I will act as the operator"
            />

            {/* {actAsOperator && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ ml: 4, mt: 0 }}
              >
                Your name and email will be used for this operator
              </Typography>
            )} */}
          </Box>

          <Typography
            variant="body1"
            sx={{
              mt: 3,
              mb: 1,
              color: "#2d2d2d",
              fontSize: "18px",
              fontWeight: 550,
            }}
          >
            Permissions
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={newOperator.permissions.canApplyJobs}
                onChange={handlePermissionChange}
                name="canApplyJobs"
              />
            }
            label="Can apply for jobs"
          />
          {!(planDetails?.planId === 'basic_enterprise_plan') &&
            <FormControlLabel
              control={
                <Checkbox
                  checked={newOperator.permissions.canViewAnalytics}
                  onChange={handlePermissionChange}
                  name="canViewAnalytics"
                />
              }
              label="Can view analytics"
            />}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={handleCloseAddModal}
            sx={{
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "16px",
              fontWeight: 550,
              textTransform: "none",
              color: "#2d2d2d",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddOperator}
            variant="contained"
            sx={{
              backgroundColor: "#2d2d2d",
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "14px",
              fontWeight: 550,
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#3a3a3a",
              },
            }}
          >
            Add Operator
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Modal */}
      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            boxShadow: "0 10px 25px rgba(0,0,0,0.2)",
          },
        }}
      >
        <DialogTitle sx={{ color: "#d73a3a", fontWeight: "bold" }}>
          Delete Operator
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 2 }}>
            {currentOperator?.name}?
          </Typography>
          <Typography variant="body2" color="text.secondary">
            This action will remove all their permissions and cannot be undone.
            It will delete all the users assigned to this operator.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={handleCloseDeleteModal}
            sx={{
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "14px",
              fontWeight: 550,
              textTransform: "none",
              color: "#2d2d2d",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteOperator}
            sx={{
              backgroundColor: "#d73a3a",
              padding: "8px 24px",
              fontSize: "14px",
              fontWeight: 550,
              color: "white",
              borderRadius: "12px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#c62828",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{
            width: "100%",
            fontWeight: 500,
            borderRadius: "8px",
            boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default OperatorsList;
