import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControlLabel,
  Checkbox,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import SchoolIcon from "@mui/icons-material/School";
import GroupIcon from "@mui/icons-material/Group";
import axios from "axios";
import { useStateStore } from "../../../../store";
import { CircularProgress } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// Styled components
const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "6px",
    fontSize: "1rem",
    backgroundColor: "#fff",
    "& fieldset": {
      borderColor: "#e0e0e0",
      borderWidth: "1px",
      transition: "all 0.2s ease",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2d2d2d",
      borderWidth: "1px",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "16px",
    "&::placeholder": {
      color: "#9ca3af",
      opacity: 1,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#6b7280",
    "&.Mui-focused": {
      color: "#2d2d2d",
    },
  },
  "& .MuiSelect-select": {
    padding: "16px",
  },
}));

const UserCountChip = styled(({ color, ...other }) => <Chip {...other} />)(
  ({ theme, color }) => ({
    borderRadius: "25px",
    padding: "2px",
    fontSize: "20px",
    height: "40px",
    fontWeight: 600,
    backgroundColor:
      color === "green"
        ? "#e6f7e6"
        : color === "yellow"
          ? "#fff8e6"
          : "#ffeded",
    color:
      color === "green"
        ? "#2d8a2d"
        : color === "yellow"
          ? "#e6ac00"
          : "#d73a3a",
    marginLeft: "8px",
  })
);

const PlanChip = styled(Chip)(({ theme }) => ({
  borderRadius: "12px",
  fontSize: "14px",
  fontWeight: 500,
  marginRight: "8px",
  backgroundColor: "#E0F2FE",
  color: "#0369A1",
}));

const StyledButton = styled(Button)(({ variant }) => ({
  borderRadius: "12px",
  padding: "8px 24px",
  fontSize: "16px",
  textTransform: "none",
  fontWeight: 550,
  boxShadow: "none",
  backgroundColor:
    variant === "delete"
      ? "#ffeded"
      : variant === "primary"
        ? "#2d2d2d"
        : "#f8f9fa",
  color:
    variant === "delete"
      ? "#d73a3a"
      : variant === "primary"
        ? "#ffffff"
        : "#2d2d2d",
  "&:hover": {
    backgroundColor:
      variant === "delete"
        ? "#ffe6e6"
        : variant === "primary"
          ? "#3a3a3a"
          : "#f0f0f0",
    boxShadow: "none",
  },
}));

const StatsBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  padding: "12px 16px",
  minWidth: "180px",
  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
}));

const ActionButton = styled(Button)(({ variant }) => ({
  borderRadius: "8px",
  padding: "6px 16px",
  fontSize: "14px",
  textTransform: "none",
  fontWeight: 550,
  marginRight: "8px",
  boxShadow: "none",
  backgroundColor:
    variant === "delete"
      ? "#ffeded"
      : variant === "primary"
        ? "#2d2d2d"
        : "#f8f9fa",
  color:
    variant === "delete"
      ? "#d73a3a"
      : variant === "primary"
        ? "#ffffff"
        : "#2d2d2d",
  "&:hover": {
    backgroundColor:
      variant === "delete"
        ? "#ffe6e6"
        : variant === "primary"
          ? "#3a3a3a"
          : "#f0f0f0",
    boxShadow: "none",
  },
}));

// Mock data for the educational institution
const mockInstitution = {
  id: 1,
  name: "Springfield University",
  email: "admin@springfield.edu",
  plan: "Educational Pro",
  assignedUsers: 245,
  maxUsers: 500,
  analytics: {
    emailsSent: 5837,
    jobsApplied: 1296,
  },
};

// Mock data for users
const mockUsers = [
  {
    id: 1,
    name: "Taylor Johnson",
    email: "taylor.j@springfield.edu",
    canViewAnalytics: true,
    analytics: {
      emailsSent: 32,
      jobsApplied: 8,
    },
  },
  {
    id: 2,
    name: "Morgan Smith",
    email: "morgan.s@springfield.edu",
    canViewAnalytics: true,
    analytics: {
      emailsSent: 45,
      jobsApplied: 12,
    },
  },
  {
    id: 3,
    name: "Jamie Williams",
    email: "jamie.w@springfield.edu",
    canViewAnalytics: true,
    analytics: {
      emailsSent: 18,
      jobsApplied: 5,
    },
  },
  {
    id: 4,
    name: "Casey Thompson",
    email: "casey.t@springfield.edu",
    canViewAnalytics: true,
    analytics: {
      emailsSent: 67,
      jobsApplied: 22,
    },
  },
  {
    id: 5,
    name: "Riley Garcia",
    email: "riley.g@springfield.edu",
    canViewAnalytics: true,
    analytics: {
      emailsSent: 29,
      jobsApplied: 10,
    },
  },
  {
    id: 6,
    name: "Jordan Miller",
    email: "jordan.m@springfield.edu",
    canViewAnalytics: true,
    analytics: {
      emailsSent: 53,
      jobsApplied: 15,
    },
  },
  {
    id: 7,
    name: "Avery Davis",
    email: "avery.d@springfield.edu",
    canViewAnalytics: true,
    analytics: {
      emailsSent: 41,
      jobsApplied: 14,
    },
  },
];

const EducationOwnerView = () => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    canViewAnalytics: false,
  });
  const [users, setUsers] = useState(mockUsers);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 5;
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editingUser, setEditingUser] = useState(null);

  const { token, planDetails, userData, enterpriseUser, setEnterpriseUser } =
    useStateStore();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Instead of mockInstitution and mockUsers
  const [operatorData, setOperatorData] = useState(null);
  const [pagination, setPagination] = useState({
    totalUsers: 0,
    totalPages: 1,
    currentPage: 1,
    limit: 10,
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error", // can be "error", "warning", "info", "success"
  });

  // Institution state
  const [institutionState, setInstitutionState] = useState(mockInstitution);

  // Validation function to check user limits
  const isUserLimitReached = () => {
    return institutionState.assignedUsers >= institutionState.maxUsers;
  };

  // Pagination logic
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(users.length / usersPerPage);

  const openSnackbar = (message, severity = "error") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const fetchOperatorUsers = async () => {
    try {
      setLoading(true);
      const operatorId = userData?.v2Id || "";

      const response = await axios.get(
        `https://backend.lazyapply.com/lazyapplyV2/operator/users/${operatorId}`,
        {
          params: {
            page: pagination.currentPage,
            limit: pagination.limit,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setOperatorData(response.data.data.operator);
        setUsers(response.data.data.users);
        setPagination(response.data.data.pagination);
      } else {
        openSnackbar(response.data.message || "Failed to fetch data");
        throw new Error(response.data.message || "Failed to fetch data");
      }
    } catch (err) {
      console.error("Error fetching operator data:", err);
      openSnackbar("Failed to fetch data");

      setError(err.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  };

  // Use the function in useEffect
  useEffect(() => {
    if (token && userData.v2Id) {
      fetchOperatorUsers();
    }
  }, [token, pagination.currentPage, pagination.limit, userData]);

  const handleAddUser = async () => {
    // Validate form
    if (!newUser.name || !newUser.email) {
      alert("Name and email are required");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        "https://backend.lazyapply.com/lazyapplyV2/owner/assign-user",
        {
          userName: newUser.name,
          userEmail: newUser.email,
          subscriptionId: planDetails.subscriptionId,
          permissions: newUser.canViewAnalytics ? ['apply_jobs', 'view_analytics'] : ['apply_jobs']
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        // Refresh the users list by calling fetchOperatorUsers
        if (response.data.user)
          setEnterpriseUser({
            users: [
              ...enterpriseUser.users,
              {
                v2Id: response.data.user.userV2Id,
                name: response.data.user.userName,
                email: response.data.user.email,
              },
            ],
          });
        await fetchOperatorUsers();
        handleCloseAddModal();
      } else {
        openSnackbar(response.data.message || "Failed to add user");
        // alert(response.data.message || "Failed to add user");
      }
    } catch (error) {
      console.error("Error adding user:", error);
      openSnackbar("Failed to add user. Please try again.");
      // alert(error.response?.data?.message || "Failed to add user. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    setNewUser({
      name: "",
      email: "",
    });
  };

  const handleOpenDeleteModal = (user) => {
    setCurrentUser(user);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setCurrentUser(null);
  };

  const handleDeleteUser = async () => {
    try {
      const response = await axios.post(
        `https://backend.lazyapply.com/lazyapplyV2/remove-user`,
        {
          operatorV2Id: userData.v2Id,
          userV2Id: currentUser.v2Id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        await fetchOperatorUsers();
        handleCloseDeleteModal();
      } else {
        openSnackbar(response.data.message || "Failed to delete user");
        setError(response.data.message || "Failed to delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      openSnackbar("An error occurred while deleting the user");
      setError(
        error.response?.data?.message ||
        "An error occurred while deleting the user"
      );
    } finally {
      console.log("User deletion completed");
    }

    // setUsers(users.filter((u) => u.id !== currentUser.id));
    // Decrement the assignedUsers count
    // setInstitutionState({
    //   ...institutionState,
    //   assignedUsers: institutionState.assignedUsers - 1,
    // });
    // handleCloseDeleteModal();
    // In a real application, you would make an API call here
  };

  const handleEditUser = () => {
    // Email validation for edu domain
    // if (!editingUser.email.includes("@")) {
    //   alert("Please enter a valid  email address");
    //   return;
    // }

    // Update the user in the users array
    setUsers(
      users.map((user) => (user.id === editingUser.id ? editingUser : user))
    );
    handleCloseEditModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({
      ...newUser,
      [name]: value,
    });
  };

  const handleOpenEditModal = (user) => {
    setEditingUser({ ...user });
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setEditingUser(null);
  };

  // Handle checkbox change in Add User modal
  const handlePermissionChange = (e) => {
    const { name, checked } = e.target;
    setNewUser({
      ...newUser,
      [name]: checked,
    });
  };

  // Determine user count color
  const getUserCountColor = (count) => {
    const percentage = (count / (planDetails?.licenses?.total || 100)) * 100;
    if (percentage < 50) return "green";
    if (percentage < 80) return "yellow";
    return "red";
  };

  if (loading && !operatorData) {
    return (
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ textAlign: "center", mt: 5 }}>
        <Typography variant="h5" color="error">
          Error: {error}
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          onClick={() => window.location.reload()}
        >
          Retry
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4, minHeight: "100vh" }}>
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h4"
            component="h1"
            sx={{ fontWeight: "bold", color: "#2d2d2d", mb: 1 }}
          >
            My Dashboard
          </Typography>

          {/* Institution info section */}
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            {/* <Box sx={{ display: "flex", alignItems: "center", mr: 4 }}>
              <SchoolIcon sx={{ color: "#666", mr: 1 }} />
              <Typography
                variant="body1"
                sx={{ color: "#666", fontWeight: 500 }}
              >
                {operatorData?.name}
              </Typography>
            </Box> */}

            <Box sx={{ display: "flex", alignItems: "center", mr: 4 }}>
              <EmailIcon sx={{ color: "#666", mr: 1 }} />
              <Typography variant="body1" sx={{ color: "#666" }}>
                {operatorData?.email}
              </Typography>
            </Box>

            {/* <Box sx={{ display: "flex", alignItems: "center" }}>
              <PlanChip label={institutionState.plan} size="small" />
            </Box> */}
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: "#f8f9fa",
            borderRadius: "12px",
            p: 2.5,
            mb: 7,
            mt: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <UserCountChip
              label={`${pagination.totalUsers}`}
              color={getUserCountColor(pagination.totalUsers)}
            />
            <Typography variant="body1" sx={{ ml: 1, fontSize: "16px" }}>
              / {planDetails?.licenses?.total || 100} users assigned
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            {/* <StatsBox sx={{ backgroundColor: "#ECFDF5" }}>
              <Typography
                variant="body2"
                sx={{ color: "#2d2d2d", fontSize: "16px" }}
              >
                Total Emails Sent:{" "}
                <strong>{institutionState.analytics.emailsSent}</strong>
              </Typography>
            </StatsBox>

            <StatsBox sx={{ backgroundColor: "#FEF3C7" }}>
              <Typography
                variant="body2"
                sx={{ color: "#2d2d2d", fontSize: "16px" }}
              >
                Total Jobs Applied:{" "}
                <strong>{institutionState.analytics.jobsApplied}</strong>
              </Typography>
            </StatsBox> */}

            <StyledButton
              variant="primary"
              startIcon={<AddIcon />}
              onClick={handleOpenAddModal}
              disabled={isUserLimitReached()}
              sx={{
                ...(isUserLimitReached() && {
                  opacity: 0.7,
                  cursor: "not-allowed",
                  "&:hover": {
                    backgroundColor: "#2d2d2d",
                  },
                }),
              }}
            >
              {isUserLimitReached() ? "User Limit Reached" : "Add User"}
            </StyledButton>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            sx={{ fontWeight: "bold", color: "#2d2d2d" }}
          >
            Users
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <GroupIcon sx={{ color: "#666", mr: 1 }} />
            <Typography variant="body2" sx={{ color: "#666" }}>
              Each user can log in and apply for jobs independently
            </Typography>
          </Box>
        </Box>

        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            borderRadius: "12px",
            overflow: "hidden",
          }}
        >
          <Table>
            <TableHead
              sx={{
                backgroundColor: "#f8f9fa",
                borderBottom: "1px solid #eaeaea",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#2d2d2d",
                    fontSize: "15px",
                    padding: "16px 20px",
                    width: "30%",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#2d2d2d",
                    fontSize: "15px",
                    padding: "16px 20px",
                    width: "40%",
                  }}
                >
                  Email
                </TableCell>
                {/* <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#2d2d2d",
                    fontSize: "15px",
                    padding: "16px",
                  }}
                >
                  Emails Sent
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#2d2d2d",
                    fontSize: "15px",
                    padding: "16px",
                  }}
                >
                  Jobs Applied
                </TableCell> */}
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#2d2d2d",
                    fontSize: "15px",
                    padding: "16px 20px",
                    width: "30%",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={3} align="center" sx={{ py: 3 }}>
                    <CircularProgress size={30} />
                  </TableCell>
                </TableRow>
              ) : users.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={3}
                    sx={{ textAlign: "center", padding: "24px 16px" }}
                  >
                    <Typography variant="body1" color="text.secondary">
                      No users added yet. Add users using the "Add User" button
                      above.
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                users.map((user) => (
                  <TableRow key={user.v2Id}>
                    <TableCell
                      sx={{
                        color: "#2d2d2d",
                        fontSize: "15px",
                        padding: "16px 20px",
                      }}
                    >
                      {user.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#2d2d2d",
                        fontSize: "15px",
                        padding: "16px 20px",
                      }}
                    >
                      {user.email}
                    </TableCell>
                    <TableCell sx={{ padding: "16px 20px" }}>
                      <Box sx={{ display: "flex" }}>
                        <ActionButton
                          variant="delete"
                          onClick={() => handleOpenDeleteModal(user)}
                        >
                          <DeleteIcon sx={{ fontSize: "18px", mr: 0.5 }} />
                          Delete
                        </ActionButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {pagination.totalPages > 1 && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Button
              variant="outlined"
              disabled={pagination.currentPage === 1 || loading}
              onClick={handlePrevPage}
              sx={{
                borderColor: "#eaeaea",
                color: "#2d2d2d",
                borderRadius: "12px",
                textTransform: "none",
                mx: 1,
                "&:hover": {
                  borderColor: "#d0d0d0",
                  backgroundColor: "#f8f9fa",
                },
              }}
            >
              ← Previous
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#eaeaea",
                color: "#2d2d2d",
                borderRadius: "12px",
                textTransform: "none",
                "&:hover": {
                  borderColor: "#d0d0d0",
                  backgroundColor: "#f8f9fa",
                },
              }}
            >
              Page {pagination.currentPage} of {pagination.totalPages}
            </Button>
            <Button
              variant="outlined"
              disabled={
                pagination.currentPage === pagination.totalPages || loading
              }
              onClick={handleNextPage}
              sx={{
                borderColor: "#eaeaea",
                color: "#2d2d2d",
                borderRadius: "12px",
                textTransform: "none",
                mx: 1,
                "&:hover": {
                  borderColor: "#d0d0d0",
                  backgroundColor: "#f8f9fa",
                },
              }}
            >
              Next →
            </Button>
          </Box>
        )}
      </Box>

      {/* Add User Modal */}
      <Dialog
        open={openAddModal}
        onClose={handleCloseAddModal}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            padding: "16px",
            boxShadow: "0 10px 25px rgba(0,0,0,0.2)",
            width: "500px",
            maxWidth: "90vw",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            fontSize: "22px",
            color: "#2d2d2d",
            pb: 1,
          }}
        >
          Add New User
        </DialogTitle>
        <DialogContent sx={{ pb: 2 }}>
          <StyledTextField
            autoFocus
            margin="normal"
            id="name"
            name="name"
            label="User Full Name"
            type="text"
            fullWidth
            value={newUser.name}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
          <StyledTextField
            margin="normal"
            id="email"
            name="email"
            label="User Email"
            type="email"
            fullWidth
            value={newUser.email}
            onChange={handleInputChange}
            helperText="Must be a valid email address"
          />

          {!(planDetails?.planId === 'basic_enterprise_plan') &&
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newUser.canViewAnalytics}
                    onChange={handlePermissionChange}
                    name="canViewAnalytics"
                  />
                }
                label="Enable analytics tracking for this user"
              />
            </Box>
          }
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={handleCloseAddModal}
            sx={{
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "16px",
              fontWeight: 550,
              textTransform: "none",
              color: "#2d2d2d",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddUser}
            variant="contained"
            disabled={loading}
            sx={{
              backgroundColor: "#2d2d2d",
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "14px",
              fontWeight: 550,
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#3a3a3a",
              },
            }}
          >
            Add User
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete User Confirmation Modal */}
      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            boxShadow: "0 10px 25px rgba(0,0,0,0.2)",
            padding: "8px",
            minWidth: "480px",
          },
        }}
      >
        <DialogTitle
          sx={{ color: "#d73a3a", fontWeight: "bold", fontSize: "24px" }}
        >
          Delete User
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 2 }}>
            {currentUser?.name}?
          </Typography>
          <Typography variant="body2" color="text.secondary">
            This action cannot be undone. The user will lose access to
            LazyApply.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={handleCloseDeleteModal}
            sx={{
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "14px",
              fontWeight: 550,
              textTransform: "none",
              color: "#2d2d2d",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteUser}
            sx={{
              backgroundColor: "#d73a3a",
              padding: "8px 24px",
              fontSize: "14px",
              fontWeight: 550,
              color: "white",
              borderRadius: "12px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#c62828",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit User Modal */}
      <Dialog
        open={openEditModal}
        onClose={handleCloseEditModal}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            padding: "16px",
            boxShadow: "0 10px 25px rgba(0,0,0,0.2)",
            width: "500px",
            maxWidth: "90vw",
          },
        }}
      >
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "22px", color: "#2d2d2d", pb: 1 }}
        >
          Edit User
        </DialogTitle>
        <DialogContent sx={{ pb: 2 }}>
          <StyledTextField
            autoFocus
            margin="normal"
            id="edit-name"
            name="name"
            label="User Full Name"
            type="text"
            fullWidth
            value={editingUser?.name || ""}
            onChange={(e) =>
              setEditingUser({ ...editingUser, name: e.target.value })
            }
            sx={{ mb: 2 }}
          />
          <StyledTextField
            margin="normal"
            id="edit-email"
            name="email"
            label="User Email"
            type="email"
            fullWidth
            value={editingUser?.email || ""}
            onChange={(e) =>
              setEditingUser({ ...editingUser, email: e.target.value })
            }
            helperText="Must be a valid email address"
          />

          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={editingUser?.canViewAnalytics || false}
                  onChange={(e) =>
                    setEditingUser({
                      ...editingUser,
                      canViewAnalytics: e.target.checked,
                    })
                  }
                  name="canViewAnalytics"
                />
              }
              label="Enable analytics tracking for this user"
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={handleCloseEditModal}
            sx={{
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "16px",
              fontWeight: 550,
              textTransform: "none",
              color: "#2d2d2d",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleEditUser}
            variant="contained"
            sx={{
              backgroundColor: "#2d2d2d",
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "14px",
              fontWeight: 550,
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#3a3a3a",
              },
            }}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{
            width: "100%",
            fontWeight: 500,
            borderRadius: "8px",
            boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EducationOwnerView;
