// ResumeUploadModal.jsx
import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Button,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const UploadBox = styled(Box)(({ theme, isDragActive }) => ({
  border: `2px dashed ${isDragActive ? "#2D2D2D" : "#E0E0E0"}`,
  borderRadius: "12px",
  padding: "32px",
  textAlign: "center",
  backgroundColor: isDragActive ? "rgba(45, 45, 45, 0.04)" : "#FAFAFA",
  cursor: "pointer",
  transition: "all 0.2s ease",
  "&:hover": {
    backgroundColor: "rgba(45, 45, 45, 0.04)",
    borderColor: "#2D2D2D",
  },
}));

const StatusMessage = styled(Box)(({ type }) => ({
  padding: "12px 16px",
  borderRadius: "8px",
  marginTop: "16px",
  backgroundColor:
    type === "success" ? "rgba(75, 206, 136, 0.1)" : "rgba(229, 53, 53, 0.1)",
  color: type === "success" ? "#35a347" : "#E53535",
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  transition: "all 0.3s ease",
}));

const ResumeUploadModal = ({ open, onClose, onUpload }) => {
  const [file, setFile] = useState(null);
  const [isDragActive, setIsDragActive] = useState(false);
  const fileInputRef = useRef(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const resetModalState = () => {
    setFile(null);
    setUploadStatus(null);
    setStatusMessage("");
    setIsDragActive(false);
    setIsUploading(false);
  };

  const handleClose = () => {
    resetModalState();
    onClose();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);

    const droppedFile = e.dataTransfer.files[0];
    if (validateFile(droppedFile)) {
      setFile(droppedFile);
    }
  };

  const validateFile = (file) => {
    const validTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    if (!validTypes.includes(file?.type)) {
      alert("Please upload a PDF or Word document");
      return false;
    }
    return true;
  };

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    if (validateFile(selectedFile)) {
      setFile(selectedFile);
    }
  };

  const handleBoxClick = () => {
    fileInputRef.current?.click();
  };

  const handleUpload = () => {
    if (file) {
      setUploadStatus(null);
      setIsUploading(true);
      onUpload(file)
        .then(() => {
          setUploadStatus("success");
          setStatusMessage(
            "Resume uploaded successfully! The page will refresh shortly."
          );
          setTimeout(() => {
            handleClose();
          }, 1000);
        })
        .catch((error) => {
          setUploadStatus("error");
          setStatusMessage(
            error.message || "Failed to upload resume. Please try again."
          );
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
  };

  // const handleUpload = () => {
  //   if (file) {
  //     onUpload(file);
  //     onClose();
  //   }
  // };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "16px",
          p: 2,
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight={600}>
            Replace Resume
          </Typography>
          {/* <IconButton
            onClick={onClose}
            size="small"
            sx={{
              bgcolor: "#E53535",
              color: "#000000",
              padding: "4px",
              "&:hover": {
                bgcolor: "#D32F2F",
              },
            }}
          >
            <CloseIcon sx={{ fontSize: "18px" }} />
          </IconButton> */}
        </Box>
      </DialogTitle>

      <DialogContent>
        <UploadBox
          isDragActive={isDragActive}
          onClick={handleBoxClick}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            accept=".pdf,.doc,.docx"
            style={{ display: "none" }}
          />

          <CloudUploadIcon sx={{ fontSize: 48, color: "#666666", mb: 2 }} />

          {file ? (
            <Box>
              <Typography
                variant="body1"
                color="primary"
                fontWeight={500}
                mb={1}
              >
                {file.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Click or drag to replace
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="body1" fontWeight={500} mb={1}>
                Drag & drop your resume here
              </Typography>
              <Typography variant="body2" color="text.secondary">
                or click to select file
              </Typography>
            </Box>
          )}

          <Typography
            variant="caption"
            color="text.secondary"
            display="block"
            mt={2}
          >
            Supported formats: PDF, DOC, DOCX
          </Typography>
        </UploadBox>

        {uploadStatus && (
          <StatusMessage type={uploadStatus}>
            {uploadStatus === "success" ? (
              <CheckCircleOutlineIcon fontSize="small" />
            ) : (
              <ErrorOutlineIcon fontSize="small" />
            )}
            {statusMessage}
          </StatusMessage>
        )}

        <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
          <Button
            onClick={onClose}
            sx={{
              color: "#666",
              textTransform: "none",
              padding: "8px 16px",
              fontSize: "16px",
              borderRadius: "8px",
              fontWeight: 550,
              "&:hover": { backgroundColor: "#f5f5f5" },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disableRipple
            disabled={!file || isUploading}
            onClick={handleUpload}
            sx={{
              bgcolor: "#2D2D2D",
              textTransform: "none",
              padding: "8px 16px",
              fontSize: "16px",
              borderRadius: "8px",
              fontWeight: 550,
              "&:hover": { bgcolor: "#404040" },
            }}
          >
            {isUploading ? "Uploading..." : "Upload Resume"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ResumeUploadModal;
