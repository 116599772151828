// components/sections/Certifications/ViewMode.jsx
import React from "react";
import { Box, Typography, Stack, Link } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const ViewMode = ({ certifications }) => {
  if (certifications.length === 0) {
    return (
      <Typography
        sx={{
          color: "#666666",
          fontStyle: "italic",
          textAlign: "center",
          py: 4,
        }}
      >
        No certifications added yet.
      </Typography>
    );
  }

  return (
    <Stack spacing={3}>
      {certifications.map((certification, index) => (
        <Box
          key={index}
          sx={{
            p: 2.5,
            backgroundColor: "#F8F9FA",
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#2D2D2D",
              fontSize: "17px",
              fontWeight: 600,
              mb: 1,
            }}
          >
            {certification.name}
          </Typography>

          <Typography
            sx={{
              color: "#2D2D2D",
              fontSize: "15px",
              fontWeight: 500,
              mb: 0.5,
            }}
          >
            {certification.issuer}
          </Typography>

          <Typography
            sx={{
              color: "#666666",
              fontSize: "14px",
              mb: 1,
            }}
          >
            {certification.issueDate && `Issued: ${certification.issueDate}`}
            {certification.expiryDate &&
              ` • Expires: ${certification.expiryDate}`}
          </Typography>

          {certification.credentialId && (
            <Typography
              sx={{
                color: "#666666",
                fontSize: "14px",
                mb: 0.5,
              }}
            >
              Credential ID: {certification.credentialId}
            </Typography>
          )}

          {certification.credentialUrl && (
            <Link
              href={certification.credentialUrl}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "inline-flex",
                alignItems: "center",
                color: "#2D2D2D",
                fontSize: "14px",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              View Credential
              <OpenInNewIcon sx={{ ml: 0.5, fontSize: 16 }} />
            </Link>
          )}
        </Box>
      ))}
    </Stack>
  );
};

export default ViewMode;
