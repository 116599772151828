export const simplyHiredFilters = {
  Distance: {
    value: "sr",
    options: [
      {
        label: "Exact location",
        value: "0",
      },
      {
        label: "5 miles",
        value: "5",
      },
      {
        label: "10 miles",
        value: "10",
      },
      {
        label: "15 miles",
        value: "15",
      },
      {
        label: "25 miles",
        value: "",
      },
      {
        label: "50 miles",
        value: "50",
      },
      {
        label: "100 miles",
        value: "100",
      },
    ],
  },
  "Job Type": {
    value: "jt",
    options: [
      {
        label: "All Job Types",
        value: "",
      },
      {
        label: "Full-time",
        value: "CF3CP",
      },
      {
        label: "Contract",
        value: "NJXCK",
      },
      {
        label: "Part-time",
        value: "75GKK",
      },
      {
        label: "Temporary",
        value: "4HKF7",
      },
      {
        label: "Internship",
        value: "VDTG7",
      },
    ],
  },
  "Date Added": {
    value: "t",
    options: [
      {
        label: "Anytime",
        value: "",
      },
      {
        label: "24 hours",
        value: "1",
      },
      {
        label: "7 days",
        value: "7",
      },
      {
        label: "14 days",
        value: "14",
      },
      {
        label: "30 days",
        value: "30",
      },
    ],
  },
  "Relevance/Date": {
    value: "s",
    options: [
      {
        label: "Relevance",
        value: "",
      },
      {
        label: "Date",
        value: "d",
      },
    ],
  },
};
