// components/sections/Education/EditMode.jsx
import React from "react";
import {
  Grid,
  Box,
  TextField,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    "&:hover fieldset": {
      borderColor: "#2D2D2D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2D2D2D",
      borderWidth: "1px",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E0E0E0",
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: "#2D2D2D",
    },
  },
};

const FormTextField = React.memo(
  ({ label, value, onChange, onBlur, error, helperText, ...props }) => (
    <TextField
      fullWidth
      label={label}
      value={value || ""}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      variant="outlined"
      sx={textFieldStyles}
      {...props}
    />
  )
);

const EducationForm = ({
  education,
  index,
  errors = {},
  touched = {},
  onChange,
  onBlur,
  onDelete,
}) => {
  const handleChange =
    (field, subField = null) =>
    (event) => {
      const value = event.target.value;
      let updatedEducation = { ...education };

      if (subField) {
        if (field === "institution") {
          updatedEducation.institution = {
            ...updatedEducation.institution,
            [subField]: value,
          };
        } else if (field === "degree") {
          updatedEducation.degree = {
            ...updatedEducation.degree,
            [subField]: value,
          };
        } else if (field === "location") {
          updatedEducation.institution.location = {
            ...updatedEducation.institution.location,
            [subField]: value,
          };
        }
      } else {
        updatedEducation[field] = value;
      }

      onChange(index, updatedEducation);
    };

  const handleBlur = (field) => () => {
    onBlur(index, field);
  };

  return (
    <Box
      sx={{
        mb: 4,
        pb: 4,
        borderBottom: "1px solid #E0E0E0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          mb: 2,
        }}
      >
        <Typography variant="h6" sx={{ color: "#2D2D2D" }}>
          Education {index + 1}
        </Typography>
        <IconButton
          onClick={() => onDelete(index)}
          sx={{
            color: "#666666",
            "&:hover": {
              color: "#FF7875",
            },
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>

      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <FormTextField
            label="Institution Name"
            value={education.institution?.name}
            onChange={handleChange("institution", "name")}
            onBlur={handleBlur("institution.name")}
            error={
              touched?.["institution.name"] && !!errors?.["institution.name"]
            }
            helperText={
              touched?.["institution.name"] && errors?.["institution.name"]
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="City"
            value={education.institution?.location?.city}
            onChange={handleChange("location", "city")}
            onBlur={handleBlur("institution.location.city")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="State"
            value={education.institution?.location?.state}
            onChange={handleChange("location", "state")}
            onBlur={handleBlur("institution.location.state")}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            label="Degree"
            value={education.degree?.degreeName}
            onChange={handleChange("degree", "degreeName")}
            onBlur={handleBlur("degree.degreeName")}
            error={
              touched?.["degree.degreeName"] && !!errors?.["degree.degreeName"]
            }
            helperText={
              touched?.["degree.degreeName"] && errors?.["degree.degreeName"]
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="Start Date"
            type="month"
            value={education.startDate}
            onChange={handleChange("startDate")}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="End Date"
            type="month"
            value={education.endDate}
            onChange={handleChange("endDate")}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="GPA"
            value={education.gpa}
            onChange={handleChange("gpa")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const EditMode = ({
  education,
  errors,
  touched,
  onChange,
  onBlur,
  onAdd,
  onDelete,
}) => {
  const handleFieldChange = (index, updatedEducation) => {
    console.log("Updated Education:", updatedEducation); // Log updated data
    const newEducation = [...education];
    newEducation[index] = updatedEducation;
    onChange(newEducation);
  };

  return (
    <Box>
      {education.map((edu, index) => (
        <EducationForm
          key={index}
          education={edu}
          index={index}
          errors={errors[index]}
          touched={touched[index]}
          onChange={handleFieldChange}
          onBlur={onBlur}
          onDelete={onDelete}
        />
      ))}

      <Button
        startIcon={<AddIcon />}
        onClick={onAdd}
        sx={{
          mt: 2,
          color: "#2D2D2D",
          borderColor: "#2D2D2D",
          borderRadius: "8px",
          padding: "8px 16px",
          "&:hover": {
            borderColor: "#2D2D2D",
            backgroundColor: "rgba(45, 45, 45, 0.04)",
          },
        }}
        variant="outlined"
      >
        Add Another Education
      </Button>
    </Box>
  );
};

export default EditMode;
