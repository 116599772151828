import React, { useState } from "react";
import { Box, Typography, styled, Grid } from "@mui/material";
// Import logos
import linkedinLogo from "../assets/linkedin-icon.png";
import indeedLogo from "../assets/indeed.png";
import glassdoorLogo from "../assets/Glassdoor-Logo.png";
import ziprecruiterLogo from "../assets/ziprecruiter.png";
import diceLogo from "../assets/dice.png";
import careerbuilderLogo from "../assets/cbfinal.png";
import simplyhiredLogo from "../assets/simplyHiredLogo.png";
import monsterLogo from "../assets/Monster-Logo.png";
import greenhouseLogo from "../assets/greenhouse_1701339424156.jpg";

const LogoCard = styled(Box)({
  backgroundColor: "#fff",
  boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
  borderRadius: "12px",
  padding: "16px",
  display: "flex",
  flexDirection: "column", // Changed to column layout
  gap: "8px",
  height: "80px",
  transition: "all 0.3s ease",
  border: "0.5px solid rgba(0,0,0,0.05)",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
  },
});

const LogoWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "16px",
});

const Logo = styled("img")({
  height: "40px",
  width: "40px", // Made square for consistency
  objectFit: "contain",
  borderRadius: "6px", // Slight rounding for logos
});

const LogoName = styled(Typography)({
  color: "#2D2D2D",
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: 1.2,
});

const Description = styled(Typography)({
  color: "#666666",
  fontSize: "14px",
  marginTop: "4px",
});

const JobBoardsGrid = () => {
  const jobBoards = [
    {
      id: 1,
      name: "LinkedIn",
      logo: linkedinLogo,
      description: "Professional network",
    },
    {
      id: 2,
      name: "Indeed",
      logo: indeedLogo,
      description: "Job search engine",
    },
    {
      id: 3,
      name: "Glassdoor",
      logo: glassdoorLogo,
      description: "Company reviews & jobs",
    },
    {
      id: 4,
      name: "ZipRecruiter",
      logo: ziprecruiterLogo,
      description: "Job marketplace",
    },
    {
      id: 5,
      name: "Dice",
      logo: diceLogo,
      description: "Tech jobs",
    },
    {
      id: 6,
      name: "CareerBuilder",
      logo: careerbuilderLogo,
      description: "Job board",
    },
    {
      id: 7,
      name: "SimplyHired",
      logo: simplyhiredLogo,
      description: "Job board",
    },
    // {
    //   id: 8,
    //   name: "Monster",
    //   logo: monsterLogo,
    //   description: "Job board",
    // },
    {
      id: 8,
      name: "Greenhouse",
      logo: greenhouseLogo,
      description: "Job board",
    },

    // ... rest of the job boards
  ];

  return (
    <Box
      sx={{
        maxWidth: "1100px",
        margin: "0 auto",
        px: 3,
        pt: 4,
        pb: 6,
        transition: "all 0.5s ease",
      }}
    >
      <Box sx={{ textAlign: "center", mb: 5 }}>
        <Typography
          variant="h6"
          sx={{
            color: "#000",
            fontSize: "28px",
            fontWeight: "500",
            letterSpacing: "0.5px",
            mb: 1,
          }}
        >
          Integrated Job Platforms
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {jobBoards.map((board) => (
          <Grid item xs={12} sm={6} md={4} key={board.id}>
            <LogoCard>
              <LogoWrapper>
                <Logo src={board.logo} alt={board.name} />
                <Box>
                  <LogoName>{board.name}</LogoName>
                  <Description>{board.description}</Description>
                </Box>
              </LogoWrapper>
            </LogoCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default JobBoardsGrid;
