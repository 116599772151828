import React, { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, GlobalStyles, Backdrop } from "@mui/material";
import { styled } from "@mui/material/styles";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import HowItWorks from "./components/HowItWorks";
import Benefits from "./components/Benefits";
import Pricing from "./components/Pricing";
import ReviewsSection from "./components/ReviewsSection";
import FinalCTA from "./components/FinalCTA";
import Footer from "./components/Footer";
import LoginModalWrapper from "./components/LoginModal";
import Modal from "./emailAutomation/Modal/Modal";

// Custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#2d2d2d",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {},
      },
    },
  },
});

const BlurContainer = styled(Box)(({ isBlurred }) => ({
  transition: "filter 0.3s ease",
  filter: isBlurred ? "blur(1px)" : "none",
  opacity: isBlurred ? 0.5 : 1,
}));

const LazyApplySite = () => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const handleLoginOpen = () => setIsLoginOpen(true);
  const handleLoginClose = () => setIsLoginOpen(false);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          html: {
            scrollBehavior: "smooth",
          },
        }}
      />
      <CssBaseline />

      <BlurContainer isBlurred={isLoginOpen}>
        <Modal />
        {/* Navbar */}
        {/* <Navbar onLoginClick={handleLoginOpen} />

        <Hero />
        <HowItWorks />
        <Benefits />
        <Pricing />
        <ReviewsSection />
        <FinalCTA />
        <Footer /> */}
      </BlurContainer>

      {/* Login Modal */}
      {/* <Modal
        open={isLoginOpen}
        onClose={handleLoginClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              backdropFilter: "blur(8px)",
            },
          },
        }}
      >
        <LoginModalWrapper onClose={handleLoginClose} />
      </Modal> */}
    </ThemeProvider>
  );
};

export default LazyApplySite;
