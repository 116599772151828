import React, { useState, useEffect } from "react";
import { Box, Container, Typography, Stack, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import WhatsAppChat from "./review/WhatsAppChat";
import TwitterPost from "./review/TwitterPost";
import TrustpilotReview from "./review/TrustpilotReview";
import GoogleReview from "./review/GoogleReview";
import LinkedInPost from "./review/LinkedInPost";
import SiteReview from "./review/SiteReview";
import { testimonials } from "./review/testimonials";

const FadeInBox = styled(Box)`
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  will-change: opacity, transform;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ReviewSection = () => {
  const [visibleElements, setVisibleElements] = useState(new Set());

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        const id = entry.target.getAttribute("data-review-id");

        if (id.includes("whatsapp")) {
          if (entry.isIntersecting) {
            setVisibleElements((prev) => new Set(prev).add(id));
          }
        } else {
          if (entry.isIntersecting) {
            setVisibleElements((prev) => new Set(prev).add(id));
          } else {
            setVisibleElements((prev) => {
              const newSet = new Set(prev);
              newSet.delete(id);
              return newSet;
            });
          }
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    });

    document.querySelectorAll("[data-review-id]").forEach((element) => {
      observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <Box
      id="success"
      sx={{
        py: 10,
        background:
          "linear-gradient(135deg, #F0F8FF 0%, #F5FAFF 50%, #F8FCFF 100%)",
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={2} alignItems="center" mb={12}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "1.75rem", md: "2.5rem" },
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            What Our Users Say
          </Typography>
          <Typography
            sx={{
              color: "#666666",
              textAlign: "center",
              fontSize: { xs: "1rem", md: "1.1rem" },
              maxWidth: "700px",
            }}
          >
            Join thousands of successful job seekers who automated their job
            search with LazyApply
          </Typography>
        </Stack>

        <Box sx={{ maxWidth: "1200px", mx: "auto" }}>
          <Grid container spacing={4} sx={{ alignItems: "flex-start" }}>
            {/* Left Column */}
            <Grid item xs={12} md={4}>
              <Stack spacing={6}>
                <FadeInBox
                  data-review-id="google-1"
                  className={visibleElements.has("google-1") ? "visible" : ""}
                  sx={{ maxWidth: "380px", width: "100%" }}
                >
                  <GoogleReview />
                </FadeInBox>

                <FadeInBox
                  data-review-id="trustpilot-1"
                  className={
                    visibleElements.has("trustpilot-1") ? "visible" : ""
                  }
                  sx={{ maxWidth: "380px", width: "100%" }}
                >
                  <TrustpilotReview />
                </FadeInBox>

                <FadeInBox
                  data-review-id="twitter-1"
                  className={visibleElements.has("twitter-1") ? "visible" : ""}
                  sx={{ maxWidth: "380px", width: "100%" }}
                >
                  <TwitterPost tweet={testimonials.twitter[0]} />
                </FadeInBox>

                <FadeInBox
                  data-review-id="site-2"
                  className={visibleElements.has("site-2") ? "visible" : ""}
                  sx={{ maxWidth: "380px", width: "100%" }}
                >
                  <SiteReview />
                </FadeInBox>
              </Stack>
            </Grid>

            {/* Middle Column */}
            <Grid item xs={12} md={4}>
              <Stack spacing={6}>
                <FadeInBox
                  data-review-id="twitter-2"
                  className={visibleElements.has("twitter-2") ? "visible" : ""}
                  sx={{ maxWidth: "380px", width: "100%", mx: "auto" }}
                >
                  <TwitterPost tweet={testimonials.twitter[1]} />
                </FadeInBox>

                <FadeInBox
                  data-review-id="whatsapp-1"
                  className={visibleElements.has("whatsapp-1") ? "visible" : ""}
                  sx={{ maxWidth: "380px", mx: "auto" }}
                >
                  <WhatsAppChat chat={testimonials.whatsapp[0]} />
                </FadeInBox>

                <FadeInBox
                  data-review-id="linkedin-2"
                  className={visibleElements.has("linkedin-2") ? "visible" : ""}
                  sx={{ maxWidth: "380px", width: "100%", ml: "auto" }}
                >
                  <LinkedInPost />
                </FadeInBox>
              </Stack>
            </Grid>

            {/* Right Column */}
            <Grid item xs={12} md={4}>
              <Stack spacing={3}>
                <FadeInBox
                  data-review-id="site-1"
                  className={visibleElements.has("site-1") ? "visible" : ""}
                  sx={{ maxWidth: "380px", width: "100%", ml: "auto" }}
                >
                  <SiteReview />
                </FadeInBox>

                <FadeInBox
                  data-review-id="linkedin-1"
                  className={visibleElements.has("linkedin-1") ? "visible" : ""}
                  sx={{ maxWidth: "380px", width: "100%", ml: "auto" }}
                >
                  <LinkedInPost />
                </FadeInBox>

                <FadeInBox
                  data-review-id="trustpilot-2"
                  className={
                    visibleElements.has("trustpilot-2") ? "visible" : ""
                  }
                  sx={{ maxWidth: "380px", width: "100%", ml: "auto" }}
                >
                  <TrustpilotReview />
                </FadeInBox>

                <FadeInBox
                  data-review-id="google-2"
                  className={visibleElements.has("google-2") ? "visible" : ""}
                  sx={{ maxWidth: "380px", width: "100%", mx: "auto" }}
                >
                  <GoogleReview />
                </FadeInBox>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default ReviewSection;
