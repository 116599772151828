/*global chrome*/
import React, { useState, useEffect, useCallback, useRef } from "react";
import "./dashboard.css";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
// import GoalProtectedRoute from "../ats-dashboard/GoalProtectedRoute";
import OnBoardingProtectedRoute from "../ats-dashboard/OnBoardingProtectedRoute";
import XYZPricing from "../referral/xyzpricing.js";
// import PaymentSuccess from "../paymentsuccess";
import { useStateStore } from "../../store";
import axios from "axios";
import Navbar from "../ats-dashboard/components/Navbar.js";
import AtsDashboard from "../ats-dashboard/Dashboard.js";
import ProfileManagement from "../ats-dashboard/ProfileManagement.js";
import ProfileEditor from "../ats-dashboard/profile-components/ProfileEditor.js";
import Analytics from "../ats-dashboard/Analytics.js";
import GoalPage from "../ats-dashboard/GoalPage.js";
import { Hotjar } from "../../index";
import LoginPage from "../ats-dashboard/LoginPage.js";
import PaymentSuccess from "../ats-dashboard/PaymentSuccess.js";
import OnboardingFlow from "../onboarding-flow/v2/OnboardingFlow.js";
import LazyApplySite from "../lazyapply-site/App.js";

import UpgradeBanner from "../ats-dashboard/components/update-extension/UpgradeBanner.js";
import UpgradeModal from "../ats-dashboard/components/update-extension/UpgradeModal.js";
import ProfileAndEmailManager from "../ats-dashboard/ProfileAndEmailManager";
import DataPrivacyPolicy from "../ats-dashboard/emailSettings-sections/DataPrivacyPolicy";
import ExploreSection from "../ats-dashboard/exploreSection/ExploreSection";
import DetailedView from "../ats-dashboard/exploreSection/DetailedView";
import ShareJourneyForm from "../ats-dashboard/exploreSection/ShareJourneyForm/index";
import OperatorsList from "../ats-dashboard/enterprise/enterprise-companies/OperatorsList";
import OperatorUsers from "../ats-dashboard/enterprise/enterprise-companies/OperatorUsers";
import OperatorsView from "../ats-dashboard/enterprise/enterprise-companies/OperatorsView";
import EducationOwnerView from "../ats-dashboard/enterprise/enterprise-instituition/EducationOwnerView";
import PricingPage from "../ats-dashboard/PricingPage";

function Dashboard() {
  const {
    token,
    chromeVersion,
    planDetails,
    setAvailableChromeVersion,
    availableChromeVersion,
    userData,
  } = useStateStore();
  const location = useLocation();
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  // const handleMigration = async (token) => {
  //   if (token) {  
  //     try {
  //       const result = await migrateUser(token);
  //       if (result.success && result.refresh_window === 1) {
  //         window.location.reload();
  //       }
  //     } catch (error) {
  //       console.log('Failed to migrate user data');
  //     }
  //   }
  // };
  
  // useEffect(() => {
  //   if (userData?.email?.trim() && userData?.v2Id?.trim() && token) {
  //     handleMigration(token);
  //   }
  // }, [token, userData]);
  
  // const migrateUser = async (token) => {
  //   try {
  //     if (!token) {
  //       throw new Error('Authentication required');
  //     }
  
  //     const response = await axios.get(`https://backend.lazyapply.com/lazyapplyV2/grant-access`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`
  //       }
  //     });
  
  //     return response.data;
  //   } catch (error) {
  //     console.error('Migration error:', error);
  //     throw error;
  //   }
  // };
  // banner states

  const [showUpgradeBanner, setShowUpgradeBanner] = useState(true);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const compareVersions = (v1, v2) => {
      const [, major1, minor1] = v1.split(".").map(Number);
      const [, major2, minor2] = v2.split(".").map(Number);

      if (major1 > major2) return 1;
      if (major1 < major2) return -1;

      if (major1 === major2) {
        if (minor1 > minor2) return 1;
        if (minor1 < minor2) return -1;
        return 0;
      }
    };

    const chromeVersionFn = async () => {
      try {
        if (chromeVersion === -1) return;

        const response = await axios.get(
          "https://backend.lazyapply.com/getChromeVersion"
        );

        if (!response.data.found) {
          console.log("Chrome version not found");
          setShowUpgradeBanner(false);
          return;
        }

        const latestVersion = response.data.version;
        setAvailableChromeVersion(latestVersion);

        const versionComparison = compareVersions(chromeVersion, latestVersion);

        if (versionComparison > 0) {
          setShowUpgradeBanner(false);
          return;
        }

        if (versionComparison === 0) {
          setShowUpgradeBanner(false);
        } else {
          setShowUpgradeBanner(true);
        }
      } catch (error) {
        console.error("Error fetching Chrome version:", error);
        setShowUpgradeBanner(false);
      }
    };

    chromeVersionFn();
  }, [chromeVersion]);

  useEffect(() => {
    if (planDetails !== undefined && !planDetails?.planStarted) {
      setShowUpgradeBanner(false);
    }
  }, [planDetails?.planStarted]);

  const handleDismissBanner = () => {
    setShowUpgradeBanner(false);
  };

  const handleOpenUpgradeModal = () => {
    setShowUpgradeModal(true);
  };

  const handleCloseUpgradeModal = () => {
    setShowUpgradeModal(false);
  };

  // const checkUserPlan = () => {
  //   axios
  //     .get("https://backend.lazyapply.com/getUserDetails", {
  //       headers: { Authorization: `Bearer ${token}` },
  //     })
  //     .then((response) => {
  //       const planStarted = response.data?.planDetails?.planStarted || 0;
  //       console.log("planstarted", planStarted);
  //       setCheckIfPreviousUser(planStarted);
  //       setShowAlert(planStarted);
  //     })
  //     .catch((err) => {
  //       console.log("error");
  //     });
  // };

  // const [checkIfPreviousUser, setCheckIfPreviousUser] = useState(false);
  // const [showAlert, setShowAlert] = useState(false);

  // useEffect(() => {
  //   if (token) {
  //     checkUserPlan();
  //   }
  // }, [token]);

  return (
    <div className="dashboard">
      <div
        className="dashboardRight"
        style={{
          position: "relative",
          paddingTop: "80px",
        }}
      >
        {location?.pathname === "/dashboard" && (
          <UpgradeBanner
            show={showUpgradeBanner}
            onHowToUpgrade={handleOpenUpgradeModal}
            onDismiss={handleDismissBanner}
          />
        )}

        <UpgradeModal
          availableChromeVersion={availableChromeVersion}
          open={showUpgradeModal}
          onClose={handleCloseUpgradeModal}
        />

        {location?.pathname !== "/dashboard/goal" &&
          location?.pathname !== "/dashboard/onboarding" &&
          location?.pathname !== "/dashboard/lazyapply" && (
            <Navbar onProfileStateChange={setIsProfileOpen} />
          )}

        <div
          style={{
            transition: "all 0.3s ease",
            opacity: isProfileOpen ? 0.5 : 1,
            pointerEvents: isProfileOpen ? "none" : "auto",
          }}
        >
          <Switch>
            {/* Public Routes */}
            <Route
              exact
              path="/dashboard/payment-success"
              component={PaymentSuccess}
            />
            {/* <Route exact path="/dashboard/goal" component={GoalPage} /> */}

            <Route
              exact
              path="/dashboard/onboarding"
              component={OnboardingFlow}
            />
            {/* 
            <Route
              exact
              path="/dashboard/lazyapply"
              component={LazyApplySite}
            /> */}

            <OnBoardingProtectedRoute exact path="/dashboard">
              <AtsDashboard />
            </OnBoardingProtectedRoute>

            <OnBoardingProtectedRoute exact path="/dashboard/pricing">
              <PricingPage />
              {/* <XYZPricing /> */}
            </OnBoardingProtectedRoute>

            <OnBoardingProtectedRoute exact path="/dashboard/profile">
              <ProfileAndEmailManager />
            </OnBoardingProtectedRoute>

            <OnBoardingProtectedRoute path="/dashboard/profile/email-settings">
              <ProfileAndEmailManager />
            </OnBoardingProtectedRoute>

            <OnBoardingProtectedRoute path="/dashboard/profile/:profileId">
              <ProfileEditor />
            </OnBoardingProtectedRoute>

            <OnBoardingProtectedRoute exact path="/dashboard/analytics">
              <Analytics />
            </OnBoardingProtectedRoute>

            <OnBoardingProtectedRoute exact path="/dashboard/privacy-policy">
              <DataPrivacyPolicy />
            </OnBoardingProtectedRoute>

            <OnBoardingProtectedRoute exact path="/dashboard/explore">
              <ExploreSection />
            </OnBoardingProtectedRoute>

            <OnBoardingProtectedRoute exact path="/dashboard/explore/share">
              <ShareJourneyForm />
            </OnBoardingProtectedRoute>

            <OnBoardingProtectedRoute path="/dashboard/explore/:id">
              <DetailedView />
            </OnBoardingProtectedRoute>

            <OnBoardingProtectedRoute path="/dashboard/user-management/operator/:operatorId">
              <OperatorUsers />
            </OnBoardingProtectedRoute>

            <OnBoardingProtectedRoute
              exact
              path="/dashboard/user-management/operator"
            >
              <OperatorsView />
            </OnBoardingProtectedRoute>

            <OnBoardingProtectedRoute exact path="/dashboard/user-management">
              <OperatorsList />
            </OnBoardingProtectedRoute>

            <OnBoardingProtectedRoute
              exact
              path="/dashboard/user-management-owner"
            >
              <EducationOwnerView />
            </OnBoardingProtectedRoute>

            <Route>
              <Redirect to="/dashboard" />
            </Route>
          </Switch>
        </div>

        {isProfileOpen && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.02)",
              zIndex: 1200,
              pointerEvents: "none",
            }}
          />
        )}
      </div>
    </div>
  );
}

export default Dashboard;
