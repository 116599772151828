import React from "react";
import { Box, Checkbox, Typography, styled } from "@mui/material";

const ControlContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1300px",
  margin: "0 auto",
  padding: "16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#FAFAFA",
  borderRadius: "8px",
  marginBottom: "24px",
}));

const SelectAllWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

const SelectionText = styled(Typography)({
  color: "#666666",
  fontSize: "16px",
});

const SelectionControl = ({
  totalJobs,
  selectedCount,
  allSelected,
  onSelectAll,
}) => {
  return (
    <ControlContainer>
      <SelectAllWrapper>
        <Checkbox
          checked={allSelected}
          onChange={onSelectAll}
          color="primary"
        />
        <Typography>
          {allSelected ? "Deselect" : "Select"} All ({totalJobs} jobs)
        </Typography>
      </SelectAllWrapper>

      <SelectionText>{selectedCount} Selected</SelectionText>
    </ControlContainer>
  );
};

export default SelectionControl;
