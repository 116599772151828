import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Container,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { styled } from "@mui/material/styles";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: "1px solid rgba(45, 45, 45, 0.1)",
  borderRadius: "12px !important",
  marginBottom: "24px",
  boxShadow: "0 4px 16px rgba(0, 0, 0, 0.08)",
  backgroundColor: "#FFFFFF",
  transition: "all 0.3s ease",
  "&:before": {
    display: "none",
  },
  "&.Mui-expanded": {
    margin: "0 0 24px 0",
    boxShadow: "0 6px 20px rgba(0, 0, 0, 0.12)",
  },
  "&:hover": {
    boxShadow: "0 6px 20px rgba(0, 0, 0, 0.12)",
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: "8px 24px",
  backgroundColor: "#f8f9fa",
  borderRadius: "12px",
  minHeight: "76px",
  "& .MuiAccordionSummary-content": {
    margin: "16px 0",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    width: "32px",
    height: "32px",
    border: "2px solid #2d2d2d",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.3s ease",
    "& svg": {
      fontSize: "20px",
      color: "#2d2d2d",
    },
    "&.Mui-expanded": {
      background: "#2d2d2d",
      "& svg": {
        color: "#ffffff",
      },
    },
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)({
  backgroundColor: "#FFFFFF",
  padding: "24px 24px 32px",
  borderTop: "1px solid rgba(45, 45, 45, 0.1)",
});

const StyledButton = styled(Button)(({ theme }) => ({
  color: "#2d2d2d",
  borderColor: "#2d2d2d",
  padding: "8px 18px",
  fontWeight: 550,
  marginTop: "40px",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "18px",
  "&:hover": {
    backgroundColor: "#2d2d2d",
    color: "#fff",
    borderColor: "#2d2d2d",
    transform: "translateY(-1px)",
    boxShadow: "0 4px 8px rgba(45, 45, 45, 0.2)",
  },
  transition: "all 0.2s ease-in-out",
}));

const FAQSection = () => {
  const [showAll, setShowAll] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const faqData = [
    {
      question: "What's included in the application limit per day?",
      answer:
        "The application limit includes all job applications submitted through our platform within a 24-hour period. This limit resets daily at midnight UTC. Applications are counted when you click 'Apply' on a job posting.",
    },
    {
      question: "Can I switch between plans?",
      answer:
        "Yes, you can upgrade or downgrade your plan at any time. When upgrading, you'll be charged the prorated difference for the remainder of your billing period. When downgrading, the new rate will apply at the start of your next billing cycle.",
    },
    {
      question: "What's the difference between basic and priority support?",
      answer:
        "Basic support includes email assistance with a 24-48 hour response time. Priority support provides faster response times (within 12 hours), access to our priority support queue, and the ability to schedule one-on-one support calls.",
    },
    {
      question: "How do the resume profiles work?",
      answer:
        "Resume profiles allow you to save different versions of your resume for different types of job applications. Each profile can have its own resume, cover letter templates, and custom fields. You can quickly switch between profiles when applying to different positions.",
    },
    {
      question: "What happens if I reach my daily application limit?",
      answer:
        "Once you reach your daily application limit, you'll need to wait until the limit resets at midnight UTC to submit more applications. You can view your remaining applications for the day in your dashboard.",
    },
  ];

  const visibleFaqs = showAll ? faqData : faqData.slice(0, 3);

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        py: 10,
        mt: 8,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          px: { xs: 2, sm: 3, md: 4 },
        }}
      >
        <Box textAlign="center" mb={8}>
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontSize: { xs: "1.75rem", sm: "2rem", md: "2.5rem" },
              fontWeight: 600,
              color: "#2d2d2d",
              mb: 2,
            }}
          >
            Frequently Asked Questions
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{
              fontSize: { xs: "0.9rem", sm: "1rem", md: "1.25rem" },
              color: "#666666",
              mb: 4,
            }}
          >
            Everything you need to know
          </Typography>
        </Box>

        <Box maxWidth="900px" mx="auto" sx={{ px: { xs: 0, sm: 2, md: 4 } }}>
          {visibleFaqs.map((faq, index) => (
            <StyledAccordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <StyledAccordionSummary
                expandIcon={
                  expanded === `panel${index}` ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "1.1rem", md: "1.2rem" },
                    fontWeight: 500,
                    color: "#2d2d2d",
                    pr: 2,
                  }}
                >
                  {faq.question}
                </Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <Typography
                  sx={{
                    color: "#666666",
                    lineHeight: 1.8,
                    fontSize: "1rem",
                  }}
                >
                  {faq.answer}
                </Typography>
              </StyledAccordionDetails>
            </StyledAccordion>
          ))}

          {faqData.length > 3 && (
            <Box textAlign="center">
              <StyledButton
                disableRipple
                variant="outlined"
                onClick={() => setShowAll(!showAll)}
                endIcon={showAll ? <RemoveIcon /> : <AddIcon />}
              >
                {showAll ? "Show Less" : "See More FAQs"}
              </StyledButton>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default FAQSection;
