// LoginModal.jsx
import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useGoogleOneTapLogin, GoogleOAuthProvider } from "@react-oauth/google";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useStateStore } from "../../../store/index"; // adjust path as needed
import axios from "axios";

const ModalContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  backgroundColor: "#fff",
  borderRadius: "24px",
  padding: "40px",
  boxShadow: "0 8px 32px rgba(0,0,0,0.12)",
  outline: "none",
  animation: "modalFadeIn 0.3s ease-out",
  "@keyframes modalFadeIn": {
    from: {
      opacity: 0,
      transform: "translate(-50%, -48%) scale(0.96)",
    },
    to: {
      opacity: 1,
      transform: "translate(-50%, -50%) scale(1)",
    },
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    maxHeight: "90vh",
    overflowY: "auto",
  },
}));

const Decorator = styled(Box)({
  marginBottom: "32px",
  display: "flex",
  gap: "4px",
  justifyContent: "center",
  width: "100%",
  "& .main-part": {
    width: "24px",
    height: "24px",
    backgroundColor: "#2D2D2D",
    borderRadius: "4px",
  },
  "& .small-part": {
    width: "16px",
    height: "24px",
    backgroundColor: "#2D2D2D",
    borderRadius: "4px",
    opacity: 0.7,
  },
});

const GoogleButton = styled(Button)({
  backgroundColor: "#2D2D2D",
  color: "#fff",
  borderRadius: "36px",
  padding: "15px 40px",
  textTransform: "none",
  fontSize: "17px",
  fontWeight: 500,
  marginTop: "40px",
  display: "flex",
  alignItems: "center",
  gap: "16px",
  width: "400px",
  "&:hover": {
    backgroundColor: "#404040",
  },
});

const ErrorMessage = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  marginTop: "16px",
  animation: "fadeIn 0.3s ease-in-out",
  "@keyframes fadeIn": {
    from: { opacity: 0, transform: "translateY(-10px)" },
    to: { opacity: 1, transform: "translateY(0)" },
  },
});

const GoogleIcon = () => (
  <svg
    width="22"
    height="22"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
  >
    <path
      fill="#EA4335"
      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
    />
    <path
      fill="#4285F4"
      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
    />
    <path
      fill="#FBBC05"
      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
    />
    <path
      fill="#34A853"
      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
    />
  </svg>
);

const LoginModal = ({ onClose }) => {
  const [signInError, setSignInError] = useState(false);
  const {
    userDataInit,
    userAuthentication,
    updateToken,
    userImageSrc,
    isUserAuthenticated,
  } = useStateStore();

  function handleLogin(googleData, typeOfButton) {
    setSignInError(false);
    if (googleData) {
      axios
        .post(
          "https://backend.lazyapply.com/lazyapplyV2/create",
          {
            token: googleData,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          userAuthentication(1);
          userImageSrc(response.data.user.picture);
          updateToken(response.data.token);
          window.localStorage.setItem("token", response.data.token);
          window.localStorage.setItem(
            "user",
            JSON.stringify(response.data.user)
          );
          userDataInit(response.data.user);
          onClose(); // Close modal on successful login
        })
        .catch(function (error) {
          console.log("err", error);
          setSignInError(true);
        });
    }
  }

  useGoogleOneTapLogin({
    disabled: isUserAuthenticated === 1,
    onSuccess: (credentialResponse) => {
      handleLogin(credentialResponse.credential, "one_tap");
    },
    onError: () => {
      console.log("Login Failed");
      setSignInError(true);
    },
  });

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    script.onload = () => {
      window.gapi.load("auth2", () => {
        window.gapi.auth2.init({
          client_id:
            "715818145189-7geg3j3td9u21nc9qe8eujb2l4dcbt88.apps.googleusercontent.com",
        });
      });
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const signIn = () => {
    setSignInError(false);
    if (window.gapi && window.gapi.auth2) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      auth2.signIn().then(
        (googleUser) => {
          const id_token = googleUser.getAuthResponse().id_token;
          handleLogin(id_token, "main_button");
        },
        (error) => {
          console.log("Sign-in error:", error);
          setSignInError(true);
        }
      );
    }
  };

  return (
    <ModalContainer>
      <Stack alignItems="center">
        <Decorator>
          <Box className="main-part" />
          <Box className="small-part" />
        </Decorator>

        <Typography
          sx={{
            fontWeight: 600,
            color: "#2D2D2D",
            fontSize: "34px",
            textAlign: "center",
          }}
        >
          Welcome back!
        </Typography>

        <Typography
          sx={{
            color: "#666666",
            textAlign: "center",
            mb: 1,
          }}
        >
          One Click Away From Your Next Career Opportunity
        </Typography>

        <GoogleButton
          variant="contained"
          startIcon={<GoogleIcon />}
          onClick={signIn}
          sx={{ mx: "auto" }}
        >
          Continue with Google
        </GoogleButton>

        {signInError && (
          <ErrorMessage>
            <ErrorOutlineIcon sx={{ color: "#d32f2f", fontSize: "22px" }} />
            <Typography
              sx={{
                color: "#d32f2f",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              Unable to sign in with Google. Please try again.
            </Typography>
          </ErrorMessage>
        )}

        <Box
          sx={{
            mt: 4,
            textAlign: "center",
          }}
        >
          <Typography
            component="span"
            sx={{ color: "#666666", fontSize: "18px" }}
          >
            Don't have an account?{" "}
          </Typography>
          <Typography
            component="span"
            onClick={signIn}
            sx={{
              color: "#2D2D2D",
              fontWeight: 500,
              fontSize: "18px",
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Sign up
          </Typography>
        </Box>
      </Stack>
    </ModalContainer>
  );
};

// Wrapper component with Google OAuth Provider
export const LoginModalWrapper = ({ onClose }) => {
  const CLIENT_ID = encodeURIComponent(
    "715818145189-7geg3j3td9u21nc9qe8eujb2l4dcbt88.apps.googleusercontent.com"
  );

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <LoginModal onClose={onClose} />
    </GoogleOAuthProvider>
  );
};

export default LoginModalWrapper;
