// components/sections/Education/index.jsx
import React, { useState, useEffect } from "react";
import { Grid, Box, Button } from "@mui/material";
import BaseSection from "../BaseSection";
import ViewMode from "./ViewMode";
import EditMode from "./EditMode";

const validateEducation = (education) => {
  const errors = [];

  if (education.length === 0) {
    return [
      { institution: { name: "At least one education entry is required" } },
    ];
  }

  education.forEach((edu, index) => {
    const eduErrors = {};

    if (!edu.institution?.name?.trim()) {
      eduErrors["institution.name"] = "Institution name is required"; // Direct string error
    }

    if (!edu.degree?.degreeName?.trim()) {
      eduErrors["degree.degreeName"] = "Degree name is required"; // Direct string error
    }

    // if (!edu.startDate) {
    //   eduErrors.startDate = "Start date is required";
    // }

    // if (!edu.endDate) {
    //   eduErrors.endDate = "End date is required";
    // }

    // // Add date comparison validation
    // if (edu.startDate && edu.endDate) {
    //   const start = new Date(edu.startDate);
    //   const end = new Date(edu.endDate);
    //   if (end < start) {
    //     eduErrors.endDate = "End date cannot be before start date";
    //   }
    // }

    if (Object.keys(eduErrors).length > 0) {
      errors[index] = eduErrors;
    }
  });

  return errors;
};

const Education = ({ data = [], isExpanded, onToggleExpand, onUpdate }) => {
  const [education, setEducation] = useState(data);
  const [errors, setErrors] = useState([]);
  const [touched, setTouched] = useState([]);
  const [sectionStatus, setSectionStatus] = useState("missing_required");

  const getNestedValue = (obj, path) => {
    const keys = path.split(".");
    return keys.reduce((acc, key) => (acc ? acc[key] : undefined), obj);
  };

  const validateField = (field, value) => {
    const requiredFields = ["institution.name", "degree.degreeName"];
    if (!requiredFields.includes(field)) {
      return "";
    }

    return !value?.toString().trim() ? `${field} is required` : "";

    // if (!value?.toString().trim()) {
    //   return `${field} is required`;
    // }
    // return "";
  };

  const handleChange = (newEducation) => {
    setEducation(newEducation);

    // Update errors for touched fields
    const newErrors = [...errors];
    const requiredFields = ["institution.name", "degree.degreeName"];

    touched.forEach((touchedFields, index) => {
      if (touchedFields) {
        Object.keys(touchedFields).forEach((field) => {
          if (touchedFields[field] && requiredFields.includes(field)) {
            let value;
            if (field === "institution.name") {
              value = newEducation[index]?.institution?.name;
            } else if (field === "degree.degreeName") {
              value = newEducation[index]?.degree?.degreeName;
            }

            if (!newErrors[index]) newErrors[index] = {};
            newErrors[index][field] = validateField(field, value);
          }
        });
      }
    });

    setErrors(newErrors);
  };

  const handleBlur = (index, field) => {
    // Mark field as touched
    const newTouched = [...touched];
    if (!newTouched[index]) newTouched[index] = {};
    newTouched[index][field] = true;
    setTouched(newTouched);

    const requiredFields = ["institution.name", "degree.degreeName"];

    // Skip validation for non-required fields
    if (!requiredFields.includes(field)) {
      return;
    }

    // Validate the field
    const newErrors = [...errors];
    if (!newErrors[index]) newErrors[index] = {};

    // Get the value based on the field path
    let value;
    if (field === "institution.name") {
      value = education[index]?.institution?.name;
    } else if (field === "degree.degreeName") {
      value = education[index]?.degree?.degreeName;
    }

    newErrors[index][field] = validateField(field, value);
    setErrors(newErrors);
  };

  const handleAddEducation = () => {
    setEducation((prev) => [
      ...prev,
      {
        institution: {
          name: "",
          location: {
            city: "",
            state: "",
          },
          type: "",
        },
        degree: {
          degreeType: "",
          specialization: [],
          degreeName: "",
        },
        endDate: "",
        gpa: "",
        startDate: "",
      },
    ]);
  };

  const handleSave = () => {
    console.log("Saving education:", education);

    const validationErrors = validateEducation(education);
    setErrors(validationErrors);

    const hasErrors = validationErrors.some(
      (eduErrors) => eduErrors && Object.keys(eduErrors).length > 0
    );

    if (!hasErrors) {
      const newStatus = getSectionStatus(education);
      setSectionStatus(newStatus);
      onUpdate(education);
      return true;
    }

    // Mark all as touched if there are errors
    const newTouched = education.map(() => ({
      "institution.name": true,
      "degree.degreeName": true,
    }));
    setTouched(newTouched);

    return false;
  };

  const handleCancel = () => {
    setEducation(data);
    setErrors([]);
    setTouched([]);
    setSectionStatus(getSectionStatus(data));
  };

  const handleDelete = (indexToDelete) => {
    const updatedEducation = education.filter(
      (_, index) => index !== indexToDelete
    );
    setEducation(updatedEducation);
    onUpdate(updatedEducation);
  };

  const getSectionStatus = (educationData) => {
    // If no education entries, it's missing required
    if (educationData.length === 0) {
      return "missing_required";
    }

    // Check if all required fields are filled in all education entries
    const hasAllRequired = educationData.every(
      (edu) => edu.institution?.name?.trim() && edu.degree?.degreeName?.trim()
      // &&
      // edu.startDate &&
      // edu.endDate
    );

    // Check for any validation errors
    const hasErrors = errors.some(
      (error) => error && Object.keys(error).length > 0
    );

    if (hasAllRequired && !hasErrors) {
      return "complete";
    } else {
      return "missing_required";
    }
  };

  useEffect(() => {
    const initialStatus = getSectionStatus(data);
    setSectionStatus(initialStatus);
  }, [data]);

  // const isFormValid = !errors.some(
  //   (eduErrors) => eduErrors && Object.values(eduErrors).some((error) => error)
  // );

  const isFormValid = !errors.some((eduErrors) => {
    if (!eduErrors) return false;

    const requiredFields = ["institution.name", "degree.degreeName"];
    return Object.entries(eduErrors).some(
      ([field, error]) => requiredFields.includes(field) && error
    );
  });

  return (
    <BaseSection
      title="Education"
      isExpanded={isExpanded}
      onToggleExpand={onToggleExpand}
      onSave={handleSave}
      onCancel={handleCancel}
      canSave={isFormValid}
      sectionStatus={sectionStatus}
    >
      {({ isEditing, handleSave, handleCancel }) => (
        <Grid container>
          {isEditing ? (
            <>
              <EditMode
                education={education}
                errors={errors}
                touched={touched}
                onAdd={handleAddEducation}
                onChange={handleChange}
                setTouched={setTouched}
                onDelete={handleDelete}
                onBlur={handleBlur}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  mt: 3,
                  borderTop: "1px solid #E0E0E0",
                  pt: 3,
                  width: "100%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  sx={{
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: 600,
                    padding: "4px 12px",
                    color: "#666666",
                    borderColor: "#666666",
                    "&:hover": {
                      borderColor: "#2D2D2D",
                      backgroundColor: "rgba(45, 45, 45, 0.04)",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={!isFormValid}
                  sx={{
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: 600,
                    padding: "6px 18px",
                    backgroundColor: isFormValid ? "#2D2D2D" : "#E0E0E0",
                    color: isFormValid ? "#FFFFFF" : "#666666",
                    "&:hover": {
                      backgroundColor: isFormValid ? "#404040" : "#E0E0E0",
                    },
                  }}
                >
                  Save
                </Button>
              </Box>
            </>
          ) : (
            <ViewMode education={education} />
          )}
        </Grid>
      )}
    </BaseSection>
  );
};

export default Education;
