// components/UpgradeModal.jsx
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const StepItem = ({ number, title, steps }) => (
  <Box sx={{ mb: 4 }}>
    <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 2 }}>
      <Box
        sx={{
          width: 28,
          height: 28,
          borderRadius: "50%",
          backgroundColor: "#000",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "14px",
          fontWeight: 600,
        }}
      >
        {number}
      </Box>
      <Typography sx={{ fontSize: "18px", fontWeight: 600, color: "#2D2D2D" }}>
        {title}
      </Typography>
    </Box>
    <Box
      sx={{
        backgroundColor: "#F8FBFF",
        borderRadius: "12px",
        p: 2,
        ml: 5,
      }}
    >
      {steps.map((step, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 1.5,
            mb: index !== steps.length - 1 ? 1.5 : 0,
          }}
        >
          <CheckCircleIcon sx={{ color: "#000", fontSize: 20, mt: 0.3 }} />
          <Typography sx={{ color: "#666666", fontSize: "16px", flex: 1 }}>
            {step}
          </Typography>
        </Box>
      ))}
    </Box>
  </Box>
);

const UpgradeModal = ({ open, onClose, availableChromeVersion }) => {
  const handleChromeStore = () => {
    window.open(
      "https://chrome.google.com/webstore/detail/pgnfaifdbfoiehcndkoeemaifhhbgkmm",
      "_blank"
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "16px",
          maxWidth: "600px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 3,
        }}
      >
        <Typography
          sx={{ fontSize: "24px", fontWeight: 600, color: "#2D2D2D" }}
        >
          Upgrade Your Extension
        </Typography>
        <Button
          onClick={onClose}
          sx={{
            minWidth: "auto",
            p: 1,
            color: "#666666",
            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
          }}
        >
          <CloseIcon />
        </Button>
      </DialogTitle>

      <Divider />

      <DialogContent sx={{ p: 3 }}>
        <StepItem
          number="1"
          title="Remove Current Extension"
          steps={[
            "Click the button below to open LazyApply in Chrome Web Store",
            "Click 'Remove' to uninstall the current version",
          ]}
        />

        <StepItem
          number="2"
          title={`Install New Version (${availableChromeVersion})`}
          steps={["Click 'Add to Chrome' to install the latest version"]}
        />

        <Box sx={{ display: "flex", gap: 3, mt: 4 }}>
          <Button
            onClick={handleChromeStore}
            sx={{
              backgroundColor: "#000",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#404040",
              },
              textTransform: "none",
              px: 3,
              py: 1.5,
              borderRadius: "8px",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            Get New Extension
          </Button>
          <Button
            onClick={onClose}
            sx={{
              color: "#666666",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
              textTransform: "none",
              px: 3,
              py: 1.5,
              borderRadius: "8px",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UpgradeModal;
