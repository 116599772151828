import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Container,
  Button,
  Dialog,
  Avatar,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import PermissionIcon from "@mui/icons-material/VerifiedUser";
import { useStateStore } from "../../../../store";
import { useAccessModeHandler } from "../../../../utils/access-utils";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";

// Styled components
const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "6px",
    fontSize: "1rem",
    backgroundColor: "#fff",
    "& fieldset": {
      borderColor: "#e0e0e0",
      borderWidth: "1px",
      transition: "all 0.2s ease",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2d2d2d",
      borderWidth: "1px",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "16px",
    "&::placeholder": {
      color: "#9ca3af",
      opacity: 1,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#6b7280",
    "&.Mui-focused": {
      color: "#2d2d2d",
    },
  },
  "& .MuiSelect-select": {
    padding: "16px",
  },
}));

const UserCountChip = styled(({ color, ...other }) => <Chip {...other} />)(
  ({ theme, color }) => ({
    borderRadius: "25px",
    padding: "2px",
    fontSize: "20px",
    height: "40px",
    fontWeight: 600,
    backgroundColor:
      color === "green"
        ? "#e6f7e6"
        : color === "yellow"
        ? "#fff8e6"
        : "#ffeded",
    color:
      color === "green"
        ? "#2d8a2d"
        : color === "yellow"
        ? "#e6ac00"
        : "#d73a3a",
    marginLeft: "8px",
  })
);

const PermissionChip = styled(Chip)(({ theme }) => ({
  borderRadius: "12px",
  fontSize: "14px",
  fontWeight: 500,
  marginRight: "8px",
  backgroundColor: "#e6f7e6",
  color: "#2d8a2d",
}));

const StyledButton = styled(Button)(({ variant }) => ({
  borderRadius: "12px",
  padding: "8px 24px",
  fontSize: "16px",
  textTransform: "none",
  fontWeight: 550,
  boxShadow: "none",
  backgroundColor:
    variant === "delete"
      ? "#ffeded"
      : variant === "primary"
      ? "#2d2d2d"
      : "#f8f9fa",
  color:
    variant === "delete"
      ? "#d73a3a"
      : variant === "primary"
      ? "#ffffff"
      : "#2d2d2d",
  "&:hover": {
    backgroundColor:
      variant === "delete"
        ? "#ffe6e6"
        : variant === "primary"
        ? "#3a3a3a"
        : "#f0f0f0",
    boxShadow: "none",
  },
}));

const StatsBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  padding: "12px 16px",
  minWidth: "180px",
  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
}));

const ActionButton = styled(Button)(({ variant }) => ({
  borderRadius: "8px",
  padding: "6px 16px",
  fontSize: "14px",
  textTransform: "none",
  fontWeight: 550,
  marginRight: "8px",
  boxShadow: "none",
  backgroundColor:
    variant === "delete"
      ? "#ffeded"
      : variant === "primary"
      ? "#2d2d2d"
      : "#f8f9fa",
  color:
    variant === "delete"
      ? "#d73a3a"
      : variant === "primary"
      ? "#ffffff"
      : "#2d2d2d",
  "&:hover": {
    backgroundColor:
      variant === "delete"
        ? "#ffe6e6"
        : variant === "primary"
        ? "#3a3a3a"
        : "#f0f0f0",
    boxShadow: "none",
  },
}));

const OperatorsView = () => {
  const { token, userData, planDetails, enterpriseUser, setEnterpriseUser } =
    useStateStore();
  const { handleAccessModeChange: accessModeHandler } = useAccessModeHandler();
  const operatorId = userData.v2Id;
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [editingUser, setEditingUser] = useState(null);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
  });
  // State for data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [operatorData, setOperatorData] = useState(null);
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalUsers: 0,
    limit: 5,
  });
  const [actionLoading, setActionLoading] = useState(false);
  const history = useHistory();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [userToImpersonate, setUserToImpersonate] = useState(null);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error", // can be "error", "warning", "info", "success"
  });

  const API_BASE_URL = "https://backend.lazyapply.com/lazyapplyV2";

  const openSnackbar = (message, severity = "error") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  // Fetch users from the backend API
  const fetchUsers = async (page = 1, limit = 5) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/operator/users/${operatorId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: {
            page,
            limit,
          },
        }
      );

      if (response.data.success) {
        const { operator, users, pagination } = response.data.data;
        setOperatorData(operator);
        setUsers(users);
        setPagination({
          currentPage: parseInt(pagination.currentPage),
          totalPages: parseInt(pagination.totalPages),
          totalUsers: parseInt(pagination.totalUsers),
          limit: parseInt(pagination.limit),
        });
      } else {
        openSnackbar(response.data.message || "Failed to fetch data");
        setError(response.data.message || "Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      openSnackbar("An error occurred");
      setError(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (operatorId && token) {
      fetchUsers(pagination.currentPage, pagination.limit);
    }
  }, [operatorId, token]);

  // Pagination handlers
  const handleNextPage = () => {
    if (pagination.currentPage < pagination.totalPages) {
      const nextPage = pagination.currentPage + 1;
      fetchUsers(nextPage, pagination.limit);
    }
  };

  const handlePrevPage = () => {
    if (pagination.currentPage > 1) {
      const prevPage = pagination.currentPage - 1;
      fetchUsers(prevPage, pagination.limit);
    }
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    setNewUser({
      name: "",
      email: "",
    });
  };

  const handleOpenDeleteModal = (user) => {
    setCurrentUser(user);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setCurrentUser(null);
  };

  const handleDeleteUser = async () => {
    if (!currentUser) return;

    setActionLoading(true);
    try {
      const response = await axios.post(
        `${API_BASE_URL}/remove-user`,
        {
          operatorV2Id: operatorId,
          userV2Id: currentUser.v2Id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        // Refresh the user list after successful deletion
        fetchUsers(pagination.currentPage, pagination.limit);
        handleCloseDeleteModal();
      } else {
        openSnackbar(response.data.message || "Failed to delete user");
        setError(response.data.message || "Failed to delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      openSnackbar("An error occurred while deleting the user");
      setError(
        error.response?.data?.message ||
          "An error occurred while deleting the user"
      );
    } finally {
      setActionLoading(false);
    }
  };

  const handleAddUser = async () => {
    // Validate form
    if (!newUser.name || !newUser.email) {
      // In a real application, you would show validation errors
      setError("Name and email are required");
      return;
    }

    // Check user limit
    if (isUserLimitReached()) {
      setError("Cannot add more users. The maximum limit has been reached.");
      return;
    }

    setActionLoading(true);
    try {
      const response = await axios.post(
        `${API_BASE_URL}/assign-user`,
        {
          operatorV2Id: operatorId,
          email: newUser.email,
          name: newUser.name,
          subscriptionId: userData.subscriptionId || "default", // You may need to get this from user data
          permissions: ["apply_jobs", "view_analytics"],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        // Refresh the user list after successful addition
        if (response.data.data)
          setEnterpriseUser({
            users: [
              ...enterpriseUser.users,
              {
                v2Id: response.data.data.userV2Id,
                name: response.data.data.name,
                email: response.data.data.email,
              },
            ],
          });
        fetchUsers(pagination.currentPage, pagination.limit);
        handleCloseAddModal();
      } else {
        openSnackbar(response.data.message || "Failed to add user");
        setError(response.data.message || "Failed to add user");
      }
    } catch (error) {
      console.error("Error adding user:", error);
      openSnackbar("An error occurred while adding the user");
      setError(
        error.response?.data?.message ||
          "An error occurred while adding the user"
      );
    } finally {
      setActionLoading(false);
    }
  };

  const handleAccessModeChange = (mode, userId = null) => {
    accessModeHandler(mode, userId, {
      onSuccess: () => {
        console.log("successfully changed");
      },
    });
  };

  const handleApplyAsUser = (user) => {
    // Mock functionality for applying as user
    console.log("Applying as user:", user);
    // In a real application, you would redirect or change context
    //alert(`Now applying as user ID ${user}`);
    setUserToImpersonate(user);
    setConfirmModalOpen(true);

    // let userId = user.v2Id
    // handleAccessModeChange('user', userId)
  };

  const handleEditUser = () => {
    // Update the user in the users array
    setUsers(
      users.map((user) => (user.id === editingUser.id ? editingUser : user))
    );
    handleCloseEditModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({
      ...newUser,
      [name]: value,
    });
  };

  const handleModalClose = () => {
    setConfirmModalOpen(false);
    setUserToImpersonate(null);
  };

  const handleContinue = () => {
    if (userToImpersonate) {
      handleAccessModeChange("user", userToImpersonate.v2Id);
      setConfirmModalOpen(false);
      setUserToImpersonate(null);
      // history.push("/dashboard");
    }
  };

  const handleOpenEditModal = (user) => {
    setEditingUser({ ...user });
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setEditingUser(null);
  };

  // Determine user count color
  const getUserCountColor = (count) => {
    if (count === 0) return "red";
    if (count < 30) return "green";
    return "yellow";
  };

  const getInitials = (name) => {
    if (!name) return "";

    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  // Check if user limit is reached (100 users)
  const isUserLimitReached = () => {
    return pagination.totalUsers >= (planDetails?.licenses?.total || 100);
  };

  // Loading state - show while initially loading
  if (loading && !operatorData) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ display: "flex", justifyContent: "center", my: 10 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  // Error state - show if there's an error and no data
  if (error && !operatorData) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ my: 4 }}>
          <Typography color="error" variant="h6">
            Error: {error}
          </Typography>
          <Button
            variant="contained"
            onClick={() => fetchUsers(1, pagination.limit)}
            sx={{ mt: 2 }}
          >
            Try Again
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4, minHeight: "100vh" }}>
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h4"
            component="h1"
            sx={{ fontWeight: "bold", color: "#2d2d2d", mb: 1 }}
          >
            My Dashboard
          </Typography>

          {/* Operator info section */}
          {operatorData && (
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", mr: 4 }}>
                <EmailIcon sx={{ color: "#666", mr: 1 }} />
                <Typography variant="body1" sx={{ color: "#666" }}>
                  {operatorData.email}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PermissionIcon sx={{ color: "#2d8a2d", mr: 1 }} />
                <Box>
                  {operatorData.permission.includes("apply_jobs") && (
                    <PermissionChip label="Can apply for jobs" size="small" />
                  )}
                  {operatorData.permission.includes("view_analytics") && (
                    <PermissionChip label="Can view analytics" size="small" />
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            backgroundColor: "#f8f9fa",
            borderRadius: "12px",
            p: 2.5,
            mb: 5,
            mt: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <UserCountChip
              label={`${pagination.totalUsers}`}
              color={getUserCountColor(pagination.totalUsers)}
            />
            <Typography variant="body1" sx={{ ml: 1, fontSize: "16px" }}>
              Total users assigned
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            {/* <StatsBox sx={{ backgroundColor: "#ECFDF5" }}>
              <Typography
                variant="body2"
                sx={{ color: "#2d2d2d", fontSize: "16px" }}
              >
                Emails Sent:{" "}
                <strong>{currentOperatorState.analytics.emailsSent}</strong>
              </Typography>
            </StatsBox>

            <StatsBox sx={{ backgroundColor: "#FEF3C7" }}>
              <Typography
                variant="body2"
                sx={{ color: "#2d2d2d", fontSize: "16px" }}
              >
                Jobs Applied:{" "}
                <strong>{currentOperatorState.analytics.jobsApplied}</strong>
              </Typography>
            </StatsBox> */}

            <StyledButton
              variant="primary"
              startIcon={<AddIcon />}
              onClick={handleOpenAddModal}
              disabled={isUserLimitReached() || actionLoading}
              sx={{
                ...(isUserLimitReached() && {
                  opacity: 0.7,
                  cursor: "not-allowed",
                  "&:hover": {
                    backgroundColor: "#2d2d2d",
                  },
                }),
              }}
            >
              {isUserLimitReached() ? "User Limit Reached" : "Add User"}
            </StyledButton>
          </Box>
        </Box>

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", color: "#2d2d2d", mb: 3 }}
        >
          My Users
        </Typography>

        {loading && operatorData ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {/* Show error message if any */}
            {error && (
              <Typography color="error" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                borderRadius: "12px",
                overflow: "hidden",
              }}
            >
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "#f8f9fa",
                    borderBottom: "1px solid #eaeaea",
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        color: "#2d2d2d",
                        fontSize: "15px",
                        padding: "16px 20px",
                        width: "30%",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        color: "#2d2d2d",
                        fontSize: "15px",
                        padding: "16px 20px",
                        width: "30%",
                      }}
                    >
                      Email
                    </TableCell>
                    {/* <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#2d2d2d",
                    fontSize: "15px",
                    padding: "16px",
                  }}
                >
                  Emails Sent
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#2d2d2d",
                    fontSize: "15px",
                    padding: "16px",
                  }}
                >
                  Jobs Applied
                </TableCell> */}
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        color: "#2d2d2d",
                        fontSize: "15px",
                        padding: "16px 20px",
                        width: "40%",
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        sx={{ textAlign: "center", padding: "24px 16px" }}
                      >
                        <Typography variant="body1" color="text.secondary">
                          No users assigned to you yet. Add users using the "Add
                          User" button above.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    users.map((user) => (
                      <TableRow key={user.v2Id}>
                        <TableCell
                          sx={{
                            color: "#2d2d2d",
                            fontSize: "15px",
                            padding: "16px 20px",
                          }}
                        >
                          {user.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#2d2d2d",
                            fontSize: "15px",
                            padding: "16px 20px",
                          }}
                        >
                          {user.email}
                        </TableCell>
                        {/* <TableCell
                      sx={{
                        color: "#2d2d2d",
                        fontSize: "15px",
                        padding: "16px",
                      }}
                    >
                      {user.analytics.emailsSent}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#2d2d2d",
                        fontSize: "15px",
                        padding: "16px",
                      }}
                    >
                      {user.analytics.jobsApplied}
                    </TableCell> */}
                        <TableCell sx={{ padding: "16px 20px" }}>
                          <Box sx={{ display: "flex" }}>
                            <ActionButton
                              variant="primary"
                              onClick={() => handleApplyAsUser(user)}
                              disabled={actionLoading}
                            >
                              Apply as User
                            </ActionButton>

                            {/* <ActionButton onClick={() => handleOpenEditModal(user)}>
                          <EditIcon sx={{ fontSize: "18px", mr: 0.5 }} />
                          Edit
                        </ActionButton> */}

                            <ActionButton
                              variant="delete"
                              onClick={() => handleOpenDeleteModal(user)}
                              disabled={actionLoading}
                            >
                              <DeleteIcon sx={{ fontSize: "18px", mr: 0.5 }} />
                              Delete
                            </ActionButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {pagination.totalPages > 1 && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Button
              variant="outlined"
              disabled={pagination.currentPage === 1 || actionLoading}
              onClick={handlePrevPage}
              sx={{
                borderColor: "#eaeaea",
                color: "#2d2d2d",
                borderRadius: "12px",
                textTransform: "none",
                mx: 1,
                "&:hover": {
                  borderColor: "#d0d0d0",
                  backgroundColor: "#f8f9fa",
                },
              }}
            >
              ← Previous
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#eaeaea",
                color: "#2d2d2d",
                borderRadius: "12px",
                textTransform: "none",
                "&:hover": {
                  borderColor: "#d0d0d0",
                  backgroundColor: "#f8f9fa",
                },
              }}
            >
              Page {pagination.currentPage} of {pagination.totalPages}
            </Button>
            <Button
              variant="outlined"
              disabled={
                pagination.currentPage === pagination.totalPages ||
                actionLoading
              }
              onClick={handleNextPage}
              sx={{
                borderColor: "#eaeaea",
                color: "#2d2d2d",
                borderRadius: "12px",
                textTransform: "none",
                mx: 1,
                "&:hover": {
                  borderColor: "#d0d0d0",
                  backgroundColor: "#f8f9fa",
                },
              }}
            >
              Next →
            </Button>
          </Box>
        )}
      </Box>

      {/* Add User Modal */}
      <Dialog
        open={openAddModal}
        onClose={handleCloseAddModal}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            padding: "16px",
            boxShadow: "0 10px 25px rgba(0,0,0,0.2)",
            width: "500px",
            maxWidth: "90vw",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            fontSize: "22px",
            color: "#2d2d2d",
            pb: 1,
          }}
        >
          Add New User
        </DialogTitle>
        <DialogContent sx={{ pb: 2 }}>
          <StyledTextField
            autoFocus
            margin="normal"
            id="name"
            name="name"
            label="Full Name"
            type="text"
            fullWidth
            value={newUser.name}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
          <StyledTextField
            margin="normal"
            id="email"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={newUser.email}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={handleCloseAddModal}
            disabled={actionLoading}
            sx={{
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "16px",
              fontWeight: 550,
              textTransform: "none",
              color: "#2d2d2d",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddUser}
            variant="contained"
            disabled={actionLoading}
            sx={{
              backgroundColor: "#2d2d2d",
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "14px",
              fontWeight: 550,
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#3a3a3a",
              },
            }}
          >
            {actionLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Add User"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete User Confirmation Modal */}
      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            boxShadow: "0 10px 25px rgba(0,0,0,0.2)",
            padding: "8px",
            minWidth: "480px",
          },
        }}
      >
        <DialogTitle
          sx={{ color: "#d73a3a", fontWeight: "bold", fontSize: "24px" }}
        >
          Delete User
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 2 }}>
            {currentUser?.name}?
          </Typography>
          <Typography variant="body2" color="text.secondary">
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={handleCloseDeleteModal}
            disabled={actionLoading}
            sx={{
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "14px",
              fontWeight: 550,
              textTransform: "none",
              color: "#2d2d2d",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteUser}
            disabled={actionLoading}
            sx={{
              backgroundColor: "#d73a3a",
              padding: "8px 24px",
              fontSize: "14px",
              fontWeight: 550,
              color: "white",
              borderRadius: "12px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#c62828",
              },
            }}
          >
            {actionLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Delete"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit User Modal */}
      <Dialog
        open={openEditModal}
        onClose={handleCloseEditModal}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            padding: "16px",
            boxShadow: "0 10px 25px rgba(0,0,0,0.2)",
            width: "500px",
            maxWidth: "90vw",
          },
        }}
      >
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "22px", color: "#2d2d2d", pb: 1 }}
        >
          Edit User
        </DialogTitle>
        <DialogContent sx={{ pb: 2 }}>
          <StyledTextField
            autoFocus
            margin="normal"
            id="edit-name"
            name="name"
            label="Full Name"
            type="text"
            fullWidth
            value={editingUser?.name || ""}
            onChange={(e) =>
              setEditingUser({ ...editingUser, name: e.target.value })
            }
            sx={{ mb: 2 }}
          />
          <StyledTextField
            margin="normal"
            id="edit-email"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={editingUser?.email || ""}
            onChange={(e) =>
              setEditingUser({ ...editingUser, email: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={handleCloseEditModal}
            disabled={actionLoading}
            sx={{
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "16px",
              fontWeight: 550,
              textTransform: "none",
              color: "#2d2d2d",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleEditUser}
            variant="contained"
            disabled={actionLoading}
            sx={{
              backgroundColor: "#2d2d2d",
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "14px",
              fontWeight: 550,
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#3a3a3a",
              },
            }}
          >
            {actionLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Save Changes"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmModalOpen}
        onClose={handleModalClose}
        PaperProps={{
          sx: {
            borderRadius: "16px",
            boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
            overflow: "visible",
            maxWidth: "460px",
            margin: "16px",
            width: "100%",
          },
        }}
      >
        <Box sx={{ position: "relative", pt: 5, px: 3, pb: 3 }}>
          <Avatar
            sx={{
              bgcolor: "#4F46E5",
              width: 70,
              height: 70,
              fontSize: "28px",
              fontWeight: "600",
              position: "absolute",
              top: -35,
              left: "50%",
              transform: "translateX(-50%)",
              border: "4px solid white",
              boxShadow: "0 4px 14px rgba(0,0,0,0.1)",
            }}
          >
            {getInitials(userToImpersonate?.name || "")}
          </Avatar>

          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              right: 12,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogTitle
            id="access-mode-confirmation-dialog"
            sx={{
              textAlign: "center",
              pb: 1,
              pt: 3,
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
              User Access Activated
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Typography
              variant="body1"
              sx={{ textAlign: "center", color: "#6B7280" }}
            >
              You are now browsing and applying on behalf of{" "}
              {userToImpersonate?.name}. All actions will be performed as this
              user.
            </Typography>

            <Box
              sx={{
                mt: 3,
                p: 3,
                borderRadius: "12px",
                background: "linear-gradient(135deg, #F9FAFB 0%, #F3F4F6 100%)",
                border: "1px solid #E5E7EB",
                boxShadow:
                  "0 4px 6px -1px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.03)",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Avatar
                  sx={{
                    bgcolor: "#E0E0E0",
                    color: "#2D2D2D",
                    width: 45,
                    height: 45,
                  }}
                >
                  {getInitials(userToImpersonate?.name || "")}
                </Avatar>
                <Box>
                  <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                    {userToImpersonate?.name || ""}
                  </Typography>
                  <Typography sx={{ color: "#6B7280", fontSize: "14px" }}>
                    {userToImpersonate?.email || ""}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </DialogContent>

          <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
            <Button
              onClick={handleContinue}
              variant="contained"
              disableElevation
              sx={{
                background: "linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)",
                py: 1.25,
                px: 5,
                borderRadius: "8px",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "16px",
                boxShadow:
                  "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  background:
                    "linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)",
                  transform: "translateY(-2px)",
                  boxShadow:
                    "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                },
              }}
            >
              Continue
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{
            width: "100%",
            fontWeight: 500,
            borderRadius: "8px",
            boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default OperatorsView;
