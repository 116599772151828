// src/components/GoalPage.js
import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useStateStore } from "../../store";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInput-root": {
    fontSize: "1.25rem",
    "&:before": {
      borderBottom: "1px solid #E0E0E0",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "1.5px solid #2D2D2D",
    },
    "&:after": {
      borderBottom: "1.5px solid #666",
    },
    "&.Mui-focused:after": {
      borderBottom: "1.5px solid #2D2D2D",
    },
  },
  "& .MuiInput-input": {
    padding: "8px 0",
    "&::placeholder": {
      fontSize: "1.25rem",
    },
    "&:focus": {
      outline: "none", // Remove default focus outline
    },
  },
  "& .MuiFormHelperText-root": {
    fontSize: "0.875rem",
    marginTop: "4px",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#2D2D2D",
  color: "white",
  padding: "12px 32px", // Slightly larger padding
  borderRadius: "12px",
  textTransform: "none",
  fontSize: "1.125rem", // Increased font size
  fontWeight: 500,
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#666",
    transform: "translateY(-2px)",
    boxShadow: "0 4px 8px rgba(45, 45, 45, 0.2)",
  },
  "&.Mui-disabled": {
    backgroundColor: "rgba(45, 45, 45, 0.6)",
    color: "white",
  },
}));

const GoalPage = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    jobTitle: "",
    salary: "",
    country: "",
  });
  const history = useHistory();
  const [formData, setFormData] = useState({
    jobTitle: "",
    salary: "",
    country: "",
  });
  const { token, userData } = useStateStore();

  const validateField = (field, value) => {
    if (!value.trim()) {
      return `${
        field.charAt(0).toUpperCase() +
        field
          .slice(1)
          .replace(/([A-Z])/g, " $1")
          .trim()
      } is required`;
    }
    return "";
  };

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Clear error when user starts typing
    setErrors((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  const handleBlur = (field) => () => {
    const error = validateField(field, formData[field]);
    setErrors((prev) => ({
      ...prev,
      [field]: error,
    }));
  };

  const handleSubmit = async () => {
    const newErrors = {
      jobTitle: validateField("jobTitle", formData.jobTitle),
      salary: validateField("salary", formData.salary),
      country: validateField("country", formData.country),
    };

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error !== "")) {
      return;
    }

    try {
      setLoading(true);
      const v2Id = userData?.v2Id || "";
      const url = `https://backend.lazyapply.com/lazyapplyV2/goal/${v2Id}`;
      await axios.post(url, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Redirect on success
      history.push("/dashboard");
    } catch (error) {
      console.error("Error creating goal:", error);
      setErrors((prev) => ({
        ...prev,
        submit: error.response?.data?.message || "Failed to save goal",
      }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 3,
      }}
    >
      <Box sx={{ maxWidth: 800, width: "100%", mb: 6 }}>
        {" "}
        {/* Increased maxWidth */}
        <Typography
          variant="h2" // Larger heading
          align="center"
          gutterBottom
          sx={{
            mb: 8,
            fontWeight: 600,
            color: "#2D2D2D",
            "& .underline": {
              borderBottom: "3px solid #2D2D2D",
              paddingBottom: "4px",
            },
          }}
        >
          Tell u<span className="underline">s about you</span>r goal
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 4,
              "& .MuiTypography-root": {
                fontSize: "1.25rem",
                whiteSpace: "nowrap",
                color: "#666",
              },
            }}
          >
            <Typography sx={{ mr: 2 }}>I want a</Typography>
            <StyledTextField
              variant="standard"
              placeholder="Job Title ex. - Software Developer"
              fullWidth
              value={formData.jobTitle}
              onChange={handleInputChange("jobTitle")}
              onBlur={handleBlur("jobTitle")}
              error={!!errors.jobTitle}
              helperText={errors.jobTitle}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 4,
              "& .MuiTypography-root": {
                fontSize: "1.25rem",
                whiteSpace: "nowrap",
                color: "#666",
              },
            }}
          >
            <Typography sx={{ mr: 2 }}>role with salary more than</Typography>
            <StyledTextField
              variant="standard"
              placeholder="Salary ex. - 50k $"
              fullWidth
              value={formData.salary}
              onChange={handleInputChange("salary")}
              onBlur={handleBlur("salary")}
              error={!!errors.salary}
              helperText={errors.salary}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 4,
              "& .MuiTypography-root": {
                fontSize: "1.25rem",
                whiteSpace: "nowrap",
                color: "#666",
              },
            }}
          >
            <Typography sx={{ mr: 2 }}>in</Typography>
            <StyledTextField
              variant="standard"
              placeholder="Country ex. - United States"
              fullWidth
              value={formData.country}
              onChange={handleInputChange("country")}
              onBlur={handleBlur("country")}
              error={!!errors.country}
              helperText={errors.country}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center", mt: 8 }}>
            <StyledButton
              onClick={handleSubmit}
              size="large"
              disabled={loading}
            >
              {loading ? (
                <>
                  <CircularProgress
                    size={20}
                    sx={{
                      marginRight: "10px",
                      color: "white",
                    }}
                  />
                  Getting Started...
                </>
              ) : (
                "Get Started"
              )}
            </StyledButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GoalPage;
