// screens/SecondScreen.jsx
import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, Card, Radio } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion, AnimatePresence } from "framer-motion";

const OptionCard = styled(Card)(({ selected }) => ({
  padding: "16px 24px",
  cursor: "pointer",
  borderRadius: "12px",
  border: "2px solid",
  borderColor: selected ? "#4F46E5" : "#E5E7EB",
  backgroundColor: selected ? "rgba(79, 70, 229, 0.03)" : "white",
  transition: "all 0.15s ease",
  marginBottom: "12px",
  boxShadow: "none",
  minHeight: "80px", // Fixed minimum height
  display: "flex",
  alignItems: "center",
  "&:hover": {
    borderColor: selected ? "#4F46E5" : "#4F46E5",
    backgroundColor: selected
      ? "rgba(79, 70, 229, 0.03)"
      : "rgba(79, 70, 229, 0.02)",
  },
}));

const RadioOption = styled(Radio)({
  color: "#E5E7EB",
  "&.Mui-checked": {
    color: "#4F46E5",
  },
});

const MainOptionsContainer = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr", // Two columns of equal width
  gap: "12px",
  maxWidth: "600px",
  margin: "0 auto",
  width: "100%",
});

const GridContainer = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
  gap: "12px",
  maxWidth: "600px",
  margin: "0 auto",
  width: "100%",
});

const SecondScreen = ({ userData, setUserData, onNext }) => {
  // Check for both Organization and Education to preserve selection when navigating
  const [showRole, setShowRole] = useState(
    userData.applyingFor === "Organization" ||
      userData.applyingFor === "Education"
  );
  const roleRef = useRef(null);
  const isNewSelection = useRef(false);

  useEffect(() => {
    if (isNewSelection.current && userData.applyingFor) {
      if (userData.applyingFor === "Myself") {
        onNext();
        isNewSelection.current = false;
      }
    }
  }, [userData.applyingFor]);

  useEffect(() => {
    if (isNewSelection.current && userData.role) {
      onNext();
      isNewSelection.current = false;
    }
  }, [userData.role]);

  useEffect(() => {
    if (
      userData.applyingFor === "Organization" ||
      userData.applyingFor === "Education"
    ) {
      setShowRole(true);
      setTimeout(() => {
        roleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 200);
    } else {
      setShowRole(false);
    }
  }, [userData.applyingFor]);

  const handleApplyingForChange = (value) => {
    isNewSelection.current = true;
    setUserData((prev) => ({
      ...prev,
      applyingFor: value,
      role: "",
    }));
  };

  const handleRoleChange = (value) => {
    isNewSelection.current = true;
    setUserData((prev) => ({
      ...prev,
      role: value,
    }));
  };

  const applyingForOptions = [
    { value: "Myself", label: "For Myself" },
    { value: "Organization", label: "For My Organization" },
    { value: "Education", label: "For My Cohort" },
  ];

  const getRoleOptions = (type) => {
    if (type === "Organization") {
      return [
        { value: "Recruiter", label: "Recruiting Agency" },
        { value: "HR", label: "HR Department" },
        { value: "Career", label: "Career Development Team" },
      ];
    } else if (type === "Education") {
      return [
        { value: "University", label: "University, College" },
        { value: "Bootcamp", label: "Bootcamp Training Program" },
        { value: "Coach", label: "Career Coaching Service" },
      ];
    }
    return [];
  };

  // Faster animation variants
  const itemVariant = {
    hidden: { opacity: 0, y: 5 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.2 },
    },
  };

  const containerVariant = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
        duration: 0.2,
      },
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxWidth: "600px",
        mx: "auto",
        width: "100%",
        mt: 2,
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: { xs: "28px", md: "28px" },
            fontWeight: 600,
            color: "#111827",
            textAlign: "center",
            mb: 4,
          }}
        >
          Hi {userData.name}, so who are you applying for?
        </Typography>

        <motion.div
          variants={containerVariant}
          initial="hidden"
          animate="visible"
        >
          {/* First row - "For Myself" option taking full width */}
          <Box sx={{ maxWidth: "600px", margin: "0 auto 12px", width: "100%" }}>
            <motion.div
              key={applyingForOptions[0].value}
              variants={itemVariant}
            >
              <OptionCard
                selected={userData.applyingFor === applyingForOptions[0].value}
                onClick={() =>
                  handleApplyingForChange(applyingForOptions[0].value)
                }
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <RadioOption
                    checked={
                      userData.applyingFor === applyingForOptions[0].value
                    }
                    onChange={() => {}}
                  />
                  <Typography
                    sx={{
                      ml: 1,
                      fontSize: "16px",
                      fontWeight:
                        userData.applyingFor === applyingForOptions[0].value
                          ? 600
                          : 400,
                    }}
                  >
                    {applyingForOptions[0].label}
                  </Typography>
                </Box>
              </OptionCard>
            </motion.div>
          </Box>

          {/* Second row - Organization and Cohort options side by side */}
          <MainOptionsContainer>
            {applyingForOptions.slice(1).map((option) => (
              <motion.div key={option.value} variants={itemVariant}>
                <OptionCard
                  selected={userData.applyingFor === option.value}
                  onClick={() => handleApplyingForChange(option.value)}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <RadioOption
                      checked={userData.applyingFor === option.value}
                      onChange={() => {}}
                    />
                    <Typography
                      sx={{
                        ml: 1,
                        fontSize: "16px",
                        fontWeight:
                          userData.applyingFor === option.value ? 600 : 400,
                      }}
                    >
                      {option.label}
                    </Typography>
                  </Box>
                </OptionCard>
              </motion.div>
            ))}
          </MainOptionsContainer>
        </motion.div>

        <AnimatePresence>
          {showRole && (
            <motion.div
              ref={roleRef}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.25 }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: { xs: "24px", md: "28px" },
                  fontWeight: 600,
                  color: "#111827",
                  textAlign: "center",
                  mb: 3,
                  mt: 6,
                }}
              >
                What describes you best?
              </Typography>

              <motion.div
                variants={containerVariant}
                initial="hidden"
                animate="visible"
              >
                <GridContainer>
                  {getRoleOptions(userData.applyingFor).map((option) => (
                    <motion.div key={option.value} variants={itemVariant}>
                      <OptionCard
                        selected={userData.role === option.value}
                        onClick={() => handleRoleChange(option.value)}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <RadioOption
                            checked={userData.role === option.value}
                            onChange={() => {}}
                          />
                          <Typography
                            sx={{
                              ml: 1,
                              fontSize: "16px",
                              fontWeight:
                                userData.role === option.value ? 600 : 400,
                            }}
                          >
                            {option.label}
                          </Typography>
                        </Box>
                      </OptionCard>
                    </motion.div>
                  ))}
                </GridContainer>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </Box>
  );
};

export default SecondScreen;
