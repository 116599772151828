// DataPrivacyPolicy.jsx
import React from "react";
import {
  Box,
  Typography,
  Container,
  Paper,
  Divider,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const SectionTitle = styled(Typography)({
  color: "#2D2D2D",
  fontWeight: 600,
  fontSize: "22px",
  marginBottom: "16px",
});

const SubSection = styled(Typography)({
  color: "#2D2D2D",
  fontWeight: 600,
  fontSize: "18px",
  marginTop: "24px",
  marginBottom: "12px",
});

const ContentText = styled(Typography)({
  color: "#666666",
  fontSize: "16px",
  lineHeight: 1.6,
  marginBottom: "16px",
});

const DataPrivacyPolicy = () => {
  return (
    <Box sx={{ backgroundColor: "#fff", minHeight: "100vh", py: 4 }}>
      <Container maxWidth="lg">
        <Paper
          elevation={0}
          sx={{
            p: 4,
            borderRadius: "16px",
            border: "1px solid #e0e0e0",
          }}
        >
          <SectionTitle variant="h1">Data Usage & Privacy Policy</SectionTitle>

          <Box sx={{ mb: 4 }}>
            <SubSection variant="h2">Introduction:</SubSection>
            <ContentText>
              At Lazyapply, we prioritize your privacy and ensure that your data
              is handled with the utmost care. This policy provides detailed
              information about how we utilize and protect the data you entrust
              us with.
            </ContentText>
          </Box>

          <Box sx={{ mb: 4 }}>
            <SubSection variant="h2">Data Collection & Usage:</SubSection>
            <ContentText component="div">
              <ol style={{ paddingLeft: "20px" }}>
                <li style={{ marginBottom: "12px" }}>
                  <strong>Resume Data:</strong> We collect and process the
                  details provided in your resume. This includes your
                  educational background, work experience, skills, and other
                  related information.
                </li>
                <li>
                  <strong>Job Details:</strong> When you use Lazyapply to search
                  or apply for jobs, we process job-related data such as the job
                  title, location, and any other details you provide.
                </li>
              </ol>
            </ContentText>
          </Box>

          <Box sx={{ mb: 4 }}>
            <SubSection variant="h2">Purpose of Data Processing:</SubSection>
            <ContentText>
              The primary purpose of processing this data is to create
              personalized emails for HR outreach. By analyzing the details in
              your resume and the specific job you're interested in, our system,
              with the assistance of a third-party AI model, crafts a tailored
              email that increases the likelihood of a positive response from
              potential employers.
            </ContentText>
          </Box>

          <Box sx={{ mb: 4 }}>
            <SubSection variant="h2">Third-Party AI Model:</SubSection>
            <ContentText>
              To enhance the efficiency and accuracy of our email generation
              process, we utilize a third-party AI model. This model solely
              processes your resume and job data for the aforementioned purpose
              and doesn't retain any of your information post-processing.
            </ContentText>
          </Box>

          <Box sx={{ mb: 4 }}>
            <SubSection variant="h2">Data Protection:</SubSection>
            <ContentText>
              Your data is precious, and its confidentiality is paramount. We
              assure you that apart from the AI model processing, your data is
              not shared with any external entity. Our collaboration with the
              third-party AI model is strictly limited to generating
              personalized emails, and we have stringent measures in place to
              prevent any unauthorized access or use of your data.
            </ContentText>
          </Box>

          <Box sx={{ mb: 4 }}>
            <SubSection variant="h2">Your Rights & Control:</SubSection>
            <ContentText>
              You have complete control over your data. At any point, if you
              wish to update, retrieve, or delete your data from our system,
              please reach out to us, and we will assist you promptly.
            </ContentText>
          </Box>

          <Box sx={{ mb: 4 }}>
            <SubSection variant="h2">Updates & Changes:</SubSection>
            <ContentText>
              We may occasionally update this policy to reflect changes in our
              practices or for other operational, legal, or regulatory reasons.
              We recommend revisiting this section periodically to stay informed
              about our latest data handling practices.
            </ContentText>
          </Box>

          <Divider sx={{ my: 4 }} />

          <ContentText>
            For further queries or concerns about our data usage policy, please
            feel free to contact us at{" "}
            <Link href="mailto:info@lazyapply.com" sx={{ color: "#2D2D2D" }}>
              info@lazyapply.com
            </Link>
          </ContentText>
        </Paper>
      </Container>
    </Box>
  );
};

export default DataPrivacyPolicy;
