// components/sections/Certifications/EditMode.jsx
import React from "react";
import {
  Grid,
  Box,
  TextField,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    "&:hover fieldset": {
      borderColor: "#2D2D2D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2D2D2D",
      borderWidth: "1px",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E0E0E0",
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: "#2D2D2D",
    },
  },
};

const CertificationForm = ({
  certification,
  index,
  errors = {},
  touched = {},
  onChange,
  onBlur,
  onDelete,
}) => {
  const handleChange = (field) => (event) => {
    const value = event.target.value;
    const updatedCertification = {
      ...certification,
      [field]: value,
    };
    onChange(index, updatedCertification);
  };

  const handleBlur = (field) => () => {
    onBlur(index, field);
  };

  return (
    <Box
      sx={{
        mb: 4,
        pb: 4,
        borderBottom: "1px solid #E0E0E0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          mb: 2,
        }}
      >
        <Typography variant="h6" sx={{ color: "#2D2D2D" }}>
          Certification {index + 1}
        </Typography>
        <IconButton
          onClick={() => onDelete(index)}
          sx={{
            color: "#666666",
            "&:hover": {
              color: "#FF7875",
            },
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>

      <Grid container spacing={2.5}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Certification Name"
            value={certification.name || ""}
            onChange={handleChange("name")}
            onBlur={handleBlur("name")}
            error={touched?.name && !!errors?.name}
            helperText={touched?.name && errors?.name}
            variant="outlined"
            sx={textFieldStyles}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Issuing Organization"
            value={certification.issuer || ""}
            onChange={handleChange("issuer")}
            onBlur={handleBlur("issuer")}
            error={touched?.issuer && !!errors?.issuer}
            helperText={touched?.issuer && errors?.issuer}
            variant="outlined"
            sx={textFieldStyles}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Issue Date"
            type="month"
            value={certification.issueDate || ""}
            onChange={handleChange("issueDate")}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            sx={textFieldStyles}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Expiry Date"
            type="month"
            value={certification.expiryDate || ""}
            onChange={handleChange("expiryDate")}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            sx={textFieldStyles}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Credential ID"
            value={certification.credentialId || ""}
            onChange={handleChange("credentialId")}
            variant="outlined"
            sx={textFieldStyles}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Credential URL"
            value={certification.credentialUrl || ""}
            onChange={handleChange("credentialUrl")}
            variant="outlined"
            sx={textFieldStyles}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const EditMode = ({
  certifications,
  errors,
  touched,
  onChange,
  onBlur,
  onAdd,
  onDelete,
}) => {
  const handleFieldChange = (index, updatedCertification) => {
    const newCertifications = [...certifications];
    newCertifications[index] = updatedCertification;
    onChange(newCertifications);
  };

  return (
    <Box>
      {certifications.map((certification, index) => (
        <CertificationForm
          key={index}
          certification={certification}
          index={index}
          errors={errors[index]}
          touched={touched[index]}
          onChange={handleFieldChange}
          onBlur={onBlur}
          onDelete={onDelete}
        />
      ))}

      <Button
        startIcon={<AddIcon />}
        onClick={onAdd}
        sx={{
          mt: 2,
          color: "#2D2D2D",
          borderColor: "#2D2D2D",
          borderRadius: "8px",
          padding: "8px 16px",
          "&:hover": {
            borderColor: "#2D2D2D",
            backgroundColor: "rgba(45, 45, 45, 0.04)",
          },
        }}
        variant="outlined"
      >
        Add Another Certification
      </Button>
    </Box>
  );
};

export default EditMode;
