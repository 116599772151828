export const linkedinFilters = {
  workplace: {
    label: "Workplace",
    type: "dropdown",
    options: [
      {
        label: "On-site",
        value: "1",
      },
      {
        label: "Remote",
        value: "2",
      },
      {
        label: "Hybrid",
        value: "3",
      },
    ],
  },
  datePosted: {
    label: "Date Posted",
    type: "dropdown",
    options: [
      {
        value: "",
        label: "Any time",
      },
      {
        value: "r2592000",
        label: "Past month",
      },
      {
        value: "r604800",
        label: "Past week",
      },
      {
        value: "r86400",
        label: "Past 24 hours",
      },
    ],
  },
  jobType: {
    label: "Job Type",
    type: "dropdown",
    options: [
      {
        value: "F",
        label: "Full-time",
      },
      {
        value: "P",
        label: "Part-time",
      },
      {
        value: "C",
        label: "Contract",
      },
      {
        value: "T",
        label: "Temporary",
      },
      {
        value: "V",
        label: "Volunteer",
      },
      {
        value: "I",
        label: "Internship",
      },
    ],
  },
  experience: {
    label: "Experience Level",
    type: "dropdown",
    options: [
      {
        value: "1",
        label: "Internship",
      },
      {
        value: "2",
        label: "Entry level",
      },
      {
        value: "3",
        label: "Associate",
      },
      {
        value: "4",
        label: "Mid-Senior level",
      },
      {
        value: "5",
        label: "Director",
      },
      {
        value: "6",
        label: "Executive",
      },
    ],
  },
};
