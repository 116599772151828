import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  Dialog,
  Avatar,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory, useParams } from "react-router-dom";
import { useStateStore } from "../../../../store";
import axios from "axios";
import { useAccessModeHandler } from "../../../../utils/access-utils";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
// Styled components
const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "6px",
    fontSize: "1rem",
    backgroundColor: "#fff",
    "& fieldset": {
      borderColor: "#e0e0e0",
      borderWidth: "1px",
      transition: "all 0.2s ease",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2d2d2d",
      borderWidth: "1px",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "16px",
    "&::placeholder": {
      color: "#9ca3af",
      opacity: 1,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#6b7280",
    "&.Mui-focused": {
      color: "#2d2d2d",
    },
  },
  "& .MuiSelect-select": {
    padding: "16px",
  },
}));

const UserCountChip = styled(({ color, ...other }) => <Chip {...other} />)(
  ({ theme, color }) => ({
    borderRadius: "25px",
    padding: "2px",
    fontSize: "20px",
    height: "40px",
    fontWeight: 600,
    backgroundColor:
      color === "green"
        ? "#e6f7e6"
        : color === "yellow"
        ? "#fff8e6"
        : "#ffeded",
    color:
      color === "green"
        ? "#2d8a2d"
        : color === "yellow"
        ? "#e6ac00"
        : "#d73a3a",
    marginLeft: "8px",
  })
);

const StyledButton = styled(Button)(({ variant }) => ({
  borderRadius: "12px",
  padding: "8px 24px",
  fontSize: "16px",
  textTransform: "none",
  fontWeight: 550,
  boxShadow: "none",
  backgroundColor:
    variant === "delete"
      ? "#ffeded"
      : variant === "primary"
      ? "#2d2d2d"
      : "#f8f9fa",
  color:
    variant === "delete"
      ? "#d73a3a"
      : variant === "primary"
      ? "#ffffff"
      : "#2d2d2d",
  "&:hover": {
    backgroundColor:
      variant === "delete"
        ? "#ffe6e6"
        : variant === "primary"
        ? "#3a3a3a"
        : "#f0f0f0",
    boxShadow: "none",
  },
}));

const StatsBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  padding: "12px 16px",
  minWidth: "180px",
  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
}));

const ActionButton = styled(Button)(({ variant }) => ({
  borderRadius: "8px",
  padding: "6px 16px",
  fontSize: "14px",
  textTransform: "none",
  fontWeight: 550,
  marginRight: "8px",
  boxShadow: "none",
  backgroundColor:
    variant === "delete"
      ? "#ffeded"
      : variant === "primary"
      ? "#2d2d2d"
      : "#f8f9fa",
  color:
    variant === "delete"
      ? "#d73a3a"
      : variant === "primary"
      ? "#ffffff"
      : "#2d2d2d",
  "&:hover": {
    backgroundColor:
      variant === "delete"
        ? "#ffe6e6"
        : variant === "primary"
        ? "#3a3a3a"
        : "#f0f0f0",
    boxShadow: "none",
  },
}));

const OperatorUsers = () => {
  const { operatorId } = useParams();
  const history = useHistory();
  const { token, planDetails, enterpriseUser, setEnterpriseUser } =
    useStateStore();
  const { handleAccessModeChange: accessModeHandler } = useAccessModeHandler();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
  });
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(5);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [currentOperator, setCurrentOperator] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [formErrors, setFormErrors] = useState({});

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [userToImpersonate, setUserToImpersonate] = useState(null);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error", // can be "error", "warning", "info", "success"
  });

  // Backend API base URL
  const API_BASE_URL =
    process.env.REACT_APP_API_URL || "https://backend.lazyapply.com";

  useEffect(() => {
    if (!operatorId) return;
    fetchOperatorData();
    fetchUsers();
  }, [operatorId, currentPage]);

  const openSnackbar = (message, severity = "error") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const fetchOperatorData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_BASE_URL}/lazyapplyV2/operator/${operatorId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setCurrentOperator(response.data.data);
      } else {
        openSnackbar(response.data.message || "Failed to fetch operator data");
        setError("Failed to fetch operator data");
        setCurrentOperator(null);
      }
    } catch (error) {
      console.error("Error fetching operator data:", error);
      openSnackbar("Failed to fetch operator data. Please try again later.");
      setError("Failed to fetch operator data. Please try again later.");
      setCurrentOperator(null);
    }
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_BASE_URL}/lazyapplyV2/operator/users/${operatorId}`,
        {
          params: {
            page: currentPage,
            limit: usersPerPage,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setUsers(response.data.data.users);
        setTotalPages(response.data.data.pagination.totalPages);
        setTotalUsers(response.data.data.pagination.totalItems);

        // Update the current operator's assigned users count if needed
        if (
          currentOperator &&
          response.data.data.pagination.totalUsers !==
            currentOperator.assignedUsers
        ) {
          setCurrentOperator({
            ...currentOperator,
            assignedUsers: response.data.data.pagination.totalUsers,
          });
        }
      } else {
        setUsers([]);
        openSnackbar(response.data.message || "Failed to fetch users");
        setError("Failed to fetch users");
      }
    } catch (error) {
      console.error("Error fetching operator users:", error);
      openSnackbar("Failed to fetch users. Please try again later.");
      setError("Failed to fetch users. Please try again later.");
      setUsers([]);
    } finally {
      setLoading(false);
    }
  };

  // Validation function to check user limits
  const isUserLimitReached = () => {
    return (
      currentOperator?.assignedUsers >= (planDetails?.licenses?.total || 100)
    ); // 100 is the limit
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
    setFormErrors({});
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    setNewUser({
      name: "",
      email: "",
    });
    setFormErrors({});
  };

  const handleOpenDeleteModal = (user) => {
    setCurrentUser(user);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setCurrentUser(null);
  };

  const handleBack = () => {
    history.push("/dashboard/user-management");
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.name) errors.name = "Name is required";
    if (!data.email) errors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(data.email))
      errors.email = "Email is invalid";

    return errors;
  };

  const handleDeleteUser = async () => {
    try {
      console.log("current", currentUser);
      const response = await axios.post(
        `${API_BASE_URL}/lazyapplyV2/remove-user`,
        {
          userV2Id: currentUser.v2Id,
          operatorV2Id: operatorId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        // Refresh data
        fetchOperatorData();
        fetchUsers();
        handleCloseDeleteModal();
      } else {
        openSnackbar(response.data.message || "Failed to delete user");
        setError("Failed to delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      openSnackbar("Failed to delete user. Please try again later.");
      setError("Failed to delete user. Please try again later.");
    }
  };

  const handleAddUser = async () => {
    // Validate form
    const errors = validateForm(newUser);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Check user limit
    if (isUserLimitReached()) {
      setError("Cannot add more users. The maximum limit has been reached.");
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/lazyapplyV2/assign-user`,
        {
          ...newUser,
          subscriptionId: "sub12345",
          operatorV2Id: operatorId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        // Refresh data
        if (response.data.data)
          setEnterpriseUser({
            users: [
              ...enterpriseUser.users,
              {
                v2Id: response.data.data.userV2Id,
                name: response.data.data.name,
                email: response.data.data.email,
              },
            ],
          });
        fetchOperatorData();
        fetchUsers();
        handleCloseAddModal();
      } else {
        openSnackbar(response.data.message || "Failed to add user");
        setError(response.data.message || "Failed to add user");
      }
    } catch (error) {
      console.error("Error adding user:", error);
      openSnackbar("Failed to add user. Please try again later.");
      setError("Failed to add user. Please try again later.");
    }
  };

  const handleAccessModeChange = (mode, userId = null) => {
    accessModeHandler(mode, userId, {
      onSuccess: () => {
        console.log("successfully changed");
      },
    });
  };

  const handleApplyAsUser = async (user) => {
    // Mock functionality for applying as user
    console.log("Applying as user:", user);
    // In a real application, you would redirect or change context
    // alert(`Now applying as user ID ${user}`);

    setUserToImpersonate(user);
    setConfirmModalOpen(true);

    // let userId = user.v2Id
    // handleAccessModeChange('user',userId)
  };

  const getInitials = (name) => {
    if (!name) return "";
    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  const handleModalClose = () => {
    setConfirmModalOpen(false);
    setUserToImpersonate(null);
  };

  const handleContinue = () => {
    if (userToImpersonate) {
      let userId = userToImpersonate.v2Id;
      handleAccessModeChange("user", userId);
      setConfirmModalOpen(false);
      setUserToImpersonate(null);

      // history.push("/dashboard");
    }
  };

  const handleEditUser = async () => {
    // Validate form
    const errors = validateForm(editingUser);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      const response = await axios.put(
        `${API_BASE_URL}/lazyapplyV2/user/${editingUser.id}`,
        {
          name: editingUser.name,
          email: editingUser.email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        // Refresh data
        fetchUsers();
        handleCloseEditModal();
      } else {
        openSnackbar(response.data.message || "Failed to update user");
        setError(response.data.message || "Failed to update user");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      openSnackbar("Failed to update user. Please try again later.");
      setError("Failed to update user. Please try again later.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({
      ...newUser,
      [name]: value,
    });
    // Clear the specific error when user types
    if (formErrors[name]) {
      setFormErrors({
        ...formErrors,
        [name]: null,
      });
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingUser({
      ...editingUser,
      [name]: value,
    });
    // Clear the specific error when user types
    if (formErrors[name]) {
      setFormErrors({
        ...formErrors,
        [name]: null,
      });
    }
  };

  const handleOpenEditModal = (user) => {
    setEditingUser({ ...user });
    setOpenEditModal(true);
    setFormErrors({});
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setEditingUser(null);
    setFormErrors({});
  };

  // Determine user count color
  const getUserCountColor = (count) => {
    if (count === 0) return "red";
    if (count < 30) return "green";
    return "yellow";
  };

  if (loading && !currentOperator) {
    return (
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
          <Typography variant="h6" sx={{ ml: 2 }}>
            Loading...
          </Typography>
        </Box>
      </Container>
    );
  }

  if (error && !currentOperator && !loading) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ my: 4, textAlign: "center", minHeight: "100vh" }}>
          <Typography variant="h5" color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
            sx={{
              mt: 2,
              backgroundColor: "#f8f9fa",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: 550,
              borderRadius: "12px",
              padding: "8px 16px",
              color: "#2d2d2d",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            Back to User Management
          </Button>
        </Box>
      </Container>
    );
  }

  if (!currentOperator && !loading) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ my: 4, textAlign: "center", minHeight: "100vh" }}>
          <Typography variant="h4" color="text.secondary">
            Operator not found
          </Typography>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
            sx={{
              mt: 2,
              backgroundColor: "#f8f9fa",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: 550,
              borderRadius: "12px",
              padding: "8px 16px",
              color: "#2d2d2d",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            Back to User Management
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4, minHeight: "100vh" }}>
        <Box sx={{ mb: 3 }}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
            sx={{
              mb: 6,
              color: "#666",
              textTransform: "none",
              fontSize: "16px",
              "&:hover": {
                background: "none",
                color: "#000",
              },
            }}
          >
            Back to User Management
          </Button>
          <Typography
            variant="h4"
            component="h1"
            sx={{ fontWeight: "bold", color: "#2d2d2d", ml: 1 }}
          >
            {currentOperator?.name || ""}
          </Typography>
        </Box>

        {error && (
          <Box
            sx={{
              backgroundColor: "#ffeded",
              p: 2,
              borderRadius: "8px",
              mb: 3,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography color="error">{error}</Typography>
            <Button
              onClick={() => setError(null)}
              sx={{ minWidth: "auto", p: 1 }}
            >
              ✕
            </Button>
          </Box>
        )}

        <Box
          sx={{
            backgroundColor: "#f8f9fa",
            borderRadius: "12px",
            p: 2.5,
            mb: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <UserCountChip
              label={`${currentOperator?.assignedUsers || 0}`}
              color={getUserCountColor(currentOperator?.assignedUsers || 0)}
            />
            <Typography variant="body1" sx={{ ml: 1, fontSize: "16px" }}>
              Total users assigned
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            {/* <StatsBox sx={{ backgroundColor: "#ECFDF5" }}>
              <Typography
                variant="body2"
                sx={{ color: "#2d2d2d", fontSize: "16px" }}
              >
                Emails Sent:{" "}
                <strong>{currentOperatorState.analytics.emailsSent}</strong>
              </Typography>
            </StatsBox> */}

            {/* <StatsBox sx={{ backgroundColor: "#FEF3C7" }}>
              <Typography
                variant="body2"
                sx={{ color: "#2d2d2d", fontSize: "16px" }}
              >
                Jobs Applied:{" "}
                <strong>{currentOperatorState.analytics.jobsApplied}</strong>
              </Typography>
            </StatsBox> */}

            <StyledButton
              variant="primary"
              startIcon={<AddIcon />}
              onClick={handleOpenAddModal}
              disabled={isUserLimitReached()}
              sx={{
                ...(isUserLimitReached() && {
                  opacity: 0.7,
                  cursor: "not-allowed",
                  "&:hover": {
                    backgroundColor: "#2d2d2d",
                  },
                }),
              }}
            >
              {isUserLimitReached() ? "User Limit Reached" : "Add User"}
            </StyledButton>
          </Box>
        </Box>

        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            borderRadius: "12px",
            overflow: "hidden",
          }}
        >
          <Table>
            <TableHead
              sx={{
                backgroundColor: "#f8f9fa",
                borderBottom: "1px solid #eaeaea",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#2d2d2d",
                    fontSize: "15px",
                    padding: "16px 20px",
                    width: "30%",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#2d2d2d",
                    fontSize: "15px",
                    padding: "16px 20px",
                    width: "30%",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#2d2d2d",
                    fontSize: "15px",
                    padding: "16px 20px",
                    width: "40%",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell
                    colSpan={3}
                    sx={{ textAlign: "center", padding: "24px 16px" }}
                  >
                    <CircularProgress size={24} sx={{ mr: 1 }} />
                    <Typography variant="body1" display="inline">
                      Loading users...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : users.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={3}
                    sx={{ textAlign: "center", padding: "24px 16px" }}
                  >
                    <Typography variant="body1" color="text.secondary">
                      No users assigned to this operator yet.
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell
                      sx={{
                        color: "#2d2d2d",
                        fontSize: "15px",
                        padding: "16px 20px",
                      }}
                    >
                      {user.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#2d2d2d",
                        fontSize: "15px",
                        padding: "16px 20px",
                      }}
                    >
                      {user.email}
                    </TableCell>
                    <TableCell sx={{ padding: "16px 20px" }}>
                      <Box sx={{ display: "flex" }}>
                        <ActionButton
                          variant="primary"
                          onClick={() => handleApplyAsUser(user)}
                        >
                          Apply as User
                        </ActionButton>

                        {/* <ActionButton onClick={() => handleOpenEditModal(user)}>
                          <EditIcon sx={{ fontSize: "18px", mr: 0.5 }} />
                          Edit
                        </ActionButton> */}

                        <ActionButton
                          variant="delete"
                          onClick={() => handleOpenDeleteModal(user)}
                        >
                          <DeleteIcon sx={{ fontSize: "18px", mr: 0.5 }} />
                          Delete
                        </ActionButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {totalPages > 1 && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Button
              variant="outlined"
              disabled={currentPage === 1}
              onClick={handlePrevPage}
              sx={{
                borderColor: "#eaeaea",
                color: "#2d2d2d",
                borderRadius: "12px",
                textTransform: "none",
                mx: 1,
                "&:hover": {
                  borderColor: "#d0d0d0",
                  backgroundColor: "#f8f9fa",
                },
              }}
            >
              ← Previous
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#eaeaea",
                color: "#2d2d2d",
                borderRadius: "12px",
                textTransform: "none",
                "&:hover": {
                  borderColor: "#d0d0d0",
                  backgroundColor: "#f8f9fa",
                },
              }}
            >
              Page {currentPage} of {totalPages}
            </Button>
            <Button
              variant="outlined"
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
              sx={{
                borderColor: "#eaeaea",
                color: "#2d2d2d",
                borderRadius: "12px",
                textTransform: "none",
                mx: 1,
                "&:hover": {
                  borderColor: "#d0d0d0",
                  backgroundColor: "#f8f9fa",
                },
              }}
            >
              Next →
            </Button>
          </Box>
        )}
      </Box>

      {/* Add User Modal */}
      <Dialog
        open={openAddModal}
        onClose={handleCloseAddModal}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            padding: "16px",
            boxShadow: "0 10px 25px rgba(0,0,0,0.2)",
            width: "500px",
            maxWidth: "90vw",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            fontSize: "22px",
            color: "#2d2d2d",
            pb: 1,
          }}
        >
          Add New User
        </DialogTitle>
        <DialogContent sx={{ pb: 2 }}>
          <StyledTextField
            autoFocus
            margin="normal"
            id="name"
            name="name"
            label="Full Name"
            type="text"
            fullWidth
            value={newUser.name}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
            error={Boolean(formErrors.name)}
            helperText={formErrors.name}
          />
          <StyledTextField
            margin="normal"
            id="email"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={newUser.email}
            onChange={handleInputChange}
            error={Boolean(formErrors.email)}
            helperText={formErrors.email}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={handleCloseAddModal}
            sx={{
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "16px",
              fontWeight: 550,
              textTransform: "none",
              color: "#2d2d2d",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddUser}
            variant="contained"
            sx={{
              backgroundColor: "#2d2d2d",
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "14px",
              fontWeight: 550,
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#3a3a3a",
              },
            }}
          >
            Add User
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete User Confirmation Modal */}
      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            boxShadow: "0 10px 25px rgba(0,0,0,0.2)",
            padding: "8px",
            minWidth: "480px",
          },
        }}
      >
        <DialogTitle
          sx={{ color: "#d73a3a", fontWeight: "bold", fontSize: "24px" }}
        >
          Delete User
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 2 }}>
            {currentUser?.name}?
          </Typography>
          <Typography variant="body2" color="text.secondary">
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={handleCloseDeleteModal}
            sx={{
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "14px",
              fontWeight: 550,
              textTransform: "none",
              color: "#2d2d2d",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteUser}
            sx={{
              backgroundColor: "#d73a3a",
              padding: "8px 24px",
              fontSize: "14px",
              fontWeight: 550,
              color: "white",
              borderRadius: "12px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#c62828",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit User Modal */}
      <Dialog
        open={openEditModal}
        onClose={handleCloseEditModal}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            padding: "16px",
            boxShadow: "0 10px 25px rgba(0,0,0,0.2)",
            width: "500px",
            maxWidth: "90vw",
          },
        }}
      >
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "22px", color: "#2d2d2d", pb: 1 }}
        >
          Edit User
        </DialogTitle>
        <DialogContent sx={{ pb: 2 }}>
          <StyledTextField
            autoFocus
            margin="normal"
            id="edit-name"
            name="name"
            label="Full Name"
            type="text"
            fullWidth
            value={editingUser?.name || ""}
            onChange={handleEditInputChange}
            sx={{ mb: 2 }}
            error={Boolean(formErrors.name)}
            helperText={formErrors.name}
          />
          <StyledTextField
            margin="normal"
            id="edit-email"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={editingUser?.email || ""}
            onChange={handleEditInputChange}
            error={Boolean(formErrors.email)}
            helperText={formErrors.email}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={handleCloseEditModal}
            sx={{
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "16px",
              fontWeight: 550,
              textTransform: "none",
              color: "#2d2d2d",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleEditUser}
            variant="contained"
            sx={{
              backgroundColor: "#2d2d2d",
              borderRadius: "12px",
              padding: "8px 24px",
              fontSize: "14px",
              fontWeight: 550,
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#3a3a3a",
              },
            }}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmModalOpen}
        onClose={handleModalClose}
        PaperProps={{
          sx: {
            borderRadius: "16px",
            boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
            overflow: "visible",
            maxWidth: "460px",
            margin: "16px",
            width: "100%",
          },
        }}
      >
        <Box sx={{ position: "relative", pt: 5, px: 3, pb: 3 }}>
          <Avatar
            sx={{
              bgcolor: "#4F46E5",
              width: 70,
              height: 70,
              fontSize: "28px",
              fontWeight: "600",
              position: "absolute",
              top: -35,
              left: "50%",
              transform: "translateX(-50%)",
              border: "4px solid white",
              boxShadow: "0 4px 14px rgba(0,0,0,0.1)",
            }}
          >
            {getInitials(userToImpersonate?.name || "")}
          </Avatar>

          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              right: 12,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogTitle
            id="access-mode-confirmation-dialog"
            sx={{
              textAlign: "center",
              pb: 1,
              pt: 3,
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
              User Access Activated
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Typography
              variant="body1"
              sx={{ textAlign: "center", color: "#6B7280" }}
            >
              You are now browsing and applying on behalf of{" "}
              {userToImpersonate?.name}. All actions will be performed as this
              user.
            </Typography>

            <Box
              sx={{
                mt: 3,
                p: 3,
                borderRadius: "12px",
                background: "linear-gradient(135deg, #F9FAFB 0%, #F3F4F6 100%)",
                border: "1px solid #E5E7EB",
                boxShadow:
                  "0 4px 6px -1px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.03)",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Avatar
                  sx={{
                    bgcolor: "#E0E0E0",
                    color: "#2D2D2D",
                    width: 45,
                    height: 45,
                  }}
                >
                  {getInitials(userToImpersonate?.name || "")}
                </Avatar>
                <Box>
                  <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                    {userToImpersonate?.name || ""}
                  </Typography>
                  <Typography sx={{ color: "#6B7280", fontSize: "14px" }}>
                    {userToImpersonate?.email || ""}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </DialogContent>

          <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
            <Button
              onClick={handleContinue}
              variant="contained"
              disableElevation
              sx={{
                background: "linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)",
                py: 1.25,
                px: 5,
                borderRadius: "8px",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "16px",
                boxShadow:
                  "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  background:
                    "linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)",
                  transform: "translateY(-2px)",
                  boxShadow:
                    "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                },
              }}
            >
              Continue
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{
            width: "100%",
            fontWeight: 500,
            borderRadius: "8px",
            boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default OperatorUsers;
