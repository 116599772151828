// components/sections/PersonalInformation/constants.js
export const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
  { value: "Prefer not to say", label: "Prefer not to say" },
];

export const countryOptions = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "American Samoa", label: "American Samoa" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Anguilla", label: "Anguilla" },
  { value: "Armenia", label: "Armenia" },
  { value: "Aruba", label: "Aruba" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bermuda", label: "Bermuda" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Brazil", label: "Brazil" },
  { value: "Brunei", label: "Brunei" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Cape Verde", label: "Cape Verde" },
  { value: "Cayman Islands", label: "Cayman Islands" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  { value: "Cook Islands", label: "Cook Islands" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czech Republic", label: "Czech Republic" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Faroe Islands", label: "Faroe Islands" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Gibraltar", label: "Gibraltar" },
  { value: "Greece", label: "Greece" },
  { value: "Greenland", label: "Greenland" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guadeloupe", label: "Guadeloupe" },
  { value: "Guam", label: "Guam" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Korea South", label: "Korea South" },
  { value: "Korea North", label: "Korea North" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Laos", label: "Laos" },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Macao", label: "Macao" },
  { value: "Macedonia", label: "Macedonia" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Martinique", label: "Martinique" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mexico", label: "Mexico" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Caledonia", label: "New Caledonia" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Niue", label: "Niue" },
  { value: "Norfolk Island", label: "Norfolk Island" },
  { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Palestine", label: "Palestine" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Qatar", label: "Qatar" },
  { value: "Reunion", label: "Reunion" },
  { value: "Romania", label: "Romania" },
  { value: "Russia", label: "Russia" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Swaziland", label: "Swaziland" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syria", label: "Syria" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Togo", label: "Togo" },
  { value: "Tokelau", label: "Tokelau" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States", label: "United States" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Virgin Islands (British)", label: "Virgin Islands (British)" },
  { value: "Virgin Islands (U.S.)", label: "Virgin Islands (U.S.)" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
];

export const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    "&:hover fieldset": {
      borderColor: "#2D2D2D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2D2D2D",
      borderWidth: "1px",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E0E0E0",
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: "#2D2D2D",
    },
  },
};

export const countryCodeOptions = [
  { value: "+93", label: "Afghanistan (+93)" },
  { value: "+358", label: "Aland Islands (+358)" },
  { value: "+355", label: "Albania (+355)" },
  { value: "+213", label: "Algeria (+213)" },
  { value: "+1", label: "American Samoa (+1)" },
  { value: "+376", label: "Andorra (+376)" },
  { value: "+244", label: "Angola (+244)" },
  { value: "+1", label: "Anguilla (+1)" },
  { value: "+374", label: "Armenia (+374)" },
  { value: "+297", label: "Aruba (+297)" },
  { value: "+61", label: "Australia (+61)" },
  { value: "+43", label: "Austria (+43)" },
  { value: "+994", label: "Azerbaijan (+994)" },
  { value: "+1", label: "Bahamas (+1)" },
  { value: "+973", label: "Bahrain (+973)" },
  { value: "+880", label: "Bangladesh (+880)" },
  { value: "+1", label: "Barbados (+1)" },
  { value: "+375", label: "Belarus (+375)" },
  { value: "+32", label: "Belgium (+32)" },
  { value: "+501", label: "Belize (+501)" },
  { value: "+229", label: "Benin (+229)" },
  { value: "+1", label: "Bermuda (+1)" },
  { value: "+975", label: "Bhutan (+975)" },
  { value: "+591", label: "Bolivia (+591)" },
  { value: "+387", label: "Bosnia and Herzegovina (+387)" },
  { value: "+267", label: "Botswana (+267)" },
  { value: "+55", label: "Brazil (+55)" },
  { value: "+673", label: "Brunei (+673)" },
  { value: "+359", label: "Bulgaria (+359)" },
  { value: "+226", label: "Burkina Faso (+226)" },
  { value: "+257", label: "Burundi (+257)" },
  { value: "+855", label: "Cambodia (+855)" },
  { value: "+237", label: "Cameroon (+237)" },
  { value: "+1", label: "Canada (+1)" },
  { value: "+238", label: "Cape Verde (+238)" },
  { value: "+1", label: "Cayman Islands (+1)" },
  { value: "+236", label: "Central African Republic (+236)" },
  { value: "+235", label: "Chad (+235)" },
  { value: "+56", label: "Chile (+56)" },
  { value: "+86", label: "China (+86)" },
  { value: "+57", label: "Colombia (+57)" },
  { value: "+269", label: "Comoros (+269)" },
  { value: "+242", label: "Congo (+242)" },
  { value: "+682", label: "Cook Islands (+682)" },
  { value: "+506", label: "Costa Rica (+506)" },
  { value: "+385", label: "Croatia (+385)" },
  { value: "+53", label: "Cuba (+53)" },
  { value: "+357", label: "Cyprus (+357)" },
  { value: "+420", label: "Czech Republic (+420)" },
  { value: "+45", label: "Denmark (+45)" },
  { value: "+253", label: "Djibouti (+253)" },
  { value: "+1", label: "Dominica (+1)" },
  { value: "+1", label: "Dominican Republic (+1)" },
  { value: "+593", label: "Ecuador (+593)" },
  { value: "+20", label: "Egypt (+20)" },
  { value: "+503", label: "El Salvador (+503)" },
  { value: "+240", label: "Equatorial Guinea (+240)" },
  { value: "+291", label: "Eritrea (+291)" },
  { value: "+372", label: "Estonia (+372)" },
  { value: "+251", label: "Ethiopia (+251)" },
  { value: "+298", label: "Faroe Islands (+298)" },
  { value: "+679", label: "Fiji (+679)" },
  { value: "+358", label: "Finland (+358)" },
  { value: "+33", label: "France (+33)" },
  { value: "+241", label: "Gabon (+241)" },
  { value: "+220", label: "Gambia (+220)" },
  { value: "+995", label: "Georgia (+995)" },
  { value: "+49", label: "Germany (+49)" },
  { value: "+233", label: "Ghana (+233)" },
  { value: "+350", label: "Gibraltar (+350)" },
  { value: "+30", label: "Greece (+30)" },
  { value: "+299", label: "Greenland (+299)" },
  { value: "+1", label: "Grenada (+1)" },
  { value: "+590", label: "Guadeloupe (+590)" },
  { value: "+1", label: "Guam (+1)" },
  { value: "+502", label: "Guatemala (+502)" },
  { value: "+224", label: "Guinea (+224)" },
  { value: "+245", label: "Guinea-Bissau (+245)" },
  { value: "+592", label: "Guyana (+592)" },
  { value: "+509", label: "Haiti (+509)" },
  { value: "+504", label: "Honduras (+504)" },
  { value: "+852", label: "Hong Kong (+852)" },
  { value: "+36", label: "Hungary (+36)" },
  { value: "+354", label: "Iceland (+354)" },
  { value: "+91", label: "India (+91)" },
  { value: "+62", label: "Indonesia (+62)" },
  { value: "+98", label: "Iran (+98)" },
  { value: "+964", label: "Iraq (+964)" },
  { value: "+353", label: "Ireland (+353)" },
  { value: "+972", label: "Israel (+972)" },
  { value: "+39", label: "Italy (+39)" },
  { value: "+1", label: "Jamaica (+1)" },
  { value: "+81", label: "Japan (+81)" },
  { value: "+962", label: "Jordan (+962)" },
  { value: "+7", label: "Kazakhstan (+7)" },
  { value: "+254", label: "Kenya (+254)" },
  { value: "+686", label: "Kiribati (+686)" },
  { value: "+82", label: "Korea South (+82)" },
  { value: "+850", label: "Korea North (+850)" },
  { value: "+965", label: "Kuwait (+965)" },
  { value: "+996", label: "Kyrgyzstan (+996)" },
  { value: "+856", label: "Laos (+856)" },
  { value: "+371", label: "Latvia (+371)" },
  { value: "+961", label: "Lebanon (+961)" },
  { value: "+266", label: "Lesotho (+266)" },
  { value: "+231", label: "Liberia (+231)" },
  { value: "+218", label: "Libya (+218)" },
  { value: "+423", label: "Liechtenstein (+423)" },
  { value: "+370", label: "Lithuania (+370)" },
  { value: "+352", label: "Luxembourg (+352)" },
  { value: "+853", label: "Macao (+853)" },
  { value: "+389", label: "Macedonia (+389)" },
  { value: "+261", label: "Madagascar (+261)" },
  { value: "+265", label: "Malawi (+265)" },
  { value: "+60", label: "Malaysia (+60)" },
  { value: "+960", label: "Maldives (+960)" },
  { value: "+223", label: "Mali (+223)" },
  { value: "+356", label: "Malta (+356)" },
  { value: "+692", label: "Marshall Islands (+692)" },
  { value: "+596", label: "Martinique (+596)" },
  { value: "+222", label: "Mauritania (+222)" },
  { value: "+230", label: "Mauritius (+230)" },
  { value: "+52", label: "Mexico (+52)" },
  { value: "+373", label: "Moldova (+373)" },
  { value: "+377", label: "Monaco (+377)" },
  { value: "+976", label: "Mongolia (+976)" },
  { value: "+382", label: "Montenegro (+382)" },
  { value: "+1", label: "Montserrat (+1)" },
  { value: "+212", label: "Morocco (+212)" },
  { value: "+258", label: "Mozambique (+258)" },
  { value: "+95", label: "Myanmar (+95)" },
  { value: "+264", label: "Namibia (+264)" },
  { value: "+674", label: "Nauru (+674)" },
  { value: "+977", label: "Nepal (+977)" },
  { value: "+31", label: "Netherlands (+31)" },
  { value: "+687", label: "New Caledonia (+687)" },
  { value: "+64", label: "New Zealand (+64)" },
  { value: "+505", label: "Nicaragua (+505)" },
  { value: "+227", label: "Niger (+227)" },
  { value: "+234", label: "Nigeria (+234)" },
  { value: "+683", label: "Niue (+683)" },
  { value: "+672", label: "Norfolk Island (+672)" },
  { value: "+1", label: "Northern Mariana Islands (+1)" },
  { value: "+47", label: "Norway (+47)" },
  { value: "+968", label: "Oman (+968)" },
  { value: "+92", label: "Pakistan (+92)" },
  { value: "+680", label: "Palau (+680)" },
  { value: "+970", label: "Palestine (+970)" },
  { value: "+507", label: "Panama (+507)" },
  { value: "+675", label: "Papua New Guinea (+675)" },
  { value: "+595", label: "Paraguay (+595)" },
  { value: "+51", label: "Peru (+51)" },
  { value: "+63", label: "Philippines (+63)" },
  { value: "+48", label: "Poland (+48)" },
  { value: "+351", label: "Portugal (+351)" },
  { value: "+1", label: "Puerto Rico (+1)" },
  { value: "+974", label: "Qatar (+974)" },
  { value: "+262", label: "Reunion (+262)" },
  { value: "+40", label: "Romania (+40)" },
  { value: "+7", label: "Russia (+7)" },
  { value: "+250", label: "Rwanda (+250)" },
  { value: "+1", label: "Saint Kitts and Nevis (+1)" },
  { value: "+1", label: "Saint Lucia (+1)" },
  { value: "+1", label: "Saint Vincent and the Grenadines (+1)" },
  { value: "+685", label: "Samoa (+685)" },
  { value: "+378", label: "San Marino (+378)" },
  { value: "+966", label: "Saudi Arabia (+966)" },
  { value: "+221", label: "Senegal (+221)" },
  { value: "+381", label: "Serbia (+381)" },
  { value: "+248", label: "Seychelles (+248)" },
  { value: "+232", label: "Sierra Leone (+232)" },
  { value: "+65", label: "Singapore (+65)" },
  { value: "+421", label: "Slovakia (+421)" },
  { value: "+386", label: "Slovenia (+386)" },
  { value: "+677", label: "Solomon Islands (+677)" },
  { value: "+252", label: "Somalia (+252)" },
  { value: "+27", label: "South Africa (+27)" },
  { value: "+211", label: "South Sudan (+211)" },
  { value: "+34", label: "Spain (+34)" },
  { value: "+94", label: "Sri Lanka (+94)" },
  { value: "+249", label: "Sudan (+249)" },
  { value: "+597", label: "Suriname (+597)" },
  { value: "+268", label: "Swaziland (+268)" },
  { value: "+46", label: "Sweden (+46)" },
  { value: "+41", label: "Switzerland (+41)" },
  { value: "+963", label: "Syria (+963)" },
  { value: "+886", label: "Taiwan (+886)" },
  { value: "+992", label: "Tajikistan (+992)" },
  { value: "+255", label: "Tanzania (+255)" },
  { value: "+66", label: "Thailand (+66)" },
  { value: "+228", label: "Togo (+228)" },
  { value: "+690", label: "Tokelau (+690)" },
  { value: "+676", label: "Tonga (+676)" },
  { value: "+1", label: "Trinidad and Tobago (+1)" },
  { value: "+216", label: "Tunisia (+216)" },
  { value: "+90", label: "Turkey (+90)" },
  { value: "+993", label: "Turkmenistan (+993)" },
  { value: "+1", label: "Turks and Caicos Islands (+1)" },
  { value: "+688", label: "Tuvalu (+688)" },
  { value: "+256", label: "Uganda (+256)" },
  { value: "+380", label: "Ukraine (+380)" },
  { value: "+971", label: "United Arab Emirates (+971)" },
  { value: "+44", label: "United Kingdom (+44)" },
  { value: "+1", label: "United States (+1)" },
  { value: "+598", label: "Uruguay (+598)" },
  { value: "+998", label: "Uzbekistan (+998)" },
  { value: "+678", label: "Vanuatu (+678)" },
  { value: "+58", label: "Venezuela (+58)" },
  { value: "+84", label: "Vietnam (+84)" },
  { value: "+1", label: "Virgin Islands (British) (+1)" },
  { value: "+1", label: "Virgin Islands (U.S.) (+1)" },
  { value: "+967", label: "Yemen (+967)" },
  { value: "+260", label: "Zambia (+260)" },
  { value: "+263", label: "Zimbabwe (+263)" },
].map((country) => {
  const countryName = country.label.split(" (")[0];
  const value = `${countryName
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "_")}_${country.value.replace("+", "")}`;

  return {
    value,
    label: country.label,
    dialCode: country.value,
  };
});
