export const diceFilters = {
  employer: {
    label: "Employer Type",
    options: [
      {
        value: "Direct Hire",
        label: "Direct Hire",
      },
      {
        value: "Recruiter",
        label: "Recruiter",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
  },
  employmentType: {
    label: "Employment Type",
    options: [
      {
        value: "FULLTIME",
        label: "Full-Time",
      },
      {
        value: "PARTTIME",
        label: "Part-Time",
      },
      {
        value: "CONTRACTS",
        label: "Contract",
      },
      {
        value: "THIRD_PARTY",
        label: "Third Party",
      },
    ],
  },
  datePosted: {
    label: "Date Posted",
    options: [
      {
        value: "",
        label: "Any Date",
      },
      {
        value: "ONE",
        label: "Today",
      },
      {
        value: "THREE",
        label: "Last 3 Days",
      },
      {
        value: "SEVEN",
        label: "Last 7 Days",
      },
    ],
  },
  remote: {
    label: "Remote Jobs",
    options: [
      {
        value: "",
        label: "-",
      },
      {
        value: "true",
        label: "Yes",
      },
    ],
  },
};
