// components/sections/BaseSection.jsx
import React, { useState } from "react";
import {
  Paper,
  Box,
  Typography,
  IconButton,
  Collapse,
  Button,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Tooltip } from "@mui/material";

const SectionCard = styled(Paper)(() => ({
  borderRadius: "16px",
  marginBottom: "16px",
  overflow: "hidden",
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
}));

const SectionHeader = styled(Box)(() => ({
  padding: "16px 24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#FFFFFF",
  borderBottom: "1px solid #E0E0E0",
  cursor: "pointer",
  "&:hover": {},
}));

const ActionButtons = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
}));

const SectionContent = styled(Box)(() => ({
  padding: "24px",
  backgroundColor: "#FFFFFF",
}));

const BaseSection = ({
  title,
  isExpanded,
  onToggleExpand,
  onSave,
  onCancel,
  canSave = true,
  sectionStatus,
  children,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleStartEdit = (e) => {
    e.stopPropagation();
    if (!isExpanded) {
      onToggleExpand();
    }
    setIsEditing(true);
  };

  const handleSave = (e) => {
    e.stopPropagation();
    const saveSuccessful = onSave?.();
    if (saveSuccessful !== false) {
      setIsEditing(false);
    }
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    onCancel?.();
    setIsEditing(false);
  };

  const handleHeaderClick = () => {
    onToggleExpand();
  };

  const getStatusIcon = () => {
    switch (sectionStatus) {
      case "complete":
        return (
          <Tooltip title="Section Complete">
            <CheckCircleIcon
              sx={{
                color: "#4CAF50",
                fontSize: "24px",
                ml: 1,
              }}
            />
          </Tooltip>
        );
      case "missing_required":
        return (
          <Tooltip title="Required Fields Missing">
            <ErrorIcon
              sx={{
                color: "#f44336",
                fontSize: "24px",
                ml: 1,
              }}
            />
          </Tooltip>
        );
      case "missing_optional":
        return (
          <Tooltip title="Optional Fields Available">
            <AddCircleOutlineIcon
              sx={{
                color: "#9e9e9e",
                fontSize: "24px",
                ml: 1,
              }}
            />
          </Tooltip>
        );
      default:
        return null;
    }
  };

  return (
    <SectionCard>
      <SectionHeader onClick={handleHeaderClick}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" sx={{ color: "#2D2D2D", fontWeight: 600 }}>
            {title}
          </Typography>
          {getStatusIcon()}
        </Box>
        <ActionButtons>
          {/* <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}> */}
          {!isEditing ? (
            <IconButton onClick={handleStartEdit}>
              <EditIcon />
            </IconButton>
          ) : (
            <>
              <Button
                variant="outlined"
                onClick={handleCancel}
                sx={{
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "4px 12px",
                  color: "#666666",
                  borderColor: "#666666",
                  "&:hover": {
                    borderColor: "#2D2D2D",
                    backgroundColor: "rgba(45, 45, 45, 0.04)",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSave}
                disabled={!canSave}
                sx={{
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "6px 18px",
                  backgroundColor: canSave ? "#2D2D2D" : "#E0E0E0",
                  color: canSave ? "#FFFFFF" : "#666666",
                  "&:hover": {
                    backgroundColor: canSave ? "#404040" : "#E0E0E0", // Dynamic hover color
                  },
                }}
              >
                Save
              </Button>
            </>
          )}
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onToggleExpand();
            }}
          >
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </ActionButtons>
        {/* </Box> */}
      </SectionHeader>
      <Collapse in={isExpanded}>
        <SectionContent>
          {typeof children === "function"
            ? children({ isEditing, setIsEditing, handleSave, handleCancel })
            : children}
        </SectionContent>
      </Collapse>
    </SectionCard>
  );
};

export default BaseSection;
