// screens/NameScreen.jsx
import React, { useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: "24px",
  width: "100%",
  maxWidth: "400px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    fontSize: "16px",
    "& fieldset": {
      borderColor: "#E5E7EB",
      borderWidth: "2px",
    },
    "&:hover fieldset": {
      borderColor: "#4F46E5",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#4F46E5",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "16px",
    "&::placeholder": {
      color: "#9CA3AF",
      opacity: 1,
    },
  },
}));

const NameScreen = ({ userData, setUserData, onNext }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleNameChange = (e) => {
    setUserData((prev) => ({
      ...prev,
      name: e.target.value,
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && userData.name.trim()) {
      onNext();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        textAlign: "center",
        mt: 8, // Reduced from 10
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, ease: "easeOut" }} // Faster animation
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: { xs: "24px", md: "32px" },
            fontWeight: 600,
            color: "#111827",
            mb: 1,
          }}
        >
          Hi there 👋
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: { xs: "24px", md: "32px" },
            fontWeight: 600,
            color: "#111827",
            mb: 3,
          }}
        >
          What should I call you?
        </Typography>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.3 }} // Faster animation, less delay
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "24px", // Reduced from 32px
          }}
        >
          <StyledTextField
            placeholder="Enter your name"
            value={userData.name}
            onChange={handleNameChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onKeyDown={handleKeyDown}
            InputProps={{
              sx: {
                backgroundColor: isFocused
                  ? "rgba(79, 70, 229, 0.03)"
                  : "transparent",
                transition: "background-color 0.2s ease", // Faster transition
              },
            }}
            autoFocus
          />
        </motion.div>

        {userData.name && (
          <motion.div
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }} // Faster animation
          >
            <Typography
              sx={{
                mt: 2,
                color: "#2d2d2d",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              Nice to meet you, {userData.name}! 👋
            </Typography>
          </motion.div>
        )}
      </motion.div>
    </Box>
  );
};

export default NameScreen;
