export const ziprecruiterFilters = {
  locationType: {
    // changed from refine_by_location_type
    label: "Location Type",
    options: [
      {
        value: "",
        label: "All remote/in-person",
      },
      {
        value: "no_remote",
        label: "In-person",
      },
      {
        value: "only_remote",
        label: "Remote",
      },
    ],
  },
  datePosted: {
    // changed from days
    label: "Date Posted",
    options: [
      {
        value: "",
        label: "Posted anytime",
      },
      {
        value: "30",
        label: "Within 30 days",
      },
      {
        value: "10",
        label: "Within 10 days",
      },
      {
        value: "5",
        label: "Within 5 days",
      },
      {
        value: "1",
        label: "Within 1 day",
      },
    ],
  },
  employmentType: {
    // changed from refine_by_employment
    label: "Employment Type",
    options: [
      {
        value: "employment_type:all",
        label: "All employment types",
      },
      {
        value: "employment_type:full_time",
        label: "Full time",
      },
      {
        value: "employment_type:contract",
        label: "Contract",
      },
      {
        value: "employment_type:part_time",
        label: "Part time",
      },
      {
        value: "employment_type:temporary",
        label: "Temporary",
      },
      {
        value: "employment_type:as_needed",
        label: "Per diem",
      },
      {
        value: "employment_type:other",
        label: "Other",
      },
    ],
  },
  distance: {
    // changed from radius
    label: "Distance",
    options: [
      {
        value: "5",
        label: "5mi",
      },
      {
        value: "10",
        label: "10mi",
      },
      {
        value: "25",
        label: "25mi",
      },
      {
        value: "50",
        label: "50mi",
      },
      {
        value: "100",
        label: "100mi",
      },
    ],
  },
};
