import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";

const CardWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  borderRadius: "16px",
  padding: "28px", // Increased padding
  height: "280px", // Increased height to match ProfileCard
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  maxWidth: "100%",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 6px 16px rgba(0,0,0,0.12)",
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: "70px", // Larger icon wrapper
  height: "70px", // Larger icon wrapper
  borderRadius: "50%",
  backgroundColor: "#F0F2F5",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "24px", // Increased margin
  "& .MuiSvgIcon-root": {
    fontSize: "32px", // Larger icon
    color: "#666666",
  },
}));

const CreateProfileCard = ({ onClick }) => {
  return (
    <CardWrapper onClick={onClick}>
      <IconWrapper>
        <AddIcon />
      </IconWrapper>
      <Typography
        variant="h6"
        sx={{
          color: "#2D2D2D",
          fontWeight: 600,
          fontSize: "22px", // Increased font size
          textAlign: "center",
          mb: 2, // Increased margin
        }}
      >
        Create New Profile
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: "#666666",
          fontSize: "16px", // Increased font size
          textAlign: "center",
        }}
      >
        Upload resume or start from scratch
      </Typography>
    </CardWrapper>
  );
};

export default CreateProfileCard;
