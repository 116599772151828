import React, { useState, useEffect } from "react";
import { Grid, Box, Button } from "@mui/material";
import BaseSection from "../BaseSection";
import ViewMode from "./ViewMode";
import EditMode from "./EditMode";

const validateCertifications = (certifications) => {
  const errors = [];

  certifications.forEach((cert, index) => {
    const certErrors = {};

    if (!cert.name?.trim()) {
      certErrors.name = "Certification name is required";
    }
    if (!cert.issuer?.trim()) {
      certErrors.issuer = "Issuer is required";
    }

    // Only add errors if there are any
    if (Object.keys(certErrors).length > 0) {
      errors[index] = certErrors;
    }
  });

  return errors;
};

const Certifications = ({
  data = [],
  isExpanded,
  onToggleExpand,
  onUpdate,
}) => {
  const [certifications, setCertifications] = useState(data);
  const [errors, setErrors] = useState([]);
  const [touched, setTouched] = useState([]);
  const [sectionStatus, setSectionStatus] = useState("missing_optional");

  const validateField = (field, value) => {
    if (!value?.toString().trim()) {
      return `${field} is required`;
    }
    return "";
  };

  const handleChange = (newCertifications) => {
    setCertifications(newCertifications);

    // Clear errors for valid fields
    const newErrors = [...errors];
    newCertifications.forEach((cert, index) => {
      if (newErrors[index]) {
        Object.keys(newErrors[index]).forEach((field) => {
          if (cert[field]?.toString().trim()) {
            delete newErrors[index][field];
          }
        });

        // Remove the error object if empty
        if (Object.keys(newErrors[index]).length === 0) {
          delete newErrors[index];
        }
      }
    });
    setErrors(newErrors);
  };

  const handleBlur = (index, field) => {
    const newTouched = [...touched];
    if (!newTouched[index]) newTouched[index] = {};
    newTouched[index][field] = true;
    setTouched(newTouched);

    // Validate the field
    const fieldError = validateField(field, certifications[index][field]);
    const newErrors = [...errors];
    if (!newErrors[index]) newErrors[index] = {};

    if (fieldError) {
      newErrors[index][field] = fieldError;
    } else {
      delete newErrors[index][field];
      // Remove empty error objects
      if (Object.keys(newErrors[index]).length === 0) {
        delete newErrors[index];
      }
    }
    setErrors(newErrors);
  };

  const handleAddCertification = () => {
    setCertifications((prev) => [
      ...prev,
      {
        name: "",
        issuer: "",
        issueDate: "",
        expiryDate: "",
        credentialId: "",
        credentialUrl: "",
      },
    ]);
  };

  const handleSave = () => {
    const validationErrors = validateCertifications(certifications);

    if (validationErrors.length === 0) {
      const newStatus = getSectionStatus(certifications);
      setSectionStatus(newStatus);
      onUpdate(certifications);
      setErrors([]);
      setTouched([]);
      return true;
    }

    setErrors(validationErrors);
    const touchedFields = certifications.map(() => ({
      name: true,
      issuer: true,
    }));
    setTouched(touchedFields);
    return false;
  };

  const handleCancel = () => {
    setCertifications(data);
    setErrors([]);
    setTouched([]);
    setSectionStatus(getSectionStatus(data));
  };

  const handleDelete = (indexToDelete) => {
    const updatedCertifications = certifications.filter(
      (_, index) => index !== indexToDelete
    );
    setCertifications(updatedCertifications);
    setErrors(errors.filter((_, index) => index !== indexToDelete));
    setTouched(touched.filter((_, index) => index !== indexToDelete));
    onUpdate(updatedCertifications);
  };

  const getSectionStatus = (certificationsData) => {
    // If no certifications, it's optional
    if (certificationsData.length === 0) {
      return "missing_optional";
    }

    // If there are certifications, check if they're properly filled
    const hasAllRequired = certificationsData.every(
      (cert) => cert.name?.trim() && cert.issuer?.trim()
    );

    // Check for any validation errors
    const hasErrors = errors.some(
      (error) => error && Object.keys(error).length > 0
    );

    if (hasAllRequired && !hasErrors) {
      return "complete";
    } else {
      return "missing_required"; // If started but not complete
    }
  };

  useEffect(() => {
    const initialStatus = getSectionStatus(data);
    setSectionStatus(initialStatus);
  }, [data]);

  // Check if form is valid (no errors present)
  const isFormValid = !errors.some(
    (error) => error && Object.keys(error).length > 0
  );

  return (
    <BaseSection
      title="Certifications"
      isExpanded={isExpanded}
      onToggleExpand={onToggleExpand}
      onSave={handleSave}
      onCancel={handleCancel}
      canSave={isFormValid}
      sectionStatus={sectionStatus}
    >
      {({ isEditing, handleSave, handleCancel }) => (
        <Grid container>
          {isEditing ? (
            <>
              <EditMode
                certifications={certifications}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
                onAdd={handleAddCertification}
                onDelete={handleDelete}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  mt: 3,
                  borderTop: "1px solid #E0E0E0",
                  pt: 3,
                  width: "100%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  sx={{
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: 600,
                    padding: "4px 12px",
                    color: "#666666",
                    borderColor: "#666666",
                    "&:hover": {
                      borderColor: "#2D2D2D",
                      backgroundColor: "rgba(45, 45, 45, 0.04)",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={!isFormValid}
                  sx={{
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: 600,
                    padding: "6px 18px",
                    backgroundColor: isFormValid ? "#2D2D2D" : "#E0E0E0",
                    color: isFormValid ? "#FFFFFF" : "#666666",
                    "&:hover": {
                      backgroundColor: isFormValid ? "#404040" : "#E0E0E0",
                    },
                  }}
                >
                  Save
                </Button>
              </Box>
            </>
          ) : (
            <ViewMode certifications={certifications} />
          )}
        </Grid>
      )}
    </BaseSection>
  );
};

export default Certifications;
