// components/sections/Miscellaneous/index.jsx
import React, { useState, useEffect } from "react";
import { Grid, Box, Button } from "@mui/material";
import BaseSection from "../BaseSection";
import ViewMode from "./ViewMode";
import EditMode from "./EditMode";

const Miscellaneous = ({
  data = {
    githubUrl: "",
    linkedinUrl: "",
    twitterUrl: "",
    portfolioUrl: "",
    dribbleUrl: "",
    otherUrl: "",
    currentSalary: "",
    currentSalaryCurrency: "",
    expectedSalaryAmount: "",
    expectedSalaryCurrency: "",
    expectedDateOfJoining: "",
    noticePeriod: "",
    totalExperience: "",
    highestEducationLevel: "",
    authorisedToWork: [],
    disability: "",
    veteranStatus: "",
    visaRequirement: "",
    drivingLicense: "",
    willingToRelocation: "",
    companiesToExclude: "",
    coverLetter: "",
    securityClearanceType: "",
    securityClearanceActive: "",
    otherSecurityClearanceType: "",
    defaultExperience: "",
  },
  isExpanded,
  onToggleExpand,
  onUpdate,
}) => {
  const [formData, setFormData] = useState(data);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [sectionStatus, setSectionStatus] = useState("missing_optional");

  const requiredFields = [
    "noticePeriod",
    "totalExperience",
    "expectedSalaryAmount",
    "expectedSalaryCurrency",
    "willingToRelocation",
    "defaultExperience",
    "currentSalary",
    "currentSalaryCurrency",
    "highestEducationLevel",
  ];

  const validateField = (field, value) => {
    console.log(`Validating ${field}:`, value, typeof value);

    if (requiredFields.includes(field) && !value?.toString().trim()) {
      const fieldNames = {
        noticePeriod: "Notice Period",
        totalExperience: "Total Experience",
        expectedSalaryAmount: "Expected Salary",
        expectedSalaryCurrency: "Expected Salary Currency",
        willingToRelocation: "Willing to Relocate",
        defaultExperience: "Default Experience",
        currentSalary: "Current Salary",
        currentSalaryCurrency: "Current Salary Currency",
        highestEducationLevel: "Highest Education Level",
      };
      return `${fieldNames[field]} is required`;
    }

    if (field === "totalExperience" || field === "defaultExperience") {
      const numValue = parseFloat(value);
      if (isNaN(numValue) || numValue < 0) {
        return `${
          field === "totalExperience" ? "Total" : "Default"
        } Experience must be a positive number`;
      }
    }

    if (field === "currentSalary" || field === "expectedSalaryAmount") {
      const numValue = parseFloat(value);

      if (isNaN(numValue) || numValue <= 0) {
        return `${
          field === "currentSalary" ? "Current" : "Expected"
        } Salary must be a positive number`;
      }
    }

    return "";
  };

  const handleChange = (field) => (event) => {
    const value = event.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Clear error if field was touched
    if (touched[field] && errors[field]) {
      setErrors((prev) => ({
        ...prev,
        [field]: "",
      }));
    }
  };

  const handleBlur = (field) => {
    setTouched((prev) => ({
      ...prev,
      [field]: true,
    }));

    const error = validateField(field, formData[field]);
    setErrors((prev) => ({
      ...prev,
      [field]: error,
    }));
  };

  const handleSave = () => {
    console.log("Miscellaneous handleSave started");
    const newErrors = {};
    const newTouched = {};

    // Validate all required fields

    requiredFields.forEach((field) => {
      newTouched[field] = true;
      newErrors[field] = validateField(field, formData[field]);
    });

    setTouched(newTouched);
    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error !== "");
    console.log("Validation errors:", hasErrors, newErrors);

    if (!hasErrors) {
      const newStatus = getSectionStatus(formData);
      setSectionStatus(newStatus);
      onUpdate(formData);
      return true; // Return true when successfully saved
    }
    return false; // Return false when there are validation errors
  };

  const handleCancel = () => {
    setFormData(data);
    setErrors({});
    setTouched({});
    setSectionStatus(getSectionStatus(data));
  };

  const getSectionStatus = (miscData) => {
    const hasStarted = requiredFields.some((field) =>
      miscData[field]?.toString().trim()
    );

    if (!hasStarted) {
      return "missing_optional";
    }

    const hasAllRequired = requiredFields.every((field) =>
      miscData[field]?.toString().trim()
    );

    const hasErrors = Object.values(errors).some((error) => error !== "");

    if (hasAllRequired && !hasErrors) {
      return "complete";
    } else {
      return "missing_required";
    }
  };

  useEffect(() => {
    const initialStatus = getSectionStatus(data);
    setSectionStatus(initialStatus);
  }, [data]);

  const canSave = !Object.values(errors).some((error) => error !== "");

  return (
    <BaseSection
      title="Job Preferences"
      isExpanded={isExpanded}
      onToggleExpand={onToggleExpand}
      onSave={handleSave}
      onCancel={handleCancel}
      canSave={true}
      sectionStatus={sectionStatus}
    >
      {({ isEditing, handleSave, handleCancel }) => (
        <Grid container>
          {isEditing ? (
            <>
              <EditMode
                data={formData}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  mt: 3,
                  borderTop: "1px solid #E0E0E0",
                  pt: 3,
                  width: "100%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  sx={{
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: 600,
                    padding: "4px 12px",
                    color: "#666666",
                    borderColor: "#666666",
                    "&:hover": {
                      borderColor: "#2D2D2D",
                      backgroundColor: "rgba(45, 45, 45, 0.04)",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={!canSave}
                  sx={{
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: 600,
                    padding: "6px 18px",
                    backgroundColor: canSave ? "#2D2D2D" : "#E0E0E0",
                    color: canSave ? "#FFFFFF" : "#666666",
                    "&:hover": {
                      backgroundColor: canSave ? "#404040" : "#E0E0E0",
                    },
                  }}
                >
                  Save
                </Button>
              </Box>
            </>
          ) : (
            <ViewMode data={formData} />
          )}
        </Grid>
      )}
    </BaseSection>
  );
};

export default Miscellaneous;
