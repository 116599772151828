// ShareJourneyForm/StepProgressBar.jsx
import React from "react";
import { Box, Typography } from "@mui/material";

const StepProgressBar = ({ steps, activeStep }) => {
  return (
    <Box sx={{ width: "100%", mb: 6 }}>
      {/* Steps text */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        {steps.map((step, index) => (
          <Typography
            key={index}
            sx={{
              fontSize: "0.95rem",
              color: activeStep >= index ? "#000" : "grey.500",
              flex: 1,
              textAlign: "center",
            }}
          >
            {step}
          </Typography>
        ))}
      </Box>

      {/* Progress bars */}
      <Box sx={{ display: "flex", gap: 2 }}>
        {steps.map((_, index) => (
          <Box
            key={index}
            sx={{
              height: "4px",
              borderRadius: "2px",
              flex: 1,
              bgcolor: activeStep >= index ? "#4F46E5" : "#E5E7EB",
              transition: "background-color 0.3s ease",
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default StepProgressBar;
