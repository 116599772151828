/*global chrome*/
import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Container,
  ThemeProvider,
  createTheme,
  CssBaseline,
  CircularProgress,
  Grid,
  Typography,
  styled,
  Button,
  Snackbar,
  Alert,
  Dialog,
} from "@mui/material";
import Navbar from "./components/Navbar";
import AnalyticsCards from "./components/AnalyticsCards";
import SearchBar from "./components/SearchBar";
import JobBoards from "./components/JobBoards";
import JobFilters from "./components/JobFilters";
import ResultsView from "../ats-jobs/ResultsView";
import SelectionControl from "../ats-jobs/SelectionControl";
import SearchResultsHeader from "../ats-jobs/SearchResultsHeader";
import JobCard from "../ats-jobs/JobCard";
import axios from "axios";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useStateStore } from "../../store/index";
import ProgressCard from "./cards/ProgressCard";
import ErrorCard from "./ErrorCard";
import { v1 as uuidv1 } from "uuid";
import ProfileSelector from "./components/ProfileSelector";
import ApplicationOptionsCard from "./cards/ApplicationOptionsCard";
import PlatformSelectionModal from "./PlatformSelectionModal";
import { countryUrlMapGlassdoor, countryUrlMapSimplyHired } from "./constants";
import { mainFiltersFormation } from "./filters/mainFiltersFormation";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background:
            "linear-gradient(135deg, #F8FCFF 0%, #FAFEFF 50%, #F9FDFF 100%)",
          minHeight: "100%",
        },
      },
    },
  },
});

const GridContainer = styled(Box)({
  width: "100%",
  maxWidth: "1300px",
  margin: "0 auto",
  padding: "0 16px",
});

const LoadMore = styled(Box)(({ theme }) => ({
  textAlign: "center",
  padding: "12px 24px",
  margin: "24px auto",
  marginBottom: "150px",
  cursor: "pointer",
  transition: "all 0.3s ease",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  borderRadius: "8px",
  backgroundColor: "#fff",
  fontSize: "18px",
  fontWeight: 600,
  color: "#666666",
  "& .MuiSvgIcon-root": {
    transition: "transform 0.3s ease",
    fontSize: "20px",
  },
  "&:hover": {
    color: "#fff",
    backgroundColor: "#2D2D2D",
    "& .MuiSvgIcon-root": {
      transform: "translateY(4px)",
    },
  },
}));

const ApplyButton = styled(Button)(({ theme, searchCompleted }) => ({
  textTransform: "none",
  padding: "12px 24px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 800,
  backgroundColor: searchCompleted ? "#2D2D2D" : "#E0E0E0",
  color: searchCompleted ? "#fff" : "#9E9E9E",
  border: "none",
  boxShadow: searchCompleted ? "0 1px 4px rgba(0,0,0,0.1)" : "none",
  cursor: searchCompleted ? "pointer" : "not-allowed",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: searchCompleted ? "#404040" : "#E0E0E0",
    boxShadow: searchCompleted ? "0 2px 5px rgba(0,0,0,0.1)" : "none",
  },
  "&:active": {
    backgroundColor: searchCompleted ? "#1a1a1a" : "#E0E0E0",
    boxShadow: "none",
  },
  "&.Mui-disabled": {
    backgroundColor: "#E0E0E0",
    color: "#9E9E9E",
    cursor: "not-allowed",
  },
  width: "100%",
  whiteSpace: "nowrap",
}));

const StyledCircularProgress = styled(CircularProgress)({
  color: "#fff",
  marginLeft: "10px",
  width: "20px !important",
  height: "20px !important",
});

const HeadlineContainer = styled(Box)({
  textAlign: "center",
  width: "100%",
  marginBottom: "12px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const MainHeadline = styled(Typography)({
  fontSize: "38px",
  fontWeight: 600,
  color: "#000",
  marginBottom: "6px",
  maxWidth: "800px",
  letterSpacing: 0.2,
});

const SubHeadline = styled(Typography)({
  fontSize: "18px",
  color: "#2d2d2d",
  fontWeight: 500,
  maxWidth: "800px",
  marginBottom: "8px",
});

const EndMessage = styled(Box)({
  textAlign: "center",
  padding: "24px",
  marginTop: "24px",
  color: "#666",
  borderTop: "1px solid #e0e0e0",
});

const GradientText = styled("span")({
  background: "linear-gradient(to right, #4F46E5, #7C3AED)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
});

const NoResultsFound = styled(Box)({
  display: "flex",
  flexDirection: "column",
  maxWidth: "1300px",
  width: "800px",
  margin: "0 auto",
  alignItems: "center",
  justifyContent: "center",
  padding: "48px 24px",
  textAlign: "center",
  backgroundColor: "#fff",
  borderRadius: "16px",
  boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
});

const NoResultsTitle = styled(Typography)({
  fontSize: "24px",
  fontWeight: 600,
  color: "#2D2D2D",
  marginBottom: "16px",
});

const NoResultsMessage = styled(Typography)({
  fontSize: "16px",
  color: "#666",
  maxWidth: "500px",
  marginBottom: "24px",
  lineHeight: 1.6,
});

const SuggestionsList = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  textAlign: "left",
  backgroundColor: "#F8FCFF",
  padding: "16px 24px",
  borderRadius: "8px",
  width: "100%",
  maxWidth: "600px",
});

const LoadingJobsCard = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#fff",
  borderRadius: "16px",
  padding: "32px",
  margin: "40px auto",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
  width: "100%",
  maxWidth: "600px",
  textAlign: "center",
  animation: "fadeIn 0.5s ease",
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
      transform: "translateY(10px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
});

const LoadingSpinner = styled(CircularProgress)({
  marginBottom: "24px",
  color: "#4F46E5",
});

const SuggestionItem = styled(Typography)({
  fontSize: "14px",
  color: "#2D2D2D",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "&:before": {
    content: '"•"',
    color: "#1890ff",
  },
});

const Dashboard = () => {
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const {
    yourExtensionId,
    scriptInjectedGlobal,
    setScriptInjectedGlobal,
    setExtensionPresent,
    setGlobalTabId,
    planDetails,
    extensionPresent,
    dailyLimit,
    applicationDaily,
    userData,
    token,
    resumesMeta,
    setResumesMeta,
    automationResults,
    setAutomationResults,
    enterpriseUser,
    routeHeaders,
    navClickCount,
  } = useStateStore();

  const [jobs, setJobs] = useState([]);
  const [searchValues, setSearchValues] = useState({
    jobTitle: "",
    jobCount: 100,
  });

  const [filters, setFilters] = useState({
    location: "",
    jobType: "",
    experienceLevel: "",
    workType: ""
  });
  const [hasResults, setHasResults] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [companies, setCompanies] = useState([]);

  // Pagination States
  const [page, setPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // Selection States
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [isApplying, setIsApplying] = useState(false);

  // View States
  const [showResults, setShowResults] = useState(false);
  // error cards
  const [errorType, setErrorType] = useState("purchase");
  const [showError, setShowError] = useState(false);

  // profile selector
  const profiles = [
    { id: 1, title: "Developer Profile" },
    { id: 2, title: "Designer Profile" },
    { id: 3, title: "Manager Profile" },
  ];
  const [selectedProfile, setSelectedProfile] = useState("");
  const [showOptionsDialog, setShowOptionsDialog] = useState(false);

  // infinite scroll

  const [observerTarget, setObserverTarget] = useState(null);
  const [hasReachedLimit, setHasReachedLimit] = useState(false);

  // for more than 2 filters

  const [showFilterWarning, setShowFilterWarning] = useState(false);
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  const viewType = enterpriseUser.viewType || "individual";

  console.log(planDetails, "plan details");

  // Update filters once plan status is determined
  // useEffect(() => {
  //   if (planDetails !== undefined) {
  //     if (planDetails?.planStarted && !filters.platform) {
  //       setFilters((prev) => ({
  //         ...prev,
  //         // platform: "linkedin",
  //       }));
  //     } else if (!planDetails?.planStarted && filters.platform) {
  //       const { platform, ...restFilters } = filters;
  //       setFilters(restFilters);
  //     }
  //   }
  // }, [planDetails, filters.platform]);

  useEffect(() => {
    const initializeExtension = () => {
      const usermain = JSON.parse(window.localStorage.getItem("user") || "{}");
      const tokenmain = window.localStorage.getItem("token");
      if (
        tokenmain !== "" &&
        usermain !== undefined &&
        Object.keys(usermain).length > 0
      ) {
        if (typeof chrome !== "undefined" && chrome && chrome.runtime)
          chrome.runtime.sendMessage(
            yourExtensionId,
            // "kgdjinndmimkmmojllafoenpcnimmaka",
            {
              dashboard: true,
              saveValues: {
                token: tokenmain,
                user: usermain,
              },
              injected: scriptInjectedGlobal,
              global: true,
              message: "loginToken",
            },
            (response) => {
              var lastError = chrome.runtime?.lastError;
              if (lastError) {
                console.log("some error");
                setScriptInjectedGlobal(false);
                setExtensionPresent(false);
              } else {
                console.log("response of initial request", response);
                setScriptInjectedGlobal(true);
                setGlobalTabId(response.globalTabId);
              }
            }
          );
      }
    };

    if (document.readyState === "complete") {
      initializeExtension();
    } else {
      window.addEventListener("load", initializeExtension);
      return () => window.removeEventListener("load", initializeExtension);
    }
  }, []);

  useEffect(() => {
    if (token && token !== "") getResumes();
  }, [token, viewType, navClickCount]);

  useEffect(() => {
    let hasHandledLinkedInScan = false;

    const handleAutomationResults = (event) => {
      console.log(event.data, "hello from frontend");
      if (event.data.type === "AUTOMATION_RESULTS") {
        console.log("Received automation results:", event.data);
        setAutomationResults(event.data.data);
        setShowResults(true);
        setIsApplying(false);

        const automationData = event.data.data;
        const totalProcessed =
          automationData.processedLinks.length +
          automationData.failedLinks.length;
        const processedPercentage =
          (totalProcessed / automationData.totalLinks) * 100;

        if (processedPercentage >= 50) {
          axios
            .post(
              "https://backend.lazyapply.com/referral/handleApplicationComplete",
              {
                v2Id: userData?.v2Id,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .catch((error) => {
              console.error("Error updating search records:", error);
            });
        }
      } else if (event.data.type === "SCANNING_RESULTS") {
        const newJobs = event.data.data.jobLinks;

        if (!hasHandledLinkedInScan) {
          hasHandledLinkedInScan = true;
          updateCompanies(newJobs);
          setJobs(newJobs);
          setSelectedJobs(newJobs.map((job) => job.link));
          setHasResults(true);
          setPage(1);
          setHasMore(false);
          setIsSearching(false);
          setIsTransitioning(false);
        }
      } else if (event.data.type === "NEW_SEARCH_STARTED") {
        hasHandledLinkedInScan = false;
      }
    };
    window.addEventListener("message", handleAutomationResults);
    return () => {
      window.removeEventListener("message", handleAutomationResults);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          hasMore &&
          !isLoadingMore &&
          jobs.length < 300
        ) {
          setTimeout(() => {
            handleLoadMore();
          }, 300);
        }
      },
      { threshold: 0.1, rootMargin: "100px" }
    );

    if (observerTarget) {
      observer.observe(observerTarget);
    }

    return () => {
      if (observerTarget) {
        observer.unobserve(observerTarget);
      }
    };
  }, [observerTarget, hasMore, isLoadingMore, jobs.length]);

  const createSearchQuery = (searchData, filterData) => {
    console.log(searchData, filterData, "hello");
    const queryParts = [];

    if (searchData.jobTitle) {
      queryParts.push(`"${searchData.jobTitle}"`);
    }

    if (searchData.location) {
      queryParts.push(`"${searchData.location}"`);
    }

    Object.entries(filterData).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          if (item) queryParts.push(`"${item}"`);
        });
      } else {
        if (value) queryParts.push(`"${value}"`);
      }
    });

    return queryParts.join(" and ");
  };

  const updateCompanies = (newJobs) => {
    const newCompanies = newJobs
      .map((job) => job.companyName)
      .filter((name) => name && !companies.includes(name));

    if (newCompanies.length > 0) {
      setCompanies((prev) => [...prev, ...newCompanies]);
    }
  };

  const handleFilterChange = (newFilters) => {
    if (
      "profileSelector" in newFilters &&
      newFilters.profileSelector !== filters.profileSelector
    ) {
      setSelectedProfile(newFilters.profileSelector);
    }

    const excludedFilters = [
      "profileSelector",
      "Country",
      "country",
      "platform",
    ];
    const filterCount = Object.entries(newFilters).filter(([key, value]) => {
      // Skip excluded filters
      if (excludedFilters.includes(key)) return false;

      // Check if the filter has a value
      if (Array.isArray(value)) {
        return value.length > 0;
      } else {
        return value !== "" && value !== null && value !== undefined;
      }
    }).length;

    setActiveFilterCount(filterCount);

    if (filterCount >= 2 && filterCount > activeFilterCount) {
      setShowFilterWarning(true);
    }

    setFilters(newFilters);
  };

  const createUrl = (platform, jobTitle, location, filters) => {
    console.log("filters", filters);
    switch (platform.toLowerCase()) {
      case "dice":
        return mainFiltersFormation(
          "dice",
          filters,
          `https://www.dice.com/jobs?q=${encodeURIComponent(
            jobTitle
          )}&pageSize=100&filters.easyApply=true&page=1`
        );

      case "glassdoor": {
        const formattedTitle = jobTitle
          .split(" ")
          .reduce((acc, word, index) => {
            return index === 0 ? word : `${acc}-${word}`;
          }, "");

        const urlString = `${formattedTitle}-jobs-SRCH_KO0,${formattedTitle.length}.htm`;

        const country =
          filters.Country && countryUrlMapGlassdoor[filters.Country]
            ? filters.Country
            : "us";

        return mainFiltersFormation(
          "glassdoor",
          filters,
          `${countryUrlMapGlassdoor[country]}${urlString}?applicationType=1`
        );
      }

      case "indeed": {
        let countryPrefix = filters.country === "us" ? "www" : filters.country;

        const cleanLocation = location
          ? location.replace(/[^a-zA-Z\s]/g, "")
          : "";

        let urlmain = "";

        urlmain = `https://${countryPrefix}.indeed.com/jobs?q=${encodeURIComponent(
          jobTitle
        )}&iafilter=1`;

        if (cleanLocation != "") {
          urlmain = `https://${countryPrefix}.indeed.com/jobs?q=${encodeURIComponent(
            jobTitle
          )}&l=${encodeURIComponent(cleanLocation)}&iafilter=1`;
        }

        return mainFiltersFormation("indeed", filters, urlmain);
      }

      case "linkedin": {
        return mainFiltersFormation(
          "linkedin",
          filters,
          `https://www.linkedin.com/jobs/search/?keywords=${encodeURIComponent(
            jobTitle
          )}&f_AL=true&start=0`
        );
      }

      case "simplyhired": {
        const country = "us";

        const formattedTitle = jobTitle.split(" ").join("+");

        const formattedLocation = location
          ? location
            .replace(/[^a-zA-Z\s]/g, "")
            .split(" ")
            .join("+")
          : "";

        let urlmain = `${countryUrlMapSimplyHired[country]}?q=${formattedTitle}`;
        if (formattedLocation != "") {
          urlmain = `${countryUrlMapSimplyHired[country]}?q=${formattedTitle}&l=${formattedLocation}`;
        }

        return mainFiltersFormation("simplyhired", filters, urlmain);
      }

      case "ziprecruiter": {
        const formattedTitle = jobTitle
          .split(" ")
          .reduce((acc, word, index) => {
            return index === 0 ? word : `${acc}+${word}`;
          }, "");

        let urlmain = "";
        urlmain = `https://www.ziprecruiter.com/candidate/search?search=${formattedTitle}`;

        if (location) {
          const cleanLocation = location.replace(/[^a-zA-Z\s]/g, "");
          urlmain = `https://www.ziprecruiter.com/candidate/search?search=${formattedTitle}&location=${cleanLocation}`;
        }

        return mainFiltersFormation("ziprecruiter", filters, urlmain);
      }

      default:
        return { url: "", filterReturned: {} };
    }
  };

  const findJobCheckError = () => {
    const resumes = resumesMeta;
    const platform = filters.platform;

    if (planDetails?.planStarted) {
      if (!extensionPresent) {
        setShowOptionsDialog(false);
        setIsApplying(false);
        setShowError(true);
        setErrorType("extension");
        return false;
      }

      if (!resumes.length) {
        setShowOptionsDialog(false);
        setIsApplying(false);
        setShowError(true);
        setErrorType("profile");
        return false;
      }

      if (!filters.profileSelector) {
        setShowOptionsDialog(false);
        setIsApplying(false);
        setShowError(true);
        setErrorType("profile_selection");
        return false;
      }

      if (platform === "glassdoor" || platform === "indeed") {
        const countryValue = filters.Country || filters.country;

        if (!countryValue) {
          setShowOptionsDialog(false);
          setIsApplying(false);
          setShowError(true);
          setErrorType("country_selection");

          return false;
        }
      }

      if (dailyLimit >= applicationDaily) {
        setShowOptionsDialog(false);
        setIsApplying(false);
        setShowError(true);
        setErrorType("limit");
        return false;
      }
    } else {
      console.log("Plan Not Started or Details not filled");
      setShowOptionsDialog(false);
      setIsApplying(false);
      setShowError(true);
      setErrorType("purchase");
      return false;
    }

    return true;
  };

  function canonicalizeURL(link) {
    let urlObj;
    try {
      urlObj = new URL(link);
    } catch (e) {
      return link;
    }

    urlObj.search = "";
    urlObj.hash = "";

    let segments = urlObj.pathname.split("/").filter(Boolean);

    const host = urlObj.host.toLowerCase();

    if (host.includes("lever.co")) {
      const lastSeg = segments[segments.length - 1];
      if (lastSeg === "apply") {
        segments.pop();
      }
    } else if (host.includes("ashbyhq.com")) {
      const lastSeg = segments[segments.length - 1];
      if (lastSeg === "application") {
        segments.pop();
      }
    } else if (host.includes("rippling.com")) {
      const lastSeg = segments[segments.length - 1];
      if (lastSeg === "apply") {
        segments.pop();
      }
    }

    urlObj.pathname = "/" + segments.join("/");
    urlObj.pathname = urlObj.pathname.replace(/\/$/, "");

    return urlObj.toString();
  }

  const handleSearch = async (searchData) => {
    setJobs([]);
    setSelectedJobs([]);
    setPage(1);
    setHasMore(true);
    setHasReachedLimit(false);
    setHasResults(false);

    setSearchValues(searchData);
    setIsTransitioning(true);
    setIsSearching(true);
    setCompanies([]);

    console.log(filters, "filters");

    // if (!filters.profileSelector) {
    //   setShowOptionsDialog(false);
    //   setIsApplying(false);
    //   setShowError(true);
    //   setIsSearching(false);
    //   setIsTransitioning(false);
    //   setErrorType("profile_selection");
    //   return false;
    // }

    if (filters.platform && filters.platform !== "all") {
      if (!findJobCheckError()) {
        console.log("Stopping execution due to an error");
        setIsSearching(false);
        setIsTransitioning(false);
        return;
      }

      window.postMessage({ type: "NEW_SEARCH_STARTED" }, "*");

      console.log("routesssss", routeHeaders);

      chrome.runtime.sendMessage(
        yourExtensionId,
        {
          type: "Scanning_Jobs",
          jobLimitV2: searchData.jobCount || 100,
          platform: filters.platform,
          filters: createUrl(
            filters.platform,
            searchData.jobTitle,
            filters?.location,
            filters
          ).filterReturned,
          token: token,
          url: createUrl(
            filters.platform,
            searchData.jobTitle,
            filters?.location,
            filters
          ).url,
          jobTitle: searchData.jobTitle,
          jobLocation: filters?.location,
          sessionId: uuidv1(),
          resumeMeta: resumesMeta.length > 0 ? resumesMeta[0] : null,
          routeHeaders,
        },
        (response) => {
          const lastError = chrome.runtime?.lastError;
          if (lastError) {
            console.log("Error starting automation");
            setIsApplying(false);
          } else {
            console.log("Automation started:", response);
          }
        }
      );

      setIsSearchMode(true);
      setTimeout(() => {
        setIsTransitioning(false);
      }, 800);

      return;
    }

    const v2Id = userData?.v2Id || "";

    console.log(filters, "filters");

    try {
      const filtersForBackend =
        "profileSelector" in filters || "location" in filters
          ? (({ profileSelector, location, ...rest }) => rest)(filters)
          : filters;

      const response = await axios.post(
        "https://backend.lazyapply.com/referral/getJobLinksV2/",
        {
          resultsNeeded: searchData.jobCount,
          v2Id: v2Id,
          search: {
            jobTitle: searchData.jobTitle,
            location: filters?.location || "",
          },
          filters: filtersForBackend,
          page: 1,
          companies: [],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const newJobs = response.data.results;
      const uniqueJobs = [];
      const seenLinks = new Set();

      newJobs.forEach((job) => {
        const canonicalLink = canonicalizeURL(job.link);
        if (!seenLinks.has(canonicalLink)) {
          uniqueJobs.push(job);
          seenLinks.add(canonicalLink);
        }
      });

      setIsSearchMode(true);

      setTimeout(() => {
        updateCompanies(uniqueJobs);
        setJobs(uniqueJobs);
        setSelectedJobs(uniqueJobs.map((job) => job.link));
        setPage(1);
        setHasMore(false);
        setIsTransitioning(false);
        setHasResults(true);
      }, 800);
    } catch (error) {
      console.error("Error fetching links:", error);
      setIsTransitioning(false);
      setHasResults(true);
    } finally {
      setIsSearching(false);
    }
  };

  const handleLoadMore = async () => {
    if (isLoadingMore || jobs.length >= 300) {
      setHasReachedLimit(true);
      return;
    }

    setIsLoadingMore(true);
    try {
      const nextPage = page + 1;
      const v2Id = userData?.v2Id || "";
      const filtersForBackend =
        "profileSelector" in filters || "location" in filters
          ? (({ profileSelector, location, ...rest }) => rest)(filters)
          : filters;

      // const queryString = createSearchQuery(searchValues, filters);

      const response = await axios.post(
        "https://backend.lazyapply.com/referral/getJobLinksV2/",
        {
          v2Id: v2Id,
          search: {
            jobTitle: searchValues.jobTitle,
            location: filters?.location || "",
          },
          filters: filtersForBackend,
          companies: [],
          page: nextPage,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const newJobs = response.data.results;

      if (newJobs?.length > 0) {
        updateCompanies(newJobs);

        const allJobs = [...jobs];

        const seenLinks = new Set(
          allJobs.map((job) => canonicalizeURL(job.link))
        );

        newJobs.forEach((job) => {
          const canonicalLink = canonicalizeURL(job.link);
          if (!seenLinks.has(canonicalLink)) {
            allJobs.push(job);
            seenLinks.add(canonicalLink);
          }
        });

        setJobs(allJobs);
        setSelectedJobs((prev) => [...prev, ...newJobs.map((job) => job.link)]);
        setPage(nextPage);
        setHasMore(allJobs.length < 300);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error loading more jobs:", error);
      setHasMore(false);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const handleCardSelect = (jobLink) => {
    setSelectedJobs((prev) => {
      if (prev.includes(jobLink)) {
        return prev.filter((link) => link !== jobLink);
      } else {
        return [...prev, jobLink];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedJobs.length === jobs.length) {
      setSelectedJobs([]);
    } else {
      setSelectedJobs(jobs.map((job) => job.link));
    }
  };

  const handleStartNewSearch = () => {
    // First fade out results
    setIsTransitioning(true);

    setTimeout(() => {
      setIsSearchMode(false);

      setShowResults(false);
      setAutomationResults(null);
      setJobs([]);
      setSelectedJobs([]);
      setHasResults(false);
      setIsApplying(false);
      setCompanies([]);
      setPage(1);
      setIsLoadingMore(false);
      setHasMore(true);
      setIsSearching(false);
      setSearchValues({
        jobTitle: "",
        jobCount: 100,
      });
      setFilters({
        jobType: "",
        location: "",
        experienceLevel: "",
        // salaryRange: "",
        workType: "",
        // companySize: [],
      });

      // After animation completes, remove transition state
      setTimeout(() => {
        setIsTransitioning(false);
      }, 500);
    }, 300);
  };

  const getResumes = async () => {
    try {
      const { data } = await axios.get(
        `https://backend.lazyapply.com/lazyapplyV2/resumes`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setResumesMeta(data?.data || []);
      if (data?.data && data.data.length == 1) {
        setSelectedProfile(data.data[0].resumeId);
      }
    } catch (error) {
      console.error("Error fetching resumes:", error.message);
    }
  };

  const handleStartApplying = () => {
    // First check for blockers

    // Get and validate resumes
    const resumes = resumesMeta;
    const validResumes = resumes.filter(
      (resume) => resume.percentageCompleted >= 70
    );

    // Check if any resumes exist
    if (!resumes.length) {
      setShowError(true);
      setErrorType("no_profile");
      return;
    }

    // Check if any valid resumes exist
    if (!validResumes.length) {
      setShowError(true);
      setErrorType("profile");
      return;
    }

    // Check if plan is started
    if (!planDetails?.planStarted) {
      setShowError(true);
      setErrorType("purchase");
      return;
    }

    // Check for extension
    if (!extensionPresent) {
      setShowError(true);
      setErrorType("extension");
      return;
    }

    // Check for profile selection
    if (!filters.profileSelector) {
      setShowError(true);
      setErrorType("profile_selection");
      return;
    }

    const platform = filters.platform;
    if (platform === "glassdoor" || platform === "indeed") {
      const countryValue = filters.Country || filters.country;
      if (!countryValue) {
        setShowError(true);
        setErrorType("country_selection");
        return;
      }
    }

    setShowOptionsDialog(true);
  };

  const wait = (time) => {
    return new Promise((resolve, _) => {
      setTimeout(() => {
        resolve();
      }, time);
    });
  };

  const handleOptionContinue = async (option = { type: "without-emails" }) => {
    setIsApplying(true);
    try {
      // if (!extensionPresent) {
      //   setShowOptionsDialog(false);
      //   setIsApplying(false);
      //   setShowError(true);
      //   setErrorType("extension");
      //   return;
      // }

      // if (!selectedProfile) {
      //   setShowOptionsDialog(false);
      //   setIsApplying(false);
      //   setShowError(true);
      //   setErrorType("profile_selection");
      //   return;
      // }

      // Get and validate resumes
      // const resumes = resumesMeta;
      // const validResumes = resumes.filter(
      //   (resume) => resume.percentageCompleted >= 70
      // );

      // if (!resumes.length) {
      //   setShowOptionsDialog(false);
      //   setIsApplying(false);
      //   setShowError(true);
      //   setErrorType("no_profile");
      //   return;
      // }

      // if (!validResumes.length) {
      //   setShowOptionsDialog(false);
      //   setIsApplying(false);
      //   setShowError(true);
      //   setErrorType("profile");
      //   return;
      // }

      // if (!planDetails?.planStarted) {
      //   setShowOptionsDialog(false);
      //   setIsApplying(false);
      //   setShowError(true);
      //   setErrorType("purchase");
      //   return;
      // }

      // if (dailyLimit >= applicationDaily) {
      //   setShowOptionsDialog(false);
      //   setIsApplying(false);
      //   setShowError(true);
      //   setErrorType("limit");
      //   return;
      // }

      if (selectedJobs.length > 0) {
        setIsApplying(true);
      }

      const selectedJobsList = jobs.filter((job) =>
        selectedJobs.includes(job.link)
      );

      if (scriptInjectedGlobal && chrome && chrome.runtime) {
        await wait(2000);
        console.log("routessssss", routeHeaders);
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            type: "START_AUTOMATION",
            emailApplyOption: option.type === "with-emails" ? true : false,
            emailApplyDetails: {
              ...option,
            },
            data: selectedJobsList,
            sessionId: uuidv1(),
            resumeMeta:
              resumesMeta.find(
                (resume) => resume.resumeId === selectedProfile
              ) || null,
            routeHeaders: routeHeaders,
          },
          (response) => {
            const lastError = chrome.runtime?.lastError;
            if (lastError) {
              console.log("Error starting automation");
              setIsApplying(false);
            } else {
              console.log("Automation started:", response);
            }
          }
        );
      } else {
        console.log("Extension not ready");
        setIsApplying(false);
      }

      setShowOptionsDialog(false);
    } catch (error) {
      console.error("Error in handleOptionContinue:", error);
      setIsApplying(false);
    }
  };

  const [isSticky, setIsSticky] = useState(false);
  const stickyRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (stickyRef.current) {
        setIsSticky(stickyRef.current.getBoundingClientRect().top <= 16);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // To close the snackbar
  const handleCloseWarning = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowFilterWarning(false);
  };

  // const handleStartApplying = async () => {
  //   try {
  //     if (!planDetails?.planStarted) {
  //       setShowError(true);
  //       setErrorType("purchase");
  //       return;
  //     }

  //     const resumes = await getResumes();
  //     const validResumes = resumes.filter(
  //       (resume) => resume.percentageCompleted >= 70
  //     );

  //     if (!resumes.length || !validResumes.length) {
  //       setShowError(true);
  //       setErrorType("profile");
  //       return;
  //     }

  //     if (!extensionPresent) {
  //       setShowError(true);
  //       setErrorType("extension");
  //       return;
  //     }

  //     if (dailyLimit >= applicationDaily) {
  //       setShowError(true);
  //       setErrorType("limit");
  //       return;
  //     }

  //     if (selectedJobs.length > 0) {
  //       setIsApplying(true);
  //     }

  //     const selectedJobsList = jobs.filter((job) =>
  //       selectedJobs.includes(job.link)
  //     );

  //     if (scriptInjectedGlobal && chrome && chrome.runtime) {
  //       chrome.runtime.sendMessage(
  //         yourExtensionId,
  //         // "kgdjinndmimkmmojllafoenpcnimmaka",
  //         {
  //           type: "START_AUTOMATION",
  //           data: selectedJobsList,
  //           sessionId: uuidv1(),
  //         },
  //         (response) => {
  //           var lastError = chrome.runtime?.lastError;
  //           if (lastError) {
  //             console.log("Error starting automation");
  //             // Handle error
  //           } else {
  //             console.log("Automation started:", response);
  //             // Handle successful start
  //           }
  //         }
  //       );
  //     } else {
  //       console.log("Extension not ready");
  //     }
  //   } catch (error) {
  //     console.error("Error in handleStartApplying:", error);
  //   }
  // };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            margin: "0 auto",
            // px: 3,
            py: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!showResults ? (
            <>
              {/* Analytics Cards - with transition */}
              {/* <Box
                sx={{
                  opacity: isTransitioning || isSearchMode ? 0 : 1,
                  transition: "opacity 0.5s ease",
                  height: isSearchMode ? 0 : "auto",
                  overflow: "hidden",
                  px: 3,
                }}
              >
                <AnalyticsCards /> */}

              {/* <ApplicationOptionsCard
                  onClose={() => console.log("Closed")}
                  onContinue={(option) =>
                    console.log("Selected option:", option)
                  }
                /> */}
              {/* </Box> */}

              {/* Search Section - with transition */}
              <Box
                sx={{
                  transform: isSearchMode
                    ? "translateY(-40px)"
                    : "translateY(0)",
                  mt: isSearchMode ? 2 : 0,
                  transition: "all 0.8s ease",
                  position: "relative",
                  zIndex: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  px: 3,
                  width: "100%",
                }}
              >
                <HeadlineContainer
                  sx={{
                    opacity: isSearchMode ? 0 : 1,
                    transition: "opacity 0.5s ease",
                    height: isSearchMode ? 0 : "auto",
                    overflow: "hidden",
                  }}
                >
                  <MainHeadline>Apply Jobs Across Platforms</MainHeadline>
                  <SubHeadline>
                    Find and apply to jobs from LinkedIn, Indeed, and more all
                    in one place, with a single search.
                  </SubHeadline>
                </HeadlineContainer>
                <SearchBar onSearch={handleSearch} isSearching={isSearching} />
                <JobFilters
                  filters={filters}
                  onFilterChange={handleFilterChange}
                />

                {isSearching &&
                  (isTransitioning || isSearchMode) &&
                  jobs.length === 0 ? (
                  <LoadingJobsCard>
                    <LoadingSpinner size={50} thickness={4} />
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: 600, mb: 2, color: "#2D2D2D" }}
                    >
                      Searching for {searchValues.jobCount || 100} jobs...
                    </Typography>
                    <Typography
                      sx={{
                        color: "#666666",
                        maxWidth: "400px",
                        mx: "auto",
                        fontSize: "17px",
                      }}
                    >
                      Our agent is browsing platforms to find the best matches
                      for "
                      <strong style={{ color: "#2D2D2D" }}>
                        {searchValues.jobTitle}
                      </strong>
                      "
                      {filters?.location ? (
                        <>
                          {" "}
                          in{" "}
                          <strong style={{ color: "#2D2D2D" }}>
                            {filters.location}
                          </strong>
                        </>
                      ) : (
                        ""
                      )}
                      .
                    </Typography>
                  </LoadingJobsCard>
                ) : (
                  <></>
                )}

                {hasResults &&
                  jobs.length === 0 &&
                  !isSearching &&
                  !isTransitioning && (
                    <NoResultsFound>
                      <NoResultsTitle>
                        No jobs found for your search
                      </NoResultsTitle>
                      <NoResultsMessage>
                        We couldn't find any jobs matching your criteria. Here
                        are some suggestions to help you find more
                        opportunities:
                      </NoResultsMessage>
                      <SuggestionsList>
                        <SuggestionItem>
                          Try using broader or alternative job titles (e.g.,
                          "Software Engineer" instead of "React Developer")
                        </SuggestionItem>
                        <SuggestionItem>
                          Remove some filters to widen your search
                        </SuggestionItem>

                        <SuggestionItem>
                          If searching in a specific location, try expanding the
                          search radius
                        </SuggestionItem>

                        {/* {filters.platform && filters.platform !== "all" && (
                              <SuggestionItem>
                                Try searching across all platforms instead of
                                just {filters.platform}
                              </SuggestionItem>
                            )} */}
                      </SuggestionsList>
                    </NoResultsFound>
                  )}

                {hasResults && (
                  <>
                    <Box
                      sx={{
                        position: "relative",
                      }}
                    >
                      {jobs.length > 0 && (
                        <Box
                          sx={{
                            // position: "sticky",
                            top: "16px",
                            zIndex: 10,
                            paddingBottom: "16px",
                            marginTop: "16px",
                            paddingTop: "16px",
                            width: "100%",
                          }}
                        // ref={stickyRef}
                        >
                          <Box
                            sx={{
                              paddingLeft: "24px",
                              paddingRight: "24px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              transition: "margin-top 0.3s ease-in-out",
                              marginTop: isSticky ? "70px" : "0",
                            }}
                          >
                            <SearchResultsHeader
                              jobs={jobs}
                              searchValues={searchValues}
                              hasResults={hasResults}
                              profileSelector={
                                <ProfileSelector
                                  selectedProfile={selectedProfile}
                                  profiles={resumesMeta.filter(
                                    (resume) => resume.percentageCompleted >= 70
                                  )}
                                  searchValues={searchValues}
                                />
                              }
                            >
                              <ApplyButton
                                id="start-applying-automation"
                                onClick={handleStartApplying}
                                disabled={
                                  !hasResults ||
                                  selectedJobs.length === 0 ||
                                  isApplying
                                }
                                searchCompleted={hasResults}
                              >
                                {isApplying ? (
                                  <>
                                    Applying...
                                    <StyledCircularProgress size={20} />
                                  </>
                                ) : (
                                  "Start Applying"
                                )}
                              </ApplyButton>
                            </SearchResultsHeader>
                          </Box>
                          <Box
                            sx={{
                              paddingLeft: "24px",
                              paddingRight: "24px",
                              // paddingBottom: "10px",
                              paddingTop: "20px",
                            }}
                          >
                            <SelectionControl
                              totalJobs={jobs.length}
                              selectedCount={selectedJobs.length}
                              allSelected={
                                jobs.length > 0 &&
                                jobs.length === selectedJobs.length
                              }
                              onSelectAll={handleSelectAll}
                            />
                          </Box>
                        </Box>
                      )}

                      {hasResults && jobs.length > 0 && (
                        <>
                          <GridContainer>
                            <Grid container spacing={3}>
                              {jobs.map((job) => (
                                <Grid item xs={12} sm={6} md={4} key={job.link}>
                                  <JobCard
                                    job={job}
                                    isSelected={selectedJobs.includes(job.link)}
                                    onSelect={() => handleCardSelect(job.link)}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </GridContainer>
                          {isLoadingMore ? (
                            <Box sx={{ textAlign: "center", py: 3 }}>
                              <CircularProgress size={24} />
                            </Box>
                          ) : hasReachedLimit ? (
                            <EndMessage>
                              <Typography
                                variant="h6"
                                sx={{ color: "#2D2D2D", mb: 1 }}
                              >
                                You've reached the maximum of 300 jobs
                              </Typography>
                              <Typography>
                                Consider refining your search terms or adjusting
                                filters for more specific results
                              </Typography>
                            </EndMessage>
                          ) : (
                            <div
                              ref={setObserverTarget}
                              style={{ height: "20px" }}
                            />
                          )}
                        </>
                      )}
                    </Box>
                  </>
                )}

                {/* {hasResults &&
                  hasMore &&
                  jobs.length >= 0 &&
                  jobs.length < 100 && (
                    <LoadMore onClick={handleLoadMore}>
                      {isLoadingMore ? (
                        <>
                          <CircularProgress size={16} color="inherit" />
                          Loading more jobs...
                        </>
                      ) : (
                        <>
                          <KeyboardArrowDownIcon />
                          Load more jobs
                        </>
                      )}
                    </LoadMore>
                  )} */}
              </Box>

              {/* Job Boards Section - with transition */}
              <Box
                sx={{
                  opacity: isTransitioning || isSearchMode ? 0 : 1,
                  transition: "opacity 0.5s ease",
                  height: isSearchMode ? 0 : "auto",
                  overflow: "hidden",
                  mt: 10,
                  // bgcolor: "#f5f8ff",
                  px: 3,
                }}
              >
                {/* <PlatformSelectionModal open={true} /> */}
                <JobBoards />
              </Box>
            </>
          ) : (
            <ResultsView
              results={automationResults}
              onStartNewSearch={handleStartNewSearch}
            />
          )}
        </Box>
        {isApplying && (
          <ProgressCard
            company="Google"
            position="Software Developer"
            onClose={() => console.log("Card closed")}
          />
        )}
        {showError && (
          <ErrorCard
            type={errorType} // or whatever type you need
            onClose={() => setShowError(false)}
          />
        )}

        <Dialog
          open={showOptionsDialog}
          onClose={() => setShowOptionsDialog(false)}
          PaperProps={{
            sx: {
              borderRadius: "12px",
              maxWidth: "600px",
              width: "90vw",
              m: 2,
            },
          }}
        >
          <ApplicationOptionsCard
            onClose={() => setShowOptionsDialog(false)}
            onContinue={handleOptionContinue}
          />
        </Dialog>
      </Box>

      {/* Filter Warning Snackbar */}
      <Snackbar
        open={showFilterWarning}
        autoHideDuration={6000}
        onClose={handleCloseWarning}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseWarning}
          severity="warning"
          sx={{
            width: "100%",
            backgroundColor: "#FDE9E8",
            color: "#D32F2F",
            fontWeight: 500,
            fontSize: "16px",
            boxShadow: "0 2px 8px rgba(211, 47, 47, 0.2)",
            "& .MuiAlert-icon": {
              color: "#D32F2F",
            },
          }}
        >
          Applying 2 or more filters may narrow your results significantly.
          Consider removing some filters if you don't see enough jobs.
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default Dashboard;
