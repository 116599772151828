import React, { useState, useEffect } from "react";
import { Grid, Box, Button } from "@mui/material";
import BaseSection from "../BaseSection";
import ViewMode from "./ViewMode";
import EditMode from "./EditMode";

const validateWorkExperiences = (experiences) => {
  const errors = [];

  experiences.forEach((exp, index) => {
    const expErrors = {};

    if (!exp.jobTitle?.trim()) {
      expErrors.jobTitle = "Job title is required";
    }
    if (!exp.company?.trim()) {
      expErrors.company = "Company name is required";
    }
    if (!exp.workLocation?.trim()) {
      expErrors.workLocation = "Location is required";
    }
    if (!exp.startDate) {
      expErrors.startDate = "Start date is required";
    }
    if (!exp.currentlyWorkHere && !exp.endDate) {
      expErrors.endDate = "End date is required";
    }
    if (!exp.description?.trim()) {
      expErrors.description = "Description is required";
    }

    // Only add errors if there are any
    if (Object.keys(expErrors).length > 0) {
      errors[index] = expErrors;
    }
  });

  return errors;
};

const WorkExperience = ({
  data = [],
  isExpanded,
  onToggleExpand,
  onUpdate,
}) => {
  const [experiences, setExperiences] = useState(data);
  const [errors, setErrors] = useState([]);
  const [touched, setTouched] = useState([]);
  const [sectionStatus, setSectionStatus] = useState("missing_required");

  useEffect(() => {
    setExperiences(data);
  }, [data]);

  const validateField = (experience, field) => {
    if (field === "endDate" && experience.currentlyWorkHere) {
      return "";
    }
    if (!experience[field]?.toString().trim()) {
      return `${field} is required`;
    }
    return "";
  };

  const handleChange = (newExperiences) => {
    setExperiences(newExperiences);

    // Validate all experiences
    const validationErrors = newExperiences.map((exp) => {
      const expErrors = {};

      // Only add errors for touched fields or previously errored fields
      newExperiences.forEach((exp, index) => {
        if (touched[index]) {
          if (!exp.jobTitle?.trim() && touched[index].jobTitle)
            expErrors.jobTitle = "Job title is required";
          if (!exp.company?.trim() && touched[index].company)
            expErrors.company = "Company name is required";
          if (!exp.workLocation?.trim() && touched[index].workLocation)
            expErrors.workLocation = "Location is required";
          if (!exp.startDate && touched[index].startDate)
            expErrors.startDate = "Start date is required";
          if (!exp.description?.trim() && touched[index].description)
            expErrors.description = "Description is required";
          if (!exp.currentlyWorkHere && !exp.endDate && touched[index].endDate)
            expErrors.endDate = "End date is required";
        }
      });

      return Object.keys(expErrors).length > 0 ? expErrors : {};
    });

    setErrors(validationErrors);
  };

  const handleBlur = (index, field) => {
    const newTouched = [...touched];
    if (!newTouched[index]) newTouched[index] = {};
    newTouched[index][field] = true;
    setTouched(newTouched);

    // Validate the field
    const fieldError = validateField(experiences[index], field);
    const newErrors = [...errors];
    if (!newErrors[index]) newErrors[index] = {};

    if (fieldError) {
      newErrors[index][field] = fieldError;
    } else {
      delete newErrors[index][field];
      // Remove empty error objects
      if (Object.keys(newErrors[index]).length === 0) {
        delete newErrors[index];
      }
    }

    setErrors(newErrors);
  };

  const handleSave = () => {
    const validationErrors = validateWorkExperiences(experiences);

    if (validationErrors.length === 0) {
      const newStatus = getSectionStatus(experiences);
      setSectionStatus(newStatus);
      onUpdate(experiences);
      return true;
    }

    setErrors(validationErrors);
    const touchedFields = experiences.map(() => ({
      jobTitle: true,
      company: true,
      workLocation: true,
      startDate: true,
      endDate: true,
      description: true,
    }));
    setTouched(touchedFields);
    return false;
  };

  const handleCancel = () => {
    setExperiences(data);
    setErrors([]);
    setTouched([]);
    setSectionStatus(getSectionStatus(data));
  };

  const handleDelete = (indexToDelete) => {
    const updatedExperiences = experiences.filter(
      (_, index) => index !== indexToDelete
    );
    setExperiences(updatedExperiences);
    setErrors(errors.filter((_, index) => index !== indexToDelete));
    setTouched(touched.filter((_, index) => index !== indexToDelete));
    onUpdate(updatedExperiences);
  };

  const handleAddExperience = () => {
    setExperiences((prev) => [
      ...prev,
      {
        jobTitle: "",
        company: "",
        workLocation: "",
        startDate: "",
        endDate: "",
        currentlyWorkHere: false,
        description: "",
      },
    ]);
  };

  // Simplified isFormValid logic that only checks for presence of errors
  const isFormValid = errors.every(
    (error) => !error || Object.keys(error).length === 0
  );

  const getSectionStatus = (experiencesData) => {
    // If no experiences added yet, it's missing required
    if (experiencesData.length === 0) {
      return "missing_required";
    }

    // Check if all required fields are filled in all experiences
    const hasAllRequired = experiencesData.every(
      (exp) =>
        exp.jobTitle?.trim() &&
        exp.company?.trim() &&
        exp.workLocation?.trim() &&
        exp.startDate &&
        exp.description?.trim() &&
        (exp.currentlyWorkHere || exp.endDate)
    );

    // Check for any validation errors
    const hasErrors = errors.some(
      (error) => error && Object.keys(error).length > 0
    );

    if (hasAllRequired && !hasErrors) {
      return "complete";
    } else {
      return "missing_required";
    }
  };

  useEffect(() => {
    const initialStatus = getSectionStatus(data);
    setSectionStatus(initialStatus);
  }, [data]);

  return (
    <BaseSection
      title="Work Experience"
      isExpanded={isExpanded}
      onToggleExpand={onToggleExpand}
      onSave={handleSave}
      onCancel={handleCancel}
      canSave={isFormValid}
      sectionStatus={sectionStatus}
    >
      {({ isEditing, handleSave, handleCancel }) => (
        <Grid container>
          {isEditing ? (
            <>
              <EditMode
                experiences={experiences}
                errors={errors}
                touched={touched}
                onAdd={handleAddExperience}
                onChange={handleChange}
                onBlur={handleBlur}
                onDelete={handleDelete}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  mt: 3,
                  borderTop: "1px solid #E0E0E0",
                  pt: 3,
                  width: "100%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  sx={{
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: 600,
                    padding: "4px 12px",
                    color: "#666666",
                    borderColor: "#666666",
                    "&:hover": {
                      borderColor: "#2D2D2D",
                      backgroundColor: "rgba(45, 45, 45, 0.04)",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={!isFormValid}
                  sx={{
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: 600,
                    padding: "6px 18px",
                    backgroundColor: isFormValid ? "#2D2D2D" : "#E0E0E0",
                    color: isFormValid ? "#FFFFFF" : "#666666",
                    "&:hover": {
                      backgroundColor: isFormValid ? "#404040" : "#E0E0E0",
                    },
                  }}
                >
                  Save
                </Button>
              </Box>
            </>
          ) : (
            <ViewMode experiences={experiences} />
          )}
        </Grid>
      )}
    </BaseSection>
  );
};

export default WorkExperience;
