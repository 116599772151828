import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Stack,
  IconButton,
  Avatar,
  Badge,
  Popover,
  Tooltip,
  Divider,
  List,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import logo from "../assets/panda1.png";
import { useStateStore } from "../../../store";
import EmailIcon from "@mui/icons-material/Email";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import PeopleIcon from "@mui/icons-material/People";
import PersonSwitchIcon from "@mui/icons-material/SwitchAccount";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useAccessModeHandler } from "../../../utils/access-utils";
import {
  addDynamicHeader,
  removeDynamicHeader,
} from "../../../apis/axios-config";
import AccessModeConfirmationModal from "../cards/AccessModeConfirmationModal";

const StyledAppBar = styled(AppBar)({
  // backgroundColor: "#fff",
  background: "linear-gradient(135deg, #F8FCFF 0%, #FAFEFF 50%, #F9FDFF 100%)",
  boxShadow: "none",
  position: "fixed",
  width: "100%",
  zIndex: 1100,
  // borderBottom: "0.5px solid rgba(0,0,0,0.2)", // Explicitly removing border
});

const NavLink = styled(Typography)(({ active }) => ({
  cursor: "pointer",
  color: active ? "#2D2D2D" : "#666666",
  fontWeight: active ? 800 : 550,
  fontSize: "16px",
  transition: "color 0.2s ease",
  "&:hover": {
    color: "#2D2D2D",
  },
}));

const StyledIconButton = styled(IconButton)({
  "&:hover": {
    "& .MuiSvgIcon-root": {
      color: "#2D2D2D",
    },
  },
});

const MenuItemWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "9px",
  padding: "12px 16px",
  color: "#666666",
  cursor: "pointer",
  transition: "all 0.2s ease",
  "&:hover": {
    backgroundColor: "rgba(0,0,0,0.04)",
    color: "#2D2D2D",
  },
});

const StreakDisplay = styled(Box)({
  display: "flex",
  alignItems: "center",
  // gap: "0.5px",
  fontSize: "15px",
  fontWeight: 550,
});

const WelcomeCard = styled(Box)({
  padding: "24px",
  backgroundColor: "#fff",
  borderRadius: "12px",
  marginBottom: "8px",
  textAlign: "left",
});

const Navbar = ({ onProfileStateChange }) => {
  const {
    planDetails,
    applicationDaily,
    dailyLimit,
    setEnterpriseUser,
    enterpriseUser,
    incrementNavClick,
    selectedMode,
    selectedUserId,
  } = useStateStore();
  const { handleAccessModeChange: accessModeHandler } = useAccessModeHandler();
  const history = useHistory();
  const location = useLocation();
  const users = enterpriseUser?.users || [];
  const isAnalyticsRoute = location.pathname === "/dashboard/analytics";
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const notificationOpen = Boolean(notificationAnchorEl);
  const { userData, globalLogoutV2, streakData } = useStateStore();
  const isActive = (path) => location.pathname === path;

  const [userRole, setUserRole] = useState("operator"); // This will come from backend
  const [accessModeAnchorEl, setAccessModeAnchorEl] = useState(null);
  const accessModeOpen = Boolean(accessModeAnchorEl);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);

  const [showModalOnModeChange, setShowModalOnModeChange] = useState(false);

  const dummyUsers = users;

  const handleAccessModeClick = (event) => {
    setAccessModeAnchorEl(event.currentTarget);
  };

  const handleAccessModeClose = () => {
    setAccessModeAnchorEl(null);
  };

  useEffect(() => {
    // After initial render and setup, enable modal for subsequent user changes
    const timer = setTimeout(() => {
      setShowModalOnModeChange(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isAnalyticsRoute && selectedMode === "role") {
      handleAccessModeChange("personal");
      if (accessModeOpen) {
        handleAccessModeClose();
      }
    }
  }, [isAnalyticsRoute, selectedMode]);

  useEffect(() => {
    if (
      planDetails.type &&
      (planDetails.type === "owner" || planDetails.type === "operator")
    ) {
      handleAccessModeChange("role");
    }
  }, [planDetails]);

  const handleAccessModeChange = (mode, userId = null) => {
    let userData = null;
    if (mode === "user" && userId) {
      userData = dummyUsers.find((user) => user.v2Id === userId);
    }
    setSelectedUserData(userData);

    if (showModalOnModeChange && accessModeOpen) {
      setConfirmModalOpen(true);
    }

    accessModeHandler(mode, userId, {
      onSuccess: () => {
        handleAccessModeClose();
      },
    });
  };

  const handleNavigation = (path) => {
    history.push(path);
  };

  const getInitials = (name) => {
    if (!name) return "";

    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  const handleClick = (event) => {
    setAnchorEl(open ? null : event.currentTarget);
    onProfileStateChange?.(!open);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onProfileStateChange?.(false);
  };

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(notificationOpen ? null : event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  // Also add handleLogout function
  const handleLogout = () => {
    globalLogoutV2();
    handleClose(); // Close popover
    // Add your logout logic here
  };

  const formatDate = (isoDate) => {
    try {
      const date = new Date(isoDate);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
    } catch (error) {
      console.error("Error formatting date:", error);
      return isoDate;
    }
  };

  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };

  const oldplans = ['premium_plan_new', 'basic_plan_new', 'unlimited_plan']

  const secondaryValue = () => {

    if (planDetails.planStarted == 0) {
      return 'Lifetime'
    }

    if (planDetails.planId && planDetails.planId !== "") {
      if (oldplans.includes(planDetails.planId)) {
        return 'Lifetime'
      }
    }

    return planDetails?.planEndDate
      ? formatDate(planDetails?.planEndDate)
      : "Never";
  }

  return (
    <StyledAppBar elevation={0}>
      {" "}
      {/* Added elevation={0} to ensure no shadow */}
      <Toolbar
        sx={{
          justifyContent: "space-between",
          px: { xs: 4, md: 4 },
          py: 1,
          borderBottom: "none", // Explicitly removing border from Toolbar
        }}
      >
        {/* Logo */}
        <Box
          onClick={() => handleNavigation("/")}
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <img
            src={logo}
            alt="LazyApply"
            style={{ height: "60px", width: "60px" }}
          />
          <Typography
            fontSize="20px"
            fontWeight={700}
            color="#2d2d2d"
            sx={{
              letterSpacing: "0.3px",
            }}
          >
            LazyApply
          </Typography>
        </Box>

        {/* Navigation Links - Increased spacing from 5 to 8 */}
        <Stack direction="row" spacing={8}>
          <NavLink
            active={isActive("/dashboard")}
            onClick={() => handleNavigation("/dashboard")}
          >
            Jobs
          </NavLink>
          <NavLink
            active={isActive("/dashboard/profile")}
            onClick={() => handleNavigation("/dashboard/profile")}
          >
            Profile
          </NavLink>

          <NavLink
            active={isActive("/dashboard/analytics")}
            onClick={() => handleNavigation("/dashboard/analytics")}
          >
            Analytics
          </NavLink>

          <NavLink
            active={isActive("/dashboard/pricing")}
            onClick={() => handleNavigation("/dashboard/pricing")}
          >
            Pricing
          </NavLink>

          {/* <NavLink
            active={isActive("/dashboard/explore")}
            onClick={() => handleNavigation("/dashboard/explore")}
          >
            Explore
          </NavLink> */}

          {planDetails.type && planDetails.type !== "individual" ? (
            <NavLink
              active={isActive(
                planDetails.modelName === "school"
                  ? "/dashboard/user-management-owner"
                  : planDetails.type === "operator"
                  ? "/dashboard/user-management/operator"
                  : "/dashboard/user-management"
              )}
              onClick={() =>
                handleNavigation(
                  planDetails.modelName === "school"
                    ? "/dashboard/user-management-owner"
                    : planDetails.type === "operator"
                    ? "/dashboard/user-management/operator"
                    : "/dashboard/user-management"
                )
              }
            >
              User Management
            </NavLink>
          ) : (
            <></>
          )}

          {planDetails.type && planDetails.type !== "individual" && (
            <Box sx={{ position: "relative" }}>
              <NavLink
                active={accessModeOpen}
                onClick={handleAccessModeClick}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <PersonSwitchIcon sx={{ fontSize: "18px" }} />
                Switch Mode
                <KeyboardArrowDownIcon
                  sx={{
                    fontSize: "18px",
                    transform: accessModeOpen
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.2s",
                  }}
                />
              </NavLink>

              <Popover
                open={accessModeOpen}
                anchorEl={accessModeAnchorEl}
                onClose={handleAccessModeClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                slotProps={{
                  paper: {
                    sx: {
                      mt: 1.5,
                      boxShadow: "0 8px 32px rgba(0,0,0,0.12)",
                      borderRadius: "12px",
                      width: "360px",
                      overflow: "hidden",
                      animation: "popoverEnter 0.2s ease-out",
                      "@keyframes popoverEnter": {
                        "0%": {
                          opacity: 0,
                          transform: "scale(0.95) translateY(-10px)",
                        },
                        "100%": {
                          opacity: 1,
                          transform: "scale(1) translateY(0)",
                        },
                      },
                    },
                  },
                }}
              >
                <Box sx={{ p: 2, bgcolor: "#4F46E5", color: "white" }}>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Access Mode
                  </Typography>
                  <Typography variant="body2" sx={{ opacity: 0.8 }}>
                    Select how you want to use the product
                  </Typography>
                </Box>

                <List sx={{ p: 0 }}>
                  {/* Personal Mode */}
                  <ListItemButton
                    onClick={() => handleAccessModeChange("personal")}
                    sx={{
                      py: 1.5,
                      "&:hover": { bgcolor: "rgba(79, 70, 229, 0.08)" },
                      position: "relative",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: "rgba(79, 70, 229, 0.1)" }}>
                        <PersonIcon sx={{ color: "#4F46E5" }} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontWeight: 600 }}>
                          Personal Access
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" sx={{ color: "#6B7280" }}>
                          Use as yourself
                        </Typography>
                      }
                    />
                    {selectedMode === "personal" && (
                      <CheckCircleIcon
                        sx={{
                          color: "#4F46E5",
                          position: "absolute",
                          right: 16,
                        }}
                      />
                    )}
                  </ListItemButton>

                  {/* Role-based Mode - Only show on analytics route */}
                  {isAnalyticsRoute && (
                    <ListItemButton
                      onClick={() => handleAccessModeChange("role")}
                      sx={{
                        py: 1.5,
                        "&:hover": { bgcolor: "rgba(79, 70, 229, 0.08)" },
                        position: "relative",
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: "rgba(79, 70, 229, 0.1)" }}>
                          <PeopleIcon sx={{ color: "#4F46E5" }} />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontWeight: 600 }}>
                            Team Access
                          </Typography>
                        }
                        secondary={
                          <Typography variant="body2" sx={{ color: "#6B7280" }}>
                            Use as {userRole} of this team
                          </Typography>
                        }
                      />
                      {selectedMode === "role" && (
                        <CheckCircleIcon
                          sx={{
                            color: "#4F46E5",
                            position: "absolute",
                            right: 16,
                          }}
                        />
                      )}
                    </ListItemButton>
                  )}

                  {dummyUsers.length > 0 ? (
                    <>
                      <Divider sx={{ my: 1 }} />
                      <Box sx={{ px: 2, py: 1 }}>
                        <Typography
                          sx={{
                            color: "#6B7280",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          Use on behalf of users under you
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* User List */}
                  <Box sx={{ maxHeight: 250, overflow: "auto" }}>
                    {dummyUsers.map((user) => (
                      <ListItemButton
                        key={user.v2Id}
                        onClick={() =>
                          handleAccessModeChange("user", user.v2Id)
                        }
                        sx={{
                          py: 1.5,
                          "&:hover": { bgcolor: "rgba(79, 70, 229, 0.08)" },
                          position: "relative",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar sx={{ bgcolor: "#E0E0E0", color: "#2D2D2D" }}>
                            {getInitials(user.name)}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontWeight: 500 }}>
                              {user.name}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="body2"
                              sx={{ color: "#6B7280" }}
                            >
                              {user.email}
                            </Typography>
                          }
                        />
                        {selectedMode === "user" &&
                          selectedUserId === user.v2Id && (
                            <CheckCircleIcon
                              sx={{
                                color: "#4F46E5",
                                position: "absolute",
                                right: 16,
                              }}
                            />
                          )}
                      </ListItemButton>
                    ))}
                  </Box>
                </List>
              </Popover>
            </Box>
          )}
        </Stack>

        {/* User Section */}
        <Stack direction="row" spacing={3} alignItems="center">
          <>
            <StyledIconButton onClick={handleNotificationClick}>
              <Badge badgeContent={1} color="error">
                <NotificationsIcon sx={{ color: "#666666" }} />
              </Badge>
            </StyledIconButton>

            <Popover
              open={notificationOpen}
              anchorEl={notificationAnchorEl}
              onClose={handleNotificationClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              slotProps={{
                paper: {
                  sx: {
                    mt: 1.5,
                    boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
                    borderRadius: "12px",
                    width: "280px",
                  },
                },
              }}
            >
              <WelcomeCard>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                  Welcome back, {userData?.name}! 👋
                </Typography>
                <Typography variant="body2" sx={{ color: "#666666" }}>
                  Ready to explore new opportunities today?
                </Typography>
              </WelcomeCard>
            </Popover>
          </>

          <Tooltip
            title={
              <Box
                sx={{
                  fontSize: "13px",
                  color: "white",
                  fontWeight: 500,
                }}
              >
                Your activity streak - keep it going!
              </Box>
            }
            placement="bottom"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#4F46E5",
                  borderRadius: "8px",
                  p: 1,
                  "& .MuiTooltip-arrow": {
                    color: "#4F46E5",
                  },
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                },
              },
            }}
          >
            <StreakDisplay>
              <LocalFireDepartmentIcon
                sx={{
                  fontSize: "28px",
                }}
                style={{
                  fill: "url(#fireGradient)",
                }}
              />
              <svg width="0" height="0">
                <defs>
                  <linearGradient
                    id="fireGradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="100%"
                  >
                    <stop offset="0%" stopColor="#FF4B4B" />
                    <stop offset="50%" stopColor="#FFA500" />
                    <stop offset="100%" stopColor="#FFD700" />
                  </linearGradient>
                </defs>
              </svg>
              <Box sx={{ color: "#000", fontSize: "18px", fontWeight: "600" }}>
                {streakData?.streak}
              </Box>
            </StreakDisplay>
          </Tooltip>

          <Stack
            direction="row"
            spacing={1.5}
            alignItems="center"
            onClick={handleClick}
            sx={{
              cursor: "pointer",
              "&:hover": {
                "& .MuiTypography-root": { color: "#2D2D2D" },
                "& .MuiAvatar-root": { bgcolor: "#D8D4F0" },
              },
            }}
          >
            <Avatar
              sx={{
                bgcolor: "#E0E0E0",
                padding: "10px",
                color: "#2D2D2D",
                width: 42,
                height: 42,
                fontSize: "16px",
                fontWeight: "550",
              }}
            >
              {getInitials(userData?.name)}
            </Avatar>
          </Stack>

          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            slotProps={{
              paper: {
                sx: {
                  mt: 1.5,
                  boxShadow: "0 8px 32px rgba(0,0,0,0.12)",
                  borderRadius: "12px",
                  width: "360px",
                  zIndex: 1300,
                  animation: "popoverEnter 0.2s ease-out",
                  "@keyframes popoverEnter": {
                    "0%": {
                      opacity: 0,
                      transform: "scale(0.95) translateY(-10px)",
                    },
                    "100%": {
                      opacity: 1,
                      transform: "scale(1) translateY(0)",
                    },
                  },
                },
              },
            }}
          >
            <Box sx={{ p: 3, bgcolor: "#F9FAFB" }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Avatar
                  sx={{
                    bgcolor: "#4F46E5",
                    width: 48,
                    height: 48,
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  {getInitials(userData?.name)}
                </Avatar>
                <Box>
                  <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                    {userData?.name}
                  </Typography>
                  <Typography sx={{ color: "#6B7280", fontSize: "14px" }}>
                    {userData?.email}
                  </Typography>
                </Box>
              </Stack>
            </Box>

            {/* Analytics Cards */}
            <Box sx={{ p: 2 }}>
              <Grid container spacing={2}>
                {/* Applied Today */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "#EEF2FF",
                      borderRadius: 2,
                      cursor: "pointer",
                      transition: "transform 0.2s, box-shadow 0.2s",
                      "&:hover": {
                        transform: "translateY(-2px)",
                        boxShadow: "0 4px 12px rgba(79, 70, 229, 0.2)",
                      },
                    }}
                    onClick={() => {
                      handleClose();
                      handleNavigation("/dashboard/analytics");
                    }}
                  >
                    <Typography
                      sx={{ color: "#6B7280", fontSize: "13px", mb: 1 }}
                    >
                      Applied Today
                    </Typography>
                    <Typography sx={{ fontWeight: 600, fontSize: "24px" }}>
                      {dailyLimit}
                    </Typography>
                  </Box>
                </Grid>

                {/* Daily Limit */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "#FEF2F2",
                      borderRadius: 2,
                      cursor: "pointer",
                      transition: "transform 0.2s, box-shadow 0.2s",
                      "&:hover": {
                        transform: "translateY(-2px)",
                        boxShadow: "0 4px 12px rgba(239, 68, 68, 0.2)",
                      },
                    }}
                    onClick={() => {
                      handleClose();
                      handleNavigation("/dashboard/pricing");
                    }}
                  >
                    <Typography
                      sx={{ color: "#6B7280", fontSize: "13px", mb: 1 }}
                    >
                      Daily Limit
                    </Typography>
                    <Typography sx={{ fontWeight: 600, fontSize: "24px" }}>
                      {applicationDaily}
                    </Typography>
                  </Box>
                </Grid>

                {/* Plan Type */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "#ECFDF5",
                      borderRadius: 2,
                      cursor: "pointer",
                      transition: "transform 0.2s, box-shadow 0.2s",
                      "&:hover": {
                        transform: "translateY(-2px)",
                        boxShadow: "0 4px 12px rgba(16, 185, 129, 0.2)",
                      },
                    }}
                    onClick={() => {
                      handleClose();
                      handleNavigation("/dashboard/pricing");
                    }}
                  >
                    <Typography
                      sx={{ color: "#6B7280", fontSize: "13px", mb: 1 }}
                    >
                      Your Plan
                    </Typography>
                    <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
                      {planDetails.planStarted == 0 ? "Free" : planDetails?.planName}
                    </Typography>
                  </Box>
                </Grid>

                {/* Plan Ending */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "#FEF3C7",
                      borderRadius: 2,
                      cursor: "pointer",
                      transition: "transform 0.2s, box-shadow 0.2s",
                      "&:hover": {
                        transform: "translateY(-2px)",
                        boxShadow: "0 4px 12px rgba(245, 158, 11, 0.2)",
                      },
                    }}
                    onClick={() => {
                      handleClose();
                      handleNavigation("/dashboard/pricing");
                    }}
                  >
                    <Typography
                      sx={{ color: "#6B7280", fontSize: "13px", mb: 1 }}
                    >
                      Plan Ending
                    </Typography>
                    <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                      {secondaryValue()}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {/* <Tooltip title={userData?.name} placement="left">
              <MenuItemWrapper>
                <PersonIcon sx={{ fontSize: 20 }} />
                <Typography
                  noWrap
                  sx={{
                    flex: 1,
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {userData?.name}
                </Typography>
              </MenuItemWrapper>
            </Tooltip>

            <Tooltip title={userData?.email} placement="left">
              <MenuItemWrapper>
                <EmailIcon sx={{ fontSize: 20 }} />
                <Typography
                  noWrap
                  sx={{
                    flex: 1,
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {userData?.email}
                </Typography>
              </MenuItemWrapper>
            </Tooltip> */}

            <Divider sx={{ my: "2px" }} />

            <Box
              onClick={handleLogout}
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                gap: 1.5,
                cursor: "pointer",
                "&:hover": {
                  bgcolor: "rgba(0,0,0,0.04)",
                },
              }}
            >
              <LogoutIcon sx={{ fontSize: 20, color: "#666666" }} />
              <Typography sx={{ color: "#666666" }}>Logout</Typography>
            </Box>

            {/* <MenuItemWrapper onClick={handleLogout}>
              <LogoutIcon sx={{ fontSize: 20 }} />
              <Typography sx={{ fontSize: "14px" }}>Logout</Typography>
            </MenuItemWrapper> */}
          </Popover>
        </Stack>
      </Toolbar>
      <AccessModeConfirmationModal
        open={confirmModalOpen}
        onClose={handleConfirmModalClose}
        userName={selectedUserData?.name || userData?.name}
        userEmail={selectedUserData?.email || userData?.email}
        accessMode={selectedMode}
      />
    </StyledAppBar>
  );
};

export default Navbar;
