import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const InfoContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "1270px",
  margin: "0 auto",
  padding: "20px",
  background: "#FAFAFA",
  borderRadius: "16px",
  gap: 1,
});

const ProfileSelector = ({ selectedProfile, profiles, searchValues }) => {
  const selectedProfileDetails = profiles.find(
    (profile) => profile.resumeId === selectedProfile
  );

  return (
    <InfoContainer>
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 500,
          color: "#000",
          mb: 1,
        }}
      >
        {searchValues?.jobTitle && (
          <span>Showing results for "{searchValues.jobTitle}"</span>
        )}
      </Typography>

      <Typography
        sx={{
          fontSize: "16px",
          color: "#404040",
          display: "flex",
          alignItems: "center",
          gap: "4px",
        }}
      >
        {selectedProfileDetails ? (
          <>
            Profile selected -{" "}
            <strong>{selectedProfileDetails.resumeName}</strong>
            <Typography
              component="span"
              sx={{ color: "#2d2d2d", fontSize: "14px" }}
            >
              ({selectedProfileDetails.percentageCompleted}% Complete)
            </Typography>
          </>
        ) : (
          <span style={{ color: "#666" }}>No profile selected</span>
        )}
      </Typography>
    </InfoContainer>
  );
};

export default ProfileSelector;
