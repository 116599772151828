// components/sections/Languages/EditMode.jsx
import React from "react";
import {
  Grid,
  Box,
  TextField,
  Button,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const proficiencyLevels = [
  { value: "Native", label: "Native" },
  { value: "Fluent", label: "Fluent" },
  { value: "Advanced", label: "Advanced" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Basic", label: "Basic" },
];

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    "&:hover fieldset": {
      borderColor: "#2D2D2D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2D2D2D",
      borderWidth: "1px",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E0E0E0",
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: "#2D2D2D",
    },
  },
};

const FormTextField = React.memo(
  ({
    label,
    value,
    onChange,
    onBlur,
    error,
    helperText,
    select = false,
    options = [],
    ...props
  }) => (
    <TextField
      fullWidth
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      select={select}
      variant="outlined"
      sx={textFieldStyles}
      {...props}
    >
      {select && (
        <>
          <MenuItem value="">Select {label}</MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </>
      )}
    </TextField>
  )
);

const LanguageForm = ({
  language,
  index,
  errors = {},
  touched = {},
  onChange,
  onBlur,
  onDelete,
}) => {
  const handleChange = (field) => (event) => {
    const value = event.target.value;
    const updatedLanguage = {
      ...language,
      [field]: value,
    };
    onChange(index, updatedLanguage);
  };

  const handleBlur = (field) => () => {
    // Added this function
    onBlur(index, field);
  };

  return (
    <Box
      sx={{
        mb: 2.5,
        display: "flex",
        alignItems: "flex-start",
        gap: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            label="Language"
            value={language.language || ""}
            onChange={handleChange("language")}
            onBlur={handleBlur("language")}
            error={touched?.language && !!errors?.language}
            helperText={touched?.language && errors?.language}
            variant="outlined"
            sx={textFieldStyles}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            label="Proficiency"
            value={language.level || ""}
            onChange={handleChange("level")}
            onBlur={handleBlur("level")}
            error={touched?.level && !!errors?.level}
            helperText={touched?.level && errors?.level}
            variant="outlined"
            sx={textFieldStyles}
          >
            <MenuItem value="">Select Proficiency Level</MenuItem>
            {proficiencyLevels.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <IconButton
        onClick={() => onDelete(index)}
        sx={{
          color: "#666666",
          mt: 1,
          "&:hover": {
            color: "#FF7875",
          },
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

const EditMode = ({
  languages,
  errors,
  touched,
  onChange,
  onBlur,
  onAdd,
  onDelete,
}) => {
  const handleFieldChange = (index, updatedLanguage) => {
    const newLanguages = [...languages];
    newLanguages[index] = updatedLanguage;
    onChange(newLanguages);
  };

  return (
    <Box>
      {languages.map((lang, index) => (
        <LanguageForm
          key={index}
          language={lang}
          index={index}
          errors={errors[index]}
          touched={touched[index]}
          onChange={handleFieldChange}
          onBlur={onBlur}
          onDelete={onDelete}
        />
      ))}

      <Button
        startIcon={<AddIcon />}
        onClick={onAdd}
        sx={{
          mt: 2,
          color: "#2D2D2D",
          borderRadius: "8px",
          padding: "8px 16px",
          borderColor: "#2D2D2D",
          "&:hover": {
            borderColor: "#2D2D2D",
            backgroundColor: "rgba(45, 45, 45, 0.04)",
          },
        }}
        variant="outlined"
      >
        Add Another Language
      </Button>
    </Box>
  );
};

export default EditMode;
