import React, { useState, useEffect, useRef } from "react";
import { Box, Container, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import step1Image from "../../ats-dashboard/assets/Automation.png";
import step2Image from "../../ats-dashboard/assets/Profile.png";
import step3Image from "../../ats-dashboard/assets/Analytics.png";

// Fade in animation component
const FadeInBox = styled(Box)`
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  will-change: opacity, transform;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Gradient border box for demo
const GradientBorderBox = styled(Box)({
  position: "relative",
  padding: "2px",
  borderRadius: "16px",
  background: "linear-gradient(to right, #4F46E5, #7C3AED)",
  boxShadow: "0 10px 30px rgba(0, 0, 0, 0.15)",
  "& > div": {
    background: "white",
    borderRadius: "14px",
    height: "100%",
    width: "100%",
  },
});

// Icon container with gradient
const IconBox = styled(Box)({
  width: "56px",
  height: "56px",
  borderRadius: "12px",
  background: "linear-gradient(45deg, #4F46E5 30%, #7C3AED 90%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "24px",
  boxShadow: "0 4px 15px rgba(79, 70, 229, 0.15)",
  "& svg": {
    fontSize: "32px",
    color: "white",
  },
});

// Demo Animation Component
const DemoAnimation = ({ step, imageSrc }) => {
  return (
    <GradientBorderBox
      sx={{
        height: "400px",
        width: "100%",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          overflow: "hidden",
          borderRadius: "15px",
          position: "relative",
        }}
      >
        {imageSrc ? (
          <Box
            component="img"
            src={imageSrc}
            alt={`Step ${step} demonstration`}
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              display: "block",
            }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 3,
              height: "100%",
            }}
          >
            <Typography variant="h6" color="text.secondary">
              Animation for Step {step}
            </Typography>
          </Box>
        )}
      </Box>
    </GradientBorderBox>
  );
};

const StepSection = styled(Box)({
  minHeight: "65vh",
  display: "flex",
  alignItems: "center",
  position: "relative",
  scrollSnapAlign: "start",
});

const GradientText = styled("span")({
  background: "linear-gradient(to right, #4F46E5, #7C3AED)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
});

const HowItWorks = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [visibleSections, setVisibleSections] = useState(new Set());
  const containerRef = useRef(null);

  const steps = [
    {
      id: 1,
      heading: "Automated Job Applications",
      description:
        "Apply to jobs across multiple platforms with a single click. Our agent handles the entire application process.",
      imageSrc: step1Image,
      icon: <RocketLaunchOutlinedIcon />,
    },
    {
      id: 2,
      heading: "Smart Referral Emails",
      description:
        "Automatically send customized referral emails to relevant employees at companies you're applying to.",
      imageSrc: step2Image,
      icon: <EmailOutlinedIcon />,
    },
    {
      id: 3,
      heading: "Track Everything in One Place",
      description:
        "Track all your applications and referral emails in real-time through our analytics dashboard.",
      imageSrc: step3Image,
      icon: <DashboardOutlinedIcon />,
    },
  ];

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        const stepId = parseInt(entry.target.getAttribute("data-step"));
        if (entry.isIntersecting) {
          setActiveStep(stepId);
          setVisibleSections((prev) => new Set(prev).add(stepId));
        } else {
          setVisibleSections((prev) => {
            const newSet = new Set(prev);
            newSet.delete(stepId);
            return newSet;
          });
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: "-15% 0px",
      threshold: 0.2,
    });

    const sections = document.querySelectorAll(".step-section");
    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);

  return (
    <Box
      id="how-it-works"
      sx={{
        bgcolor: "#fff",
      }}
    >
      {/* Main Heading Section */}
      <Box
        sx={{
          minHeight: "20vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          pb: 2,
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "1.75rem", md: "2.5rem" },
            fontWeight: 700,
            color: "#2d2d2d",
          }}
        >
          Your <GradientText>AI Agent</GradientText> does all the work for you
        </Typography>
      </Box>

      {/* Steps Container */}
      <Box ref={containerRef}>
        {steps.map((step) => (
          <StepSection
            key={step.id}
            className="step-section"
            data-step={step.id}
          >
            <Container maxWidth="lg">
              <Box
                sx={{
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                  py: 4,
                }}
              >
                {/* Step Content */}
                <FadeInBox
                  sx={{ flex: 0.8, mt: -18 }}
                  className={visibleSections.has(step.id) ? "visible" : ""}
                >
                  <IconBox>{step.icon}</IconBox>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: { xs: "1.25rem", md: "1.7rem" },
                      fontWeight: 600,
                      color: "#2d2d2d",
                      mb: 2,
                    }}
                  >
                    {step.heading}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#666666",
                      fontSize: { xs: "1rem", md: "1.1rem" },
                      lineHeight: 1.6,
                      maxWidth: "450px",
                    }}
                  >
                    {step.description}
                  </Typography>
                </FadeInBox>

                {/* Demo Content with fade in */}
                <FadeInBox
                  sx={{ flex: 1.2 }}
                  className={visibleSections.has(step.id) ? "visible" : ""}
                >
                  <DemoAnimation step={step.id} imageSrc={step.imageSrc} />
                </FadeInBox>
              </Box>
            </Container>
          </StepSection>
        ))}
      </Box>
    </Box>
  );
};

export default HowItWorks;
