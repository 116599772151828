// TemplateCard.jsx
import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { DeleteOutline as DeleteIcon } from "@mui/icons-material";
import {
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const CardWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  borderRadius: "16px",
  padding: "28px",
  height: "480px",
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  maxWidth: "100%",
  margin: "0 auto",
  cursor: "pointer",
  transition: "transform 0.2s ease, box-shadow 0.2s ease",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
  },
}));

const StyledButton = styled(Button)({
  textTransform: "none",
  padding: "12px",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: 600,
  backgroundColor: "#2D2D2D",
  color: "#FFFFFF",
  width: "100%",
  "&:hover": {
    backgroundColor: "#404040",
  },
});

const TemplatePreview = styled(Box)({
  flex: 1,
  backgroundColor: "#f8f9fa",
  borderRadius: "8px",
  padding: "16px",
  marginTop: "16px",
  marginBottom: "20px",
  position: "relative",
  height: "300px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
});

const SubjectPreview = styled(Box)({
  padding: "0 0 12px 0",
  borderBottom: "1px solid #e0e0e0",
  marginBottom: "12px",
});

const PreviewContent = styled(Box)({
  flex: 1,
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: "6",
  WebkitBoxOrient: "vertical",
  lineHeight: "2",
  "& span": {
    lineHeight: "2",
  },
});

const VariableChip = styled(Box)(({ color }) => ({
  display: "inline-block",
  padding: "0 8px",
  borderRadius: "12px",
  backgroundColor: `${color}10`,
  border: `1px solid ${color}`,
  color: color,
  fontSize: "14px",
  margin: "0 2px",
}));

const renderContent = (content) => {
  if (!content) return null;

  const variables = {
    "[Job Title]": "#4A90E2",
    "[Company Name]": "#50B83C",
    "[Role]": "#9B51E0",
    "[Recipient Name]": "#F5A623",
  };

  let elements = [];
  let lastIndex = 0;
  let match;
  const regex = /\[(.*?)\]/g;

  while ((match = regex.exec(content)) !== null) {
    if (match.index > lastIndex) {
      elements.push(
        <span key={lastIndex}>{content.slice(lastIndex, match.index)}</span>
      );
    }

    elements.push(
      <VariableChip key={match.index} color={variables[match[0]] || "#666666"}>
        {match[1]}
      </VariableChip>
    );

    lastIndex = match.index + match[0].length;
  }

  if (lastIndex < content.length) {
    elements.push(<span key={lastIndex}>{content.slice(lastIndex)}</span>);
  }

  return elements;
};

const TemplateCard = ({ template, isNew, onEdit, onDelete }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setShowDeleteDialog(true);
  };

  const handleCardClick = () => {
    onEdit();
  };

  if (isNew) {
    return (
      <CardWrapper onClick={handleCardClick}>
        <Box>
          <Typography
            sx={{
              color: "#2D2D2D",
              fontWeight: 600,
              fontSize: "22px",
              mb: 2,
            }}
          >
            Create New Template
          </Typography>
          <Typography sx={{ color: "#666666", fontSize: "16px" }}>
            Start with our default template and customize it to craft
            professional email templates for any purpose, or explore our proven
            templates - trusted by professionals for successful referrals. Save
            time by creating reusable templates that work.
          </Typography>
        </Box>
        <StyledButton onClick={onEdit} startIcon={<AddIcon />}>
          Create Template
        </StyledButton>
      </CardWrapper>
    );
  }

  return (
    <CardWrapper onClick={handleCardClick}>
      <Box sx={{ position: "relative" }}>
        {!template.isDefault ? (
          <IconButton
            onClick={handleDeleteClick}
            sx={{
              position: "absolute",
              right: -8,
              top: -8,
              opacity: 0.5,
              transition: "all 0.2s ease",
              "&:hover": {
                opacity: 1,
                backgroundColor: "#fff0f0",
                color: "#e85c5c",
              },
            }}
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <Chip
            label="Default"
            size="small"
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              backgroundColor: "#f0f0f0",
              color: "#666",
              height: "24px",
              fontSize: "12px",
              fontWeight: 550,
            }}
          />
        )}
        <Typography
          sx={{
            color: "#2D2D2D",
            fontWeight: 600,
            fontSize: "22px",
            mb: 2,
          }}
        >
          {template.name}
        </Typography>

        <TemplatePreview>
          <SubjectPreview>
            <Typography sx={{ color: "#666666", fontSize: "14px", mb: 1 }}>
              Subject:
            </Typography>
            {renderContent(template.subject)}
          </SubjectPreview>
          <PreviewContent>{renderContent(template.content)}</PreviewContent>
        </TemplatePreview>
      </Box>
      <StyledButton disableRipple onClick={onEdit}>
        Edit Template
      </StyledButton>

      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onClick={(e) => e.stopPropagation()}
        PaperProps={{
          sx: {
            borderRadius: "12px",
            p: 1,
          },
        }}
      >
        <DialogTitle sx={{ fontSize: "18px", fontWeight: 600 }}>
          Delete Template?
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ color: "#666" }}>
            Are you sure you want to delete "{template.name}"? This action
            cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 1 }}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setShowDeleteDialog(false);
            }}
            sx={{
              textTransform: "none",
              fontSize: "16px",
              fontWeight: 550,
              padding: "8px 16px",
              borderRadius: "8px",
              color: "#666",
              "&:hover": { backgroundColor: "#f5f5f5" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onDelete(template.id);
              setShowDeleteDialog(false);
            }}
            sx={{
              textTransform: "none",
              color: "#e85c5c",
              fontSize: "16px",
              fontWeight: 550,
              padding: "8px 16px",
              borderRadius: "8px",
              "&:hover": { backgroundColor: "#fff0f0" },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </CardWrapper>
  );
};

export default TemplateCard;
