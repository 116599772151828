// src/components/analytics/dashboard/AnalyticsDashboard.js
import React, { useState, useEffect } from "react";
import { Box, Container, Tab, Tabs, Paper, Typography } from "@mui/material";
import TimeFrameSelector from "./TimeFrameSelector";
import MetricsCards from "./MetricsCards";
import ApplicationsTable from "../tables/ApplicationsTable";
import SessionsTable from "../tables/SessionsTable";
import ResumeAnalytics from "../ResumeAnalytics";
import CompanyATSAnalytics from "../CompanyATSAnalytics";
import {
  mockApiCall,
  mockOwnerApiCall,
  mockOperatorApiCall,
  mockSchoolApiCall,
} from "../services/analyticsApi";
import axios from "axios";
import { useStateStore } from "../../../../store";
import OperatorAnalyticsTable from "../ownerView/OperatorAnalyticsTable";
import UserAnalyticsTable from "../operatorView/UserAnalyticsTable";
import StudentAnalyticsTable from "../educationOwnerView/StudentAnalyticsTable";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const mockUsers = [
  {
    id: 1,
    name: "Alex Williams",
    email: "alex.w@company.com",
    analytics: {
      emailsSent: 45,
      jobsApplied: 12,
      lastActivity: "2024-02-25T14:32:00Z",
    },
  },
  {
    id: 2,
    name: "Sarah Chen",
    email: "sarah.c@company.com",
    analytics: {
      emailsSent: 78,
      jobsApplied: 27,
      lastActivity: "2024-03-01T09:15:00Z",
    },
  },
  {
    id: 3,
    name: "David Garcia",
    email: "david.g@company.com",
    analytics: {
      emailsSent: 56,
      jobsApplied: 18,
      lastActivity: "2024-02-28T16:45:00Z",
    },
  },
  {
    id: 4,
    name: "Maria Rodriguez",
    email: "maria.r@company.com",
    analytics: {
      emailsSent: 102,
      jobsApplied: 35,
      lastActivity: "2024-03-02T11:20:00Z",
    },
  },
  {
    id: 5,
    name: "James Wilson",
    email: "james.w@company.com",
    analytics: {
      emailsSent: 91,
      jobsApplied: 29,
      lastActivity: "2024-03-01T15:10:00Z",
    },
  },
  {
    id: 6,
    name: "Jennifer Lee",
    email: "jennifer.l@company.com",
    analytics: {
      emailsSent: 63,
      jobsApplied: 21,
      lastActivity: "2024-02-27T10:05:00Z",
    },
  },
  {
    id: 7,
    name: "Michael Brown",
    email: "michael.b@company.com",
    analytics: {
      emailsSent: 37,
      jobsApplied: 14,
      lastActivity: "2024-02-26T13:30:00Z",
    },
  },
];

const AnalyticsDashboard = ({ viewType = "individual" }) => {
  const { token, userData, planDetails, planDetailsFetched, navClickCount } =
    useStateStore();
  const [timeFrame, setTimeFrame] = useState("daily");
  const [metrics, setMetrics] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error", // can be "error", "warning", "info", "success"
  });

  useEffect(() => {
    if (!planDetailsFetched) {
      return;
    }
    fetchMetrics();
  }, [timeFrame, planDetails?.planStarted, viewType, navClickCount]);

  const fetchMetrics = async () => {
    setLoading(true);
    try {
      const params = {
        timeframe: timeFrame,
        ...(timeFrame === "custom" &&
          dateRange && {
            startDate: dateRange.start,
            endDate: dateRange.end,
          }),
      };

      // Use mock data for owner view
      if (viewType === "owner") {
        const ownerData = await mockOwnerApiCall(params);
        const { data } = await axios.post(
          `https://backend.lazyapply.com/lazyapplyV2/analytics/owner`,
          {
            ...params,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setMetrics(data?.ownerMetrics || ownerData);
      } else if (viewType === "operator") {
        const operatorData = await mockOperatorApiCall(params);
        const { data } = await axios.post(
          `https://backend.lazyapply.com/lazyapplyV2/analytics/operator`,
          {
            ...params,
            maxUsers: planDetails?.licenses?.total || 100,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setMetrics(data?.operatorMetrics || operatorData);
      } else if (viewType === "school") {
        const schoolData = await mockSchoolApiCall(params);
        const { data } = await axios.post(
          `https://backend.lazyapply.com/lazyapplyV2/analytics/owner/school`,
          {
            ...params,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setMetrics(data?.ownerMetrics || schoolData);
      } else if (!planDetails?.planStarted) {
        const data = await mockApiCall("/api/analytics/metrics", {
          timeframe: timeFrame,
        });
        setMetrics(data);
      } else {
        const v2Id = userData?.v2Id || "";
        const { data } = await axios.post(
          `https://backend.lazyapply.com/lazyapplyV2/count/${v2Id}`,
          {
            ...params,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setMetrics(data);
      }
    } catch (error) {
      let message = error?.response?.data?.error || "Unknown error";
      console.error("Error fetching metrics:", message);
      if (message && message === "You don't have permission to view analytics")
        openSnackbar("Error fetching metrics. Please upgrade your plan");
      else openSnackbar("Error fetching metrics. Please try again.");
    }
    setLoading(false);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleTimeFrameChange = (newTimeFrame) => {
    setTimeFrame(newTimeFrame);
    if (newTimeFrame !== "custom") {
      setDateRange(null);
    }
  };

  const handleCustomDateSelect = (range) => {
    setDateRange(range);
    setTimeFrame("custom");
  };

  //  The function to fetch and set the selected user

  const handleViewUserStats = async (userId) => {
    setLoading(true);
    try {
      // In a real app, this would be an API call
      // For now, we'll use the mock data
      if (viewType === "operator") {
        // Find the user in our mock data
        const user = mockUsers.find((u) => u.id === userId);
        if (user) {
          setSelectedUser(user);
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
    setLoading(false);
  };

  // The function to return to overall view

  const handleBackToOverall = () => {
    setSelectedUser(null);
  };

  const openSnackbar = (message, severity = "error") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const renderTabContent = () => {
    if (viewType === "owner") {
      switch (currentTab) {
        case 0:
          return (
            <OperatorAnalyticsTable
              timeFrame={timeFrame}
              dateRange={dateRange}
            />
          );
        default:
          return <OperatorAnalyticsTable timeFrame={timeFrame} />;
      }
    } else if (viewType === "operator") {
      switch (currentTab) {
        case 0:
          return (
            <UserAnalyticsTable
              timeFrame={timeFrame}
              dateRange={dateRange}
              selectedUser={selectedUser}
              onApplyAsUser={handleViewUserStats}
              onBackToOverall={handleBackToOverall}
            />
          );
        default:
          return <UserAnalyticsTable timeFrame={timeFrame} />;
      }
    } else if (viewType === "school") {
      switch (currentTab) {
        case 0:
          return (
            <StudentAnalyticsTable
              timeFrame={timeFrame}
              dateRange={dateRange}
            />
          );
        default:
          return <StudentAnalyticsTable timeFrame={timeFrame} />;
      }
    } else {
      switch (currentTab) {
        case 0:
          return <ApplicationsTable />;
        case 1:
          return <ResumeAnalytics />;
        // case 2:
        //   return <SessionsTable />;
        // case 3:
        //   return <CompanyATSAnalytics />;
        default:
          return <ApplicationsTable />;
      }
    }
  };

  // Change tab options based on viewType
  const renderTabs = () => {
    if (viewType === "owner") {
      return (
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
          sx={{
            "& .MuiTab-root": {
              textTransform: "none",
              fontSize: "20px",
              fontWeight: 500,
              minWidth: "140px",
              color: "#666",
              py: 1.5,
              mx: 1,
              "&.Mui-selected": {
                color: "#2D2D2D",
                fontWeight: 600,

                borderRadius: "8px",
              },
              "&:hover": {
                color: "#2D2D2D",
              },
            },
          }}
        >
          <Tab disableRipple label="Operator Analytics" />
        </Tabs>
      );
    } else if (viewType === "operator") {
      return (
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
          sx={{
            "& .MuiTab-root": {
              textTransform: "none",
              fontSize: "20px",
              fontWeight: 500,
              minWidth: "140px",
              color: "#666",
              py: 1.5,
              mx: 1,
              "&.Mui-selected": {
                color: "#2D2D2D",
                fontWeight: 600,

                borderRadius: "8px",
              },
              "&:hover": {
                color: "#2D2D2D",
              },
            },
          }}
        >
          <Tab disableRipple label="User Analytics" />
        </Tabs>
      );
    } else if (viewType === "school") {
      return (
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
          sx={{
            "& .MuiTab-root": {
              textTransform: "none",
              fontSize: "20px",
              fontWeight: 500,
              minWidth: "140px",
              color: "#666",
              py: 1.5,
              mx: 1,
              "&.Mui-selected": {
                color: "#2D2D2D",
                fontWeight: 600,

                borderRadius: "8px",
              },
              "&:hover": {
                color: "#2D2D2D",
              },
            },
          }}
        >
          <Tab disableRipple label="User Analytics" />
        </Tabs>
      );
    } else {
      // Original tabs
      return (
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
          sx={{
            "& .MuiTab-root": {
              textTransform: "none",
              fontSize: "17px",
              fontWeight: 500,
              minWidth: "140px",
              color: "#666",
              py: 1.5,
              mx: 1,
              "&.Mui-selected": {
                color: "#2D2D2D",
                fontWeight: 600,

                borderRadius: "8px",
              },
              "&:hover": {
                color: "#2D2D2D",
              },
            },
          }}
        >
          <Tab disableRipple label="Applications" />
          <Tab disableRipple label="Resume Analytics" />
        </Tabs>
      );
    }
  };

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          py: 4,
          backgroundColor: "#fff",
          minHeight: "100vh",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Typography
            variant="h5"
            component="h1"
            sx={{
              fontWeight: 600,
              color: "#2D2D2D",
            }}
          >
            {viewType === "owner"
              ? "Operator Analytics"
              : viewType === "operator"
              ? selectedUser
                ? `User Analytics (${selectedUser.name})`
                : "User Analytics"
              : viewType === "school"
              ? "User Analytics"
              : "Analytics Dashboard"}
          </Typography>
          <TimeFrameSelector
            value={timeFrame}
            onChange={handleTimeFrameChange}
            onCustomDateSelect={handleCustomDateSelect}
            selectedRange={dateRange}
          />
        </Box>

        {/* Metrics Cards */}
        <Box sx={{ mt: 3 }}>
          <MetricsCards
            metrics={metrics}
            loading={loading}
            viewType={viewType}
          />
        </Box>

        {/* Tabs */}

        <Box
          sx={{
            mt: 6, // Increased top margin
            backgroundColor: "#F8F8F8", // Light gray background
            borderRadius: "12px",
            padding: "1px", // Tiny padding to contain the inner elements
            border: "1px solid rgba(0,0,0,0.12)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
            }}
          >
            {renderTabs()}

            {/* <Tabs
              value={currentTab}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              sx={{
                "& .MuiTab-root": {
                  textTransform: "none",
                  fontSize: "17px",
                  fontWeight: 500,
                  minWidth: "140px",
                  color: "#666",
                  py: 1.5, // Vertical padding for tabs
                  mx: 1, // Horizontal margin between tabs
                  "&.Mui-selected": {
                    color: "#2D2D2D",
                    fontWeight: 600,
                    // backgroundColor: "#fff",
                    borderRadius: "8px",
                  },
                  "&:hover": {
                    color: "#2D2D2D",
                  },
                },
              }}
            >
              <Tab disableRipple label="Applications" />
              <Tab disableRipple label="Sessions" />
              <Tab disableRipple label="Resume Analytics" />
              <Tab disableRipple label="Company & ATS" />
            </Tabs> */}
          </Box>

          {/* Tab Content */}
          <Box
            sx={{
              mt: 2,
              backgroundColor: "#fff",
              borderRadius: "12px",
              p: 3,
            }}
          >
            {renderTabContent()}
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{
            width: "100%",
            fontWeight: 500,
            borderRadius: "8px",
            boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AnalyticsDashboard;
