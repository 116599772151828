// DetailedView/index.jsx
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Container, IconButton, Grid, Skeleton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import LeftSection from "./components/LeftSection";
import RightSection from "./components/RightSection";
import axios from "axios";
import { useStateStore } from "../../../store/index";

// Sample data - Later we'll fetch based on ID
const profileData = {
  id: 1,
  role: "Software Engineer Journey",
  company: "FAANG Company",
  experience: "3-5 YOE",
  duration: "Jan-Mar 2024",
  metrics: {
    applications: 45,
    responseRate: 35,
    offers: 3,
  },
  timeline: [
    { dateRange: "Jan 15-30", stage: "Applications", count: "45" },
    { dateRange: "Feb 1-15", stage: "Responses", count: "16" },
    { dateRange: "Feb 15-28", stage: "Interviews", count: "8" },
    { dateRange: "Mar 1-15", stage: "Offers", count: "3" },
  ],
};

const LoadingSkeleton = () => (
  <Grid container spacing={4}>
    <Grid item xs={12} md={6}>
      {/* Left Section Loading */}
      <Box mb={4}>
        <Skeleton variant="text" width="70%" height={40} />
        <Skeleton variant="text" width="50%" height={24} />
      </Box>

      {/* Metrics Loading */}
      <Grid container spacing={2} mb={4}>
        {[1, 2, 3].map((item) => (
          <Grid item xs={4} key={item}>
            <Skeleton variant="rectangular" height={100} />
          </Grid>
        ))}
      </Grid>

      {/* Timeline Loading */}
      <Skeleton variant="text" width="40%" height={32} />
      <Skeleton variant="rectangular" height={200} sx={{ mt: 2 }} />
    </Grid>

    <Grid item xs={12} md={6}>
      {/* Right Section Loading */}
      <Skeleton variant="text" width="40%" height={32} />
      <Skeleton variant="rectangular" height={400} sx={{ mt: 2 }} />
      <Skeleton variant="text" width="40%" height={32} sx={{ mt: 4 }} />
      <Skeleton variant="rectangular" height={100} sx={{ mt: 2 }} />
    </Grid>
  </Grid>
);

const DetailedView = () => {
  const history = useHistory();
  const { id } = useParams();
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useStateStore();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `https://backend.lazyapply.com/lazyapplyV2/explore/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          setProfile(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        setError(error.response?.data?.message || "Failed to load profile");
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfile();
  }, [id]);

  const handleBack = () => {
    history.push("/dashboard/explore");
  };

  return (
    <Box sx={{ bgcolor: "#fff", minHeight: "100vh" }}>
      <Box sx={{ borderBottom: 1, borderColor: "grey.100", py: 1 }}>
        <IconButton onClick={handleBack} sx={{ color: "text.primary", ml: 4 }}>
          <ArrowBack />
        </IconButton>
      </Box>

      <Container maxWidth="lg" sx={{ py: 4 }}>
        {isLoading ? (
          <LoadingSkeleton />
        ) : error ? (
          <Box
            sx={{
              py: 4,
              textAlign: "center",
              color: "text.secondary",
              bgcolor: "grey.50",
              borderRadius: 2,
              p: 4,
            }}
          >
            {error}
          </Box>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <LeftSection data={profile} />
            </Grid>
            <Grid item xs={12} md={6}>
              <RightSection data={profile} />
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default DetailedView;
