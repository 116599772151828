import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import {
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import GmailPermissionModal from "./GmailPermissionModal";
import { GoogleOAuthProvider } from "@react-oauth/google";

const GmailCard = styled(Paper)(({ isDefault }) => ({
  padding: "24px",
  height: "220px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  borderRadius: "16px",
  border: isDefault ? "2px solid #2D2D2D" : "none",
}));

const StyledButton = styled(Button)({
  textTransform: "none",
  padding: "8px 16px",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: 550,
  backgroundColor: "#2D2D2D",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#404040",
  },
});

const EmailCard = ({ email, onSetDefault, onConnect, onFailure, onEdit, onDelete }) => {
  const CLIENT_ID =
  "715818145189-7geg3j3td9u21nc9qe8eujb2l4dcbt88.apps.googleusercontent.com";
  const [isEditing, setIsEditing] = useState(false);
  const [tempEmail, setTempEmail] = useState(email.email);
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!email) {
      setEmailError("Email is required");
      return false;
    }
    if (!regex.test(email)) {
      setEmailError("Please enter a valid email address");
      return false;
    }
    setEmailError("");
    return true;
  };

  const handleSave = () => {
    if (validateEmail(tempEmail.trim())) {
      if (tempEmail !== email.email) {
        onEdit(email.id, tempEmail);
      }
      setEmailError("");
      setIsEditing(false);
    }
  };

  return (
    <>
      <GmailCard isDefault={email.isDefault}>
        <Box>
          {isEditing ? (
            <Box sx={{ display: "flex", gap: 2, alignItems: "flex-start" }}>
              <Box sx={{ flex: 1 }}>
                <TextField
                  fullWidth
                  value={tempEmail}
                  onChange={(e) => {
                    setTempEmail(e.target.value);
                    if (emailError) setEmailError("");
                  }}
                  size="small"
                  error={!!emailError}
                  helperText={emailError}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                    "& .MuiFormHelperText-root": {
                      color: "#d32f2f",
                      marginLeft: "0px",
                    },
                  }}
                />
              </Box>
              <Button
                variant="contained"
                onClick={handleSave}
                sx={{
                  bgcolor: "#2D2D2D",
                  "&:hover": { bgcolor: "#404040" },
                  textTransform: "none",
                  borderRadius: "8px",
                  fontWeight: 500,
                }}
              >
                Save
              </Button>
            </Box>
          ) : (
            <Box sx={{ mb: 2 }}>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Typography
                  sx={{ fontSize: "18px", fontWeight: 500, color: "#000" }}
                >
                  {email.email}
                </Typography>

                <Box sx={{ display: "flex", gap: 1 }}>
                  {!email.isDefault && (
                    <IconButton
                      onClick={() => setShowDeleteConfirm(true)}
                      size="small"
                      sx={{
                        "&:hover": {
                          backgroundColor: "#fff0f0",
                          color: "#ff4444",
                        },
                      }}
                    >
                      <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                  )}

                  {/* <IconButton
                    onClick={() => setIsEditing(true)}
                    size="small"
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton> */}
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box
                    sx={{
                      width: 8,
                      height: 8,
                      borderRadius: "50%",
                      backgroundColor: email.isConnected
                        ? "#4CAF50"
                        : "#ff4444",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "15px",
                      color: email.isConnected ? "#4CAF50" : "#ff4444",
                    }}
                  >
                    {email.isConnected ? "Connected" : "Not Connected"}
                  </Typography>
                </Box>
                {email.isDefault && (
                  <Chip
                    label="Default"
                    size="small"
                    sx={{
                      backgroundColor: "#2D2D2D",
                      color: "#fff",
                      fontSize: "13px",
                      padding: "2px 4px",
                    }}
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>

        <Box sx={{ display: "flex", gap: 2 }}>
          {!email.isDefault && (
            <StyledButton onClick={onSetDefault} sx={{ flex: 1 }}>
              Set as Default
            </StyledButton>
          )}
          {!email.isConnected && (
            <StyledButton
              onClick={() => setIsPermissionModalOpen(true)}
              sx={{ flex: 1 }}
            >
              Connect
            </StyledButton>
          )}
        </Box>
        <GoogleOAuthProvider clientId={CLIENT_ID}>
        <GmailPermissionModal
          open={isPermissionModalOpen}
          onClose={() => setIsPermissionModalOpen(false)}
          onConnect={() => {
            onConnect(email.id);
            setIsPermissionModalOpen(false);
          }}
          onFailure={(message)=>{
            onFailure(message);
            setIsPermissionModalOpen(false);
          }}
          email={email}
        />
        </GoogleOAuthProvider>
      </GmailCard>

      <Dialog
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "16px",
            padding: "8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            color: "#2D2D2D",
            padding: "16px 24px",
          }}
        >
          Delete Email Configuration?
        </DialogTitle>
        <DialogContent sx={{ padding: "16px 24px" }}>
          <DialogContentText sx={{ color: "#666666", fontSize: "16px" }}>
            Are you sure you want to remove <strong>{email.email}</strong>? This
            action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "16px 24px", gap: 1 }}>
          <Button
            onClick={() => setShowDeleteConfirm(false)}
            sx={{
              color: "#666666",
              textTransform: "none",
              padding: "8px 16px",
              borderRadius: "8px",
              fontWeight: 600,
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onDelete(email.id);
              setShowDeleteConfirm(false);
            }}
            sx={{
              color: "#ff4444",
              textTransform: "none",
              padding: "8px 16px",
              borderRadius: "8px",
              fontWeight: 600,
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "#fff0f0",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmailCard;
