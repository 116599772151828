import React, { useState } from "react";
import {
  Box,
  Card,
  Typography,
  Radio,
  Button,
  styled,
  CircularProgress,
} from "@mui/material";
import ReferralOptionsCard from "./ReferralOptionsCard";

const OptionCard = styled(Box)(({ theme, selected }) => ({
  padding: "24px",
  backgroundColor: "#f8f8f8",
  borderRadius: "8px",
  border: "1px solid #e0e0e0",
  display: "flex",
  alignItems: "flex-start",
  cursor: "pointer",
  transition: "all 0.2s ease",
  "&:hover": {
    backgroundColor: "#f5f5f5",
    borderColor: "#d0d0d0",
  },
  ...(selected && {
    borderColor: "#2d2d2d",
    backgroundColor: "rgba(45, 45, 45, 0.02)",
  }),
}));

const ApplicationOptionsCard = ({ onClose, onContinue }) => {
  const [selectedOption, setSelectedOption] = useState("without-emails");
  const [isProcessing, setIsProcessing] = useState(false);
  const [showReferralOptions, setShowReferralOptions] = useState(false);

  const options = [
    {
      id: "without-emails",
      title: "Direct Application",
      description: "Apply directly to the selected positions",
      subtext: "Quick and simple application process",
    },
    {
      id: "with-emails",
      title: "Application with Referral",
      description: "Apply and send referral emails to increase your chances",
      subtext: "Recommended for better visibility",
    },
  ];

  const handleOptionSelect = (optionId) => {
    setSelectedOption(optionId);
  };

  const handleContinue = async () => {
    if (selectedOption === "with-emails") {
      setShowReferralOptions(true);
      return;
    }

    setIsProcessing(true);
    await onContinue(selectedOption);
    setIsProcessing(false);
  };

  if (showReferralOptions) {
    return (
      <ReferralOptionsCard
        onClose={() => setShowReferralOptions(false)}
        onContinue={async (referralOptions) => {
          setIsProcessing(true);
          await onContinue({
            type: "with-emails",
            ...referralOptions,
          });
          setIsProcessing(false);
        }}
      />
    );
  }

  return (
    <Card
      sx={{
        width: "600px",
        maxWidth: "90vw",
        maxHeight: "90vh",
        overflow: "auto",
        borderRadius: "12px",
        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          backgroundColor: "#2d2d2d",
          padding: "24px",
          color: "#fff",
          borderRadius: "12px 12px 0 0",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 500, mb: 0.5 }}>
          Choose Application Method
        </Typography>
        <Typography variant="body2" sx={{ opacity: 0.8 }}>
          Select how you want to proceed with your applications
        </Typography>
      </Box>

      {/* Options */}
      <Box sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
        {options.map((option) => (
          <OptionCard
            disabled={option.id === "with-emails"}
            key={option.id}
            selected={selectedOption === option.id}
            onClick={() => {
              if (option.id !== "with-emails")
                handleOptionSelect(option.id)
            }}
          >
            <Radio
              disabled={option.id === "with-emails"}
              checked={selectedOption === option.id}
              onChange={() => {
                if (option.id !== "with-emails")
                  handleOptionSelect(option.id)
              }
              }
              sx={{
                color: "#2d2d2d",
                "&.Mui-checked": {
                  color: "#2d2d2d",
                },
              }}
            />
            <Box sx={{ ml: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 500,
                  color: "#2d2d2d",
                  mb: 0.5,
                }}
              >
                {option.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#2d2d2d",
                  mb: 0.5,
                  fontSize: "15px",
                }}
              >
                {option.description}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#666",
                  fontSize: "14px",
                }}
              >
                {option.subtext}
              </Typography>
            </Box>
          </OptionCard>
        ))}
      </Box>

      {/* Footer */}
      <Box
        sx={{
          p: 3,
          pt: 0,
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{
            borderRadius: "8px",
            textTransform: "none",
            fontSize: "16px",
            padding: "8px 24px",
            color: "#2d2d2d",
            borderColor: "#e0e0e0",
            "&:hover": {
              borderColor: "#2d2d2d",
              backgroundColor: "rgba(45, 45, 45, 0.04)",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isProcessing}
          onClick={handleContinue}
          sx={{
            borderRadius: "8px",
            textTransform: "none",
            fontSize: "16px",
            padding: "8px 24px",
            backgroundColor: "#2d2d2d",
            "&:hover": {
              backgroundColor: "#404040",
            },
            "&.Mui-disabled": {
              backgroundColor: "#cccccc",
            },
          }}
        >
          {isProcessing ? (
            <>
              Processing... <CircularProgress size={16} sx={{ ml: 1 }} />
            </>
          ) : (
            "Continue"
          )}
        </Button>
      </Box>
    </Card>
  );
};

export default ApplicationOptionsCard;
