/*global chrome*/
import React, { useEffect, useCallback, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import Dashboard from "./dashboard/dashboard";
import ScrollToTop from "./ScrollToTop";
import { Switch, Route, Redirect } from "react-router-dom";
import NoMatch from "./error";
import { useStateStore } from "../store/index";
import Login from "./ats-dashboard/LoginPage";
import { Hotjar } from "../index";
import axios from "axios";
import TeamTypeModal from "./ats-dashboard/cards/TeamTypeModal";
import Intercom from "@intercom/messenger-js-sdk";

function Popup() {
  const scriptinjected = useRef(null);
  const {
    setExtensionPresent,
    scriptInjectedGlobal,
    yourExtensionId,
    setChromeVersion,
    setScriptInjectedGlobal,
    globalLogoutV2,
    userDataInit,
    updateToken,
    userImageSrc,
    userAuthentication,
    isUserAuthenticated,
    userData,
    setDailyLimit,
    setApplicationDailyLimit,
    setPlanDetails,
    automationResults,
    setStreakData,
    setPlanDetailsFetched,
    enterpriseUser,
    setEnterpriseUser,
    planDetails,
    navClickCount,
  } = useStateStore();
  const userToken = useStateStore((state) => state.token);
  const viewType = enterpriseUser?.viewType || "individual";
  const [teamTypeModalOpen, setTeamTypeModalOpen] = useState(false);

  const handleTeamTypeSelect = (teamType) => {
    setPlanDetails({ ...planDetails, modelName: teamType });
    setEnterpriseUser({ viewType: teamType });
  };


  useEffect(() => {
    console.log("userdata", userData);
    if (Object.keys(userData).length > 0)
      Intercom({
        app_id: "cpi0banm",
        user_id: userData.email,
        name: userData.name,
        email: userData.email,
        user_v2Id: userData.v2Id,
        created_at: userData.created_at,
      });
  }, [userData]);

  useEffect(() => {
    console.log("userdata", userData);
    if (Object.keys(userData).length > 0)
      Intercom({
        app_id: "cpi0banm",
        user_id: userData.email,
        name: userData.name,
        email: userData.email,
        created_at: userData.created_at,
      });
  }, [userData]);

  useEffect(() => {
    console.log("userdata", userData);
    if (Object.keys(userData).length > 0)
      Intercom({
        app_id: "cpi0banm",
        user_id: userData.email,
        name: userData.name,
        email: userData.email,
        user_v2Id: userData.v2Id,
        created_at: userData.created_at,
      });
  }, [userData]);

  useEffect(() => {
    let token = null;
    let user = null;
    const usermain = JSON.parse(window.localStorage.getItem("user") || "{}");
    const tokenmain = window.localStorage.getItem("token");
    if (usermain != undefined && Object.keys(usermain).length > 0) {
      user = usermain;
    }
    if (tokenmain != undefined) {
      token = tokenmain;
    }
    console.log(tokenmain);

    if (token) {
      updateToken(token);
      axios
        .get("https://backend.lazyapply.com/lazyapplyV2/details", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (response) => {
          const v2Id = response.data?.user?.v2Id || "";
          console.log("v2idd", v2Id);
          let obj = {
            firstName: response.data?.user?.name,
            email: response.data?.user?.email,
          };
          Hotjar.identify(response.data?.user?.email, {
            firstName: response.data?.user?.name,
            email: response.data?.user?.email,
          });
          console.log("hotjar identity");
          console.log("onload", response.data);

          if (window.$crisp)
            window.$crisp.push([
              "set",
              "user:email",
              response.data?.user?.email,
            ]);

          window?.fcWidget?.user?.setProperties(obj);
          userDataInit(response.data?.user || {});
          userAuthentication(1);
        })
        .catch((error) => {
          console.log(error);
          if (error.message == "Request failed with status code 403") {
            setTimeout(() => {
              globalLogoutV2();
            }, 1500);
          } else {
            console.log("some error occured");
          }
        });
    }
    if (user) {
      userImageSrc(user.picture);
    }
  }, [userToken]);

  const getDailyMetrics = async (v2Id) => {
    try {
      const { data } = await axios.post(
        `https://backend.lazyapply.com/lazyapplyV2/count/${v2Id}`,
        { timeframe: "daily" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log("data-getDailyMetrics", data);
      setDailyLimit(data?.totalApplications ?? 0);
    } catch (error) {
      console.error("Error fetching daily metrics:", error?.message);
      // Consider adding error state handling
      setDailyLimit(0);
    }
  };

  const setViewTypeForAnalytics = (type, modelName) => {
    if (modelName === "school") {
      setEnterpriseUser({ viewType: "school" });
    } else {
      setEnterpriseUser({ viewType: type });
    }
  };

  const getPlanDetails = async (v2Id) => {
    try {
      const { data } = await axios.get(
        `https://backend.lazyapply.com/lazyapplyV2/plan-details/${v2Id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log("data-getPlanDetails", data);

      const defaultPlanDetails = {
        planStarted: 0,
        planName: "Free",
        applicationDailyLimit: 0,
        type: "individual",
        modelName: "user",
      };

      const planData = data?.data || defaultPlanDetails;

      if (userData.email)
        Hotjar.identify(userData.email, {
          planPurchased: planData.planStarted ? true : false,
          userData: userData
        });

      if (planData.type === "owner" && !planData.modelName) {
        setTeamTypeModalOpen(true);
      }

      setViewTypeForAnalytics(planData.type, planData.modelName);
      getUsersUnderMe(planData.type, planData.modelName);
      setPlanDetailsFetched(1);
      setPlanDetails(planData);
      setApplicationDailyLimit(planData.applicationDailyLimit ?? 0);
    } catch (error) {
      console.error("Error fetching plan details:", error?.message);
      setViewTypeForAnalytics("individual", "user");
      setPlanDetails({
        planStarted: 0,
        planName: "Free",
        applicationDailyLimit: 0,
        type: "individual",
        modelName: "user",
      });
      setApplicationDailyLimit(0);
    }
  };

  const getStreakData = async (v2Id) => {
    try {
      const { data } = await axios.get(
        `https://backend.lazyapply.com/lazyapplyV2/streak/${v2Id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log("data-getStreakData", data);
      setStreakData(data);
    } catch (error) {
      console.error("Error fetching streak data:", error?.message);
    }
  };

  const getUsersUnderMe = async (type, modelName) => {
    try {
      if (type === "individual" || modelName === "school") {
        return;
      }

      const response = await axios.get(
        `https://backend.lazyapply.com/lazyapplyV2/allusers`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          params: {
            page: 1,
            limit: 100,
          },
        }
      );

      if (response.data.success) {
        const { users } = response.data.data;
        console.log("users", users);
        setEnterpriseUser({ users: users });
      } else {
        console.error("Error fetching users:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (userData?.email?.trim() && userData?.v2Id?.trim()) {
      getDailyMetrics(userData.v2Id);
      getPlanDetails(userData.v2Id);
      getStreakData(userData.v2Id);
    }
  }, [userData]);

  useEffect(() => {
    if (userToken) {
      getDailyMetrics(userData.v2Id);
      getStreakData();
    }
  }, [viewType, userToken, navClickCount]);

  useEffect(() => {
    if (
      automationResults &&
      userData?.email?.trim() &&
      userData?.v2Id?.trim()
    ) {
      getDailyMetrics(userData.v2Id);
      getStreakData(userData.v2Id);
    }
  }, [automationResults]);

  useEffect(() => {
    if (typeof chrome !== "undefined" && chrome && chrome.runtime) {
      chrome.runtime.sendMessage(
        yourExtensionId,
        {
          lazyapply: "lazyapply",
          message: "check",
          injected: scriptInjectedGlobal,
        },
        function (response) {
          var lastError = chrome.runtime.lastError;
          if (lastError) {
            console.log(lastError.message, "error");
            return;
          }
          console.log("check", response);
          if ("status" in response && response.status == "installed") {
            setExtensionPresent(true);
            setScriptInjectedGlobal(true);
          }
        }
      );
    }
  }, []);

  const messageFn = useCallback((event) => {
    if (
      typeof event.data === "object" &&
      !Array.isArray(event.data) &&
      event.data !== null &&
      !scriptinjected.current &&
      event.data.injectedScript == "true"
    ) {
      if (event.data.version != -1) {
        setChromeVersion(event.data.version);
      }
      console.log("hereyoyo");
      setScriptInjectedGlobal(true);
      scriptinjected.current = true;
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", messageFn);
    return () => {
      window.removeEventListener("message", messageFn);
    };
  }, []);

  return (
    <>
      <TeamTypeModal
        open={teamTypeModalOpen}
        handleClose={() => setTeamTypeModalOpen(false)}
        onSelectTeamType={handleTeamTypeSelect}
      />
      <Switch>
        <Route exact path={`/`}>
          {isUserAuthenticated === 1 ? (
            <>
              <Redirect to="/dashboard" />
            </>
          ) : (
            <>
              <ScrollToTop>
                <>
                  <Login></Login>
                </>
              </ScrollToTop>
            </>
          )}
        </Route>
        <Route path="/dashboard">
          {isUserAuthenticated === 1 ? (
            <>
              <Dashboard></Dashboard>
            </>
          ) : (
            <>
              <Login></Login>
            </>
          )}
        </Route>
        <Route>
          <NoMatch />
        </Route>
      </Switch>
    </>
  );
}

export default Popup;
