export function changeFiltersValuesLinkedin(filters, url) {
    const filterMap = {
        experience: "f_E",
        jobType: "f_JT",
        workplace: "f_WT",
        datePosted: "f_TPR"
    };

    Object.keys(filterMap).forEach(key => {
        if (filters[key] !== "") {
            url += `&${filterMap[key]}=${filters[key]}`;
        }
    });

    return { url, filterReturned: [] };
}

export function changeFiltersValuesIndeed(filters, url) {
    let filtersMain = {}
    for (let key in filters) {
        if (key != 'jobCount' && key != 'platform') {
            const value = filters[key];

            if (value) {

                filtersMain[key] = {
                    selected: {
                        label: key,
                        value: filters[key]
                    }
                }
            }

        }
    }

    return { url, filterReturned: filtersMain }
}

export function changeFiltersValuesGlassdoor(filters, url) {

    let filtersMain = {}
    for (let key in filters) {
        if (key != 'jobCount' && key != 'platform') {
            const value = filters[key];

            if (value) {
                filtersMain[key] = filters[key]
            }

        }
    }

    return { url, filterReturned: filtersMain }
}

export function changeFiltersValuesZiprecruiter(filters, url) {

    if (filters.datePosted !== "") {
        url += "&days=" + filters.datePosted;
    }
    if (filters.locationType !== "") {
        url += "&refine_by_location_type=" + filters.locationType;
    }
    if (filters.employmentType !== "") {
        url += "&refine_by_employment=" + filters.employmentType;
    }
    if (filters.distance !== "") {
        url += "&radius=" + filters.distance;
    }

    return { url, filterReturned: [] }
}

export function changeFiltersValuesDice(filters, url) {

    if (filters.employer !== "") {
        url =
            url +
            "&filters.employerType=" +
            encodeURIComponent(filters.employer);
    }
    if (filters.employmentType !== "") {
        url =
            url +
            "&filters.employmentType=" +
            encodeURIComponent(filters.employmentType);
    }

    if (filters.datePosted != "") {
        url = url + "&filters.postedDate=" + filters.datePosted;

    }

    if (filters.remote != "") {
        url = url + "&filters.postedDate=" + filters.remote;
    }
    return { url, filterReturned: [] }

}

export function changeFiltersValuesSimplyHired(filters, url) {
    let finalUrl = url;

    for (let key in filters) {
        if (filters.hasOwnProperty(key)) {
            const value = filters[key];

            if (typeof value === 'object' && value.selected) {
                finalUrl += `&${value.value}=${value.selected}`;
            }

        }
    }

    return { url: finalUrl, filterReturned: [] }
}
