// DetailedView/LeftSection.jsx
import React from "react";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";

const LeftSection = ({ data }) => {
  return (
    <Box>
      {/* Header */}
      <Box mb={4}>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            fontWeight: 600,
            color: "text.primary",
            mb: 1,
          }}
        >
          {data.role}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "text.secondary",
            fontSize: "1rem",
          }}
        >
          {data.company} • {data.experience} • {data.duration}
        </Typography>
      </Box>

      {/* Metrics Cards - More Compact */}
      <Grid container spacing={2} mb={8}>
        <Grid item xs={4}>
          <Box
            sx={{
              p: 2.5,
              bgcolor: "#FEF3C7",
              borderRadius: 2,
              height: "100%",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                color: "text.primary",
                mb: 0.5,
                fontSize: "1.75rem", // Slightly smaller
              }}
            >
              {data.totalApplications}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              fontSize="0.85rem"
            >
              Applications
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              p: 2.5,
              bgcolor: "#ECFDF5",
              borderRadius: 2,
              height: "100%",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                color: "text.primary",
                mb: 0.5,
                fontSize: "1.75rem",
              }}
            >
              {data.responseRate}%
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              fontSize="0.85rem"
            >
              Response Rate
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              p: 2.5,
              bgcolor: "#EEF2FF",
              borderRadius: 2,
              height: "100%",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                color: "text.primary",
                mb: 0.5,
                fontSize: "1.75rem",
              }}
            >
              {data.numberOfOffers}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              fontSize="0.85rem"
            >
              Offers
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/*  Timeline */}

      <Box mb={5}>
        <Typography variant="h6" sx={{ fontWeight: 600, mb: 3 }}>
          Application Journey
        </Typography>
        <Card
          elevation={0}
          sx={{
            border: 1,
            borderColor: "grey.100",
            borderRadius: 2,
          }}
        >
          <CardContent sx={{ p: 4 }}>
            {/* Timeline container */}
            <Box position="relative" minHeight="180px" sx={{ pt: 4, pb: 4 }}>
              {/* Horizontal line */}
              <Box
                position="absolute"
                left="5%"
                right="5%"
                top="42%"
                height="1px"
                bgcolor="grey.200"
                sx={{ transform: "translateY(-50%)" }}
              />

              {/* Timeline Points */}
              <Box
                display="flex"
                justifyContent="space-between"
                mx="5%"
                position="relative"
                sx={{ height: "100%" }}
              >
                {data.timeline.map((point, index) => (
                  <Box
                    key={index}
                    position="relative"
                    width="25%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    {/* Date */}
                    <Typography
                      sx={{
                        color: "text.secondary",
                        fontSize: "0.85rem",
                        mb: 2,
                      }}
                    >
                      {point.dateRange}
                    </Typography>

                    {/* Dot */}
                    <Box
                      sx={{
                        width: 15,
                        height: 15,
                        borderRadius: "50%",
                        backgroundColor:
                          point.stage === "Offers" ? "#4caf50" : "#64b5f6",
                        border: "2px solid white",
                        boxShadow: `0 0 0 2px ${
                          point.stage === "Offers" ? "#4caf5040" : "#64b5f640"
                        }`,
                        position: "relative",
                        top: "0",
                        mb: 2,
                        zIndex: 2,
                      }}
                    />

                    {/* Stage and Count */}
                    <Box textAlign="center">
                      <Typography
                        sx={{
                          color: "text.primary",
                          fontSize: "1rem",
                          fontWeight: 500,
                        }}
                      >
                        {point.count}
                      </Typography>
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontSize: "0.85rem",
                        }}
                      >
                        {point.stage}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>

      {/* Response Rate Pie Chart */}
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 600, mb: 3 }}>
          Response Rate
        </Typography>
        <Card
          elevation={0}
          sx={{
            border: 1,
            borderColor: "grey.100",
            borderRadius: 2,
          }}
        >
          <CardContent sx={{ p: 3, display: "flex", justifyContent: "center" }}>
            <Box position="relative" width={200} height={200}>
              {/* Base Circle */}
              <Box
                component="svg"
                viewBox="0 0 36 36"
                width="100%"
                height="100%"
                sx={{ transform: "rotate(-90deg)" }}
              >
                {/* Background Circle */}
                <circle
                  cx="18"
                  cy="18"
                  r="16"
                  fill="none"
                  stroke="#f0f0f0"
                  strokeWidth="3.2"
                />
                {/* Progress Circle */}
                <circle
                  cx="18"
                  cy="18"
                  r="16"
                  fill="none"
                  stroke="#4caf50"
                  strokeWidth="3.2"
                  strokeDasharray={`${
                    (data.responseRate * 3.14 * 32) / 100
                  } 100`}
                  strokeLinecap="round"
                />
              </Box>
              {/* Percentage Text */}
              <Box
                position="absolute"
                top="50%"
                left="50%"
                sx={{
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 600,
                    color: "text.primary",
                  }}
                >
                  {data.responseRate}%
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                    mt: 0.5,
                  }}
                >
                  Response Rate
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default LeftSection;
