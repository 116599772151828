import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  TextField,
  Button,
  styled,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { CircularProgress } from "@mui/material";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    padding: "24px",
    maxWidth: "600px",
    width: "100%",
  },
}));

const UploadArea = styled(Box)(({ isDragging }) => ({
  border: `2px dashed ${isDragging ? "#2D2D2D" : "#E0E0E0"}`,
  borderRadius: "12px",
  padding: "40px 20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: isDragging ? "rgba(45, 45, 45, 0.04)" : "#F8F9FA",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "&:hover": {
    borderColor: "#2D2D2D",
    backgroundColor: "rgba(45, 45, 45, 0.04)",
  },
}));

const StyledButton = styled(Button)(({ variant }) => ({
  textTransform: "none",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: 600,
  ...(variant === "contained" && {
    padding: "10px 24px",
    backgroundColor: "#2D2D2D",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#404040",
    },
  }),
  ...(variant === "outlined" && {
    padding: "8px 24px",
    borderColor: "#666666",
    color: "#666666",
    "&:hover": {
      borderColor: "#2D2D2D",
      backgroundColor: "rgba(45, 45, 45, 0.05)",
    },
  }),
}));

const CreateProfileModal = ({ open, onClose, onCreate, showNameFields }) => {
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [titleError, setTitleError] = useState("");
  const [fileError, setFileError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (!open) {
      // Reset all state when modal closes
      setTitle("");
      setFile(null);
      setTitleError("");
      setFileError("");
      setIsDragging(false);
      setIsLoading(false);
      setFirstName("");
      setLastName("");
      setFirstNameError("");
      setLastNameError("");
    }
  }, [open]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const validateFile = (file) => {
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!allowedTypes.includes(file.type)) {
      setFileError("Please upload a PDF or Word document");
      return false;
    }

    if (file.size > maxSize) {
      setFileError("File size should be less than 5MB");
      return false;
    }

    setFileError("");
    return true;
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && validateFile(droppedFile)) {
      setFile(droppedFile);
    }
  };

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && validateFile(selectedFile)) {
      setFile(selectedFile);
    }
  };

  const handleTitleChange = (e) => {
    const value = e.target.value;
    setTitle(value);
    if (!value.trim()) {
      setTitleError("Profile title is required");
    } else {
      setTitleError("");
    }
  };

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);
    if (!value.trim()) {
      setFirstNameError("First name is required");
    } else {
      setFirstNameError("");
    }
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    setLastName(value);
    if (!value.trim()) {
      setLastNameError("Last name is required");
    } else {
      setLastNameError("");
    }
  };

  const handleSubmit = async () => {
    // Validate form
    let isValid = true;

    if (showNameFields === true) {
      if (!firstName.trim()) {
        setFirstNameError("First name is required");
        isValid = false;
      }
      if (!lastName.trim()) {
        setLastNameError("Last name is required");
        isValid = false;
      }
    }

    if (!title.trim()) {
      setTitleError("Profile title is required");
      isValid = false;
    }

    if (!file) {
      setFileError("Please upload a resume");
      isValid = false;
    }

    if (!isValid) return;

    setIsLoading(true);
    try {
      const resumeId = await onCreate({
        title,
        file,
        ...(showNameFields === true && { firstName, lastName }),
      });

      history.push(`/dashboard/profile/${resumeId}`);

      onClose();
    } catch (error) {
      console.error("Error creating profile:", error);
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setTitle("");
    setFile(null);
    setTitleError("");
    setFileError("");
    setIsDragging(false);
    onClose();
  };

  return (
    <StyledDialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ mb: 1 }}>
        <Typography variant="h5" sx={{ color: "#2D2D2D", fontWeight: 600 }}>
          Create New Profile
        </Typography>
      </DialogTitle>

      <DialogContent>
        {showNameFields === true && (
          <Box sx={{ mb: 3, display: "flex", gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#2D2D2D",
                  fontWeight: 500,
                  mb: 1,
                  ml: 0.5,
                  fontSize: "18px",
                }}
              >
                First Name
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter your first name"
                value={firstName}
                onChange={handleFirstNameChange}
                error={!!firstNameError}
                helperText={firstNameError}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                }}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#2D2D2D",
                  fontWeight: 500,
                  mb: 1,
                  ml: 0.7,
                  fontSize: "18px",
                }}
              >
                Last Name
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter your last name"
                value={lastName}
                onChange={handleLastNameChange}
                error={!!lastNameError}
                helperText={lastNameError}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                }}
              />
            </Box>
          </Box>
        )}

        <Box sx={{ mb: 3 }}>
          <Typography
            variant="subtitle1"
            sx={{ color: "#2D2D2D", fontWeight: 500, mb: 1, fontSize: "18px" }}
          >
            Profile Title
          </Typography>
          <TextField
            fullWidth
            placeholder="e.g., Software Developer, Data Analyst"
            value={title}
            onChange={handleTitleChange}
            error={!!titleError}
            helperText={titleError}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
              },
            }}
          />
        </Box>

        <Box>
          <Typography
            variant="subtitle1"
            sx={{ color: "#2D2D2D", fontWeight: 500, mb: 1, fontSize: "18px" }}
          >
            Upload Resume
          </Typography>

          <input
            type="file"
            accept=".pdf,.doc,.docx"
            onChange={handleFileSelect}
            style={{ display: "none" }}
            id="resume-upload"
          />

          <UploadArea
            isDragging={isDragging}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => document.getElementById("resume-upload").click()}
          >
            <FileUploadIcon sx={{ fontSize: 48, color: "#666666", mb: 2 }} />
            <Typography variant="body1" sx={{ color: "#2D2D2D", mb: 1 }}>
              {file ? file.name : "Drag and drop your resume here"}
            </Typography>
            <Typography variant="body2" sx={{ color: "#666666" }}>
              or click to browse (PDF, DOC, DOCX)
            </Typography>
            {fileError && (
              <Typography variant="body2" sx={{ color: "#FF4D4F", mt: 1 }}>
                {fileError}
              </Typography>
            )}
          </UploadArea>
        </Box>
      </DialogContent>

      <DialogActions sx={{ padding: "24px" }}>
        <StyledButton
          variant="outlined"
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </StyledButton>
        <StyledButton
          variant="contained"
          onClick={handleSubmit}
          disabled={isLoading || !title || !file}
        >
          {isLoading ? (
            <>
              Parsing Resume...
              <CircularProgress
                size={20}
                sx={{
                  color: "#666",
                  ml: 1,
                }}
              />
            </>
          ) : (
            "Create Profile"
          )}
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default CreateProfileModal;
