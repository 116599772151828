import React from "react";
import AnalyticsDashboard from "./analytics/dashboard/AnalyticsDashboard";
import { useStateStore } from "../../store";

const Analytics = () => {
  const { enterpriseUser } = useStateStore();
  const { viewType } = enterpriseUser;
  return (
    <div>
      <AnalyticsDashboard viewType={viewType} />
    </div>
  );
};

export default Analytics;
