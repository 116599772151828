// components/UpgradeBanner.jsx
import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { Collapse } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const UpgradeBanner = ({ show, onHowToUpgrade, onDismiss }) => {
  if (!show) return null;

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#fff",
        borderBottom: "1px solid #E0E0E0",
        position: "relative",
        zIndex: 1000,
        animation: show ? "slideIn 0.3s ease-out" : "slideOut 0.3s ease-out",
        "@keyframes slideIn": {
          from: {
            transform: "translateY(-100%)",
            opacity: 0,
          },
          to: {
            transform: "translateY(0)",
            opacity: 1,
          },
        },
        "@keyframes slideOut": {
          from: {
            transform: "translateY(0)",
            opacity: 1,
          },
          to: {
            transform: "translateY(-100%)",
            opacity: 0,
          },
        },
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "250px",
          maxWidth: "1300px",
          margin: "0 auto",
          py: 1.25,
          px: { xs: 2, md: 3 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 32,
              height: 32,
              borderRadius: "50%",
              backgroundColor: "#FFF3E0",
            }}
          >
            <ErrorOutlineIcon
              sx={{
                color: "#FF9800",
                fontSize: 30,
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 550,
              color: "#2D2D2D",
            }}
          >
            Important: Please upgrade your LazyApply extension - it is out of
            date
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            onClick={onHowToUpgrade}
            sx={{
              backgroundColor: "#000",
              color: "#fff",
              fontSize: "16px",
              py: 1,
              px: 3,
              borderRadius: "8px",
              textTransform: "none",
              fontWeight: 550,
              "&:hover": {
                backgroundColor: "#404040",
              },
            }}
          >
            How to Upgrade
          </Button>
          <Button
            onClick={onDismiss}
            sx={{
              color: "#666666",
              fontSize: "16px",
              py: 1,
              px: 3,
              borderRadius: "8px",
              textTransform: "none",
              fontWeight: 550,
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            Dismiss
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default UpgradeBanner;
