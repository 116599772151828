import { addDynamicHeader, removeDynamicHeader } from "../apis/axios-config";
import { useStateStore } from "../store/index";

/**
 * Handle access mode changes (switching between personal, role, and user impersonation modes)
 *
 * @param {string} mode - The access mode to switch to ('personal', 'role', 'user')
 * @param {string|null} userId - The user ID to impersonate (only used when mode is 'user')
 * @param {object} options - Additional options
 * @param {function|null} options.onSuccess - Callback function to execute on successful mode change
 */
export const useAccessModeHandler = () => {
  const {
    planDetails,
    setEnterpriseUser,
    incrementNavClick,
    addRouteHeader: addStoreHeader,
    removeRouteHeader: removeStoreHeader,
    clearAllHeaders,
    // Use the new state from the store
    setSelectedAccessMode,
    setSelectedUserId,
  } = useStateStore();

  const handleAccessModeChange = (mode, userId = null, options = {}) => {
    // Clear all existing headers first
    clearAllHeaders();

    // Update analytics data
    incrementNavClick();

    // Set the enterprise user view type based on the mode
    if (mode === "personal") {
      setEnterpriseUser({ viewType: "individual" });
    } else if (mode === "user") {
      setEnterpriseUser({ viewType: "user" });
    } else {
      if (planDetails.modelName === "school") {
        setEnterpriseUser({ viewType: "school" });
      } else {
        setEnterpriseUser({ viewType: planDetails.type });
      }
    }

    // Define API routes that need header modifications
    const baseUrl = "https://backend.lazyapply.com/lazyapplyV2";
    const baseUrl1 = "https://backend.lazyapply.com/referral";
    const routes = [
      `${baseUrl}/streak/*`,
      `${baseUrl}/applications/already-completed/*`,
      `${baseUrl}/applications/*`,
      `${baseUrl}/count/*`,
      `${baseUrl}/all-time/*`,
      `${baseUrl}/resume-analytics/*`,
      `${baseUrl}/job-applications/*`,
      `${baseUrl}/resumes`,
      `${baseUrl}/resumes-all`,
      `${baseUrl}/resume/*/hide`,
      `${baseUrl}/resume/*`,
      `${baseUrl1}/getJobLinks/`,
      `${baseUrl1}/getJobLinks`,
      `${baseUrl1}/handleApplicationComplete`,
      `${baseUrl}/user-names/*`,
    ];

    // Remove any existing impersonation headers
    routes.forEach((route) => {
      removeStoreHeader(route, "X-Operator-Impersonate-User");
      removeStoreHeader(route, "X-Owner-Impersonate-User");

      removeDynamicHeader(route, "X-Operator-Impersonate-User");
      removeDynamicHeader(route, "X-Owner-Impersonate-User");
    });

    // Add impersonation headers if in user mode
    if (mode === "user" && userId) {
      if (planDetails.type === "operator") {
        routes.forEach((route) => {
          addStoreHeader(route, "X-Operator-Impersonate-User", userId);
          addDynamicHeader(route, "X-Operator-Impersonate-User", userId);
        });
      } else if (
        planDetails.type === "owner" ||
        planDetails.type === "operator" ||
        planDetails.modelName === "school"
      ) {
        routes.forEach((route) => {
          addStoreHeader(route, "X-Owner-Impersonate-User", userId);
          addDynamicHeader(route, "X-Owner-Impersonate-User", userId);
        });
      }

      console.log(
        `Changed to mode: ${mode} for user ID: ${userId} - Impersonation active`
      );
    } else {
      console.log(
        `Changed to mode: ${mode}${
          userId ? ` for user ID: ${userId}` : ""
        } - Normal access`
      );
    }

    // Update the mode and userId in the store
    setSelectedAccessMode(mode);
    setSelectedUserId(userId);

    // Execute success callback if provided
    if (options.onSuccess && typeof options.onSuccess === "function") {
      options.onSuccess();
    }

    return { mode, userId };
  };

  return { handleAccessModeChange };
};
