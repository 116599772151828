const countryOptions = [
  { label: "Select country", value: "" },
  { label: "Argentina", value: "ar" },
  { label: "Australia", value: "au" },
  { label: "Austria", value: "at" },
  { label: "Bahrain", value: "bh" },
  { label: "Belgium", value: "be" },
  { label: "Brazil", value: "br" },
  { label: "Canada", value: "ca" },
  { label: "Chile", value: "cl" },
  { label: "China", value: "cn" },
  { label: "Colombia", value: "co" },
  { label: "Costa Rica", value: "cr" },
  { label: "Czech Republic", value: "cz" },
  { label: "Denmark", value: "dk" },
  { label: "Ecuador", value: "ec" },
  { label: "Egypt", value: "eg" },
  { label: "Finland", value: "fi" },
  { label: "France", value: "fr" },
  { label: "Germany", value: "de" },
  { label: "Greece", value: "gr" },
  { label: "Hong Kong", value: "hk" },
  { label: "Hungary", value: "hu" },
  { label: "India", value: "in" },
  { label: "Indonesia", value: "id" },
  { label: "Ireland", value: "ie" },
  { label: "Israel", value: "il" },
  { label: "Italy", value: "it" },
  { label: "Japan", value: "jp" },
  { label: "Kuwait", value: "kw" },
  { label: "Luxembourg", value: "lu" },
  { label: "Malaysia", value: "malaysia" },
  { label: "Mexico", value: "mx" },
  { label: "Morocco", value: "ma" },
  { label: "Netherlands", value: "nl" },
  { label: "New Zealand", value: "nz" },
  { label: "Nigeria", value: "ng" },
  { label: "Norway", value: "no" },
  { label: "Oman", value: "om" },
  { label: "Pakistan", value: "pk" },
  { label: "Panama", value: "pa" },
  { label: "Peru", value: "pe" },
  { label: "Philippines", value: "ph" },
  { label: "Poland", value: "pl" },
  { label: "Portugal", value: "pt" },
  { label: "Qatar", value: "qa" },
  { label: "Romania", value: "ro" },
  { label: "Russia", value: "ru" },
  { label: "Saudi Arabia", value: "sa" },
  { label: "Singapore", value: "sg" },
  { label: "South Africa", value: "za" },
  { label: "South Korea", value: "kr" },
  { label: "Spain", value: "es" },
  { label: "Sweden", value: "se" },
  { label: "Switzerland", value: "ch" },
  { label: "Taiwan", value: "tw" },
  { label: "Thailand", value: "th" },
  { label: "Turkey", value: "tr" },
  { label: "Ukraine", value: "ua" },
  { label: "United Arab Emirates", value: "ae" },
  { label: "United Kingdom", value: "uk" },
  { label: "United States", value: "www" },
  { label: "Uruguay", value: "uy" },
  { label: "Venezuela", value: "ve" },
  { label: "Vietnam", value: "vn" },
].map((country) => ({
  label: country.label,
  value: country.value,
}));

export const indeedFilters = {
  country: {
    label: "Country",
    type: "dropdown",
    options: countryOptions,
  },
  Remote: {
    label: "Job Type",
    type: "dropdown",
    options: [
      {
        label: "All jobs",
        value: "All jobs",
      },
      {
        label: "Remote",
        value: "Remote",
      },
      {
        label: "Hybrid work",
        value: "Hybrid work",
      },
    ],
  },
  "Date posted": {
    label: "Date posted",
    type: "dropdown",
    options: [
      {
        label: "Jobs you haven't seen",
        value: "Jobs you haven't seen",
      },
      {
        label: "Last 24 hours",
        value: "Last 24 hours",
      },
      {
        label: "Last 3 days",
        value: "Last 3 days",
      },
      {
        label: "Last 7 days",
        value: "Last 7 days",
      },
      {
        label: "Last 14 days",
        value: "Last 14 days",
      },
    ],
  },
};
