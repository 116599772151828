import React, { useState } from "react";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  styled,
} from "@mui/material";

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  "& .MuiTypography-root": {
    fontSize: "15px",
  },
  "& .MuiRadio-root": {
    color: "#2D2D2D",
    "&.Mui-checked": {
      color: "#2557a7",
    },
  },
}));

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2d2d2d",
    },
  },
});

export const jobCountFilter = {
  label: "Number of Jobs",
  type: "jobCount",
  options: [
    { label: "50 jobs", value: "50" },
    { label: "100 jobs", value: "100" },
    { label: "150 jobs", value: "150" },
  ],
  allowCustomInput: true,
};

const JobCountSelector = ({ value, onChange }) => {
  const [customCount, setCustomCount] = useState("");
  const [useCustom, setUseCustom] = useState(false);

  const handleOptionChange = (selectedValue) => {
    if (selectedValue === "custom") {
      setUseCustom(true);
    } else {
      setUseCustom(false);
      onChange(selectedValue);
    }
  };

  const handleCustomInputChange = (e) => {
    const value = e.target.value;
    if (value === "" || (Number(value) >= 1 && Number(value) <= 300)) {
      setCustomCount(value);
      onChange(value);
    }
  };

  return (
    <Box>
      <RadioGroup
        value={useCustom ? "custom" : value}
        onChange={(e) => handleOptionChange(e.target.value)}
      >
        {jobCountFilter.options.map((option) => (
          <StyledFormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}

        {/* <StyledFormControlLabel
          value="custom"
          control={<Radio />}
          label="Custom"
        /> */}
      </RadioGroup>

      {/* {useCustom && (
        <Box sx={{ mt: 1.5, ml: 4 }}>
          <StyledTextField
            type="number"
            value={customCount}
            onChange={handleCustomInputChange}
            placeholder="Enter number (1-150)"
            size="small"
            fullWidth
            inputProps={{
              min: 1,
              max: 300,
            }}
          />
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              mt: 1,
              display: "block",
              ml: 1,
              fontSize: "14px",
              color: "#000",
            }}
          >
            Maximum: 150 jobs
          </Typography>
        </Box>
      )} */}
    </Box>
  );
};

export default JobCountSelector;
