import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Container,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TemplateEditor from "./TemplateEditor";
import TemplateCard from "./TemplateCard";
import axios from "axios";
import { useStateStore } from "../../../store";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#fff",
          minHeight: "100%",
        },
      },
    },
  },
});

const defaultTemplates = [
  {
    id: 1,
    name: "General Referral",
    attachResume: true,
    isDefault: true,
    subject: "Referral for [Job Title] position at [Company Name]",
    content: `Dear [Recipient Name],

I hope this email finds you well. I'd like to refer a highly qualified candidate for the [Job Title] position at [Company Name]. They would be an excellent fit for the [Role] and bring valuable experience to your team.

Best regards,
[Your name]`,
  },
  {
    id: 2,
    name: "Technical Role",
    attachResume: true,
    isDefault: true,
    subject: "Referral for [Role] position - Experienced Candidate",
    content: `Dear [Recipient Name],

I'm writing to refer an exceptional candidate for the [Job Title] position at [Company Name]. They have extensive experience in [Role] and would be a valuable addition to your team.

Best regards,
[Your name]`,
  },
];

const EmailTemplates = () => {
  const [templates, setTemplates] = useState(defaultTemplates);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [isNewTemplate, setIsNewTemplate] = useState(false);
  const { token, userData } = useStateStore();

  const handleEditTemplate = (template, isNew = false) => {
    setIsNewTemplate(isNew);
    setEditingTemplate(template);
  };

  const handleSaveTemplate = async (updatedTemplate) => {
    try {
      const baseUrl = "https://backend.lazyapply.com/lazyapplyV2";
      const v2Id = userData.v2Id;

      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      if (isNewTemplate) {
        const { data: newTemplate } = await axios.post(
          `${baseUrl}/${v2Id}/templates`,
          {
            id: Date.now(),
            name: updatedTemplate.name,
            subject: updatedTemplate.subject,
            content: updatedTemplate.content,
            attachResume: updatedTemplate.attachResume,
          },
          axiosConfig
        );

        setTemplates([...templates, newTemplate]);
      } else {
        const { data: updatedData } = await axios.put(
          `${baseUrl}/${v2Id}/templates/${updatedTemplate.id}`,
          {
            name: updatedTemplate.name,
            subject: updatedTemplate.subject,
            content: updatedTemplate.content,
            attachResume: updatedTemplate.attachResume,
          },
          axiosConfig
        );

        setTemplates(
          templates.map((t) => (t.id === updatedTemplate.id ? updatedData : t))
        );
      }

      setIsNewTemplate(false);
      setEditingTemplate(null);
    } catch (error) {
      console.error("Error saving template:", error);
    }
  };

  const handleDeleteTemplate = (templateId) => {
    setTemplates(templates.filter((t) => t.id !== templateId));
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const baseUrl = "https://backend.lazyapply.com/lazyapplyV2";
        const v2Id = userData.v2Id;

        const { data } = await axios.get(`${baseUrl}/${v2Id}/templates`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const mergedTemplates = defaultTemplates.map((defaultTemplate) => {
          const backendTemplate = data.find((t) => t.id === defaultTemplate.id);
          return backendTemplate || defaultTemplate;
        });

        const additionalTemplates = data.filter(
          (template) => !defaultTemplates.some((dt) => dt.id === template.id)
        );

        setTemplates([...mergedTemplates, ...additionalTemplates]);
      } catch (error) {
        console.error("Error fetching templates:", error);
        setTemplates(defaultTemplates);
      }
    };

    fetchTemplates();
  }, [userData.v2Id, token]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            mt: 4,
            mb: 4,
            flex: 1,
            px: { xs: 2, sm: 3, md: 4 },
          }}
        >
          {editingTemplate ? (
            <TemplateEditor
              template={editingTemplate}
              onSave={handleSaveTemplate}
              onBack={() => setEditingTemplate(null)}
              isNewTemplate={isNewTemplate}
            />
          ) : (
            <>
              <Typography
                sx={{
                  color: "#2D2D2D",
                  fontWeight: 550,
                  fontSize: "24px",
                  mb: 5,
                }}
              >
                Email Templates
              </Typography>

              <Grid container spacing={3}>
                {/* Add New Template Card */}
                <Grid item xs={12} md={6}>
                  <TemplateCard
                    isNew
                    onEdit={() =>
                      handleEditTemplate(
                        {
                          id: Date.now(),
                          name: "Untitled Template",
                          content: defaultTemplates[0].content,
                          subject: defaultTemplates[0].subject,
                          attachResume: true,
                          isDefault: false,
                        },
                        true
                      )
                    }
                  />
                </Grid>

                {templates.map((template) => (
                  <Grid item xs={12} md={6} key={template.id}>
                    <TemplateCard
                      template={template}
                      onEdit={() => handleEditTemplate(template)}
                      onDelete={handleDeleteTemplate}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default EmailTemplates;
