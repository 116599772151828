import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Button,
  styled,
  CircularProgress,
  Select,
  MenuItem,
  Link,
  Alert,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import EmailIcon from "@mui/icons-material/Email";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useStateStore } from "../../../store";
import axios from "axios";

const defaultTemplates = [
  {
    id: 1,
    name: "General Referral",
    attachResume: true,
    isDefault: true,
    subject: "Referral for [Job Title] position at [Company Name]",
    content: `Dear [Recipient Name],

I hope this email finds you well. I'd like to refer a highly qualified candidate for the [Job Title] position at [Company Name]. They would be an excellent fit for the [Role] and bring valuable experience to your team.

Best regards,
[Your name]`,
  },
  {
    id: 2,
    name: "Technical Role",
    attachResume: true,
    isDefault: true,
    subject: "Referral for [Role] position - Experienced Candidate",
    content: `Dear [Recipient Name],

I'm writing to refer an exceptional candidate for the [Job Title] position at [Company Name]. They have extensive experience in [Role] and would be a valuable addition to your team.

Best regards,
[Your name]`,
  },
];

const defaultPositions = [
  {
    id: 1,
    title: "Hiring Manager",
  },
  {
    id: 2,
    title: "Department Head",
  },
  {
    id: 3,
    title: "Team Lead",
  },
];

const StyledSelect = styled(Select)({
  backgroundColor: "#f8f8f8",
  borderRadius: "8px",
  "& .MuiSelect-select": {
    padding: "12px 16px",
    fontSize: "15px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
});

const RecipientsList = styled(Box)({
  backgroundColor: "#f8f8f8",
  borderRadius: "8px",
  padding: "16px",
});

const ReferralOptionsCard = ({ onClose, onContinue }) => {
  const { token, userData } = useStateStore();
  const [emailSettings, setEmailSettings] = useState({
    connectedEmails: [],
    templates: defaultTemplates,
    positions: defaultPositions,
  });
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchEmailSettings = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const v2Id = userData?.v2Id || "";

      const { data } = await axios.get(
        `https://backend.lazyapply.com/lazyapplyV2/${v2Id}/email-settings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const transformedData = {
        connectedEmails: data.connectedEmails.map((x) => {
          return { ...x, label: x.email };
        }),
        templates:
          data.templates?.length > 0
            ? [...defaultTemplates, ...data.templates]
            : defaultTemplates,
        positions:
          data.positions?.length > 0
            ? data.positions.map((pos) => ({
                id: pos.id,
                position: pos.title,
              }))
            : defaultPositions.map((pos) => ({
                id: pos.id,
                position: pos.title,
              })),
      };

      console.log("transformed", transformedData);

      setEmailSettings(transformedData);

      if (transformedData.connectedEmails.length > 0) {
        setSelectedEmail(transformedData.connectedEmails[0].id);
      }
      if (transformedData.templates.length > 0) {
        setSelectedTemplate(transformedData.templates[0].id);
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "Failed to fetch email settings";
      setError(errorMessage);
      setEmailSettings((prev) => ({
        ...prev,
        templates: defaultTemplates,
        positions: defaultPositions.map((pos) => ({
          id: pos.id,
          position: pos.title,
        })),
      }));
      console.error("Error fetching email settings:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEmailSettings();
  }, [userData?.v2Id]);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await fetchEmailSettings();
    setIsRefreshing(false);
  };

  if (isLoading) {
    return (
      <Card
        sx={{ width: "600px", maxWidth: "90vw", p: 4, textAlign: "center" }}
      >
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>Loading email settings...</Typography>
      </Card>
    );
  }

  if (error) {
    return (
      <Card sx={{ width: "600px", maxWidth: "90vw", p: 4 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
        <Button onClick={fetchEmailSettings} variant="contained">
          Retry
        </Button>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        width: "600px",
        maxWidth: "90vw",
        maxHeight: "90vh",
        borderRadius: "12px",
        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#2d2d2d",
          padding: "18px",
          color: "#fff",
          borderRadius: "12px 12px 0 0",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 500, mb: 0.5 }}>
          Configure Referral Options
        </Typography>
        <Typography variant="body2" sx={{ opacity: 0.8 }}>
          Select options for sending your referral emails
        </Typography>
      </Box>

      <Box sx={{ py: 2, px: 3 }}>
        {/* Email Selection */}
        <Box sx={{ mb: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ fontWeight: 500, color: "#000", fontSize: "17px" }}
            >
              Select Email
            </Typography>
            <Button
              onClick={handleRefresh}
              startIcon={
                isRefreshing ? <CircularProgress size={20} /> : <RefreshIcon />
              }
              disabled={isRefreshing}
              sx={{
                color: "#666",
                padding: "8px 16px",
                textTransform: "none",
                borderRadius: "8px",
                "&:hover": { backgroundColor: "rgba(0,0,0,0.04)" },
              }}
            >
              Refresh
            </Button>
          </Box>
          <Typography sx={{ color: "#666", mb: 2, fontSize: "15px" }}>
            Choose the email address you want to use to send referrals
          </Typography>

          {emailSettings.connectedEmails.length > 0 ? (
            <StyledSelect
              fullWidth
              value={selectedEmail}
              onChange={(e) => setSelectedEmail(e.target.value)}
              IconComponent={KeyboardArrowDownIcon}
            >
              {emailSettings.connectedEmails.map((email) => (
                <MenuItem key={email.id} value={email.id}>
                  {email.email}
                </MenuItem>
              ))}
            </StyledSelect>
          ) : (
            <Box
              sx={{
                backgroundColor: "#f8f8f8",
                p: 2,
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ color: "#666" }}>
                No connected emails found
              </Typography>
              <Button
                onClick={() =>
                  window.open("/dashboard/profile/email-settings", "_blank")
                }
                startIcon={<EmailIcon />}
                endIcon={<OpenInNewIcon sx={{ fontSize: 16 }} />}
                sx={{
                  color: "#2d2d2d",
                  "&:hover": { backgroundColor: "rgba(0,0,0,0.04)" },
                  textTransform: "none",
                  padding: "4px 16px",
                  borderRadius: "8px",
                  fontSize: "15px",
                }}
              >
                Connect Email
              </Button>
            </Box>
          )}
        </Box>

        {/* Recipients Section */}
        <Box sx={{ mb: 2 }}>
          <Typography
            sx={{ fontWeight: 500, color: "#000", fontSize: "17px", mb: 1 }}
          >
            Recipients ({emailSettings.positions.length})
          </Typography>
          <Typography sx={{ color: "#666", mb: 2, fontSize: "15px" }}>
            These people will receive your referral email
          </Typography>
          <RecipientsList>
            {emailSettings.positions.map((recipient) => (
              <Typography
                key={recipient.id}
                sx={{
                  fontSize: "15px",
                  fontWeight: 500,
                  "&:not(:last-child)": { mb: 2 },
                }}
              >
                {recipient.position}
              </Typography>
            ))}
          </RecipientsList>
        </Box>

        {/* Template Selection */}
        <Box sx={{ mb: 2 }}>
          <Typography
            sx={{ fontWeight: 500, color: "#000", fontSize: "17px", mb: 1 }}
          >
            Select Template
          </Typography>
          <Typography sx={{ color: "#666", mb: 2, fontSize: "15px" }}>
            Pick a template for your referral email
          </Typography>
          <StyledSelect
            fullWidth
            value={selectedTemplate}
            onChange={(e) => setSelectedTemplate(e.target.value)}
            IconComponent={KeyboardArrowDownIcon}
          >
            {emailSettings.templates.map((template) => (
              <MenuItem key={template.id} value={template.id}>
                {template.name}
              </MenuItem>
            ))}
          </StyledSelect>
        </Box>

        {/* Quick Links */}
        <Box sx={{ fontSize: "14px", fontWeight: 500, color: "#666" }}>
          Need to make changes?{" "}
          <Link
            onClick={() =>
              window.open("dashboard/profile/email-settings", "_blank")
            }
            sx={{
              color: "#2d2d2d",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Manage emails
          </Link>{" "}
          or{" "}
          <Link
            onClick={() =>
              window.open("dashboard/profile/email-settings", "_blank")
            }
            sx={{
              color: "#2d2d2d",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Customize templates
          </Link>{" "}
          or{" "}
          <Link
            onClick={() =>
              window.open("dashboard/profile/email-settings", "_blank")
            }
            sx={{
              color: "#2d2d2d",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Edit recipients
          </Link>
        </Box>
      </Box>

      <Box
        sx={{
          p: 3,
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
          borderTop: "1px solid #e0e0e0",
        }}
      >
        <Button
          variant="outlined"
          disableRipple
          onClick={onClose}
          sx={{
            borderRadius: "8px",
            textTransform: "none",
            fontSize: "16px",
            padding: "8px 24px",
            color: "#2d2d2d",
            borderColor: "#e0e0e0",
            "&:hover": {
              borderColor: "#2d2d2d",
              backgroundColor: "rgba(45, 45, 45, 0.04)",
            },
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          disableRipple
          onClick={() => {
            const selectedEmailData = emailSettings.connectedEmails.find(
              (email) => email.id === selectedEmail
            );
            const selectedTemplateData = emailSettings.templates.find(
              (template) => template.id === selectedTemplate
            );

            onContinue({
              selectedEmail: selectedEmailData,
              selectedTemplate: selectedTemplateData,
              allData: {
                emails: emailSettings.connectedEmails,
                templates: emailSettings.templates,
                positions: emailSettings.positions,
              },
            });
          }}
          disabled={!selectedEmail || !selectedTemplate}
          sx={{
            borderRadius: "8px",
            textTransform: "none",
            fontSize: "16px",
            padding: "8px 24px",
            backgroundColor: "#2d2d2d",
            "&:hover": {
              backgroundColor: "#404040",
            },
            "&.Mui-disabled": {
              backgroundColor: "#cccccc",
            },
          }}
        >
          Continue
        </Button>
      </Box>
    </Card>
  );
};

export default ReferralOptionsCard;
