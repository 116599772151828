// ShareJourneyForm/steps/Timeline.jsx
import React from "react";
import { Box, Typography, Grid, IconButton } from "@mui/material";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import StyledTextField from "../components/StyledTextField";

const Timeline = ({ formData, updateFormData }) => {
  const handleAddPoint = () => {
    const newTimeline = [
      ...formData.timeline,
      { dateRange: "", stage: "", count: "" },
    ];
    updateFormData("timeline", newTimeline);
  };

  const handleRemovePoint = (index) => {
    if (formData.timeline.length > 1) {
      const newTimeline = formData.timeline.filter((_, i) => i !== index);
      updateFormData("timeline", newTimeline);
    }
  };

  const handleChange = (index, field) => (event) => {
    const newTimeline = formData.timeline.map((point, i) => {
      if (i === index) {
        return { ...point, [field]: event.target.value };
      }
      return point;
    });
    updateFormData("timeline", newTimeline);
  };

  return (
    <Box>
      <Box mb={4}>
        <Typography
          variant="h6"
          sx={{ fontWeight: 600, color: "#2d2d2d", mb: 1 }}
        >
          Application Timeline
        </Typography>
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: "0.9rem",
            mb: 4,
          }}
        >
          Add significant points in your application journey. Include key stages
          like when you started applying, received responses, gave interviews,
          and got offers. This helps others understand your timeline better.
        </Typography>

        {formData.timeline.map((point, index) => (
          <Box
            key={index}
            sx={{
              mb: 3,
              p: 3,
              borderRadius: 2,
              bgcolor: "#fafafa",
            }}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={4}>
                <StyledTextField
                  fullWidth
                  label="Date Range"
                  value={point.dateRange}
                  onChange={handleChange(index, "dateRange")}
                  placeholder="e.g., Jan 15-30"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledTextField
                  fullWidth
                  label="Stage"
                  value={point.stage}
                  onChange={handleChange(index, "stage")}
                  placeholder="e.g., Applications"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <StyledTextField
                  fullWidth
                  label="Count"
                  value={point.count}
                  onChange={handleChange(index, "count")}
                  placeholder="e.g., 45"
                />
              </Grid>
              <Grid item xs={12} sm={1} sx={{ textAlign: "center" }}>
                <IconButton
                  onClick={() => handleRemovePoint(index)}
                  disabled={formData.timeline.length === 1}
                  sx={{
                    color: "grey.500",
                    "&:hover": { color: "#ff4444" },
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        ))}

        <Box sx={{ textAlign: "center", mt: 3 }}>
          <IconButton
            onClick={handleAddPoint}
            sx={{
              bgcolor: "grey.100",
              "&:hover": { bgcolor: "grey.200" },
              p: 1,
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Timeline;
