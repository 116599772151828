import React from "react";
import { Grid } from "@mui/material";
import ProfileCard from "./ProfileCard";
import CreateProfileCard from "./CreateProfileCard";

const ProfileGrid = ({ profiles, onCreateNew, onEdit, onDelete }) => {
  return (
    <Grid container spacing={3}>
      {/* Create New Profile Card */}
      <Grid item xs={12} sm={6} md={4}>
        <CreateProfileCard onClick={onCreateNew} />
      </Grid>

      {/* Existing Profile Cards */}
      {profiles.map((profile) => (
        <Grid item xs={12} sm={6} md={4} key={profile.id}>
          <ProfileCard
            profile={profile}
            onEdit={() => onEdit(profile.id)}
            onDelete={() => onDelete(profile.id)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProfileGrid;
