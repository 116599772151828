// components/sections/Languages/ViewMode.jsx
import React from "react";
import { Box, Typography, Chip, Stack } from "@mui/material";

const ViewMode = ({ languages }) => {
  if (languages.length === 0) {
    return (
      <Typography
        sx={{
          color: "#666666",
          fontStyle: "italic",
          textAlign: "center",
          py: 4,
        }}
      >
        No languages added yet.
      </Typography>
    );
  }

  return (
    <Box>
      <Stack direction="row" spacing={1.5} flexWrap="wrap" gap={1.5}>
        {languages.map((lang, index) => (
          <Chip
            key={index}
            label={
              <Typography component="span">
                {lang.language}
                {lang.level && (
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "12px",
                      color: "#666666",
                      ml: 1,
                    }}
                  >
                    • {lang.level}
                  </Typography>
                )}
              </Typography>
            }
            sx={{
              backgroundColor: "#F8F9FA",
              borderRadius: "16px",
              px: 1,
              "& .MuiChip-label": {
                px: 1,
                color: "#2D2D2D",
                fontWeight: 500,
              },
            }}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default ViewMode;
