// pages/profile/ProfileEditor.jsx
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Container,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import ProfileOverview from "./sections/ProfileOverview";
import PersonalInformation from "./sections/PersonalInformation/PersonalInformation";
import WorkExperience from "./sections/WorkExperience/WorkExperience";
import Education from "./sections/Education/Education";
import Skills from "./sections/Skills/Skills";
import Languages from "./sections/Languages/Languages";
import Achievements from "./sections/Achievements/Achievements";
import Certifications from "./sections/Certifications/Certifications";
import Miscellaneous from "./sections/Miscellaneous/Miscellaneous";
import { useStateStore } from "../../../store";
import ProfileControls from "./sections/ProfileControls";
import { useHistory } from "react-router-dom";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#F8F9FA",
          minHeight: "100%",
        },
      },
    },
  },
});

const initialState = {
  personalInformation: {
    firstName: "",
    lastName: "",
    country: "",
    zipCode: "",
    address: "",
    phoneNumber: "",
    gender: "",
    city: "",
    countryCode: "",
    state: "",
    email: "",
    citizenship: "",
  },
  workExperience: [],
  education: [],
  skills: [],
  languages: [],
  achievements: [],
  certifications: [],
  miscellaneous: {
    githubUrl: "",
    linkedinUrl: "",
    twitterUrl: "",
    portfolioUrl: "",
    dribbleUrl: "",
    otherUrl: "",
    currentSalary: "",
    currentSalaryCurrency: "",
    expectedSalaryAmount: "",
    expectedSalaryCurrency: "",
    expectedDateOfJoining: "",
    noticePeriod: "",
    totalExperience: "",
    highestEducationLevel: "",
    authorisedToWork: [],
    disability: "",
    veteranStatus: "",
    visaRequirement: "",
    drivingLicense: "",
    willingToRelocation: "",
    companiesToExclude: "",
    coverLetter: "",
    securityClearanceType: "",
    securityClearanceActive: "",
    otherSecurityClearanceType: "",
    defaultExperience: "",
  },
  // ... other sections will be added
};

const ProfileEditor = () => {
  const { profileId } = useParams();
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState(initialState);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [error, setError] = useState(null);
  const [fileDetails, setFileDetails] = useState({});
  const { token, userData, planDetails } = useStateStore();
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [resumeMetaInfo, setResumeMetaInfo] = useState({
    resumeName: "",
    resumeId: "",
  });

  // Track expanded sections
  const [expandedSections, setExpandedSections] = useState({
    personal: true,
    work: false,
    education: false,
    skills: false,
    languages: false,
    achievements: false,
    certifications: false,
    miscellaneous: false,
  });

  const [showCelebration, setShowCelebration] = useState(false);
  const [previousCompletion, setPreviousCompletion] = useState(null);
  const celebrationMessageRef = useRef(null);
  const history = useHistory();
  const { width, height } = useWindowSize();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        setError(null);

        const url = `https://backend.lazyapply.com/lazyapplyV2/resume/${profileId}`;
        const {
          data: { data },
        } = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const transformedData = {
          personalInformation: {
            ...initialState.personalInformation,
            ...(data.personalInformation || {}),
          },
          workExperience: data.workExperience || initialState.workExperience,
          education: data.education || initialState.education,
          skills: data.skills || initialState.skills,
          languages: data.languages || initialState.languages,
          achievements: data.achievements || initialState.achievements,
          certifications: data.certifications || initialState.certifications,
          miscellaneous: {
            ...initialState.miscellaneous,
            ...(data.miscellaneous || {}),
          },
        };

        setResumeMetaInfo({
          resumeName: data?.resumeName || "",
          resumeId: data?.resumeId || "",
        });
        setFileDetails(data?.mainFileDetails || {});
        setProfileData(transformedData);

        const currentCompletion = data.percentageCompleted || 0;

        setCompletionPercentage(currentCompletion);

        if (previousCompletion === null) {
          setPreviousCompletion(currentCompletion);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        setError(
          error.response?.data?.message || "Failed to load profile data"
        );
      } finally {
        setLoading(false);
      }
    };

    if (profileId) {
      fetchProfile();
    }
  }, [profileId, previousCompletion]);

  useEffect(() => {
    if (previousCompletion === null) return;

    // Only trigger celebration if plan is not started and completion crosses 70%
    if (
      !planDetails?.planStarted &&
      previousCompletion < 70 &&
      completionPercentage >= 70
    ) {
      setShowCelebration(true);

      // Scroll to celebration message after a short delay
      setTimeout(() => {
        if (celebrationMessageRef.current) {
          celebrationMessageRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 1000);
    }

    // Update previous completion for next comparison
    setPreviousCompletion(completionPercentage);
  }, [completionPercentage, planDetails?.planStarted, previousCompletion]);

  // Auto-hide celebration after some time
  // useEffect(() => {
  //   if (showCelebration) {
  //     const timer = setTimeout(() => {
  //       setShowCelebration(false);
  //     }, 10000);

  //     return () => clearTimeout(timer);
  //   }
  // }, [showCelebration]);

  const handleSectionToggle = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleUpdateSection = async (section, newData) => {
    try {
      const url = `https://backend.lazyapply.com/lazyapplyV2/resume/${profileId}`;

      const {
        data: { data },
      } = await axios.put(
        url,
        {
          section,
          data: newData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setProfileData((prev) => ({
        ...prev,
        [section]: newData,
      }));

      if (data.resumeMeta) {
        setCompletionPercentage(data.resumeMeta.percentageCompleted);
      }
    } catch (error) {
      console.error("Error updating section:", error);
      setError(error.response?.data?.message || "Failed to update section");
    }
  };

  const handleExpandAll = () => {
    const newExpandedState = !isAllExpanded;
    setIsAllExpanded(newExpandedState);

    setExpandedSections((prev) => {
      const newState = {};
      Object.keys(prev).forEach((key) => {
        newState[key] = newExpandedState;
      });
      return newState;
    });
  };

  const handleDownloadResume = async () => {
    try {
      const fileObject = fileDetails;

      const cacheBustedUrl = `${fileObject.location}?_=${Date.now()}`;

      const response = await fetch(cacheBustedUrl);
      const fileContent = await response.blob();

      const blob = new Blob([fileContent], { type: fileObject.mimetype });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.target = "_blank";
      link.download = fileObject.originalname;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading resume:", error);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#F8F9FA",
        }}
      >
        <CircularProgress
          size={40}
          thickness={4}
          sx={{
            color: "#2D2D2D",
            mb: 2,
          }}
        />
        <Typography
          variant="body1"
          sx={{
            color: "#666666",
            fontWeight: 500,
          }}
        >
          Loading Profile...
        </Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ minHeight: "100vh", backgroundColor: "#F8F9FA", py: 4 }}>
        <Container maxWidth="lg" sx={{ py: 1 }}>
          <ProfileControls />

          {/* Celebration UI */}
          {showCelebration && (
            <>
              <Confetti
                width={width}
                height={height}
                recycle={false}
                numberOfPieces={500}
                gravity={0.15}
              />
              <Box
                ref={celebrationMessageRef}
                sx={{
                  p: 5,
                  mb: 5,
                  mt: 2,
                  borderRadius: "12px",
                  backgroundColor: "#F8FCFF",
                  textAlign: "center",
                  position: "relative",
                  boxShadow: "0 4px 20px rgba(0, 102, 204, 0.15)",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 8px 30px rgba(0, 102, 204, 0.2)",
                  },
                  zIndex: 10,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 600, mb: 2, color: "#0066cc" }}
                >
                  🎉 Congratulations! Your profile is now over 70% complete! 🎉
                </Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>
                  You're now ready to start applying for jobs! Explore our plans
                  to begin your job search journey.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/dashboard/pricing")}
                  sx={{
                    backgroundColor: "#2D2D2D",
                    "&:hover": {
                      backgroundColor: "#404040",
                      transform: "translateY(-3px)",
                      boxShadow: "0 10px 25px rgba(0, 102, 204, 0.4)",
                    },
                    px: 4,
                    py: 1.5,
                    borderRadius: "8px",
                    textTransform: "none",
                    fontSize: "16px",
                    fontWeight: 600,
                    transition: "all 0.3s ease",
                    boxShadow: "0px 6px 18px rgba(0, 102, 204, 0.3)",
                    position: "relative",
                  }}
                >
                  Explore Plans
                </Button>
              </Box>
            </>
          )}

          <ProfileOverview
            completion={completionPercentage}
            onExpandAll={handleExpandAll}
            isAllExpanded={isAllExpanded}
            onDownloadResume={handleDownloadResume}
            resumeMetaInfo={resumeMetaInfo}
            setFileDetails={setFileDetails}
          />

          <PersonalInformation
            data={profileData.personalInformation}
            isExpanded={expandedSections.personal}
            onToggleExpand={() => handleSectionToggle("personal")}
            onUpdate={(newData) =>
              handleUpdateSection("personalInformation", newData)
            }
          />

          <Miscellaneous
            data={profileData.miscellaneous}
            isExpanded={expandedSections.miscellaneous}
            onToggleExpand={() => handleSectionToggle("miscellaneous")}
            onUpdate={(newData) =>
              handleUpdateSection("miscellaneous", newData)
            }
          />

          <WorkExperience
            data={profileData.workExperience}
            isExpanded={expandedSections.work}
            onToggleExpand={() => handleSectionToggle("work")}
            onUpdate={(newData) =>
              handleUpdateSection("workExperience", newData)
            }
          />

          <Education
            data={profileData.education}
            isExpanded={expandedSections.education}
            onToggleExpand={() => handleSectionToggle("education")}
            onUpdate={(newData) => handleUpdateSection("education", newData)}
          />

          <Skills
            data={profileData.skills}
            isExpanded={expandedSections.skills}
            onToggleExpand={() => handleSectionToggle("skills")}
            onUpdate={(newData) => handleUpdateSection("skills", newData)}
          />

          <Languages
            data={profileData.languages}
            isExpanded={expandedSections.languages}
            onToggleExpand={() => handleSectionToggle("languages")}
            onUpdate={(newData) => handleUpdateSection("languages", newData)}
          />

          <Achievements
            data={profileData.achievements}
            isExpanded={expandedSections.achievements}
            onToggleExpand={() => handleSectionToggle("achievements")}
            onUpdate={(newData) => handleUpdateSection("achievements", newData)}
          />

          <Certifications
            data={profileData.certifications}
            isExpanded={expandedSections.certifications}
            onToggleExpand={() => handleSectionToggle("certifications")}
            onUpdate={(newData) =>
              handleUpdateSection("certifications", newData)
            }
          />
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default ProfileEditor;
