import React, { useState } from "react";
import { Box, Typography, Button, TextField, Paper } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const GmailCard = styled(Paper)({
  padding: "24px",
  height: "220px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  borderRadius: "16px",
});

const StyledButton = styled(Button)({
  textTransform: "none",
  padding: "8px 24px",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: 550,
  backgroundColor: "#2D2D2D",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#404040",
  },
});

const AddNewEmailCard = ({ onAdd }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!email) {
      setEmailError("Email is required");
      return false;
    }
    if (!regex.test(email)) {
      setEmailError("Please enter a valid email address");
      return false;
    }
    setEmailError("");
    return true;
  };

  const handleAdd = () => {
    if (validateEmail(newEmail.trim())) {
      onAdd(newEmail.trim());
      setNewEmail("");
      setEmailError("");
      setIsAdding(false);
    }
  };

  return (
    <GmailCard>
      {isAdding ? (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ mb: 2, fontWeight: 500, fontSize: "17px" }}>
              Add New Gmail
            </Typography>
            <TextField
              fullWidth
              value={newEmail}
              onChange={(e) => {
                setNewEmail(e.target.value);
                if (emailError) setEmailError("");
              }}
              placeholder="Enter Gmail address"
              size="small"
              error={!!emailError}
              helperText={emailError}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
                "& .MuiFormHelperText-root": {
                  color: "#d32f2f",
                  marginLeft: "3px",
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <StyledButton onClick={handleAdd}>Add</StyledButton>
            <Button
              onClick={() => setIsAdding(false)}
              sx={{
                color: "#666",
                "&:hover": { backgroundColor: "#f5f5f5" },
                textTransform: "none",
                fontSize: "15px",
                fontWeight: 550,
                borderRadius: "8px",
                padding: "8px 18px",
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box>
            <Typography
              sx={{ fontSize: "20px", fontWeight: 500, mb: 2, color: "#000" }}
            >
              Add another Email
            </Typography>
            <Typography sx={{ color: "#666666", fontSize: "15px" }}>
              Add another Gmail account to send referral emails from multiple
              addresses
            </Typography>
          </Box>
          <StyledButton
            onClick={() => setIsAdding(true)}
            startIcon={<AddIcon />}
            fullWidth
          >
            Add Gmail
          </StyledButton>
        </Box>
      )}
    </GmailCard>
  );
};

export default AddNewEmailCard;
