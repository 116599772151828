import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Popover,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormGroup,
  styled,
  Typography,
  Divider,
  Tooltip,
  TextField,
  Chip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClearIcon from "@mui/icons-material/Clear";
import linkedinLogo from "../assets/linkedin-icon.png";
import indeedLogo from "../assets/indeed.png";
import glassdoorLogo from "../assets/Glassdoor-Logo.png";
import ziprecruiterLogo from "../assets/ziprecruiter.png";
import diceLogo from "../assets/dice.png";
import careerbuilderLogo from "../assets/cbfinal.png";
import simplyhiredLogo from "../assets/simplyHiredLogo.png";
import lazyapplylogo from "../assets/panda1.png";
import { platformFilters, getFiltersForPlatform } from "./filterConfigs";
import JobCountSelector from "./JobCountSelector";
import { useStateStore } from "../../../store";
import { useHistory } from "react-router-dom";
import cities from "./cities.js";

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    fontSize: "16px",
    "& fieldset": {
      borderColor: "#E5E7EB",
      borderWidth: "2px",
    },
    "&:hover fieldset": {
      borderColor: "#4F46E5",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#4F46E5",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "12px",
    "&::placeholder": {
      color: "#9CA3AF",
      opacity: 1,
    },
  },
}));

const FilterButton = styled(Button)(
  ({ theme, active, isProfileFilter, isMandatory }) => ({
    marginRight: theme.spacing(1.5),
    textTransform: "none",
    borderRadius: "20px",
    padding: "8px 20px",
    fontSize: "16px",
    fontWeight: 500,
    position: "relative",
    paddingRight: isMandatory ? "28px" : "20px",

    backgroundColor: isProfileFilter
      ? active
        ? "#2D2D2D"
        : "#fff"
      : active
      ? "#2D2D2D"
      : "#fff",
    color: isProfileFilter
      ? active
        ? "#fff"
        : "#000"
      : active
      ? "#fff"
      : "#000",
    border: isProfileFilter
      ? active
        ? "none"
        : "1.5px solid #000"
      : active
      ? "none"
      : "1px solid #eee",

    "& .mandatory-indicator": {
      color: "#FF4D4F",
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: 1,
      display: "inline-block",
    },

    minHeight: "45px",
    "&:hover": {
      backgroundColor: isProfileFilter
        ? active
          ? "#404040"
          : "#FAFBFF"
        : active
        ? "#404040"
        : "#FAFBFF",
      border: isProfileFilter
        ? active
          ? "none"
          : "1.5px solid #000"
        : active
        ? "none"
        : "1px solid #000",
    },
    "& .MuiButton-endIcon": {
      marginLeft: "4px",
      transition: "transform 0.2s ease",
      "& svg": {
        fontSize: "18px",
        color: active ? "#fff" : "#666666",
      },
    },
    "&:hover .MuiButton-endIcon": {
      transform: "translateY(2px)",
    },
  })
);

const FilterPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPopover-paper": {
    padding: theme.spacing(2.5),
    width: "320px",
    borderRadius: "12px",
    marginTop: theme.spacing(1),
    boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  "& .MuiTypography-root": {
    fontSize: "15px",
  },
  "& .MuiCheckbox-root": {
    color: "#2D2D2D",
    "&.Mui-checked": {
      color: "#2557a7",
    },
  },
  "& .MuiRadio-root": {
    color: "#2D2D2D",
    "&.Mui-checked": {
      color: "#2557a7",
    },
  },
}));

const ClearButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 600,
  color: "#666",
  padding: "10px 16px",
  display: "flex",
  alignItems: "center",
  borderRadius: "8px",
  gap: "4px",
  "&:hover": {
    backgroundColor: "#f3f3f3",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "18px",
  },
}));

const ApplyButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#2557a7",
  color: "#fff",
  textTransform: "none",
  padding: "8px 20px",
  borderRadius: "6px",
  fontSize: "15px",
  fontWeight: 600,
  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
  "&:hover": {
    backgroundColor: "#1e4687",
    boxShadow: "0 2px 4px rgba(0,0,0,0.15)",
  },
  "&:active": {
    backgroundColor: "#194076",
    boxShadow: "none",
  },
}));

const FiltersContainer = styled(Box)({
  maxWidth: "1300px",
  margin: "12px auto 25px",
  padding: "0 8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "8px",
});

const StyledFormControlLabelWithLogo = styled(FormControlLabel)(
  ({ theme }) => ({
    margin: theme.spacing(1, 0),
    "& .MuiTypography-root": {
      fontSize: "16px",
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },
    "& .MuiRadio-root": {
      color: "#000",
      "&.Mui-checked": {
        color: "#2557a7",
      },
    },
  })
);

const getLogoSize = (platformId) => {
  switch (platformId) {
    case "indeed":
      return { width: "45px", height: "30px" };
    case "ziprecruiter":
      return { width: "60px", height: "40px" };

    default:
      return { width: "23px", height: "23px" };
  }
};

const PlatformLogo = styled("img")(({ platformId }) => ({
  ...getLogoSize(platformId),
  objectFit: "contain",
}));

const JobFilters = ({ filters, onFilterChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  const { planDetails, resumesMeta } = useStateStore();
  const history = useHistory();
  const [tempLocationValue, setTempLocationValue] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);

  const [tempSelectedValues, setTempSelectedValues] = useState([]);

  const platformOptions = [
    {
      value: "all",
      label: "All Platforms",
      logo: lazyapplylogo,
    },
    {
      value: "linkedin",
      label: "LinkedIn",
      logo: linkedinLogo,
    },
    {
      value: "glassdoor",
      label: "Glassdoor",
      logo: glassdoorLogo,
    },
    {
      value: "dice",
      label: "Dice",
      logo: diceLogo,
    },
    // {
    //   value: "careerbuilder",
    //   label: "CareerBuilder",
    //   logo: careerbuilderLogo,
    // },
    {
      value: "simplyhired",
      label: "SimplyHired",
      logo: simplyhiredLogo,
    },
    {
      value: "indeed",
      label: "Indeed",
      logo: indeedLogo,
    },
    {
      value: "ziprecruiter",
      label: "ZipRecruiter",
      logo: ziprecruiterLogo,
    },
  ];

  const filterCitySuggestions = (input) => {
    if (!input) {
      // Show popular cities if no input
      setFilteredCities([
        "Remote",
        "New York, USA",
        "London, UK",
        "San Francisco, USA",
        "Berlin, Germany",
        "Singapore, Singapore",
      ]);
      return;
    }

    const filtered = cities
      .filter((city) => city.label.toLowerCase().includes(input.toLowerCase()))
      .map((city) => city.label)
      .slice(0, 10); // Limit to 10 suggestions for better UX

    setFilteredCities(filtered);
  };

  useEffect(() => {
    if (currentFilter === "location") {
      setTempLocationValue(getFilterValue("location"));
      filterCitySuggestions(getFilterValue("location"));
    }
  }, [currentFilter]);

  const handleLocationInputChange = (e) => {
    const value = e.target.value;
    setTempLocationValue(value);
    filterCitySuggestions(value);
  };

  const handleCitySelect = (city) => {
    setTempLocationValue(city);
  };

  const handleApplyLocation = () => {
    handleChange("location", tempLocationValue);
    handleClose();
  };

  // useEffect(() => {
  //   // If jobCount is not set, initialize it to 100
  //   if (!filters.jobCount) {
  //     onFilterChange({
  //       ...filters,
  //       jobCount: "100",
  //     });
  //   }
  // }, []);

  const getCurrentPlatform = () => filters.platform || "all";

  const getFilterOptions = () => {
    const currentPlatform = getCurrentPlatform();
    return getFiltersForPlatform(currentPlatform);
  };

  const getNormalizedFilterValue = (filterKey, value) => {
    const currentPlatform = getCurrentPlatform();
    const platformConfig = platformFilters[currentPlatform];

    if (!platformConfig) return value;

    const filterConfig = platformConfig[filterKey];
    if (!filterConfig) return value;

    if (filterConfig.value) {
      return {
        value: filterConfig.value,
        selected: value,
      };
    }

    if (
      filterConfig.options &&
      filterConfig.options[0] &&
      "label" in filterConfig.options[0] &&
      !("value" in filterConfig.options[0])
    ) {
      return value;
    }

    return value;
  };

  const handleClick = (event, filterName) => {
    if (currentFilter === filterName) {
      handleClose();
      return;
    }
    setCurrentFilter(filterName);
    setAnchorEl(event.currentTarget);

    // if (filterOptions[filterName].isMulti) {
    //   setTempSelectedValues([...filters[filterName]]);
    // }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentFilter(null);
    // setTempSelectedValues([]);
  };

  const handleChange = (filterName, value) => {
    if (filterName === "platform") {
      // Handle platform change

      const currentProfileSelection = filters.profileSelector;
      const currentLocation = filters.location;

      const newFilters = {
        platform: value,
        profileSelector: currentProfileSelection,
        location: currentLocation,

        ...Object.keys(getFiltersForPlatform(value)).reduce((acc, key) => {
          if (key !== "profileSelector" && key !== "location") {
            acc[key] = "";
          }

          return acc;
        }, {}),
      };
      onFilterChange(newFilters);
      handleClose();
      return;
    }

    // Special handling for location filter - don't close popover while typing
    if (filterName === "location") {
      const normalizedValue = getNormalizedFilterValue(filterName, value);
      const newFilters = {
        ...filters,
        [filterName]: normalizedValue,
      };
      onFilterChange(newFilters);
      return;
    }

    const normalizedValue = getNormalizedFilterValue(filterName, value);
    const newFilters = {
      ...filters,
      [filterName]: normalizedValue,
    };
    onFilterChange(newFilters);
    handleClose();

    // if (filterOptions[filterName].isMulti) {
    //   setTempSelectedValues((prev) =>
    //     prev.includes(value)
    //       ? prev.filter((item) => item !== value)
    //       : [...prev, value]
    //   );
    // } else {
    //   const newFilters = {
    //     ...filters,
    //     [filterName]: value,
    //   };
    //   onFilterChange(newFilters);
    //   handleClose();
    // }
  };

  const handleLocationSuggestionSelect = (suggestion) => {
    handleChange("location", suggestion);
    handleClose();
  };

  const getFilterValue = (filterName) => {
    const value = filters[filterName];
    if (!value) return "";

    // Handle CareerBuilder style filters
    if (value.selected !== undefined) {
      return value.selected;
    }

    return value;
  };

  const isMandatoryFilter = (filterName) => {
    const platform = getCurrentPlatform();

    if (filterName === "profileSelector") return true;
    if (
      (platform === "glassdoor" || platform === "indeed") &&
      filterName.toLowerCase().includes("country")
    ) {
      return true;
    }
    return false;
  };

  const renderFilterOptions = (filterName) => {
    if (filterName === "platform") {
      return (
        <RadioGroup
          value={getCurrentPlatform()}
          onChange={(e) => handleChange("platform", e.target.value)}
        >
          {platformOptions.map((option) => (
            <StyledFormControlLabelWithLogo
              key={option.value}
              value={option.value}
              control={<Radio />}
              disabled={option.value !== "all" && !planDetails?.planStarted}
              label={
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <PlatformLogo
                    src={option.logo}
                    alt={option.label}
                    platformId={option.value}
                  />
                  <span>{option.label}</span>
                </Box>
              }
            />
          ))}
        </RadioGroup>
      );
    }

    if (filterName === "profileSelector") {
      const validProfiles = resumesMeta.filter(
        (resume) => resume.percentageCompleted >= 70
      );

      if (validProfiles.length === 0) {
        const hasProfiles = resumesMeta.length > 0;

        return (
          <Box
            sx={{
              p: 3,
              textAlign: "center",
              bgcolor: "#f8f8f8",
              borderRadius: "8px",
            }}
          >
            <Typography sx={{ color: "#666", mb: 3, fontSize: "16px" }}>
              {hasProfiles
                ? "Complete your profile to at least 70% to start applying"
                : "No profiles found"}
            </Typography>
            <Button
              variant="outlined"
              onClick={() => history.push("/dashboard/profile")}
              sx={{
                color: "#000",
                textTransform: "none",
                fontWeight: 550,
                fontSize: "18px",
                borderRadius: "8px",
                border: "1px solid #404040",
                px: 2,
                "&:hover": {
                  bgcolor: "#fff",
                  border: "1px solid #000",
                },
              }}
            >
              {hasProfiles ? "Go to Profiles" : "Create Profile"}
            </Button>
          </Box>
        );
      }

      return (
        <RadioGroup
          value={getFilterValue(filterName)}
          onChange={(e) => handleChange(filterName, e.target.value)}
        >
          {validProfiles.map((profile) => (
            <StyledFormControlLabel
              key={profile.resumeId}
              value={profile.resumeId}
              control={<Radio />}
              label={
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    {profile.resumeName}
                  </Typography>
                  <Typography variant="caption" sx={{ color: "#666" }}>
                    {profile.percentageCompleted}% Complete
                  </Typography>
                </Box>
              }
            />
          ))}
        </RadioGroup>
      );
    }

    if (filterName === "location") {
      return (
        <Box>
          <StyledTextField
            fullWidth
            placeholder="Enter location"
            value={tempLocationValue}
            onChange={handleLocationInputChange}
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
            autoFocus
          />

          {filteredCities.length > 0 && (
            <>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 1, fontSize: "16px", mt: 1 }}
              >
                {tempLocationValue ? "Matching locations" : "Popular locations"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                {filteredCities.map((city) => (
                  <Chip
                    key={city}
                    label={city}
                    onClick={() => handleCitySelect(city)}
                    clickable
                    variant="outlined"
                    size="small"
                    sx={{
                      mb: 0.5,
                      fontSize: "15px",
                      py: 1.5,
                      px: 0.5,
                      backgroundColor:
                        tempLocationValue === city ? "#e0e7ff" : "transparent",
                      borderColor:
                        tempLocationValue === city ? "#4F46E5" : "inherit",
                    }}
                  />
                ))}
              </Box>
            </>
          )}

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleApplyLocation}
              sx={{
                bgcolor: "#2D2D2D",
                color: "#fff",
                textTransform: "none",
                fontSize: "15px",
                padding: "4px 16px",
                borderRadius: "8px",
                "&:hover": { bgcolor: "#404040" },
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      );
    }

    const filterConfig = getFilterOptions()[filterName];
    if (!filterConfig) return null;

    try {
      // if (filterConfig.type === "jobCount") {
      //   return (
      //     <JobCountSelector
      //       value={getFilterValue(filterName)}
      //       onChange={(value) => handleChange(filterName, value)}
      //     />
      //   );
      // }

      return (
        <RadioGroup
          value={getFilterValue(filterName)}
          onChange={(e) => handleChange(filterName, e.target.value)}
        >
          {filterConfig.options.map((option) => (
            <StyledFormControlLabel
              key={option.value || option.label}
              value={option.value || option.label}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      );
    } catch (error) {
      console.error("Error rendering filter options:", error);
      return null;
    }
  };

  const getButtonLabel = (filterName) => {
    if (filterName === "platform") {
      const currentPlatform = getCurrentPlatform();
      const platformOption = platformOptions.find(
        (opt) => opt.value === currentPlatform
      );
      return platformOption ? platformOption.label : "All Platforms";
    }

    if (filterName === "profileSelector") {
      const profileId = getFilterValue(filterName);
      if (!profileId) return "Select Profile";

      const selectedProfile = resumesMeta.find(
        (profile) => profile.resumeId === profileId
      );
      return selectedProfile ? selectedProfile.resumeName : "Select Profile";
    }

    const value = getFilterValue(filterName);
    if (!value) return getFilterOptions()[filterName]?.label || filterName;

    // Find the option label for the current value
    const filterConfig = getFilterOptions()[filterName];
    if (!filterConfig) return filterName;

    if (filterConfig.type === "jobCount") {
      return `${value} jobs`;
    }

    const option = filterConfig?.options?.find(
      (opt) => (opt.value || opt.label) === value
    );

    return option?.label || value;
  };

  const isFilterActive = (filterName) => {
    // const value = filters[filterName];
    // return Array.isArray(value) ? value.length > 0 : Boolean(value);

    const value = filters[filterName];
    if (!value) return false;
    if (value.selected !== undefined) return !!value.selected;
    return true;
  };

  const resetAllFilters = () => {
    const currentPlatform = getCurrentPlatform();
    const resetFilters = {
      platform: currentPlatform,

      ...Object.keys(getFilterOptions()).reduce((acc, key) => {
        acc[key] = "";
        return acc;
      }, {}),
    };
    onFilterChange(resetFilters);
  };

  return (
    <FiltersContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        <FilterButton
          onClick={(e) => handleClick(e, "platform")}
          endIcon={<KeyboardArrowDownIcon />}
          active={getCurrentPlatform() !== "all"}
        >
          {getButtonLabel("platform")}
        </FilterButton>

        {Object.keys(getFilterOptions()).map((filterName) => (
          <FilterButton
            key={filterName}
            onClick={(e) => handleClick(e, filterName)}
            endIcon={<KeyboardArrowDownIcon />}
            active={isFilterActive(filterName)}
            isProfileFilter={filterName === "profileSelector"}
            isMandatory={isMandatoryFilter(filterName)}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {getButtonLabel(filterName)}
              {isMandatoryFilter(filterName) && (
                <span
                  className="mandatory-indicator"
                  style={{
                    position: "relative",
                    marginLeft: "2px",
                    top: "-3px",
                  }}
                >
                  *
                </span>
              )}
            </Box>
          </FilterButton>
        ))}

        {Object.keys(filters).some((key) => isFilterActive(key)) && (
          <ClearButton onClick={resetAllFilters} startIcon={<ClearIcon />}>
            Clear all
          </ClearButton>
        )}
      </Box>

      <FilterPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {currentFilter && (
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {currentFilter === "platform"
                  ? "Select Platform"
                  : getFilterOptions()[currentFilter]?.label}
              </Typography>

              {isMandatoryFilter(currentFilter) && (
                <Typography
                  sx={{
                    color: "#FF4D4F",
                    fontSize: "14px",
                    fontStyle: "italic",
                  }}
                >
                  * Required
                </Typography>
              )}
            </Box>

            {renderFilterOptions(currentFilter)}

            {isFilterActive(currentFilter) && (
              <>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    size="small"
                    onClick={() => {
                      if (currentFilter === "location") {
                        setTempLocationValue("");
                        filterCitySuggestions("");
                      }
                      handleChange(currentFilter, "");
                    }}
                    sx={{
                      color: "#666",
                      textTransform: "none",
                      padding: "8px 16px",
                      borderRadius: "8px",
                      fontWeight: 550,
                      fontSize: "15px",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                    startIcon={<ClearIcon sx={{ fontSize: 18 }} />}
                  >
                    Clear
                  </Button>
                </Box>
              </>
            )}
          </Box>
        )}
      </FilterPopover>
    </FiltersContainer>
  );
};

export default JobFilters;
