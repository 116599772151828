import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import BaseSection from "../BaseSection";
import ViewMode from "./ViewMode";
import EditMode from "./EditMode";
import { useStateStore } from "../../../../../store";
import axios from "axios";

const PersonalInformation = ({
  data,
  isExpanded,
  onToggleExpand,
  onUpdate,
}) => {
  const [formData, setFormData] = useState({
    ...data,
    phoneNumber: data.phoneNumber
      ? data.phoneNumber.replace(/[^0-9]/g, "")
      : data.phoneNumber,
    citizenship: data.citizenship || "",
  });

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [sectionStatus, setSectionStatus] = useState("missing_required");

  const [originalNames, setOriginalNames] = useState(null);
  const { token, userData } = useStateStore();

  useEffect(() => {
    const initialStatus = getSectionStatus(data);
    setSectionStatus(initialStatus);
  }, [data]);

  useEffect(() => {
    const fetchOriginalNames = async () => {
      try {
        const response = await axios.get(
          `https://backend.lazyapply.com/lazyapplyV2/user-names/${userData.v2Id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.exists) {
          setOriginalNames(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching original names:", error);
      }
    };

    fetchOriginalNames();
  }, []);

  const requiredFields = [
    "firstName",
    "lastName",
    "gender",
    "email",
    "countryCode",
    "phoneNumber",
    "address",
    "city",
    "state",
    "zipCode",
    "country",
  ];

  const getSectionStatus = (data) => {
    const hasAllRequired = requiredFields.every(
      (field) => data[field] && data[field].toString().trim() !== ""
    );

    if (hasAllRequired && Object.keys(errors).length === 0) {
      console.log("Returning complete");
      return "complete";
    } else if (!hasAllRequired) {
      console.log("Returning missing_required");
      return "missing_required";
    }
    console.log("Returning missing_optional");
    return "missing_optional";
  };

  const validateField = (field, value) => {
    // First check if the field is empty
    if (!value || !value.toString().trim()) {
      const fieldNames = {
        firstName: "First Name",
        lastName: "Last Name",
        gender: "Gender",
        email: "Email",
        countryCode: "Country Code",
        phoneNumber: "Phone Number",
        address: "Address",
        city: "City",
        state: "State",
        zipCode: "ZipCode",
        country: "Country",
      };
      return `${fieldNames[field]} is required`;
    }

    // Then do email specific validation
    if (field === "email") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(value)) {
        return "Invalid email format";
      }
    }

    return "";
  };

  const handleChange = (field) => (event) => {
    let value = event.target.value;

    if (field === "phoneNumber") {
      value = value.replace(/[^0-9]/g, "");
    }

    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Clear error if field was touched
    if (touched[field] && errors[field]) {
      setErrors((prev) => ({
        ...prev,
        [field]: "",
      }));
    }
  };

  const handleBlur = (field) => {
    setTouched((prev) => ({
      ...prev,
      [field]: true,
    }));

    const error = validateField(field, formData[field]);
    setErrors((prev) => ({
      ...prev,
      [field]: error,
    }));
  };

  const calculateNameSimilarity = (original, current, isLastName = false) => {
    if (!original || !current) return 1;

    const cleanStr = (str, isLastName) => {
      const clean = str
        .toLowerCase()
        .replace(/[^a-z0-9\s]/g, "")
        .trim();

      const parts = clean.split(/\s+/);

      return isLastName ? parts[parts.length - 1] : parts[0];
    };

    const s1 = cleanStr(original, isLastName);
    const s2 = cleanStr(current, isLastName);

    const track = Array(s2.length + 1)
      .fill(null)
      .map(() => Array(s1.length + 1).fill(null));

    for (let i = 0; i <= s1.length; i += 1) {
      track[0][i] = i;
    }
    for (let j = 0; j <= s2.length; j += 1) {
      track[j][0] = j;
    }

    for (let j = 1; j <= s2.length; j += 1) {
      for (let i = 1; i <= s1.length; i += 1) {
        const indicator = s1[i - 1] === s2[j - 1] ? 0 : 1;
        track[j][i] = Math.min(
          track[j][i - 1] + 1,
          track[j - 1][i] + 1,
          track[j - 1][i - 1] + indicator
        );
      }
    }

    const maxLength = Math.max(s1.length, s2.length);
    const similarity = 1 - track[s2.length][s1.length] / maxLength;

    return similarity;
  };

  const handleSave = () => {
    const newErrors = {};
    const newTouched = {};

    let hasErrors = false;
    requiredFields.forEach((field) => {
      newTouched[field] = true;
      const error = validateField(field, formData[field]);
      if (error) {
        newErrors[field] = error;
        hasErrors = true;
      }
    });

    if (!hasErrors && originalNames) {
      const firstNameSimilarity = calculateNameSimilarity(
        originalNames.firstName,
        formData.firstName,
        false
      );
      const lastNameSimilarity = calculateNameSimilarity(
        originalNames.lastName,
        formData.lastName,
        true
      );

      if (firstNameSimilarity < 0.6) {
        newErrors.firstName = `Your first name "${formData.firstName}" appears significantly different from your original name "${originalNames.firstName}". Only minor changes are allowed.`;
        hasErrors = true;
      }

      if (lastNameSimilarity < 0.7) {
        newErrors.lastName = `Your last name "${formData.lastName}" appears significantly different from your original name "${originalNames.lastName}". Only minor changes are allowed.`;
        hasErrors = true;
      }
    }

    console.log("Validation errors:", hasErrors, newErrors);
    setTouched(newTouched);
    setErrors(newErrors);

    if (!hasErrors) {
      console.log("No errors, updating with data:", formData);
      const newStatus = getSectionStatus(formData);
      setSectionStatus(newStatus);
      onUpdate(formData);
      return true;
    }

    return false;
  };

  const handleCancel = () => {
    setFormData(data);
    setErrors({});
    setTouched({});
    setSectionStatus(getSectionStatus(data));
  };

  return (
    <BaseSection
      title="Personal Information"
      isExpanded={isExpanded}
      onToggleExpand={onToggleExpand}
      onSave={handleSave}
      onCancel={handleCancel}
      canSave={true}
      sectionStatus={sectionStatus}
    >
      {({ isEditing }) => (
        <Grid container>
          {isEditing ? (
            <EditMode
              data={formData}
              errors={errors}
              touched={touched}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          ) : (
            <ViewMode data={formData} />
          )}
        </Grid>
      )}
    </BaseSection>
  );
};

export default PersonalInformation;
