import { changeFiltersValuesLinkedin, changeFiltersValuesSimplyHired, changeFiltersValuesDice, changeFiltersValuesZiprecruiter, changeFiltersValuesGlassdoor, changeFiltersValuesIndeed } from "./mainContent";

export function mainFiltersFormation(
    platform,
    filters,
    url,
) {
    platform = platform.toLowerCase()
    if (platform === "indeed") {
        return changeFiltersValuesIndeed(filters, url);
    } else if (platform === "dice") {
        return changeFiltersValuesDice(filters, url);
    }
    else if (platform === "glassdoor") {
        return changeFiltersValuesGlassdoor(filters, url);
    } else if (platform === "linkedin") {
        return changeFiltersValuesLinkedin(filters, url);
    } else if (platform === "simplyhired") {
        return changeFiltersValuesSimplyHired(filters, url);
    } else if (platform === "ziprecruiter") {
        return changeFiltersValuesZiprecruiter(filters, url);
    }
}