// components/sections/Achievements/EditMode.jsx
import React from "react";
import {
  Grid,
  Box,
  TextField,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    "&:hover fieldset": {
      borderColor: "#2D2D2D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2D2D2D",
      borderWidth: "1px",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E0E0E0",
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: "#2D2D2D",
    },
  },
};

const AchievementForm = ({
  achievement,
  index,
  errors = {},
  touched = {},
  onChange,
  onBlur,
  onDelete,
}) => {
  const handleChange = (field) => (event) => {
    const value = event.target.value;
    const updatedAchievement = {
      ...achievement,
      [field]: value,
    };
    onChange(index, updatedAchievement);
  };

  const handleBlur = (field) => () => {
    onBlur(index, field);
  };

  return (
    <Box
      sx={{
        mb: 4,
        pb: 4,
        borderBottom: "1px solid #E0E0E0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          mb: 2,
        }}
      >
        <Typography variant="h6" sx={{ color: "#2D2D2D" }}>
          Achievement {index + 1}
        </Typography>
        <IconButton
          onClick={() => onDelete(index)}
          sx={{
            color: "#666666",
            "&:hover": {
              color: "#FF7875",
            },
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>

      <Grid container spacing={2.5}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Award Title"
            value={achievement.awardTitle || ""}
            onChange={handleChange("awardTitle")}
            onBlur={handleBlur("awardTitle")}
            error={touched?.awardTitle && !!errors?.awardTitle}
            helperText={touched?.awardTitle && errors?.awardTitle}
            variant="outlined"
            sx={textFieldStyles}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Issuer"
            value={achievement.issuer || ""}
            onChange={handleChange("issuer")}
            onBlur={handleBlur("issuer")}
            error={touched?.issuer && !!errors?.issuer}
            helperText={touched?.issuer && errors?.issuer}
            variant="outlined"
            sx={textFieldStyles}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Date"
            type="month"
            value={achievement.issuingDate || ""}
            onChange={handleChange("issuingDate")}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            sx={textFieldStyles}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Description"
            value={achievement.description || ""}
            onChange={handleChange("description")}
            onBlur={handleBlur("description")}
            error={touched?.description && !!errors?.description}
            helperText={touched?.description && errors?.description}
            multiline
            rows={3}
            variant="outlined"
            sx={textFieldStyles}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const EditMode = ({
  achievements,
  errors,
  touched,
  onChange,
  onBlur,
  onAdd,
  onDelete,
}) => {
  const handleFieldChange = (index, updatedAchievement) => {
    const newAchievements = [...achievements];
    newAchievements[index] = updatedAchievement;
    onChange(newAchievements);
  };

  return (
    <Box>
      {achievements.map((achievement, index) => (
        <AchievementForm
          key={index}
          achievement={achievement}
          index={index}
          errors={errors[index]}
          touched={touched[index]}
          onChange={handleFieldChange}
          onBlur={onBlur}
          onDelete={onDelete}
        />
      ))}

      <Button
        startIcon={<AddIcon />}
        onClick={onAdd}
        sx={{
          mt: 2,
          color: "#2D2D2D",
          borderColor: "#2D2D2D",
          borderRadius: "8px",
          padding: "8px 16px",
          "&:hover": {
            borderColor: "#2D2D2D",
            backgroundColor: "rgba(45, 45, 45, 0.04)",
          },
        }}
        variant="outlined"
      >
        Add Another Achievement
      </Button>
    </Box>
  );
};

export default EditMode;
