// DetailedView/ResumePreview.jsx
import React from "react";
import { Box, Card, CardContent, Typography, IconButton } from "@mui/material";
import { Info } from "@mui/icons-material";

// Sample data - we'll move this elsewhere later
const sampleExperience = [
  {
    title: "[Company Name Redacted] • Senior Software Engineer",
    description:
      "Led the development of scalable microservices architecture, improving system performance by 40%. Mentored junior developers and implemented best practices across the team.",
  },
  {
    title: "[Company Name Redacted] • Software Engineer",
    description:
      "Developed and maintained critical features for the main product. Collaborated with cross-functional teams to deliver high-impact projects on schedule.",
  },
  {
    title: "[Company Name Redacted] • Junior Developer",
    description:
      "Contributed to frontend development using React and TypeScript. Participated in code reviews and implemented unit tests for key functionalities.",
  },
];

const skills =
  "React • Node.js • Python • AWS • Docker • Kubernetes • GraphQL • TypeScript • MongoDB • Redis • CI/CD • System Design";

const ResumePreview = ({ data, onOpenModal }) => {
  const truncateText = (text, limit = 150) => {
    if (!text) return "";
    if (text.length <= limit) return text;
    return text.slice(0, limit) + "...";
  };

  return (
    <Card
      elevation={0}
      sx={{
        border: 1,
        borderColor: "grey.100",
        borderRadius: 2,
      }}
    >
      <CardContent sx={{ p: 3 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Resume Preview
          </Typography>
          <IconButton
            onClick={onOpenModal}
            size="small"
            sx={{
              p: 0.5,
              color: "text.secondary",
              "&:hover": {
                background: "none",
                color: "#64b5f6",
              },
            }}
          >
            <Info sx={{ fontSize: 24 }} />
          </IconButton>
        </Box>

        {/* Experience Section */}
        <Box mb={4}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              color: "text.secondary",
              mb: 2,
            }}
          >
            Experience
          </Typography>
          {/* Show only first two experiences */}
          {data.resumeData.workExperience.slice(0, 2).map((exp, index) => (
            <Box
              key={index}
              sx={{
                p: 2,
                bgcolor: "grey.50",
                borderRadius: 1,
                mb: 2,
                "&:last-child": { mb: 0 },
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "text.primary", mb: 1, fontSize: "15px" }}
              >
                {exp.title}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: "text.secondary",
                  display: "block",
                  lineHeight: 1.5,
                  fontSize: "14px",
                }}
              >
                {truncateText(exp.description)}
              </Typography>
            </Box>
          ))}
        </Box>

        {/* Skills Section */}
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              color: "text.secondary",
              mb: 2,
            }}
          >
            Skills & Technologies
          </Typography>
          <Box
            sx={{
              p: 2,
              bgcolor: "grey.50",
              borderRadius: 1,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: "text.secondary",
                lineHeight: 1.5,
                display: "block",
                fontSize: "14px",
              }}
            >
              {truncateText(data.resumeData.skills.join(" • "), 100)}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ResumePreview;
