import React from "react";
import { Grid, Paper, Typography, Skeleton, Box, Avatar } from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import EmailIcon from "@mui/icons-material/Email";
import PeopleIcon from "@mui/icons-material/People";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// Mock data for testing
const mockOwnerMetrics = {
  totalJobs: 437,
  totalEmails: 1256,
  totalUsersAssigned: 68,
  maxUsers: 100,
  topOperator: {
    name: "Jane Smith",
    email: "jane.smith@company.com",
    totalActions: 593,
  },
};

const MetricCard = ({ title, value, subtitle, loading, icon }) => (
  <Paper
    elevation={0}
    sx={{
      p: 3,
      height: "100%",
      borderRadius: "12px",
      border: "1px solid #E0E0E0",
      backgroundColor: "#fff",
      "&:hover": {
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
      },
      transition: "box-shadow 0.3s ease-in-out",
    }}
  >
    {loading ? (
      <Box>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="40%" height={40} />
        <Skeleton variant="text" width="80%" />
      </Box>
    ) : (
      <>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          {icon && <Box sx={{ mr: 1, color: "#666" }}>{icon}</Box>}
          <Typography
            variant="body1"
            sx={{
              color: "#666",
              fontWeight: 500,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          component="div"
          sx={{
            fontSize: "26px",
            fontWeight: 600,
            color: "#2D2D2D",
            mb: 1,
          }}
        >
          {value}
        </Typography>
        {subtitle && (
          <Typography
            variant="body2"
            sx={{
              color: "#666",
              fontWeight: 500,
            }}
          >
            {subtitle}
          </Typography>
        )}
      </>
    )}
  </Paper>
);

const TopOperatorCard = ({ operator, loading }) => (
  <Paper
    elevation={0}
    sx={{
      p: 3,
      height: "100%",
      borderRadius: "12px",
      border: "1px solid #E0E0E0",
      backgroundColor: "#fff",
      "&:hover": {
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
      },
      transition: "box-shadow 0.3s ease-in-out",
    }}
  >
    {loading ? (
      <Box>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="40%" height={40} />
        <Skeleton variant="text" width="80%" />
      </Box>
    ) : (
      <>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Box sx={{ mr: 1, color: "#666" }}>
            <TrendingUpIcon />
          </Box>
          <Typography
            variant="body1"
            sx={{
              color: "#666",
              fontWeight: 500,
            }}
          >
            Top Performing Operator
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Avatar
            sx={{
              bgcolor: "#e6f7e6",
              color: "#2d8a2d",
              width: 40,
              height: 40,
              mr: 1.5,
            }}
          >
            {operator?.name.charAt(0)}
          </Avatar>
          <Box>
            <Typography
              component="div"
              sx={{
                fontSize: "18px",
                fontWeight: 600,
                color: "#2D2D2D",
              }}
            >
              {operator?.name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#666",
                fontWeight: 400,
              }}
            >
              {operator?.email}
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="body2"
          sx={{
            color: "#666",
            fontWeight: 500,
            mt: 1,
          }}
        >
          {operator?.totalActions} Total Actions
        </Typography>
      </>
    )}
  </Paper>
);

const OwnerMetricsCards = ({ metrics, loading }) => {
  // If no metrics are provided, use mock data for testing
  const data = metrics || mockOwnerMetrics;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={3}>
        <MetricCard
          title="Total Jobs Applied"
          value={data.totalJobs || 0}
          icon={<WorkIcon />}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <MetricCard
          title="Total Emails Sent"
          value={data.totalEmails || 0}
          icon={<EmailIcon />}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <MetricCard
          title="Users Assigned"
          value={`${data.totalUsersAssigned || 0} / ${data.maxUsers || 100}`}
          subtitle="Total users assigned"
          icon={<PeopleIcon />}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TopOperatorCard operator={data.topOperator} loading={loading} />
      </Grid>
    </Grid>
  );
};

export default OwnerMetricsCards;
