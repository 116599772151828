// components/sections/Skills/index.jsx
import React, { useState, useEffect } from "react";
import { Grid, Box, Button } from "@mui/material";
import BaseSection from "../BaseSection";
import ViewMode from "./ViewMode";
import EditMode from "./EditMode";

const validateSkills = (skills) => {
  const errors = [];

  if (skills.length === 0) {
    return [{ skill: "At least one skill is required" }];
  }

  skills.forEach((skill, index) => {
    const skillErrors = {};

    if (!skill.skill?.trim()) {
      skillErrors.skill = "Skill name is required";
    }
    if (!skill.experience) {
      skillErrors.experience = "Experience is required";
    }

    if (Object.keys(skillErrors).length > 0) {
      errors[index] = skillErrors;
    }
  });

  return errors;
};

const Skills = ({ data = [], isExpanded, onToggleExpand, onUpdate }) => {
  const [skills, setSkills] = useState(data);
  const [errors, setErrors] = useState([]);
  const [touched, setTouched] = useState([]);
  const [sectionStatus, setSectionStatus] = useState("missing_required");

  useEffect(() => {
    setSkills(data);
    // Validate and set initial errors
    const initialErrors = validateSkills(data);
    setErrors(initialErrors);
  }, [data]);

  const validateField = (skill, field) => {
    if (!skill[field]?.toString().trim()) {
      return `${field} is required`;
    }
    return "";
  };

  const handleBlur = (index, field) => {
    const newTouched = [...touched];
    if (!newTouched[index]) newTouched[index] = {};
    newTouched[index][field] = true;
    setTouched(newTouched);

    // Validate this field
    const fieldError = validateField(skills[index], field);
    const newErrors = [...errors];
    if (!newErrors[index]) newErrors[index] = {};
    newErrors[index][field] = fieldError;
    setErrors(newErrors);
  };

  const handleAddSkill = () => {
    const newSkills = [
      ...skills,
      {
        skill: "",
        experience: "",
      },
    ];
    setSkills(newSkills);
    // Reset errors for the new skill
    const newErrors = validateSkills(newSkills);
    setErrors(newErrors);
  };

  const handleSave = () => {
    const validationErrors = validateSkills(skills);
    setErrors(validationErrors);

    if (
      validationErrors.length === 0 ||
      !validationErrors.some((error) => error)
    ) {
      const newStatus = getSectionStatus(skills);
      setSectionStatus(newStatus);
      onUpdate(skills);
      return true;
    }

    const touchedFields = skills.map(() => ({
      skill: true,
      experience: true,
    }));
    setTouched(touchedFields);
    return false;
  };

  const handleCancel = () => {
    setSkills(data);
    setErrors([]);
    setTouched([]);
    setSectionStatus(getSectionStatus(data));
  };

  const handleDelete = (indexToDelete) => {
    const updatedSkills = skills.filter((_, index) => index !== indexToDelete);
    setSkills(updatedSkills);
    onUpdate(updatedSkills);
  };

  const getSectionStatus = (skillsData) => {
    // If no skills, it's missing required
    if (skillsData.length === 0) {
      return "missing_required";
    }

    // Check if all required fields are filled in all skills
    const hasAllRequired = skillsData.every(
      (skill) => skill.skill?.trim() && skill.experience?.toString().trim()
    );

    // Check for any validation errors
    const currentErrors = validateSkills(skillsData);
    const hasErrors = currentErrors.some(
      (error) => error && Object.keys(error).length > 0
    );

    console.log(hasAllRequired, hasErrors, "hello");

    if (hasAllRequired && !hasErrors) {
      return "complete";
    } else {
      return "missing_required";
    }
  };

  const isFormValid = !validateSkills(skills).some((error) => error);

  useEffect(() => {
    const newStatus = getSectionStatus(skills);
    setSectionStatus(newStatus);
  }, [skills]);

  return (
    <BaseSection
      title="Skills"
      isExpanded={isExpanded}
      onToggleExpand={onToggleExpand}
      onSave={handleSave}
      onCancel={handleCancel}
      canSave={isFormValid}
      sectionStatus={sectionStatus}
    >
      {({ isEditing, handleSave, handleCancel }) => (
        <Grid container>
          {isEditing ? (
            <>
              <EditMode
                skills={skills}
                errors={errors}
                touched={touched}
                onAdd={handleAddSkill}
                onChange={setSkills}
                onBlur={handleBlur}
                setTouched={setTouched}
                onDelete={handleDelete}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  mt: 3,
                  borderTop: "1px solid #E0E0E0",
                  pt: 3,
                  width: "100%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  sx={{
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: 600,
                    padding: "4px 12px",
                    color: "#666666",
                    borderColor: "#666666",
                    "&:hover": {
                      borderColor: "#2D2D2D",
                      backgroundColor: "rgba(45, 45, 45, 0.04)",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={!isFormValid}
                  sx={{
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: 600,
                    padding: "6px 18px",
                    backgroundColor: isFormValid ? "#2D2D2D" : "#E0E0E0",
                    color: isFormValid ? "#FFFFFF" : "#666666",
                    "&:hover": {
                      backgroundColor: isFormValid ? "#404040" : "#E0E0E0",
                    },
                  }}
                >
                  Save
                </Button>
              </Box>
            </>
          ) : (
            <ViewMode skills={skills} />
          )}
        </Grid>
      )}
    </BaseSection>
  );
};

export default Skills;
