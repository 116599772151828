// ResultsView.jsx
import React from "react";
import { Box, Container } from "@mui/material";
import AutomationResults from "./AutomationResults";
import FeedbackSection from "../ats-dashboard/components/FeedbackSection";

const ResultsView = ({ results, onStartNewSearch }) => {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <AutomationResults
        results={results}
        onStartNewSearch={onStartNewSearch}
      />
      <FeedbackSection />
    </Container>
  );
};

export default ResultsView;
