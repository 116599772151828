// SearchResultsHeader.jsx
import React from "react";
import { Box, Typography, styled } from "@mui/material";

const HeaderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1300px",
  margin: "0 auto",
  padding: "15px 20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "24px",
  background: "#FAFAFA", 
  borderRadius: "16px",
}));

const ResultText = styled(Typography)(({ theme }) => ({
  color: "#666666",
  fontSize: "18px",
  flex: 1,
  paddingLeft: "16px",
  "& .highlight": {
    color: "#2D2D2D",
    fontWeight: 600,
  },
}));

const ButtonWrapper = styled(Box)({
  flex: 1,
  paddingLeft: "16px", 
  minWidth: "200px", 
});

const SearchResultsHeader = ({
  jobs,
  searchValues,
  hasResults,
  children,
  profileSelector,
}) => {
  const { jobTitle, location } = searchValues;

  return (
    <HeaderContainer>
      {profileSelector}
      {/* <ResultText variant="body1">
        {hasResults && (
          <>
            Showing {jobs.length} results for{" "}
            <span className="highlight">{jobTitle}</span>
            {location && (
              <>
                {" "}
                in <span className="highlight">{location}</span>
              </>
            )}
          </>
        )}
      </ResultText> */}
      <ButtonWrapper>{children}</ButtonWrapper>
    </HeaderContainer>
  );
};

export default SearchResultsHeader;
