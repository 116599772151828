import React from "react";
import { Box, Typography, Button, styled } from "@mui/material";
import { PieChart, Pie, Cell, BarChart, Bar } from "recharts";

const ResultsCard = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1000px",
  margin: "0 auto",
  padding: "10px 32px",
  backgroundColor: "#fff",
  borderRadius: "12px",
  marginBottom: "0px",
}));

const HeaderBox = styled(Box)({
  display: "flex",
  alignItems: "center", // Center vertically
  justifyContent: "space-between",
  height: "40px",
  marginBottom: "32px",
  paddingRight: "0", // Remove any extra padding
});

const HeaderText = styled(Typography)({
  fontSize: "28px",
  fontWeight: 600,
  lineHeight: "40px", // Match header height for vertical centering
  display: "flex",
  alignItems: "center",
});

const NewSearchButton = styled(Button)({
  backgroundColor: "#2D2D2D",
  color: "#fff",
  padding: "24px 24px", // Increased padding
  height: "36px",
  borderRadius: "10px",
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 600,
  minWidth: "150px",
  marginLeft: "-40px", // Bring it closer to "Application Results"
  "&:hover": {
    backgroundColor: "#404040",
  },
});

const StatsBox = styled(Box)({
  padding: "16px 20px",
  backgroundColor: "#f8f9fa",
  borderRadius: "8px",
  width: "200px",
  height: "100px",
});

const ChartContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "32px",
});

const COLORS_Bar = ["#2D2D2D", "#ff9800"];
const COLORS_Pie = ["#2D2D2D", "#E0E0E0"];

const MAX_BAR_WIDTH = 200; // Maximum width for bars

const AutomationResults = ({ results, onStartNewSearch }) => {
  const {
    status,
    processedLinks = [],
    failedLinks = [],
    totalLinks = 0,
  } = results || {};

  // Calculate success rate
  const successRate =
    totalLinks > 0 ? Math.round((processedLinks.length / totalLinks) * 100) : 0;

  // Handle empty state
  if (!results || totalLinks === 0) {
    return (
      <ResultsCard>
        <Box sx={{ textAlign: "center", py: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 500 }}>
            No Applications Yet
          </Typography>
          <Typography color="text.secondary" sx={{ mb: 3 }}>
            There are no job applications to show at the moment.
          </Typography>
          <NewSearchButton onClick={onStartNewSearch}>
            Start New Search
          </NewSearchButton>
        </Box>
      </ResultsCard>
    );
  }

  // Calculate bar widths
  const getBarWidth = (value) => {
    if (value === 0) return 30; // Minimum width for zero values
    return Math.min(MAX_BAR_WIDTH, (value / totalLinks) * MAX_BAR_WIDTH);
  };

  // Data for charts
  const pieData = (() => {
    if (processedLinks.length === 0) {
      // No progress - show empty circle
      return [{ name: "Total", value: totalLinks }];
    } else if (processedLinks.length === totalLinks) {
      // All completed - show full circle
      return [{ name: "Completed", value: totalLinks }];
    } else {
      // Partial completion - show progress
      return [
        { name: "Successful", value: processedLinks.length },
        { name: "Total", value: totalLinks - processedLinks.length },
      ];
    }
  })();

  const barData = [
    {
      name: "Successful",
      value: processedLinks.length,
      width: getBarWidth(processedLinks.length),
    },
    {
      name: "Failed",
      value: failedLinks.length,
      width: getBarWidth(failedLinks.length),
    },
  ];

  return (
    <ResultsCard>
      <HeaderBox>
        <HeaderText>Application Results</HeaderText>

        <NewSearchButton onClick={onStartNewSearch}>
          Start New Search
        </NewSearchButton>
      </HeaderBox>

      <Box sx={{ display: "flex", gap: "24px" }}>
        <StatsBox>
          <Typography color="text.secondary" fontSize="16px" fontWeight="600">
            Status
          </Typography>
          <Typography sx={{ fontSize: "18px", fontWeight: 600, mt: 1 }}>
            {status}
          </Typography>
        </StatsBox>

        <StatsBox>
          <Typography color="text.secondary" fontSize="16px" fontWeight="600">
            Total Jobs Applied
          </Typography>
          <Typography sx={{ fontSize: "18px", fontWeight: 600, mt: 1 }}>
            {processedLinks.length}/{totalLinks}
          </Typography>
        </StatsBox>
      </Box>

      <ChartContainer>
        {/* Donut Chart */}
        <Box sx={{ position: "relative", width: "240px", height: "240px" }}>
          <PieChart width={240} height={240}>
            <Pie
              data={pieData}
              cx={120}
              cy={120}
              innerRadius={60}
              outerRadius={80}
              startAngle={90}
              endAngle={-270}
              dataKey="value"
            >
              {pieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    processedLinks.length === 0
                      ? "#E0E0E0"
                      : processedLinks.length === totalLinks
                      ? "#2D2D2D"
                      : COLORS_Pie[index]
                  }
                />
              ))}
            </Pie>
          </PieChart>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "52%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
              {successRate}%
            </Typography>
            <Typography color="text.secondary" fontSize="14px">
              Success Rate
            </Typography>
          </Box>
        </Box>

        {/* Bar Chart */}
        <Box sx={{ width: "50%" }}>
          <Box sx={{ mb: 2 }}>
            {barData.map((item, index) => (
              <Box key={item.name} sx={{ mb: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
                  <Box
                    sx={{
                      width: item.width,
                      height: "30px",
                      backgroundColor: COLORS_Bar[index],
                      borderRadius: "4px",
                      transition: "width 0.3s ease",
                    }}
                  />
                  <Typography sx={{ ml: 2 }}>
                    {item.name} ({item.value})
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </ChartContainer>
    </ResultsCard>
  );
};

export default AutomationResults;
