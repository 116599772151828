// ShareJourneyForm/steps/Metrics.jsx
import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import StyledTextField from "../components/StyledTextField";

const Metrics = ({ formData, updateFormData }) => {
  const handleChange = (field) => (event) => {
    // Only allow numbers
    const value = event.target.value.replace(/[^0-9]/g, "");
    updateFormData("metrics", {
      ...formData.metrics,
      [field]: value,
    });
  };

  return (
    <Box>
      <Box mb={4}>
        <Typography
          variant="h6"
          sx={{ fontWeight: 600, color: "#2d2d2d", mb: 3 }}
        >
          Application Metrics
        </Typography>

        <Grid container spacing={3}>
          {/* Total Applications */}
          <Grid item xs={12} sm={4}>
            <StyledTextField
              fullWidth
              label="Total Applications"
              value={formData.metrics.applications}
              onChange={handleChange("applications")}
              placeholder="e.g., 45"
              type="text"
              inputProps={{ inputMode: "numeric" }}
            />
          </Grid>

          {/* Response Rate */}
          <Grid item xs={12} sm={4}>
            <StyledTextField
              fullWidth
              label="Response Rate (%)"
              value={formData.metrics.responseRate}
              onChange={handleChange("responseRate")}
              placeholder="e.g., 35"
              type="text"
              inputProps={{
                inputMode: "numeric",
                max: 100,
              }}
            />
          </Grid>

          {/* Number of Offers */}
          <Grid item xs={12} sm={4}>
            <StyledTextField
              fullWidth
              label="Number of Offers"
              value={formData.metrics.offers}
              onChange={handleChange("offers")}
              placeholder="e.g., 3"
              type="text"
              inputProps={{ inputMode: "numeric" }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Metrics;
