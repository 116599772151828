// src/components/analytics/tables/ApplicationsTable.js
import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  InputAdornment,
  Chip,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,
  Grid,
  Link,
} from "@mui/material";
import {
  ContentCopy as ContentCopyIcon,
  OpenInNew as OpenInNewIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { mockApiCall } from "../services/analyticsApi";
import axios from "axios";
import { debounce } from "lodash";
import { useStateStore } from "../../../../store";

const ApplicationsTable = () => {
  const {
    token,
    userData,
    planDetails,
    goalData,
    enterpriseUser,
    navClickCount,
  } = useStateStore();
  const { viewType } = enterpriseUser;
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // useEffect(() => {
  //   fetchApplications();
  // }, [page, rowsPerPage, searchTerm]);

  const debouncedSearch = useCallback(
    debounce(async (searchValue) => {
      try {
        if (!planDetails?.planStarted) {
          const cachedData = localStorage.getItem("userAnalytics");

          if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            setApplications(parsedData.data || []);
            setTotalCount(parsedData.totalCount || 0);
            return;
          }

          try {
            const { data } = await axios.post(
              "https://backend.lazyapply.com/lazyapplyV2/analytics/user-specific",
              { ...goalData },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            localStorage.setItem(
              "userAnalytics",
              JSON.stringify({
                data: data?.data,
                totalCount: data?.totalCount,
              })
            );

            setApplications(data?.data || []);
            setTotalCount(data?.totalCount || 0);
          } catch (error) {
            console.error("Error fetching analytics:", error);
            const cachedData = localStorage.getItem("userAnalytics");
            if (cachedData) {
              const parsedData = JSON.parse(cachedData);
              setApplications(parsedData.data || []);
              setTotalCount(parsedData.totalCount || 0);
            }
          }
        } else {
          const v2Id = userData?.v2Id || "";
          const { data } = await axios.get(
            `https://backend.lazyapply.com/lazyapplyV2/applications/${v2Id}?page=${
              page + 1
            }&limit=${rowsPerPage}&search=${searchValue}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setApplications(data?.data || []);
          setTotalCount(data?.totalCount || 0);
        }
      } catch (error) {
        console.error("Error fetching applications:", error);
      } finally {
        setLoading(false);
      }
    }, 300),
    [page, rowsPerPage, planDetails, goalData, token, userData?.v2Id]
  );

  useEffect(() => {
    if (searchTerm === "" || searchTerm.length >= 3) {
      setLoading(true);
      debouncedSearch(searchTerm);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm, debouncedSearch, viewType, navClickCount]);

  // const fetchApplications = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await mockApiCall("/api/analytics/applications", {
  //       page: page + 1,
  //       limit: rowsPerPage,
  //       search: searchTerm,
  //     });
  //     setApplications(response.data);
  //     setTotalCount(response.totalCount);
  //   } catch (error) {
  //     console.error("Error fetching applications:", error);
  //   }
  //   setLoading(false);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    // Only search if value length is more than 3 characters
    if (value.length === 0 || value.length >= 3) {
      setPage(0);
    }
  };

  const getStatusChipProps = (status) => {
    switch (status) {
      case "completed":
      case "send_success":
        return {
          label: "Success",
          color: "success",
          sx: {
            backgroundColor: "#E7F7EE",
            color: "#1B874B",
            ".MuiChip-label": { fontWeight: 500, letterSpacing: "0.5px" },
          },
        };
      case "failed":
      case "profiles_not_found":
      case "company_not_found":
        return {
          label: "Failed",
          color: "error",
          sx: {
            backgroundColor: "#FEE4E2",
            color: "#D92D20",
            ".MuiChip-label": { fontWeight: 500, letterSpacing: "0.5px" },
          },
        };

      case "coming_soon":
        return {
          label: "Not Enabled",
          sx: {
            backgroundColor: "#666666",
            color: "#fff",
            ".MuiChip-label": {
              fontWeight: 500,
              letterSpacing: "0.5px",
            },
          },
        };

      default:
        return {
          label: "In Progress",
          color: "warning",
          sx: {
            backgroundColor: "#FEF4E6",
            color: "#DC6803",
            ".MuiChip-label": { fontWeight: 500, letterSpacing: "0.5px" },
          },
        };
    }
  };

  const handleActionsClick = (event, row) => {
    event.stopPropagation();
    console.log("Selected Row:", row); // Add this
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCopyToClipboard = (text, message) => {
    navigator.clipboard.writeText(text);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
    handleMenuClose();
  };

  const handleOpenLink = (url) => {
    window.open(url, "_blank");
    handleMenuClose();
  };

  const handleViewDetails = () => {
    setDetailsOpen(true);
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setDetailsOpen(false);
    setSelectedRow(null); // Only clear selectedRow when dialog closes
  };

  const DetailsDialog = () => (
    <Dialog
      open={detailsOpen}
      onClose={handleCloseDialog}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#F8F8F8",
          borderBottom: "1px solid #E0E0E0",
          padding: "20px 24px",
          fontSize: "18px",
          fontWeight: 600,
        }}
      >
        Application Details
      </DialogTitle>

      {console.log(selectedRow, "hello")}

      <DialogContent sx={{ p: 3 }}>
        {selectedRow && (
          <Box sx={{ pt: 1 }}>
            <Box sx={{ mb: 3 }}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Job Title
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {selectedRow.jobTitle}
              </Typography>
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Company
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {selectedRow.companyName}
              </Typography>
            </Box>

            {/* <Box sx={{ mb: 3 }}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                ATS
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {selectedRow.atsName}
              </Typography>
            </Box> */}

            <Box sx={{ mb: 3 }}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Application Status
              </Typography>
              <Box sx={{ mt: 1 }}>
                <Chip {...getStatusChipProps(selectedRow.applicationStatus)} />
              </Box>
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Email Status
              </Typography>
              <Box sx={{ mt: 1 }}>
                <Chip
                  {...getStatusChipProps(
                    selectedRow.emailStatus || "coming_soon"
                  )}
                />
              </Box>
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Date Applied
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {new Date(selectedRow.date).toLocaleString()}
              </Typography>
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Resume Used
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {selectedRow.resumeName}
              </Typography>
            </Box>
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ borderTop: "1px solid #E0E0E0", p: 2 }}>
        <Button
          onClick={handleCloseDialog}
          sx={{
            backgroundColor: "#2D2D2D",
            borderRadius: "8px",
            padding: "8px 16px",
            color: "white",
            px: 3,
            "&:hover": {
              backgroundColor: "#2D2D2D",
              opacity: 0.9,
            },
            textTransform: "none",
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const tableContent = loading ? (
    <TableRow>
      <TableCell
        colSpan={7}
        align="center"
        sx={{ py: 3, border: "none", height: "200px" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography color="textSecondary">Loading...</Typography>
        </Box>
      </TableCell>
    </TableRow>
  ) : applications.length === 0 ? (
    <TableRow>
      <TableCell
        colSpan={7}
        align="center"
        sx={{ py: 3, border: "none", height: "200px" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography color="textSecondary">No applications found</Typography>
        </Box>
      </TableCell>
    </TableRow>
  ) : (
    applications.map((row) => (
      <TableRow
        key={row.jobId}
        hover
        sx={{
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "#F8F8F8",
          },
        }}
      >
        <TableCell>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, color: "#2D2D2D" }}
          >
            {row.jobTitle}
          </Typography>
        </TableCell>
        <TableCell>{row.companyName}</TableCell>

        {/* <TableCell>{row.atsName}</TableCell> */}

        <TableCell>
          <Chip size="small" {...getStatusChipProps(row.applicationStatus)} />
        </TableCell>
        <TableCell>
          <Chip
            size="small"
            {...getStatusChipProps(row.emailStatus || "coming_soon")}
          />
        </TableCell>
        <TableCell>{new Date(row.date).toLocaleDateString()}</TableCell>
        <TableCell align="right">
          <IconButton
            size="small"
            onClick={(event) => handleActionsClick(event, row)}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ))
  );

  return (
    <Box sx={{ width: "100%" }}>
      {/* Search Bar */}
      <Box sx={{ p: 3, pb: 0 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by job title or company..."
          value={searchTerm}
          onChange={handleSearchChange}
          helperText={
            searchTerm.length > 0 && searchTerm.length < 3
              ? "Enter at least 3 characters to search"
              : " "
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#666" }} />
              </InputAdornment>
            ),
            sx: {
              backgroundColor: "#F8F8F8",
              "& fieldset": {
                border: "none",
              },
              borderRadius: "8px",
            },
          }}
          FormHelperTextProps={{
            sx: {
              margin: 0,
              marginTop: "4px",
              color: "#666666",
            },
          }}
        />
      </Box>

      {/* Table */}
      <TableContainer sx={{ mt: 3 }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 600, color: "#2D2D2D" }}>
                Job Title
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: "#2D2D2D" }}>
                Company
              </TableCell>

              {/* <TableCell sx={{ fontWeight: 600, color: "#2D2D2D" }}>
                ATS
              </TableCell> */}

              <TableCell sx={{ fontWeight: 600, color: "#2D2D2D" }}>
                Application Status
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: "#2D2D2D" }}>
                Referral Email Status
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: "#2D2D2D" }}>
                Date
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: 600, color: "#2D2D2D" }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableContent}</TableBody>
        </Table>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handleViewDetails}>
            <InfoIcon fontSize="small" sx={{ mr: 1 }} />
            View Details
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleCopyToClipboard(
                selectedRow?.jobLink,
                "Job link copied to clipboard!"
              )
            }
          >
            <ContentCopyIcon fontSize="small" sx={{ mr: 1 }} />
            Copy Job Link
          </MenuItem>
          <MenuItem onClick={() => handleOpenLink(selectedRow?.jobLink)}>
            <OpenInNewIcon fontSize="small" sx={{ mr: 1 }} />
            Open Job Link
          </MenuItem>

          {/* <MenuItem
            onClick={() =>
              handleCopyToClipboard(
                selectedRow?.atsLink,
                "ATS link copied to clipboard!"
              )
            }
          >
            <ContentCopyIcon fontSize="small" sx={{ mr: 1 }} />
            Copy ATS Link
          </MenuItem>
          <MenuItem onClick={() => handleOpenLink(selectedRow?.atsLink)}>
            <OpenInNewIcon fontSize="small" sx={{ mr: 1 }} />
            Open ATS Link
          </MenuItem> */}
        </Menu>

        {/* Details Dialog */}
        <DetailsDialog />

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        />
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          borderTop: "1px solid #E0E0E0",
          ".MuiTablePagination-select": {
            borderRadius: "4px",
            backgroundColor: "#F8F8F8",
          },
          ".MuiTablePagination-displayedRows": {
            margin: 0,
          },
          ".MuiTablePagination-actions": {
            marginLeft: 2,
            marginRight: 0,
          },
          ".MuiTablePagination-selectLabel": {
            margin: 0,
          },
        }}
      />
    </Box>
  );
};

export default ApplicationsTable;
