// JobCard.jsx
import React from "react";
import { Card, Box, Typography, Checkbox, styled } from "@mui/material";
import WorkIcon from "@mui/icons-material/Work"; // for fallback logo

const StyledCard = styled(Card)(({ theme, isSelected }) => ({
  height: "100%",
  position: "relative",
  cursor: "pointer",
  transition: "all 0.3s ease",
  border: "1px solid #E0E0E0",
  boxShadow: isSelected
    ? "0 2px 8px rgba(0,0,0,0.1)"
    : "0 1px 4px rgba(0,0,0,0.05)",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  },
}));

const UpperSection = styled(Box)(({ isSelected }) => ({
  padding: "20px",
  backgroundColor: isSelected ? "#F0F2F5" : "#F8F9FA",
  position: "relative",
}));

const ContentSection = styled(Box)(({ isSelected }) => ({
  padding: "20px",
  backgroundColor: isSelected ? "#FAFBFC" : "#fff",
}));

const LogoContainer = styled(Box)({
  width: "48px",
  height: "48px",
  borderRadius: "8px",
  overflow: "hidden",
  backgroundColor: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
  marginBottom: "12px",
});

const Logo = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "contain",
});

const FallbackLogo = styled(WorkIcon)({
  width: "28px",
  height: "28px",
  color: "#666666",
});

const JobTitle = styled(Typography)({
  fontSize: "16px",
  fontWeight: 800,
  color: "#2D2D2D",
  marginBottom: "8px",
  lineHeight: 1.3,
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const CompanyInfo = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "4px",
});

const CompanyName = styled(Typography)({
  fontSize: "14px",
  color: "#666666",
  fontWeight: 700,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

const JobBoard = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  color: "#666666",
});

const Description = styled(Typography)({
  fontSize: "14px",
  color: "#666666",
  lineHeight: 1.5,
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const CheckboxWrapper = styled(Box)({
  position: "absolute",
  top: "12px",
  right: "12px",
  zIndex: 1,
});

const JobCard = ({ job, isSelected, onSelect }) => {
  const handleCardClick = (e) => {
    // If clicking checkbox, don't open link
    if (e.target.closest(".checkbox-area")) {
      return;
    }
    window.open(job.link, "_blank");
  };

  const handleCheckboxClick = (e) => {
    e.stopPropagation();
    onSelect(job.link);
  };

  // Default company name if not provided
  const companyName = job.companyName || "Company Name";

  return (
    <StyledCard isSelected={isSelected} onClick={handleCardClick} elevation={0}>
      <UpperSection isSelected={isSelected}>
        <CheckboxWrapper className="checkbox-area">
          <Checkbox
            checked={isSelected}
            onChange={handleCheckboxClick}
            color="primary"
          />
        </CheckboxWrapper>

        <LogoContainer>
          {job.logo ? (
            <Logo
              src={job.logo}
              alt={`${companyName} logo`}
              onError={(e) => {
                e.target.style.display = "none";
                e.target.nextSibling.style.display = "block";
              }}
            />
          ) : (
            <FallbackLogo />
          )}
        </LogoContainer>

        <JobTitle>{job.title || "No Job Title"}</JobTitle>

        <CompanyInfo>
          <CompanyName>{companyName}</CompanyName>
          <JobBoard>{job.jobBoard}</JobBoard>
        </CompanyInfo>
      </UpperSection>

      <ContentSection isSelected={isSelected}>
        <Description>{job.snippet || "No description available"}</Description>
      </ContentSection>
    </StyledCard>
  );
};

export default JobCard;
