const countryOptions = [
  {
    label: "United States",
    value: "us",
  },
  {
    label: "Canada",
    value: "ca",
  },
];

export const careerBuilderFilters = {
  Country: {
    value: "country",
    options: countryOptions,
  },
  "Onsite/Remote": {
    value: "cb_workhome",
    options: [
      {
        label: "All",
        value: "all",
      },
      {
        label: "Onsite",
        value: "onsite",
      },
      {
        label: "Remote",
        value: "remote",
      },
      {
        label: "Hybrid",
        value: "hybrid",
      },
    ],
  },
  "Job Type": {
    value: "emp",
    options: [
      {
        label: "All",
        value: "all",
      },
      {
        label: "Full-Time",
        value: "jtft%2Cjtfp",
      },
      {
        label: "Part-Time",
        value: "jtpt%2Cjtfp",
      },
      {
        label: "Contractor",
        value: "jtct%2Cjtc2%2Cjtcc",
      },
      {
        label: "Contract to Hire",
        value: "jtch",
      },
      {
        label: "Intern",
        value: "jtin%2Cjtap",
      },
      {
        label: "Seasonal / Temp",
        value: "jtse%2Cjttf%2Cjttp",
      },
      {
        label: "Gig-Work",
        value: "jtfl",
      },
    ],
  },
  "Supports Veterans": {
    value: "cb_veterans",
    options: [
      {
        label: "true",
        value: "true",
      },
      {
        label: "false",
        value: "false",
      },
    ],
  },
  Distance: {
    value: "radius",
    options: [
      {
        label: "5 miles",
        value: "5",
      },
      {
        label: "10 miles",
        value: "10",
      },
      {
        label: "30 miles",
        value: "30",
      },
      {
        label: "50 miles",
        value: "50",
      },
    ],
  },
  "Date Posted": {
    value: "posted",
    options: [
      {
        label: "24 hours",
        value: "1",
      },
      {
        label: "3 days",
        value: "3",
      },
      {
        label: "7 days",
        value: "7",
      },
      {
        label: "30 days",
        value: "30",
      },
      {
        label: "30+ days",
        value: "45",
      },
    ],
  },
};
