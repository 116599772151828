export const countryUrlMapGlassdoor = {
  us: "https://www.glassdoor.com/Job/",
  uk: "https://www.glassdoor.co.uk/Job/",
  ca: "https://www.glassdoor.ca/Job/",
  au: "https://www.glassdoor.com.au/Job/",
  in: "https://www.glassdoor.co.in/Job/",
  sg: "https://www.glassdoor.sg/Job/",
  nz: "https://www.glassdoor.co.nz/Job/",
  ie: "https://www.glassdoor.ie/Job/",
};

export const countryUrlMapSimplyHired = {
  us: "https://www.simplyhired.com/jobs",
  uk: "https://www.simplyhired.co.uk/search",
  ca: "https://www.simplyhired.ca/jobs",
};
